import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { noop } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../constants/globalConstants";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type { ColumnDefContext } from "../../views/pages/spectrumForm/formBuilder/_internal/content/table/TableColumnHeader";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldNumber } from "../formFields/controlled/regrelloControlledFormFields";
import { RegrelloFormFieldText } from "../formFields/RegrelloFormFieldText";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { FrontendValueConstraintRuleName, getValueConstraintDisplayName } from "./utils/spectrumFieldConstraintUtils";

type TextFieldPluginFrontendValue = string;

export class SpectrumTextFieldPluginDecorator extends SpectrumFieldPluginDecorator<TextFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<TextFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.text";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.RAW;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find((validationType) => validationType.validationType === SpectrumFieldValidationType.RAW);
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.MAX_CHARACTER ||
        valueConstraintRule === FrontendValueConstraintRuleName.MIN_CHARACTER,
    );
  }

  public isDataFormatToggleVisible() {
    return true;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_CONTAINS]: {
        label: t`Contains`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_NOT_CONTAINS]: {
        label: t`Does not contain`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_STARTS_WITH]: {
        label: t`Starts with`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_ENDS_WITH]: {
        label: t`Ends with`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloFormFieldText disabled={true} onChange={noop} size="medium" value="" />;
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    const { constraints, disabled, form } = props;
    const getFieldKey = (index: number) => `valueConstraints.${index}.args.0` as const;

    const minCharacterConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MIN_CHARACTER,
    );
    const maxCharacterConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_CHARACTER,
    );
    if (minCharacterConstraintIndex < 0 || maxCharacterConstraintIndex < 0) {
      return null;
    }
    const minCharacterConstraintArg = form.getValues(`valueConstraints.${minCharacterConstraintIndex}.args.0`);
    const maxCharacterConstraintArg = form.getValues(`valueConstraints.${maxCharacterConstraintIndex}.args.0`);

    const typedMinCharacterConstraintArg = minCharacterConstraintArg
      ? Number.parseInt(minCharacterConstraintArg, 10)
      : Number.MIN_SAFE_INTEGER;
    const typedMaxCharacterConstraintArg = maxCharacterConstraintArg
      ? Number.parseInt(maxCharacterConstraintArg, 10)
      : Number.MAX_SAFE_INTEGER;

    return (
      <>
        {constraints.map((row, constraintIndex) => {
          const fieldKey = getFieldKey(constraintIndex);
          return (
            <RegrelloControlledFormFieldNumber
              key={row.constraint.uuid}
              className="w-60"
              controllerProps={{
                control: form.control,
                name: fieldKey,
                rules:
                  constraintIndex === minCharacterConstraintIndex && maxCharacterConstraintArg != null
                    ? {
                        ...ValidationRules.INTEGER,
                        ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(typedMaxCharacterConstraintArg),
                        ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                      }
                    : constraintIndex === maxCharacterConstraintIndex && minCharacterConstraintArg != null
                      ? {
                          ...ValidationRules.INTEGER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(typedMinCharacterConstraintArg),
                        }
                      : {
                          ...ValidationRules.INTEGER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                        },
              }}
              dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_CONSTRAINT}
              disabled={disabled}
              label={getValueConstraintDisplayName(row.constraint)}
            />
          );
        })}
      </>
    );
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, TextFieldPluginFrontendValue>, "onCellValueChanged">,
  ) {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    const context: ColumnDefContext = {
      type: "formField",
      formField,
    };
    return {
      ...colDefParams,
      field: getColumnIdFromFormField(formField),
      colId: getColumnIdFromFormField(formField),
      headerName: spectrumFieldVersion.name,
      context,
      cellClass: "regrello-center-aligned-cell",
    };
  }
}
