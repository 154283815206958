/* eslint-disable class-methods-use-this */
import type { ColDef } from "@ag-grid-community/core";
import { EMPTY_STRING } from "@regrello/core-utils";
import type {
  ConditionOperator,
  CreateFieldInstanceValueInputs,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldSpectrumFields,
  FieldType,
  FieldUnitFields,
  FormConstraintConditionOperator,
  FormFieldFields,
  Maybe,
  PropertyDataType,
  PropertyTypeFields,
  RegrelloObjectPropertyFields,
  SpectrumFieldValidationTypeFields,
  SpectrumFieldVersionFields,
  SpectrumValueConstraintFields,
  ViewFilterFields,
} from "@regrello/graphql-api";
import type { RegrelloIconProps } from "@regrello/ui-core";
import type { FieldArrayWithId, FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import type { FieldInstanceBaseFields } from "../../../../types";
import { consoleWarnInDevelopmentModeOnly } from "../../../../utils/environmentUtils";
import type { PartyTypeUnion } from "../../../../utils/parties/PartyTypeUnion";
import type { ConfigureSpectrumFieldFormFormFields } from "../../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type { RowDatum } from "../../../views/pages/table/tableV2Utils";
import type {
  CustomFieldPlugin,
  CustomFieldPluginV2RenderFormFieldProps,
  GetCreateFieldInstanceValueInputsFromFormValueParams,
  ValidateBulkUploadCellValueOptions,
  WithOwnerData,
} from "../../customFields/plugins/types/CustomFieldPlugin";
import type { ValidationResult } from "../../customFields/plugins/utils/validateBulkUploadCellValueUtils";
import { getActiveRuleAsBoolean } from "../utils/spectrumFieldConstraintUtils";

export type SpectrumFormUpdateMode = "onBlur" | "onChange";

export abstract class SpectrumFieldPluginDecorator<TFrontendValue> implements CustomFieldPlugin<TFrontendValue> {
  public isCreateAndEditAllowed: boolean;

  public uri: string;

  public version: string;

  public renderSpectrumFormField:
    | (<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
        field: FieldFields,
        props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
      ) => React.ReactNode)
    | undefined;

  private customFieldPlugin: CustomFieldPlugin<TFrontendValue>;

  constructor(plugin: CustomFieldPlugin<TFrontendValue>) {
    this.customFieldPlugin = plugin;
    this.uri = plugin.uri;
    this.version = plugin.version;
    this.isCreateAndEditAllowed = plugin.isCreateAndEditAllowed;
  }

  public getSourceFieldInstanceInputType(fieldInstance: FieldInstanceFields | FieldInstanceBaseFields) {
    return this.customFieldPlugin.getSourceFieldInstanceInputType(fieldInstance);
  }

  public canProcessPropertyDataType(propertyDataType: PropertyDataType) {
    return this.customFieldPlugin.canProcessPropertyDataType(propertyDataType);
  }

  public canProcessField(field: FieldFields) {
    return this.customFieldPlugin.canProcessField(field);
  }

  public canProcessFieldInstance(fieldInstance: FieldInstanceBaseFields) {
    // (clewis): It's particularly important to use canProcessSpectrumField to distinguish fields that
    // share a legacy property type.
    return fieldInstance.spectrumFieldVersion != null
      ? this.canProcessSpectrumField(fieldInstance.spectrumFieldVersion)
      : this.customFieldPlugin.canProcessFieldInstance(fieldInstance);
  }

  public findPropertyTypeIdFromLoadedPropertyTypeIds(loadedPropertyTypes: PropertyTypeFields[]) {
    return this.customFieldPlugin.findPropertyTypeIdFromLoadedPropertyTypeIds(loadedPropertyTypes);
  }

  public getCreateFieldInstanceValueInputsFromFormValue(inputs: GetCreateFieldInstanceValueInputsFromFormValueParams) {
    return this.customFieldPlugin.getCreateFieldInstanceValueInputsFromFormValue(inputs);
  }

  public getColumnsForTable(field: FieldSpectrumFields) {
    return this.customFieldPlugin.getColumnsForTable(field);
  }

  public getCrossWorkflowSinksFieldInstanceIds(fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) {
    return this.customFieldPlugin.getCrossWorkflowSinksFieldInstanceIds(fieldInstance);
  }

  public getCrossWorkflowSourceFieldInstanceIdFromValue(
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ) {
    return this.customFieldPlugin.getCrossWorkflowSourceFieldInstanceIdFromValue(fieldInstance);
  }

  public getFieldDisplayName() {
    return this.customFieldPlugin.getFieldDisplayName();
  }

  public getEmptyValueForFrontend(options?: { operator: ConditionOperator }, defaultValue?: string) {
    return this.customFieldPlugin.getEmptyValueForFrontend(options, defaultValue);
  }

  public getFieldProperties(field: FieldFields) {
    return this.customFieldPlugin.getFieldProperties?.(field);
  }

  public getFilterDefinition(field: FieldFields) {
    return this.customFieldPlugin.getFilterDefinition(field);
  }

  public getFilterDefinitionWithValues(field: FieldFields, filter: ViewFilterFields, parties: PartyTypeUnion[]) {
    return this.customFieldPlugin.getFilterDefinitionWithValues(field, filter, parties);
  }

  public getHelperText() {
    if (this.customFieldPlugin.getHelperText == null) {
      consoleWarnInDevelopmentModeOnly("getHelperText is not undefined by CustomFieldPlugin");
    }
    return this.customFieldPlugin.getHelperText?.() ?? EMPTY_STRING;
  }

  public getIconName(fieldType?: FieldType, field?: FieldFields) {
    return this.customFieldPlugin.getIconName(fieldType, field);
  }

  public getNameTemplateDisplayValueFromFormValue(
    value: unknown,
    options?: { fieldUnit?: FieldUnitFields; regrelloObjectProperty?: RegrelloObjectPropertyFields },
  ) {
    return this.customFieldPlugin.getNameTemplateDisplayValueFromFormValue(value, options);
  }

  public getPreferredHomeTableColumnWidth() {
    if (this.customFieldPlugin.getPreferredHomeTableColumnWidth == null) {
      consoleWarnInDevelopmentModeOnly("getPreferredHomeTableColumnWidth is not undefined by CustomFieldPlugin");
    }
    return this.customFieldPlugin.getPreferredHomeTableColumnWidth?.() ?? 100;
  }

  public getPreferredMultilinePoColumnWidth() {
    if (this.customFieldPlugin.getPreferredMultilinePoColumnWidth == null) {
      consoleWarnInDevelopmentModeOnly("getPreferredHomeTableColumnWidth is not undefined by CustomFieldPlugin");
    }
    return this.customFieldPlugin.getPreferredMultilinePoColumnWidth?.() ?? 100;
  }

  public getSourceFieldInstance(fieldInstance: FieldInstanceFields) {
    return this.customFieldPlugin.getSourceFieldInstance(fieldInstance);
  }

  public getSourceFieldInstanceId(fieldInstance: FieldInstanceFields) {
    return this.customFieldPlugin.getSourceFieldInstanceId(fieldInstance);
  }

  public getConditionOperators() {
    return this.customFieldPlugin.getConditionOperators();
  }

  public getUpdateStartingConditionsInputsFromFormValues(
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
    leftFieldInstancePropertyId?: Maybe<number>,
  ) {
    return this.customFieldPlugin.getUpdateStartingConditionsInputsFromFormValues(
      leftFieldInstance,
      value,
      operator,
      leftFieldInstancePropertyId,
    );
  }

  public getUpdateFieldInstanceValueInputsFromFieldInstance(
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ) {
    return this.customFieldPlugin.getUpdateFieldInstanceValueInputsFromFieldInstance(fieldInstance);
  }

  public getValueForFrontend(fieldInstance: FieldInstanceFields | FieldInstanceBaseFields) {
    return this.customFieldPlugin.getValueForFrontend(fieldInstance);
  }

  public getTabularValueForFrontend(fieldInstance: FieldInstanceFields | FieldInstanceBaseFields) {
    return this.customFieldPlugin.getTabularValueForFrontend(fieldInstance);
  }

  public hasAllowedValues() {
    return this.customFieldPlugin.hasAllowedValues?.() ?? false;
  }

  public hasFieldUnit() {
    return this.customFieldPlugin.hasFieldUnit?.() ?? false;
  }

  public isFeatureFlagEnabled() {
    return this.customFieldPlugin.isFeatureFlagEnabled();
  }

  public isFieldInstanceEmpty(
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    options?: { includeInactiveOptions: boolean },
  ) {
    return this.customFieldPlugin.isFieldInstanceEmpty(fieldInstance, options);
  }

  public isFieldInstanceValueUnchanged(
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ) {
    return this.customFieldPlugin.isFieldInstanceValueUnchanged(fieldInstance, proposedChange);
  }

  public isFormFieldValueRequired() {
    return this.customFieldPlugin.isFormFieldValueRequired?.() ?? false;
  }

  public isSpectrumFormFieldValueRequired(fieldInstance: FieldInstanceFields) {
    return getActiveRuleAsBoolean(fieldInstance.spectrumMetadata ?? undefined).required;
  }

  public isMultiValued() {
    return this.customFieldPlugin.isMultiValued?.() ?? false;
  }

  public isNeedsFieldUnit() {
    return this.customFieldPlugin.isNeedsFieldUnit?.() ?? false;
  }

  public renderDisplayValue(
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
    options?: {
      context?: "default" | "table" | "tableV2" | "inlineWrappable" | "inlineTruncate" | "dataTab";
      regrelloObjectProperty?: Maybe<RegrelloObjectPropertyFields>;
      maxLines?: -1 | 1 | 2 | 3 | 4 | 5;
    },
  ) {
    return this.customFieldPlugin.renderDisplayValue(fieldInstance, options);
  }

  public renderFormField<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    fieldFields: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
    options?: { context?: "RegrelloActionItemViewRequestedInfo" | "RegrelloConfigureCustomFieldsFormSection" },
  ) {
    return this.customFieldPlugin.renderFormField(fieldFields, props, options);
  }

  /**
   * Returns the icon for this field type.
   */
  public renderIcon(props?: Omit<RegrelloIconProps, "iconName"> & { fieldType?: FieldType; field?: FieldFields }) {
    return this.customFieldPlugin.renderIcon(props);
  }

  public renderMultipleDisplayValuesForDataGrid(
    fieldInstances:
      | Array<FieldInstanceBaseFields & WithOwnerData>
      | Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>,
    options?: { context: "table" | "tableV2"; regrelloObjectProperty?: Maybe<RegrelloObjectPropertyFields> },
  ) {
    return this.customFieldPlugin.renderMultipleDisplayValuesForDataGrid(fieldInstances, options);
  }

  public sortComparator(
    fieldInstance1: FieldInstanceBaseFields | undefined,
    fieldInstance2: FieldInstanceBaseFields | undefined,
    direction?: "asc" | "desc",
    options?: {
      regrelloObjectProperty?: Maybe<RegrelloObjectPropertyFields>;
    },
  ) {
    return this.customFieldPlugin.sortComparator(fieldInstance1, fieldInstance2, direction, options);
  }

  public validateBulkUploadCellValue(
    field: FieldFields,
    value: string,
    options: ValidateBulkUploadCellValueOptions,
  ): ValidationResult {
    return this.customFieldPlugin.validateBulkUploadCellValue(field, value, options);
  }

  /**
   * Returns `true` if this plugin should be used to process the `SpectrumValidationType` provided.
   * Multiple plugins may be able to process the same `PropertyDataType`. If you want a funciton
   * that only returns one unique `CustomFieldPlugin`, use the `canProcessField` or
   * `canProcessFieldInstance` functions.
   */
  public abstract canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields): boolean;

  /**
   * Returns `true` if this plugin should be used to process the provided spectrum field, which will
   * have came from the backend via GraphQL, or `false` otherwise.
   *
   * ___Warning:___ Each field returned from the backend must be processable by _exactly one_
   * custom-field plugin on the frontend; otherwise, an error will be thrown by the plugin framework
   * when that field is rendered in any capacity.
   *
   * ___Therefore:___ When adding a new custom-field plugin, please check diligently that the set of
   * fields it can process is disjoint from the set of fields that any other plugin can process.
   */
  public abstract canProcessSpectrumField(field: SpectrumFieldVersionFields): boolean;

  /**
   * Given an array of all property types (loaded from the backend via GraphQL), finds and returns
   * the property type that is compatible with this type of custom field.
   *
   * This can be used to provide the appropriate `propertyType` to the backend when the user
   * creates a field.
   */
  public abstract findPropertyTypeFromLoadedPropertyTypes(
    propertyTypes: PropertyTypeFields[],
  ): PropertyTypeFields | undefined;

  /**
   * Given an array of all property types (loaded from the backend via GraphQL), finds and returns
   * the property type that is compatible with this type of custom field.
   *
   * This can be used to provide the appropriate `propertyType` to the backend when the user
   * creates a field.
   */
  public abstract findValidationTypeFromLoadedValidationTypes(
    validationTypes: SpectrumFieldValidationTypeFields[],
  ): SpectrumFieldValidationTypeFields | undefined;

  /**
   * Given an array of all property types (loaded from the backend via GraphQL), finds and returns
   * the property type that is compatible with this type of custom field.
   *
   * This can be used to provide the appropriate `propertyType` to the backend when the user
   * creates a field.
   */
  public abstract findValueConstraintsFromLoadedValueConstraints(
    valueConstraints: SpectrumValueConstraintFields[],
  ): SpectrumValueConstraintFields[];

  /**
   * Returns the configuration for when an async update should be triggered. `onBlur` is applicable
   * to most field types. "onChange" should be used instead when the field is rendered as a complex
   * component and that the `onBlur` event isn't a good indicator. For example: a checkbox, a date
   * picker or a signature.
   */
  public abstract getSpectrumFormAutosaveMode(): SpectrumFormUpdateMode;

  /**
   * Whether we should show the data format toggle to hide/show value constraints.
   * If isValueConstraintEnabled() is false it doesn't matter what this is set to, we will never show the data format toggle or the value constraints.
   * If isValueConstraintEnabled() is true, this will determine whether we show the data format toggle or just the value constraints directly.
   */
  public abstract isDataFormatToggleVisible(): boolean;

  /**
   * Whether value constraints are applicable and meaningful for the current field type.
   */
  public abstract isValueConstraintEnabled(): boolean;

  /**
   * Returns supported `FormConstraintConditionOperator` map.
   */
  public abstract getConstraintConditionOperators(
    field: SpectrumFieldVersionFields,
  ): Partial<Record<FormConstraintConditionOperator, { label: string; inputCount: number; isMultiselect: boolean }>>;

  public abstract renderPreviewFormField(spectrumField: SpectrumFieldVersionFields): React.ReactNode;

  /**
   * Renders the value constraint input form fields appropriate for the plugin type.
   */
  public abstract renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;

    /**
     * Prevent having multiple values for this field, if the field supports multiple values at all.
     *
     * - If provided, then the field can only have a single value when instantiated and will show a
     *   tooltip with this explanation text on hover of the multiplicity switch.
     * - If undefined and the field supports multiplicity, the field will be allowed to have multiple
     *   values when instantiated.
     * - Will be ignored if the field does not innately support multiple values.
     */
    suppressMultiplicityWithExplanation?: string;

    /**
     * If the frontend value of the value constraint field isn't a string, you can pass
     * in an update function to achieve the conversion
     *
     * @param index the index of the field in it's immediate parent field array
     * @param value the value to update with
     */
    updateConstraint?: (index: number, value: ConfigureSpectrumFieldFormFormFields["valueConstraints"][number]) => void;
  }): React.ReactNode;

  /**
   * Returns the column definitions of the field instance that will be used to render the tabular
   * value in AGGrid.
   */
  public abstract getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum>, "onCellValueChanged">,
  ): ColDef;
}
