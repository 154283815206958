import type * as Types from "../../../generated/graphqlBaseTypes";

import { FieldInstanceFields } from "../../fragments/generated/FieldInstanceFields.generated";
import { FieldInstanceBaseFields } from "../../fragments/generated/FieldInstanceBaseFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FieldInstanceSpectrumMetadataFields } from "../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated";
import { FieldInstanceSpectrumFields } from "../../fragments/generated/FieldInstanceSpectrumFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldInstanceValueStringFields } from "../../fragments/generated/FieldInstanceValueStringFields.generated";
import { FieldInstanceValueStringBaseFields } from "../../fragments/generated/FieldInstanceValueStringBaseFields.generated";
import { AcyclicFieldInstanceValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated";
import { FieldInstanceMultiValueStringFields } from "../../fragments/generated/FieldInstanceMultiValueStringFields.generated";
import { FieldInstanceMultiValueStringBaseFields } from "../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated";
import { AcyclicFieldInstanceMultiValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated";
import { FieldInstanceValueIntFields } from "../../fragments/generated/FieldInstanceValueIntFields.generated";
import { FieldInstanceValueIntBaseFields } from "../../fragments/generated/FieldInstanceValueIntBaseFields.generated";
import { AcyclicFieldInstanceValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated";
import { FieldInstanceValueFloatFields } from "../../fragments/generated/FieldInstanceValueFloatFields.generated";
import { FieldInstanceValueFloatBaseFields } from "../../fragments/generated/FieldInstanceValueFloatBaseFields.generated";
import { AcyclicFieldInstanceValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated";
import { FieldInstanceValueBooleanFields } from "../../fragments/generated/FieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueBooleanBaseFields } from "../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated";
import { AcyclicFieldInstanceValueBooleanFields } from "../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueTimeFields } from "../../fragments/generated/FieldInstanceValueTimeFields.generated";
import { FieldInstanceValueTimeBaseFields } from "../../fragments/generated/FieldInstanceValueTimeBaseFields.generated";
import { AcyclicFieldInstanceValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated";
import { AcyclicFieldInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceFields.generated";
import { AcyclicFieldInstanceValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceValueDateFields.generated";
import { AcyclicFieldInstanceValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValueDocumentFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { DocumentBaseFields } from "../../fragments/generated/DocumentBaseFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { AcyclicFieldInstanceMultiValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated";
import { AcyclicFieldInstanceMultiValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated";
import { AcyclicFieldInstanceMultiValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated";
import { AcyclicFieldInstanceMultiValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDateFields.generated";
import { FieldInstanceValueDateFields } from "../../fragments/generated/FieldInstanceValueDateFields.generated";
import { FieldInstanceValueDateBaseFields } from "../../fragments/generated/FieldInstanceValueDateBaseFields.generated";
import { FieldInstanceValuePartyFields } from "../../fragments/generated/FieldInstanceValuePartyFields.generated";
import { FieldInstanceMultiValuePartyFields } from "../../fragments/generated/FieldInstanceMultiValuePartyFields.generated";
import { FieldInstanceMultiValueDocumentFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { FieldInstanceMultiValueIntFields } from "../../fragments/generated/FieldInstanceMultiValueIntFields.generated";
import { FieldInstanceMultiValueIntBaseFields } from "../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated";
import { FieldInstanceMultiValueFloatFields } from "../../fragments/generated/FieldInstanceMultiValueFloatFields.generated";
import { FieldInstanceMultiValueFloatBaseFields } from "../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated";
import { FieldInstanceMultiValueTimeFields } from "../../fragments/generated/FieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceMultiValueTimeBaseFields } from "../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated";
import { FieldInstanceMultiValueDateFields } from "../../fragments/generated/FieldInstanceMultiValueDateFields.generated";
import { FieldInstanceMultiValueDateBaseFields } from "../../fragments/generated/FieldInstanceMultiValueDateBaseFields.generated";
import { FieldInstanceFieldsWithBaseValues } from "../../fragments/generated/FieldInstanceFieldsWithBaseValues.generated";
import { FieldInstanceValuePartyBaseFields } from "../../fragments/generated/FieldInstanceValuePartyBaseFields.generated";
import { FieldInstanceMultiValuePartyBaseFields } from "../../fragments/generated/FieldInstanceMultiValuePartyBaseFields.generated";
import { FieldInstanceMultiValueDocumentBaseFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentBaseFields.generated";
import { gql } from "@apollo/client";
import { FieldInstanceValueFields } from "../../fragments/generated/FieldInstanceValueFields.generated";
import { AcyclicFieldInstanceValueFields } from "../../fragments/generated/AcyclicFieldInstanceValueFields.generated";
import { FieldInstanceValueBaseFields } from "../../fragments/generated/FieldInstanceValueBaseFields.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables = Types.Exact<{
  currentWorkflowTemplateStageId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  workflowTemplateId: Types.Scalars["ID"]["input"];
  dependingOnActionItemTemplateId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  dependingOnWorkflowTemplateStageIds?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
}>;

export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery = {
  fieldInstancesAvailableAsSourceValuesInWorkflowTemplate?: {
    actionItemFieldInstances: Array<FieldInstanceFields>;
    actionItemTemplateFieldInstances: Array<FieldInstanceFields>;
    workflowFieldInstances: Array<FieldInstanceFieldsWithBaseValues>;
    workflowTemplateFieldInstances: Array<FieldInstanceFieldsWithBaseValues>;
  } | null;
};

export const FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryDocument = gql`
    query FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery($currentWorkflowTemplateStageId: ID, $workflowTemplateId: ID!, $dependingOnActionItemTemplateId: ID, $dependingOnWorkflowTemplateStageIds: [ID!]) {
  fieldInstancesAvailableAsSourceValuesInWorkflowTemplate(
    currentWorkflowTemplateStageId: $currentWorkflowTemplateStageId
    workflowTemplateId: $workflowTemplateId
    dependingOnActionItemTemplateID: $dependingOnActionItemTemplateId
    dependingOnWorkflowTemplateStageID: null
    dependingOnWorkflowTemplateStageIDs: $dependingOnWorkflowTemplateStageIds
  ) {
    actionItemFieldInstances {
      ...FieldInstanceFields
    }
    actionItemTemplateFieldInstances {
      ...FieldInstanceFields
    }
    workflowFieldInstances {
      ...FieldInstanceFieldsWithBaseValues
    }
    workflowTemplateFieldInstances {
      ...FieldInstanceFieldsWithBaseValues
    }
  }
}
    ${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValueDateFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${AcyclicFieldInstanceMultiValueDateFields}
${FieldInstanceValueDateFields}
${FieldInstanceValueDateBaseFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${FieldInstanceMultiValueDateFields}
${FieldInstanceMultiValueDateBaseFields}
${FieldInstanceFieldsWithBaseValues}
${FieldInstanceValueBaseFields}
${FieldInstanceValuePartyBaseFields}
${FieldInstanceMultiValuePartyBaseFields}
${FieldInstanceMultiValueDocumentBaseFields}`;

/**
 * __useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery__
 *
 * To run a query within a React component, call `useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery({
 *   variables: {
 *      currentWorkflowTemplateStageId: // value for 'currentWorkflowTemplateStageId'
 *      workflowTemplateId: // value for 'workflowTemplateId'
 *      dependingOnActionItemTemplateId: // value for 'dependingOnActionItemTemplateId'
 *      dependingOnWorkflowTemplateStageIds: // value for 'dependingOnWorkflowTemplateStageIds'
 *   },
 * });
 */
export function useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  > &
    (
      | { variables: FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryDocument, options);
}
export function useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryDocument, options);
}
export function useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryDocument, options);
}
export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuerySuspenseQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuerySuspenseQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryResult = Apollo.QueryResult<
  FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQuery,
  FieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryVariables
>;
