import type * as Types from "../../../generated/graphqlBaseTypes";

import type { DocumentBaseFields } from "./DocumentBaseFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueDocumentBaseFields = {
  __typename: "FieldInstanceMultiValueDocument";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  documentMultiValue: Array<DocumentBaseFields>;
  tabularMultiValueDocument: Array<{ rowNumber: number; value: Array<number> }>;
};

export const FieldInstanceMultiValueDocumentBaseFields = gql`
    fragment FieldInstanceMultiValueDocumentBaseFields on FieldInstanceMultiValueDocument {
  __typename
  id
  inputType
  documentMultiValue {
    ...DocumentBaseFields
  }
  tabularMultiValueDocument {
    rowNumber
    value
  }
}
    `;
