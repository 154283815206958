import { t } from "@lingui/core/macro";
import { clsx, EMPTY_ARRAY, setIntersection } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  type ConditionalExpressionGroupFields,
  ConditionConnective,
  type FieldInstanceFields,
  type Maybe,
} from "@regrello/graphql-api";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { type UseFormReturn, useWatch } from "react-hook-form";
import { useMount } from "react-use";

import { TimeToCompleteUnits, TimeUnit, ValidationRules } from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { CheckboxFieldPlugin } from "../../../../molecules/customFields/plugins/CheckboxFieldPlugin";
import { CurrencyFieldPlugin } from "../../../../molecules/customFields/plugins/CurrencyFieldPlugin";
import { DateFieldPlugin } from "../../../../molecules/customFields/plugins/DateFieldPlugin";
import { MultiSelectFieldPlugin } from "../../../../molecules/customFields/plugins/MultiSelectFieldPlugin";
import { NumberFieldPlugin } from "../../../../molecules/customFields/plugins/NumberFieldPlugin";
import { RegrelloObjectFieldPlugin } from "../../../../molecules/customFields/plugins/RegrelloObjectFieldPlugin";
import { SelectFieldPlugin } from "../../../../molecules/customFields/plugins/SelectFieldPlugin";
import { TextFieldPlugin } from "../../../../molecules/customFields/plugins/TextFieldPlugin";
import type { ConditionOperatorConfig } from "../../../../molecules/customFields/plugins/types/ConditionOperator";
import { UserFieldPlugin } from "../../../../molecules/customFields/plugins/UserFieldPlugin";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
  RegrelloControlledFormFieldDate,
  RegrelloControlledFormFieldMultiSelect,
  RegrelloControlledFormFieldNumber,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { SpectrumCheckboxFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumCheckboxFieldPluginDecorator";
import { SpectrumCurrencyFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumCurrencyFieldPluginDecorator";
import { SpectrumDateFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumDateFieldPluginDecorator";
import { SpectrumMultiselectFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumMultiselectFieldPluginDecorator";
import { SpectrumNumberFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumNumberFieldPluginDecorator";
import { SpectrumObjectFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumObjectFieldPluginDecorator";
import { SpectrumSelectFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumSelectFieldPluginDecorator";
import { SpectrumTextFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumTextFieldPluginDecorator";
import { SpectrumUserFieldPluginDecorator } from "../../../../molecules/spectrumFields/SpectrumUserFieldPluginDecorator";
import type { SpectrumFieldPluginDecorator } from "../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import { RegrelloConfigureConditionsFormSection } from "./RegrelloConfigureConditionsFormSection";
import { SCHEDULE_TIME_FORM_LABEL_WIDTH } from "./scheduleTimeConstants";
import {
  CleanUpConditionGroup,
  type ConditionGroup,
  getFormStartingConditions,
  makeEmptyConditionGroup,
} from "./utils";

export enum ScheduleTimeValues {
  ON_WORKFLOW_START = "onWorkflowStart",
  START_CONDITION_WORKFLOW = "startConditionWorkflow",
  DEFAULT_START = "defaultStart",
  AFTER_DEPENDENCY = "afterDependency",
  START_CONDITION_STAGE = "startConditionStage",
  ON_DATE = "onDate",
  DAYS_TO_COMPLETE = "daysToComplete",
  TIME_TO_COMPLETE = "timeToComplete",
  TIME_TO_COMPLETE_UNIT = "timeToCompleteUnit",
  FROM_CUSTOM_FIELD = "fromCustomField",
}

export enum ScheduleTimeKeys {
  SCHEDULE_TIME = "scheduleTime",
  DEPENDENCY = "dependency",
  DEPENDENCIES = "dependencies",
  DATE = "date",
  START_CONDITION_DEPENDENCY = "startConditionDependency",
  CONDITIONS = "conditions",
  DAYS_TO_COMPLETE = "daysToComplete",
  TIME_TO_COMPLETE = "timeToComplete",
  TIME_TO_COMPLETE_UNIT = "timeToCompleteUnit",
  CONTROLLER_CUSTOM_FIELD = "controllerCustomField",
}

/** A utility type for properly typecasting conditions when reading from `react-hook-form` form. */
export type FieldInstanceCondition = {
  fieldInstance: FieldInstanceFields;
  fieldInstancePropertyId?: Maybe<number>;
  operator: ConditionOperatorConfig;
  value1: unknown;
  value2: unknown;
  displayOrder: number;
};

export type ScheduleTimeOption = {
  option: ScheduleTimeValues;
  label: string;
};

/** Represents an object (e.g., stage) that something can be scheduled to start after. */
export type RegrelloScheduleStartAfterDependency = {
  id: number;
  name: string;
  dueOn?: string | null;
  actionItems?: Array<{ id: number }>;
};

/* RegrelloScheduleTimeFormSectionBase is a common form dialog for configuring starting conditions and dependencies. */
export namespace RegrelloScheduleTimeFormSectionBase {
  export interface Fields {
    [ScheduleTimeKeys.SCHEDULE_TIME]: ScheduleTimeValues;
    [ScheduleTimeKeys.DEPENDENCY]: RegrelloScheduleStartAfterDependency | null;
    [ScheduleTimeKeys.DEPENDENCIES]: RegrelloScheduleStartAfterDependency[];
    [ScheduleTimeKeys.DATE]: Date | null;
    [ScheduleTimeKeys.START_CONDITION_DEPENDENCY]: RegrelloScheduleStartAfterDependency[];
    [ScheduleTimeKeys.CONDITIONS]: ConditionGroup | null;
    [ScheduleTimeKeys.DAYS_TO_COMPLETE]: string | null;
    [ScheduleTimeKeys.TIME_TO_COMPLETE]: string | null;
    [ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT]: TimeUnit;
    [ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD]: FieldInstanceFields | null;
  }

  export interface Props {
    /**
     * The default schedule time option to automatically select when the form is opened and no
     * initial values are provided.
     *
     * @default ScheduleTimeValues.DEFAULT_START
     */
    defaultOption?: ScheduleTimeValues;

    /**
     * The form for configuring the schedule time of the stage or action item. Should have `mode`
     * set to 'all' for form validation to work properly.
     */
    form: UseFormReturn<Fields>;

    /**
     * Label to render for the schedule time form.
     */
    formLabel: string;

    /**
     * Width of the schedule time form label.
     *
     * @default SCHEDULE_TIME_FORM_LABEL_WIDTH
     */
    formLabelWidth?: number;

    /**
     * Initial values used to populate the edit dialog. The form will reset to these values whenever
     * the user switches context and returns to the dialog.
     */
    initialValues?: {
      controllerField?: FieldInstanceFields;
      date?: string;
      daysToComplete?: string;
      timeToComplete?: string;
      timeToCompleteUnit?: TimeUnit;
      startAfterDependency?: RegrelloScheduleStartAfterDependency;
      startAfterDependencies?: RegrelloScheduleStartAfterDependency[];
      startingConditions?: ConditionalExpressionGroupFields;
      startOnWorkflowStart?: boolean;
    };

    /**
     * The default time for time-picker is 11:59PM. Set this to True
     * to use Start of Day instead, i.e. 00:00AM.
     *
     * @default false
     */
    isDefaultTimeStartOfDay?: boolean;

    /**
     * Whether the entire schedule time section is disabled.
     *
     * @default false
     */
    isDisabled?: boolean;

    /**
     * Whether the schedule time fields are optional. Doesn't apply to start condition fields, as
     * those must be required to avoid misconfigured start conditions.
     *
     * @default false
     */
    isOptional?: boolean;

    /** The earliest due date that `ScheduleTimeValues.ON_DATE` form is allowed to set. */
    minDueDate?: Date;

    /**
     * The options to render for the base schedule time select input.
     */
    options: ScheduleTimeOption[];

    /**
     * Whether to show a time-picker with the date-picker.
     *
     * @default false
     */
    showTimePicker: boolean;

    /**
     * The options to render for the user to select as a start after dependency.
     */
    startAfterDependencies?: RegrelloScheduleStartAfterDependency[];

    /**
     * Current workflow or workflow template context for the stage or task. Used to determine which
     * field instances to show users when selecting fields for due dates or start conditions.
     */
    workflowContext:
      | {
          type: "workflowTemplate";
          workflowTemplateId: number;
          workflowStageTemplateId?: number;
          workflowTemplateIsCreateViaEmailEnabled: boolean;
          dependingOnActionItemTemplateId?: number;
        }
      | {
          type: "workflow";
          workflowId: number;
          workflowStageId?: number;
          dependingOnActionItemTemplateId?: number;
        };

    /**
     * Whether the user is allowed to show or select multiple start after dependencies.
     */
    enableMultiDependencies: boolean;
  }

  export function getDefaultValues(
    defaultOption: ScheduleTimeValues,
    values?: {
      controllerField?: Maybe<FieldInstanceFields>;
      date?: Maybe<string>;
      daysToComplete?: Maybe<string>;
      timeToComplete?: Maybe<string>;
      timeToCompleteUnit?: Maybe<TimeUnit>;
      startAfterDependency?: Maybe<RegrelloScheduleStartAfterDependency>;
      startAfterDependencies?: Maybe<RegrelloScheduleStartAfterDependency[]>;
      startingConditions?: Maybe<ConditionalExpressionGroupFields>;
      startOnWorkflowStart?: boolean;
    },
  ): Fields {
    const conditionGroups = CleanUpConditionGroup(values?.startingConditions);

    const hasStartAfterDependencies =
      (values?.startAfterDependencies != null && values.startAfterDependencies.length > 0) ||
      values?.startAfterDependency != null;

    // TODO (Surya): Refactor this ternary to be clearer.
    const scheduleTimeOption =
      conditionGroups !== undefined && (values?.startOnWorkflowStart || hasStartAfterDependencies)
        ? values?.startOnWorkflowStart
          ? ScheduleTimeValues.START_CONDITION_WORKFLOW
          : ScheduleTimeValues.START_CONDITION_STAGE
        : hasStartAfterDependencies
          ? ScheduleTimeValues.AFTER_DEPENDENCY
          : values?.date != null
            ? ScheduleTimeValues.ON_DATE
            : // (surya): Time takes precedence over date.
              values?.timeToComplete != null && values?.timeToComplete !== ""
              ? ScheduleTimeValues.TIME_TO_COMPLETE
              : values?.daysToComplete != null && values?.daysToComplete !== ""
                ? ScheduleTimeValues.DAYS_TO_COMPLETE
                : values?.controllerField != null
                  ? ScheduleTimeValues.FROM_CUSTOM_FIELD
                  : values?.startOnWorkflowStart
                    ? ScheduleTimeValues.ON_WORKFLOW_START
                    : defaultOption;
    return {
      [ScheduleTimeKeys.SCHEDULE_TIME]: scheduleTimeOption,
      [ScheduleTimeKeys.START_CONDITION_DEPENDENCY]:
        scheduleTimeOption === ScheduleTimeValues.START_CONDITION_STAGE
          ? consolidateDependencies(values?.startAfterDependency, values?.startAfterDependencies)
          : [],
      [ScheduleTimeKeys.CONDITIONS]: getFormStartingConditions(conditionGroups),
      [ScheduleTimeKeys.DEPENDENCY]:
        scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY ? (values?.startAfterDependency ?? null) : null,
      [ScheduleTimeKeys.DEPENDENCIES]:
        scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY
          ? (values?.startAfterDependencies ?? EMPTY_ARRAY)
          : EMPTY_ARRAY,
      [ScheduleTimeKeys.DATE]: values?.date != null ? new Date(values?.date) : null,
      [ScheduleTimeValues.DAYS_TO_COMPLETE]: values?.daysToComplete ?? null,
      [ScheduleTimeValues.TIME_TO_COMPLETE]: values?.timeToComplete ?? null,
      [ScheduleTimeValues.TIME_TO_COMPLETE_UNIT]:
        scheduleTimeOption === ScheduleTimeValues.TIME_TO_COMPLETE
          ? values?.timeToCompleteUnit || TimeUnit.DAYS
          : TimeUnit.DAYS,
      [ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD]: values?.controllerField ?? null,
    };
  }

  export const Component = React.memo<Props>(function ComponentFn({
    defaultOption = ScheduleTimeValues.DEFAULT_START,
    form,
    formLabel,
    formLabelWidth = SCHEDULE_TIME_FORM_LABEL_WIDTH,
    initialValues,
    isDefaultTimeStartOfDay = false,
    isDisabled = false,
    isOptional = false,
    minDueDate,
    options,
    showTimePicker = false,
    startAfterDependencies,
    workflowContext,
    enableMultiDependencies,
  }: Props) {
    const scheduleTimeOption = useWatch({ control: form.control, name: ScheduleTimeKeys.SCHEDULE_TIME });

    // Tracks the form field select for choosing a start-after task. Used for auto-opening when the
    // schedule time key changes to "AFTER_DEPENDENCY"
    const startAfterSelectRef = useRef<HTMLButtonElement>(null);

    const pureOptions = useMemo(() => {
      return options.map((option) => {
        return option.option;
      });
    }, [options]);

    const optionsToLabelMap = useMemo(() => {
      const optionsMap = new Map<ScheduleTimeValues, string>();
      options.forEach((option) => {
        optionsMap.set(option.option, option.label);
      });
      return optionsMap;
    }, [options]);

    // TODO Conditional Branching: Find more elegant way to keep the FE in sync with the BE after
    // deleting a field without having to refetch all workflows and templates in the cache.
    const initialConditionsWithoutDeleted = useMemo(
      () => CleanUpConditionGroup(initialValues?.startingConditions),
      [initialValues?.startingConditions],
    );

    // Keep local state that doesn't get submitted so we can decide whether
    // changes to the form invalidate the conditions.
    const [prevDeps, setPrevDeps] = useState(
      new Set(
        consolidateDependencies(initialValues?.startAfterDependency, initialValues?.startAfterDependencies).map(
          (v) => v.id,
        ),
      ),
    );

    const handleStartConditionStageDependencyChange = useCallback(
      (_: unknown, newValue: RegrelloScheduleStartAfterDependency[]) => {
        // Preserve the current conditions iff they are compatible
        // with the new dependencies. Otherwise, clear the form.
        form.setValue(ScheduleTimeKeys.START_CONDITION_DEPENDENCY, newValue);
        const currDeps = new Set(newValue.map((v) => v.id));
        setPrevDeps(currDeps);
        // If we don't have all the dependencies we previously had, reset the conditions to cleanup
        // any potential incorrect conditions. This could be made more sophisticated, but for now overcorrecting
        // is fine.
        const currDepsContainsAllPrevDeps = setIntersection(currDeps, prevDeps).size === prevDeps.size;
        if (!currDepsContainsAllPrevDeps) {
          // Note: we do not unregister the conditions if they do not change.
          // This is important, because some of the descendant components do not react kindly
          // to unregistering only to put back the same value.
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          form.setValue(ScheduleTimeKeys.CONDITIONS, {
            connective: ConditionConnective.AND,
            // This is different from the typical empty form with one condition row.
            // The typical empty row has a constant displayOrder value of 0 for the
            // first row. Reusing the displayOrder value, which doubles as the key,
            // causes memoization to kick in and leave the form in an uncleared
            // state. By completely clearing the state, we can ensure the component
            // is unmounted before the key is used again.
            conditions: [],
            conditionGroups: [],
            displayOrder: 0,
          });
        }
      },
      [form, prevDeps],
    );

    // (zstanik): Need to clear stale validation rules on any form fields the user doesn't need to
    // fill out anymore after switching options.
    const handleScheduleTimeKeyChange = useCallback(
      (_: unknown, newValue: ScheduleTimeValues | null) => {
        if (newValue === ScheduleTimeValues.DEFAULT_START) {
          form.reset(getDefaultValues(defaultOption));
        }

        if (newValue === ScheduleTimeValues.ON_WORKFLOW_START) {
          form.reset(getDefaultValues(defaultOption, { startOnWorkflowStart: true }));
        }

        if (newValue === ScheduleTimeValues.AFTER_DEPENDENCY) {
          if (enableMultiDependencies) {
            const initialStages =
              initialValues?.startingConditions == null || initialConditionsWithoutDeleted !== undefined
                ? (initialValues?.startAfterDependencies ?? EMPTY_ARRAY)
                : EMPTY_ARRAY;

            form.setValue(ScheduleTimeKeys.DEPENDENCIES, initialStages);
          } else {
            form.setValue(
              ScheduleTimeKeys.DEPENDENCY,
              initialValues?.startingConditions == null || initialConditionsWithoutDeleted !== undefined
                ? (initialValues?.startAfterDependency ?? null)
                : null,
            );

            // (dosipiuk): To prevent issues with material-ui focus traps, this needs at least 2 macrotasks flush.
            // Therefore converting to a timeout until we migrate to radix dialog.
            setTimeout(() => {
              startAfterSelectRef?.current?.click();
            }, 100);
          }
        } else if (enableMultiDependencies) {
          form.unregister(ScheduleTimeKeys.DEPENDENCIES);
        } else {
          form.unregister(ScheduleTimeKeys.DEPENDENCY);
        }

        if (newValue === ScheduleTimeValues.ON_DATE) {
          form.setValue(ScheduleTimeKeys.DATE, initialValues?.date != null ? new Date(initialValues.date) : null);
        } else {
          form.unregister(ScheduleTimeKeys.DATE);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_STAGE) {
          // (zstanik): Clear stale validation rules if user switched from workflow start conditions
          // option.
          const restoreStageStartingConditions =
            !initialValues?.startOnWorkflowStart &&
            initialValues?.startAfterDependency != null &&
            initialConditionsWithoutDeleted !== undefined;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          form.setValue(
            ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
            restoreStageStartingConditions
              ? consolidateDependencies(initialValues.startAfterDependency, initialValues.startAfterDependencies)
              : [],
          );
          form.setValue(
            ScheduleTimeKeys.CONDITIONS,
            restoreStageStartingConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : makeEmptyConditionGroup(0),
          );
        } else {
          form.unregister(ScheduleTimeKeys.START_CONDITION_DEPENDENCY);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_WORKFLOW) {
          // (zstanik): Clear stale validation rules if user switched from stage start conditions
          // option.
          const restoreWorkflowStartConditions =
            initialValues?.startOnWorkflowStart &&
            initialValues.startAfterDependency == null &&
            initialConditionsWithoutDeleted !== undefined;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          form.setValue(
            ScheduleTimeKeys.CONDITIONS,
            restoreWorkflowStartConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : makeEmptyConditionGroup(0),
          );
        } else if (newValue !== ScheduleTimeValues.START_CONDITION_STAGE) {
          form.unregister(ScheduleTimeKeys.CONDITIONS);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_WORKFLOW) {
          // (zstanik): Clear stale validation rules if user switched from stage start conditions
          // option.
          const restoreWorkflowStartConditions =
            initialValues?.startOnWorkflowStart &&
            initialValues.startAfterDependency == null &&
            initialConditionsWithoutDeleted !== undefined;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          form.setValue(
            ScheduleTimeKeys.CONDITIONS,
            restoreWorkflowStartConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : makeEmptyConditionGroup(0),
          );
        } else if (newValue !== ScheduleTimeValues.START_CONDITION_STAGE) {
          form.unregister(ScheduleTimeKeys.CONDITIONS);
        }

        if (newValue === ScheduleTimeValues.DAYS_TO_COMPLETE) {
          form.setValue(ScheduleTimeKeys.DAYS_TO_COMPLETE, initialValues?.daysToComplete ?? null);
        } else {
          form.unregister(ScheduleTimeKeys.DAYS_TO_COMPLETE);
        }

        if (newValue === ScheduleTimeValues.TIME_TO_COMPLETE) {
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE, initialValues?.timeToComplete ?? null);
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT, initialValues?.timeToCompleteUnit ?? TimeUnit.DAYS);
        } else {
          form.unregister(ScheduleTimeKeys.TIME_TO_COMPLETE);
        }

        if (newValue === ScheduleTimeValues.TIME_TO_COMPLETE_UNIT) {
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT, initialValues?.timeToCompleteUnit ?? TimeUnit.DAYS);
        } else if (newValue !== ScheduleTimeValues.TIME_TO_COMPLETE) {
          form.unregister(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT);
        }

        if (newValue === ScheduleTimeValues.FROM_CUSTOM_FIELD) {
          form.setValue(ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD, initialValues?.controllerField ?? null);
        } else {
          form.unregister(ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD);
        }
      },
      [
        enableMultiDependencies,
        form,
        defaultOption,
        initialValues?.startingConditions,
        initialValues?.startAfterDependencies,
        initialValues?.startAfterDependency,
        initialValues?.date,
        initialValues?.startOnWorkflowStart,
        initialValues?.daysToComplete,
        initialValues?.timeToComplete,
        initialValues?.timeToCompleteUnit,
        initialValues?.controllerField,
        initialConditionsWithoutDeleted,
      ],
    );

    const memoizedDateAllowedFieldPlugins = useMemo(() => {
      return [new SpectrumDateFieldPluginDecorator(DateFieldPlugin)];
    }, []);

    useMount(() => {
      form.reset(
        getDefaultValues(defaultOption, {
          controllerField: initialValues?.controllerField,
          date: initialValues?.date,
          daysToComplete: initialValues?.daysToComplete,
          timeToComplete: initialValues?.timeToComplete,
          timeToCompleteUnit: initialValues?.timeToCompleteUnit,
          startAfterDependency: initialValues?.startAfterDependency,
          startAfterDependencies: initialValues?.startAfterDependencies,
          startingConditions: initialValues?.startingConditions,
          startOnWorkflowStart: initialValues?.startOnWorkflowStart,
        }),
      );
    });

    const memoizedAllAllowedFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>> = useMemo(() => {
      return [
        new SpectrumTextFieldPluginDecorator(TextFieldPlugin),
        new SpectrumSelectFieldPluginDecorator(SelectFieldPlugin),
        new SpectrumCurrencyFieldPluginDecorator(CurrencyFieldPlugin),
        new SpectrumNumberFieldPluginDecorator(NumberFieldPlugin),
        new SpectrumCheckboxFieldPluginDecorator(CheckboxFieldPlugin),
        new SpectrumDateFieldPluginDecorator(DateFieldPlugin),
        new SpectrumMultiselectFieldPluginDecorator(MultiSelectFieldPlugin),
        new SpectrumUserFieldPluginDecorator(UserFieldPlugin),
        new SpectrumObjectFieldPluginDecorator(RegrelloObjectFieldPlugin),
      ];
    }, []);

    const isRecursiveGroupsEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.STAGE_CONDITION_GROUPS_2024_12);

    return (
      <>
        <RegrelloFormFieldLayout className="flex-col sm:flex-row mb-0" label={formLabel} labelWidth={formLabelWidth}>
          <div className="flex justify-start gap-2">
            <RegrelloControlledFormFieldSelect
              className={clsx(
                "min-w-[38%]", // (anthony): 38 is chosen intentionally to allow space for the new due date and time picker field.
                "grow-0", // (elle): Prevent the single select to grow and shrink into the right stage multi select when its content changes.
              )}
              controllerProps={{
                control: form.control,
                name: ScheduleTimeKeys.SCHEDULE_TIME,
                rules: ValidationRules.REQUIRED,
              }}
              dataTestId={DataTestIds.SCHEDULE_TIME_SELECT}
              disabled={isDisabled}
              getOptionDisabled={(option) =>
                option === ScheduleTimeValues.AFTER_DEPENDENCY && (startAfterDependencies?.length ?? 0) === 0
              }
              getOptionLabel={(option) => optionsToLabelMap.get(option) ?? ""}
              isFilterable={false}
              isRequiredAsteriskShown={true}
              onValueChange={handleScheduleTimeKeyChange}
              options={pureOptions}
            />
            {scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY &&
              (enableMultiDependencies ? (
                <div className="grow min-w-0">
                  <RegrelloControlledFormFieldMultiSelect
                    className="min-h-15"
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.DEPENDENCIES,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_MULTI_SELECT}
                    disabled={isDisabled}
                    getSelectedItemProps={getSelectedItemProps}
                    isItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
                    isRequiredAsteriskShown={!isOptional}
                    itemPredicate={itemPredicate}
                    items={startAfterDependencies ?? EMPTY_ARRAY}
                    renderItem={renderItem}
                  />
                </div>
              ) : (
                <div className="grow min-w-0">
                  <RegrelloControlledFormFieldSelect
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.DEPENDENCY,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_SELECT}
                    disabled={isDisabled}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => `${option.name}|${option.id}`}
                    isRequiredAsteriskShown={!isOptional}
                    options={startAfterDependencies ?? EMPTY_ARRAY}
                    selectRef={startAfterSelectRef}
                  />
                </div>
              ))}
            {scheduleTimeOption === ScheduleTimeValues.ON_DATE && (
              <RegrelloControlledFormFieldDate
                controllerProps={{
                  control: form.control,
                  name: ScheduleTimeKeys.DATE,
                  rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                }}
                dataTestId={DataTestIds.SCHEDULE_TIME_ON_DATE_FIELD}
                disabled={isDisabled}
                isDefaultTimeStartOfDay={isDefaultTimeStartOfDay}
                isRequiredAsteriskShown={!isOptional}
                minDate={minDueDate}
                showTimePicker={showTimePicker}
              />
            )}
            {scheduleTimeOption === ScheduleTimeValues.DAYS_TO_COMPLETE && (
              <div className="w-[14%]">
                <RegrelloControlledFormFieldNumber
                  controllerProps={{
                    control: form.control,
                    name: ScheduleTimeKeys.DAYS_TO_COMPLETE,
                    rules: {
                      ...(!isOptional ? ValidationRules.REQUIRED : undefined),
                      ...ValidationRules.INTEGER,
                      ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(1.0),
                    },
                  }}
                  dataTestId={DataTestIds.SCHEDULE_TIME_DAYS_TO_COMPLETE_FIELD}
                  disabled={isDisabled}
                  isRequiredAsteriskShown={!isOptional}
                  placeholder={t`1-365`}
                />
              </div>
            )}
            {scheduleTimeOption === ScheduleTimeValues.TIME_TO_COMPLETE && (
              <div className="flex gap-2">
                <div className="w-1/3">
                  <RegrelloControlledFormFieldNumber
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.TIME_TO_COMPLETE,
                      rules: {
                        ...(!isOptional ? ValidationRules.REQUIRED : undefined),
                        ...ValidationRules.INTEGER,
                        ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(1.0),
                      },
                    }}
                    dataTestId={DataTestIds.SCHEDULE_TIME_TIME_TO_COMPLETE_FIELD}
                    disabled={isDisabled}
                    isRequiredAsteriskShown={!isOptional}
                    placeholder="#"
                  />
                </div>

                <div className="w-30">
                  <RegrelloControlledFormFieldSelect
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    disabled={isDisabled}
                    getOptionLabel={(option) => option}
                    isFilterable={false}
                    isRequiredAsteriskShown={!isOptional}
                    options={TimeToCompleteUnits}
                  />
                </div>
              </div>
            )}

            {scheduleTimeOption === ScheduleTimeValues.FROM_CUSTOM_FIELD && (
              <div className="grow">
                <RegrelloControlledFormFieldCustomFieldInstanceSelect
                  allowedFieldPlugins={memoizedDateAllowedFieldPlugins}
                  controllerProps={{
                    control: form.control,
                    name: ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD,
                    rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                  }}
                  dataTestId={DataTestIds.SCHEDULE_TIME_FROM_CUSTOM_FIELD_SELECT}
                  disabled={isDisabled}
                  isInactiveFieldInstancesHidden={true}
                  isRequiredAsteriskShown={!isOptional}
                  isSourceHelperTextVisible={true}
                  optionsConfig={{
                    type: "asyncLoaded",
                    dependingOnActionItemTemplateId: workflowContext.dependingOnActionItemTemplateId,
                    workflowContext:
                      workflowContext.type === "workflow"
                        ? {
                            type: "workflow",
                            workflowId: workflowContext.workflowId,
                            workflowStageId: workflowContext.workflowStageId,
                            dependingOnWorkflowStageIds: undefined,
                          }
                        : {
                            type: "workflowTemplate",
                            workflowTemplateId: workflowContext.workflowTemplateId,
                            workflowTemplateIsCreateViaEmailEnabled:
                              workflowContext.workflowTemplateIsCreateViaEmailEnabled,
                            workflowStageTemplateId: workflowContext.workflowStageTemplateId,
                            dependingOnWorkflowStageTemplateIds: undefined,
                          },
                  }}
                  placeholder={t`Select field`}
                />
              </div>
            )}
          </div>
        </RegrelloFormFieldLayout>
        {(scheduleTimeOption === ScheduleTimeValues.START_CONDITION_STAGE ||
          scheduleTimeOption === ScheduleTimeValues.START_CONDITION_WORKFLOW) && (
          <RegrelloConfigureConditionsFormSection
            allowedFieldPlugins={memoizedAllAllowedFieldPlugins}
            form={form}
            isDisabled={isDisabled}
            isRecursiveGroupsEnabled={isRecursiveGroupsEnabled}
            onConditionStageDependencyChange={handleStartConditionStageDependencyChange}
            startAfterDependencies={startAfterDependencies}
            startConditionOption={scheduleTimeOption}
            workflowContext={workflowContext}
          />
        )}
      </>
    );
  });
}

function getSelectedItemProps(item: RegrelloScheduleStartAfterDependency) {
  return {
    children: item.name,
  };
}

function itemPredicate(query: string, item: RegrelloScheduleStartAfterDependency) {
  return item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function renderItem(item: RegrelloScheduleStartAfterDependency) {
  return {
    key: item.id,
    text: item.name,
  };
}

// consolidateDependencies is used collapse the legacy singular dependency value and newer list of dependencies into a single
// list. Ideally, we'd remove the legacy value, but its use is already pervasive.
function consolidateDependencies<T extends { id: number }>(
  instance: Maybe<T> | undefined,
  instances: Maybe<T[]> | undefined,
): T[] {
  const out = new Map<number, T>();
  if (instance != null) {
    out.set(instance.id, instance);
  }

  if (instances != null) {
    instances.forEach((i) => out.set(i.id, i));
  }
  return Array.from(out.values());
}
