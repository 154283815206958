import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_STRING, noop } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import { RegrelloTooltip } from "@regrello/ui-core";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import type { PartyTypeUnion } from "../../../utils/parties/PartyTypeUnion";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldSelectV2 } from "../formFields/RegrelloFormFieldSelectV2";
import { RegrelloFormFieldSwitch } from "../formFields/RegrelloFormFieldSwitch";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import {
  ALLOW_MULTIPLE_PARTY_ARG,
  DISALLOW_MULTIPLE_PARTY_ARG,
  FrontendValueConstraintRuleName,
  getValueConstraintDisplayName,
} from "./utils/spectrumFieldConstraintUtils";

type UserFieldPluginFrontendValue = PartyTypeUnion[];

export class SpectrumUserFieldPluginDecorator extends SpectrumFieldPluginDecorator<UserFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<UserFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.user";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.PARTY;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.PARTY_ID);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.PARTY,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) => valueConstraintRule === FrontendValueConstraintRuleName.MAX_PARTY,
    );
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.ARRAY_EMPTY]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_EMPTY]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.ARRAY_EQUALS]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_EQUALS]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_CONTAINS_ONE_OF]: {
        label: t`Contains any of`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_CONTAINS_ALL]: {
        label: t`Contains all of`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_CONTAINS_ONE_OF]: {
        label: t`Contains none of`,
        inputCount: 1,
        isMultiselect: true,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return (
      <RegrelloFormFieldSelectV2
        disabled={true}
        getOptionLabel={(option) => option}
        onChange={noop}
        options={[]}
        size="medium"
        value=""
      />
    );
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
    suppressMultiplicityWithExplanation?: string;
    updateConstraint: (index: number, value: ConfigureSpectrumFieldFormFormFields["valueConstraints"][number]) => void;
  }): ReactNode {
    const { constraints, disabled, form, suppressMultiplicityWithExplanation, updateConstraint } = props;
    if (constraints.length === 0) {
      return null;
    }
    const getFieldKey = (index: number) => `valueConstraints.${index}.args.0` as const;
    return (
      <RegrelloTooltip
        content={suppressMultiplicityWithExplanation}
        disabled={suppressMultiplicityWithExplanation == null}
      >
        <div>
          <RegrelloFormFieldSwitch
            checked={form.getValues(getFieldKey(0)) === ALLOW_MULTIPLE_PARTY_ARG}
            className="ml-2"
            disabled={disabled || suppressMultiplicityWithExplanation != null}
            label={EMPTY_STRING}
            onChange={(isChecked) => {
              const previousValue = form.getValues("valueConstraints.0");
              if (!isChecked) {
                updateConstraint(0, { ...previousValue, args: [DISALLOW_MULTIPLE_PARTY_ARG] });
              } else {
                updateConstraint(0, { ...previousValue, args: [ALLOW_MULTIPLE_PARTY_ARG] });
              }
            }}
            secondaryLabel={getValueConstraintDisplayName(constraints[0].constraint)}
            variant="spectrum"
          />
        </div>
      </RegrelloTooltip>
    );
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, UserFieldPluginFrontendValue>, "onCellValueChanged">,
  ) {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    return {
      colId: getColumnIdFromFormField(formField),
      headerName: spectrumFieldVersion.name,
      ...colDefParams,
    };
  }
}
