import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type AiAgentRunFields = {
  id: number;
  createdAt: string;
  eventDetails: string;
  status: Types.AgentRunStatus;
  updatedAt: string;
  createdBy: PartyBaseFields;
  executionHistory: Array<{
    id: number;
    agentNodeName: string;
    createdAt: string;
    executionDetails: string;
    executionStepType: Types.AgentExecutionStepType;
  }>;
};

export const AiAgentRunFields = gql`
    fragment AIAgentRunFields on AIAgentRun {
  id
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  eventDetails
  executionHistory {
    id
    agentNodeName
    createdAt
    executionDetails
    executionStepType
  }
  status
  updatedAt
}
    `;
