import type { ValuesUnion } from "@regrello/core-utils";

/**
 * An enum of workflow task statuses that may be used on the frontend. This is derived from the
 * task status returned from the backend.
 */
export const WorkflowFrontendTaskStatus = {
  APPROVAL_IN_PROGRESS: "approval-in-progress",
  APPROVAL_NOT_STARTED: "approval-not-started",
  APPROVAL_OVERDUE: "approval-overdue",
  APPROVED: "approved",
  COMPLETED_LATE: "completed-late",
  COMPLETED: "completed",
  ENDED: "ended",
  EXCEPTION: "exception",
  IN_PROGRESS_ON_TRACK: "in-progress-on-track",
  IN_PROGRESS_OVERDUE: "in-progress-overdue",
  LINKED_WORKFLOW_DELAYED: "linked-workflow-delayed",
  LINKED_WORKFLOW_ENDED: "linked-workflow-ended",
  LINKED_WORKFLOW_IN_PROGRESS: "linked-workflow-in-progress",
  LINKED_WORKFLOW_NOT_STARTED: "linked-workflow-not-started",
  LINKED_WORKFLOW_PAUSED: "linked-workflow-paused",
  LINKED_WORKFLOW_PROBLEM: "linked-workflow-problem",
  NOT_STARTED_DELAYED: "not-started-delayed",
  NOT_STARTED: "not-started",
  PAUSED: "paused",
  REJECTED: "rejected",
  SKIPPED: "skipped",
} as const;

export type WorkflowFrontendTaskStatus = ValuesUnion<typeof WorkflowFrontendTaskStatus>;
