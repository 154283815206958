import { t } from "@lingui/core/macro";
import type { Optional } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  AccessRoleName,
  AccessRoleUserScope,
  type ActionItemFields,
  ActionItemStatus,
  ActionItemType,
  type DocumentFields,
  type FormVersionFields,
  type Maybe,
  type PartyFields,
  type RoleFields,
  type TeamFields,
  UserAccessLevel,
  type UserFields,
  type WorkflowFields,
  type WorkflowPermissionsFields,
  type WorkflowQueryActionItemTemplateCardFields,
  type WorkflowQueryActionItemTemplateFields,
  type WorkflowTemplateFields,
} from "@regrello/graphql-api";

import { FeatureFlagService } from "../../../services/FeatureFlagService";
import type { UserContextType } from "../authentication/UserContext";

/**
 * `ActionItemFieldsPartial<"fieldA" | "fieldB">` names any type adhering to `ActionItemFields`
 * as an interface and supplying required fields `fieldA` and `fieldB`.
 */
export type ActionItemFieldsPartial<T extends keyof ActionItemFields> = Pick<ActionItemFields, T>;

/**
 * A convenient client-side permission-checking API.
 *
 * Checks in this API will typically consult boolean flags provided from the backend (e.g.,
 * `actionItem.permissions.canRead`). However, they may occasionally weave in custom client-side
 * checks if a particular scenario is not covered by the backend; in such cases, we should aim to
 * shift this custom logic to the backend eventually, to ensure we're using consistent permissions
 * logic throughout the platform.
 *
 * @example
 * if (RegrelloPermissions.ActionItem.canView(actionItem)) {
 *   // Render the action item.
 * }
 */
export namespace RegrelloPermissions {
  export namespace AccessRole {
    // (clewis): Be careful - these strings are not strongly typed.

    export function isInternalCanViewAndComment(currentUser: UserContextType["currentUser"]): boolean {
      return currentUser.accessRole?.name === AccessRoleName.INTERNAL_CAN_VIEW_AND_COMMENT;
    }

    export function isExternal(currentUser: UserContextType["currentUser"]): boolean {
      return currentUser.accessRole?.userScope === AccessRoleUserScope.EXTERNAL;
    }

    export function isInternal(currentUser: UserContextType["currentUser"] | UserFields): boolean {
      return currentUser.accessRole?.userScope === AccessRoleUserScope.INTERNAL;
    }
  }

  export namespace ActionItem {
    export function canView(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canRead;
    }

    export function canDelete(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canDelete;
    }

    export function canDuplicate(
      actionItem: Pick<ActionItemFields, "permissions" | "type" | "approvalForActionItem">,
      workflow: Pick<WorkflowFields, "permissions">,
    ) {
      const isApprovalTask = actionItem.approvalForActionItem != null;

      // (akager) You can duplicate an action item if you have permission to create tasks in the
      // parent workflow (workflow.canEdit) and if you have view access on the action item
      // (actionItem.canRead). (zstanik): Additionally, automated tasks can't be duplicated and were
      // just being duplicated as standard tasks.
      return (
        RegrelloPermissions.Workflow.canEdit(workflow) &&
        RegrelloPermissions.ActionItem.canView(actionItem) &&
        !isApprovalTask &&
        actionItem.type !== ActionItemType.AUTOMATION
      );
    }

    export function canEdit(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEdit;
    }

    export function canReopen(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canReopen;
    }

    export function canEditName(actionItem: ActionItemFields) {
      return actionItem.permissions?.canEditName;
    }

    export function canEditAssignees(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEditAssignees;
    }

    export function canEditDate(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEditDueOn;
    }

    export function canEditCcs(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEditCC;
    }

    export function canEditDescription(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEditDescription;
    }

    export function canEditTags(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canEditTags;
    }

    export function canSubmitOrReopen(actionItem: ActionItemFieldsPartial<"permissions" | "type">) {
      // TODO Linked Workflows: remove check for type once the BE checks this in the permissions
      // resolver.
      return actionItem.permissions?.canComplete && actionItem.type !== ActionItemType.LINKED_WORKFLOW;
    }

    export function canReportOrResolveException(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canReportException;
    }

    export function canApproveOrReject(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canApprove;
    }

    export function canStar(actionItem: ActionItemFieldsPartial<"permissions">) {
      return actionItem.permissions?.canRead;
    }
  }

  export namespace ActionItemTemplate {
    /** @deprecated Use `ActionItemTemplateV2.canView`, which is backed by our permissions model, instead. */
    export function canView(
      actionItemTemplate: Optional<
        Pick<
          WorkflowQueryActionItemTemplateCardFields,
          "actionItems" | "approvalActionItemTemplates" | "assignedBy" | "assignees" | "cc"
        >,
        "actionItems" | "approvalActionItemTemplates"
      >,
      workflowCollaborators: PartyFields[],
      currentUser: UserContextType["currentUser"],
    ) {
      const isAssociatedWithThisActionItemTemplate = [
        [actionItemTemplate.assignedBy],
        actionItemTemplate.assignees,
        actionItemTemplate.cc,
      ].some((parties) => parties.some((party) => party.id === currentUser.partyId));

      const isWorkflowCollaborator = workflowCollaborators.some((party) => party.id === currentUser.partyId);

      return currentUser.isAdmin || isAssociatedWithThisActionItemTemplate || isWorkflowCollaborator;
    }
  }

  export namespace ActionItemTemplateV2 {
    export function canDelete(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canDelete;
    }

    export function canDuplicate(
      actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions" | "type">,
    ) {
      // (akager) This permission check is not quite correct. The permission to duplicate a task
      // requires the ability to view the task, and the ability to add a task to the workflow
      // the task is in. It is not related to the ability to edit that task itself. Not
      // changing this for now as it would require a lot of changes and this logic should be correct
      // for the foreseable future.
      return (
        actionItemTemplate.permissions.canEdit &&
        (actionItemTemplate.type === ActionItemType.DEFAULT ||
          actionItemTemplate.type === ActionItemType.APPROVAL ||
          actionItemTemplate.type === ActionItemType.LINKED_WORKFLOW)
      );
    }

    export function canEdit(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canEdit;
    }

    export function canStart(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canStart;
    }

    export function canView(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canRead;
    }

    export function canEditApprovals(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canEditApprovals;
    }

    export function canEditApprovers(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canEditApprovers;
    }

    export function canEditAssignees(actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">) {
      return actionItemTemplate.permissions.canEditAssignees;
    }

    export function canRemoveRequestedFields(
      actionItemTemplate: Pick<WorkflowQueryActionItemTemplateFields, "permissions">,
    ) {
      return actionItemTemplate.permissions.canRemoveRequestedFields;
    }
  }

  export namespace Create {
    export function canCreateAutomations(currentUser: UserContextType["currentUser"]) {
      return currentUser.isAdmin;
    }

    export function canCreateBlueprints(currentUser: UserContextType["currentUser"]) {
      const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);
      if (isPermissionsV2Enabled) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreateTemplateButton;
      }

      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canCreateCustomFields(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.customFieldPermissions.canCreate;
      }
      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canCreateRoles(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreateRoleButton;
      }
      return false;
    }

    export function canCreatePeople(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreatePeopleButton;
      }
      return currentUser.isAdmin;
    }

    export function canCreateTags(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.canCreateTags;
      }
      return currentUser.isAdmin;
    }

    export function canCreateUsers(currentUser: Pick<UserContextType["currentUser"], "permissions">) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreatePeopleButton;
      }
      return true;
    }

    export function canCreateWorkflows(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreateWorkflowButton;
      }
      return currentUser.accessRole?.userScope === AccessRoleUserScope.INTERNAL;
    }

    export function canCreateTeams(currentUser: Pick<UserContextType["currentUser"], "permissions" | "accessRole">) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreateTeamButton;
      }
      return (
        currentUser.accessRole?.name === AccessRoleName.ADMIN || currentUser.accessRole?.name === AccessRoleName.OWNER
      );
    }

    export function canCreateForms(currentUser: UserContextType["currentUser"]) {
      return currentUser.accessLevel === UserAccessLevel.INTERNAL;
    }
  }

  export namespace CustomField {
    export function canDelete(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.customFieldPermissions.canDelete;
      }
      return true;
    }
    export function canEdit(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.customFieldPermissions.canEdit;
      }
      return true;
    }
  }

  export namespace Document {
    export function canDeleteDocument(
      document: Pick<DocumentFields, "actionItem" | "currentVersion" | "permissions">,
      currentUser: Pick<UserContextType["currentUser"], "isAdmin" | "id">,
    ) {
      const isActionItemCompleted = document.actionItem?.status === ActionItemStatus.COMPLETED;
      const isUploadedByCurrentUser = document.currentVersion.createdBy.id === currentUser.id;
      const canCurrentUserDelete = currentUser.isAdmin || (isUploadedByCurrentUser && !isActionItemCompleted);
      const reasonWhyNotDeletable = canCurrentUserDelete
        ? undefined
        : !isUploadedByCurrentUser
          ? t`You cannot delete documents uploaded by other people`
          : isActionItemCompleted
            ? t`Documents cannot be deleted from a completed task`
            : undefined;

      return { canCurrentUserDelete: document.permissions.canDelete, reasonWhyNotDeletable };
    }
  }

  export namespace Pages {
    export function isStarredV2PageEnabled(currentUser: UserContextType["currentUser"]) {
      return currentUser.accessRole?.userScope === AccessRoleUserScope.INTERNAL;
    }

    export function isSpectrumFormPageEnabled(currentUser: UserContextType["currentUser"]) {
      const accessRoleLevelCanMake = 40;
      return currentUser.accessRole != null && currentUser.accessRole.level >= accessRoleLevelCanMake;
    }
  }

  export namespace Tag {
    export function canDelete(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.tagPermissions.canDelete;
      }
      return true;
    }
    export function canEdit(currentUser: UserContextType["currentUser"]) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return currentUser.permissions.tagPermissions.canEdit;
      }
      return true;
    }
  }

  export namespace Team {
    export function canEdit(team: TeamFields) {
      return team.permissions?.canEdit ?? false;
    }

    export function canDelete(team: TeamFields) {
      return team.permissions?.canDelete ?? false;
    }
  }

  export namespace Tenant {
    export function canUpdateTenantDisplayName(currentUser: UserContextType["currentUser"]) {
      return currentUser.isAdmin;
    }
  }

  export namespace Workflow {
    export function canView(workflow: { id: number; permissions: Partial<WorkflowPermissionsFields> | undefined }) {
      return workflow.permissions?.canRead ?? false;
    }

    export function canEditStages(workflow: WorkflowFields) {
      return workflow.permissions?.canEdit ?? false;
    }

    export function canEditCollaborators(workflow: WorkflowFields) {
      return workflow.permissions?.canEdit ?? false;
    }

    export function canEdit(workflow: Pick<WorkflowFields, "permissions">) {
      return workflow.permissions?.canEdit ?? false;
    }

    export function canEditData(workflow: Pick<WorkflowFields, "permissions">) {
      return workflow.permissions?.canEditData ?? false;
    }

    export function canEditOwner(workflow: Pick<WorkflowFields, "permissions">) {
      if (!FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return false;
      }
      return workflow.permissions?.permissionsToWorkflowOwner.canEdit;
    }

    export function canStart(workflow: WorkflowFields) {
      return workflow.permissions?.canStart ?? false;
    }

    export function canDelete(workflow: Pick<WorkflowFields, "permissions">) {
      return workflow.permissions?.canDelete ?? false;
    }

    export function canAddAutomation(
      workflow: Pick<WorkflowFields, "permissions">,
      currentUser: UserContextType["currentUser"],
    ) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return workflow?.permissions?.canAddAutomatedTask ?? false;
      }

      // (surya): Only internal users Can Make and above can add automations.
      // This is different from CREATING automations which only the admin can do.
      // That permission is listed out in canCreateAutomations().
      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canAddLinkedWorkflowTask(
      workflow: Pick<WorkflowFields, "permissions">,
      currentUser: UserContextType["currentUser"],
    ) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return workflow?.permissions?.canAddLinkedWorkflowTask ?? false;
      }

      // (krashanoff): Only internal users Can Make and above can add linked workflows.
      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canStar(workflow: Pick<WorkflowFields, "permissions">) {
      return workflow.permissions?.canRead;
    }
  }

  export namespace WorkflowTemplate {
    export function canAddAutomation(
      workflowTemplate: Pick<WorkflowTemplateFields, "permissions">,
      currentUser: UserContextType["currentUser"],
    ) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return workflowTemplate?.permissions?.canAddAutomatedTask ?? false;
      }

      // (surya): Only internal users Can Make and above can add automations.
      // This is different from CREATING automations which only the admin can do.
      // That permission is listed out in canCreateAutomations().
      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canAddLinkedWorkflowTask(
      workflowTemplate: Pick<WorkflowTemplateFields, "permissions">,
      currentUser: UserContextType["currentUser"],
    ) {
      if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
        return workflowTemplate?.permissions?.canAddLinkedWorkflowTask ?? false;
      }

      // Only internal users Can Make and above can add linked workflows.
      return currentUser.accessRole != null
        ? [
            AccessRoleName.ADMIN,
            AccessRoleName.INTERNAL_CAN_MAKE,
            AccessRoleName.INTERNAL_CAN_PUBLISH,
            AccessRoleName.INTERNAL_TEAM_ADMIN,
            AccessRoleName.OWNER,
          ].includes(currentUser.accessRole.name)
        : false;
    }

    export function canEditCollaborators(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canEdit ?? false;
    }

    export function canEditWorkflowOwner(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.CanEditWorkflowOwner ?? false;
    }

    export function canDelete(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canDelete ?? false;
    }

    export function canDownload(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canDownload ?? false;
    }

    export function canCreateWorkflowsFrom(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canCreateWorkflowsFrom ?? false;
    }

    export function canEdit(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canEdit ?? false;
    }

    export function canPublish(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canPublish ?? false;
    }

    export function canCopy(workflowTemplate: Pick<WorkflowTemplateFields, "permissions">) {
      return workflowTemplate.permissions?.canCopy ?? false;
    }

    export function canView(workflowTemplate: { permissions?: Maybe<{ canRead?: Maybe<boolean> }> }) {
      return workflowTemplate.permissions?.canRead ?? false;
    }

    export function canExport(workflowTemplate: { permissions?: Maybe<{ canExport?: Maybe<boolean> }> }) {
      return workflowTemplate.permissions?.canExport ?? false;
    }

    export function canUpdateToNewVersion(workflowTemplate: {
      permissions?: Maybe<{ canUpdateToNewVersion?: Maybe<boolean> }>;
    }) {
      return workflowTemplate.permissions?.canUpdateToNewVersion ?? false;
    }

    export function canCreateVariantsOf(workflowTemplate: {
      permissions?: Maybe<{ canCreateVariantsOf?: Maybe<boolean> }>;
    }) {
      return workflowTemplate.permissions?.canCreateVariantsOf ?? false;
    }
  }

  // (hchen): HACKHACK This is a temporary permission check for the new form feature. We will need to
  // integrate with Permissions V2 when it's ready
  export namespace Form {
    const ACCESS_ROLE_LEVEL_CAN_PUBLISH = 50;

    export function canCreate(currentUser: UserContextType["currentUser"]) {
      const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);
      // (wsheehan): TODOWS: This is a temporary workaround which is correct for now but not stable.
      // We will build rbac-form permissions in RBAC m5 for the October 2024 release.
      if (isPermissionsV2Enabled) {
        const isWorkspaceCreator =
          currentUser.permissions.permissionedNavigationFlags.createMenuContentFlags.showCreateWorkflowFromScratch;
        return isWorkspaceCreator;
      }
      return true;
    }

    export function canPublish(
      currentUser: UserContextType["currentUser"],
      userRoles: Array<{
        role: RoleFields;
      }>,
    ) {
      const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);
      // (wsheehan): TODOWS: This is a temporary workaround which is correct for now but not stable.
      // We will build rbac-form permissions in RBAC m5 for the October 2024 release.
      if (isPermissionsV2Enabled) {
        return currentUser.isAdmin || userRoles.some((role) => role.role.name === "Publisher" && role.role.isSystem);
      }
      const level = currentUser.accessRole?.level;
      return level != null && level >= ACCESS_ROLE_LEVEL_CAN_PUBLISH;
    }

    export function canEdit(form?: FormVersionFields["form"]) {
      const isFormsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.REMOVE_FORMS_PAGE_2024_08);
      if (!isFormsV2Enabled) {
        // (hchen): This check is irrelevant if forms V2 is not enabled, because the forms are not
        // associated with workflows or blueprints.
        return true;
      }
      if (form == null) {
        return false;
      }
      return (
        form.workflow?.permissions.canEdit ||
        (form.workflowTemplate?.permissions?.canEdit && form.workflowTemplate?.publishedAt == null)
      );
    }
  }
}
