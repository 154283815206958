import type * as Types from "../../../generated/graphqlBaseTypes";

import { ActionItemDetailFields } from "../../fragments/generated/ActionItemDetailFields.generated";
import { ActionItemFields } from "../../fragments/generated/ActionItemFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { AutomationRequestFields } from "../../fragments/generated/AutomationRequestFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { AutomationRequestParamFields } from "../../fragments/generated/AutomationRequestParamFields.generated";
import { FieldInstanceFields } from "../../fragments/generated/FieldInstanceFields.generated";
import { FieldInstanceBaseFields } from "../../fragments/generated/FieldInstanceBaseFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FieldInstanceSpectrumMetadataFields } from "../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated";
import { FieldInstanceSpectrumFields } from "../../fragments/generated/FieldInstanceSpectrumFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldInstanceValueStringFields } from "../../fragments/generated/FieldInstanceValueStringFields.generated";
import { FieldInstanceValueStringBaseFields } from "../../fragments/generated/FieldInstanceValueStringBaseFields.generated";
import { AcyclicFieldInstanceValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated";
import { FieldInstanceMultiValueStringFields } from "../../fragments/generated/FieldInstanceMultiValueStringFields.generated";
import { FieldInstanceMultiValueStringBaseFields } from "../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated";
import { AcyclicFieldInstanceMultiValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated";
import { FieldInstanceValueIntFields } from "../../fragments/generated/FieldInstanceValueIntFields.generated";
import { FieldInstanceValueIntBaseFields } from "../../fragments/generated/FieldInstanceValueIntBaseFields.generated";
import { AcyclicFieldInstanceValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated";
import { FieldInstanceValueFloatFields } from "../../fragments/generated/FieldInstanceValueFloatFields.generated";
import { FieldInstanceValueFloatBaseFields } from "../../fragments/generated/FieldInstanceValueFloatBaseFields.generated";
import { AcyclicFieldInstanceValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated";
import { FieldInstanceValueBooleanFields } from "../../fragments/generated/FieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueBooleanBaseFields } from "../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated";
import { AcyclicFieldInstanceValueBooleanFields } from "../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueTimeFields } from "../../fragments/generated/FieldInstanceValueTimeFields.generated";
import { FieldInstanceValueTimeBaseFields } from "../../fragments/generated/FieldInstanceValueTimeBaseFields.generated";
import { AcyclicFieldInstanceValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated";
import { AcyclicFieldInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceFields.generated";
import { AcyclicFieldInstanceValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceValueDateFields.generated";
import { AcyclicFieldInstanceValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValueDocumentFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { DocumentBaseFields } from "../../fragments/generated/DocumentBaseFields.generated";
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { AcyclicFieldInstanceMultiValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated";
import { AcyclicFieldInstanceMultiValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated";
import { AcyclicFieldInstanceMultiValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated";
import { AcyclicFieldInstanceMultiValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDateFields.generated";
import { FieldInstanceValueDateFields } from "../../fragments/generated/FieldInstanceValueDateFields.generated";
import { FieldInstanceValueDateBaseFields } from "../../fragments/generated/FieldInstanceValueDateBaseFields.generated";
import { FieldInstanceValuePartyFields } from "../../fragments/generated/FieldInstanceValuePartyFields.generated";
import { FieldInstanceMultiValuePartyFields } from "../../fragments/generated/FieldInstanceMultiValuePartyFields.generated";
import { FieldInstanceMultiValueDocumentFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { FieldInstanceMultiValueIntFields } from "../../fragments/generated/FieldInstanceMultiValueIntFields.generated";
import { FieldInstanceMultiValueIntBaseFields } from "../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated";
import { FieldInstanceMultiValueFloatFields } from "../../fragments/generated/FieldInstanceMultiValueFloatFields.generated";
import { FieldInstanceMultiValueFloatBaseFields } from "../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated";
import { FieldInstanceMultiValueTimeFields } from "../../fragments/generated/FieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceMultiValueTimeBaseFields } from "../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated";
import { FieldInstanceMultiValueDateFields } from "../../fragments/generated/FieldInstanceMultiValueDateFields.generated";
import { FieldInstanceMultiValueDateBaseFields } from "../../fragments/generated/FieldInstanceMultiValueDateBaseFields.generated";
import { ActionItemApprovalFields } from "../../fragments/generated/ActionItemApprovalFields.generated";
import { ActionItemAuditHistoryFields } from "../../fragments/generated/ActionItemAuditHistoryFields.generated";
import { ActionItemAuditHistoryDeltaFields } from "../../fragments/generated/ActionItemAuditHistoryDeltaFields.generated";
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryDocumentRelatedRecordFields.generated";
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated";
import { RootCommentFields } from "../../fragments/generated/RootCommentFields.generated";
import { CommentFields } from "../../fragments/generated/CommentFields.generated";
import { IntegrationAutomationInstanceFields } from "../../fragments/generated/IntegrationAutomationInstanceFields.generated";
import { IntegrationAutomationSummaryFields } from "../../fragments/generated/IntegrationAutomationSummaryFields.generated";
import { IntegrationAuthenticationFields } from "../../fragments/generated/IntegrationAuthenticationFields.generated";
import { IntegrationAutomationInputFields } from "../../fragments/generated/IntegrationAutomationInputFields.generated";
import { IntegrationAutomationOutputFields } from "../../fragments/generated/IntegrationAutomationOutputFields.generated";
import { ActionItemPermissionsFields } from "../../fragments/generated/ActionItemPermissionsFields.generated";
import { StarWithoutEntityFields } from "../../fragments/generated/StarWithoutEntityFields.generated";
import { ActionItemAdditionalFields } from "../../fragments/generated/ActionItemAdditionalFields.generated";
import { AiAgentRunFields } from "../../fragments/generated/AIAgentRunFields.generated";
import { FieldInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceValueBooleanWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueBooleanWithCrossWorkflowFields.generated";
import { FieldInstanceValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueTimeWithCrossWorkflowFields.generated";
import { FieldInstanceValueDateWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueDateWithCrossWorkflowFields.generated";
import { FieldInstanceValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueDateWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueDateWithCrossWorkflowFields.generated";
import { RegrelloObjectInstancesAuditHistoryFields } from "../../fragments/generated/RegrelloObjectInstancesAuditHistoryFields.generated";
import { RegrelloObjectInstanceAuditHistoryFields } from "../../fragments/generated/RegrelloObjectInstanceAuditHistoryFields.generated";
import { RegrelloObjectInstanceAuditHistoryDeltaFields } from "../../fragments/generated/RegrelloObjectInstanceAuditHistoryDeltaFields.generated";
import { LineFields } from "../../fragments/generated/LineFields.generated";
import { DelegationFields } from "../../fragments/generated/DelegationFields.generated";
import { PartyFields } from "../../fragments/generated/PartyFields.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { ActionItemRegrelloObjectAuditHistoryFields } from "../../fragments/generated/ActionItemRegrelloObjectAuditHistoryFields.generated";
import { RejectActionFields } from "../../fragments/generated/RejectActionFields.generated";
import { FormInstanceFields } from "../../fragments/generated/FormInstanceFields.generated";
import { FormMinimalFields } from "../../fragments/generated/FormMinimalFields.generated";
import { FormVersionFields } from "../../fragments/generated/FormVersionFields.generated";
import { WorkflowTemplatePermissionsFields } from "../../fragments/generated/WorkflowTemplatePermissionsFields.generated";
import { FormSectionFields } from "../../fragments/generated/FormSectionFields.generated";
import { FormSectionBaseFields } from "../../fragments/generated/FormSectionBaseFields.generated";
import { FormFieldFields } from "../../fragments/generated/FormFieldFields.generated";
import { FormFieldConstraintFields } from "../../fragments/generated/FormFieldConstraintFields.generated";
import { FormFieldConstraintRuleFields } from "../../fragments/generated/FormFieldConstraintRuleFields.generated";
import { FormConstraintConditionGroupFields } from "../../fragments/generated/FormConstraintConditionGroupFields.generated";
import { FormConstraintConditionGroupBaseFields } from "../../fragments/generated/FormConstraintConditionGroupBaseFields.generated";
import { FormConstraintConditionFields } from "../../fragments/generated/FormConstraintConditionFields.generated";
import { FormSectionConstraintFields } from "../../fragments/generated/FormSectionConstraintFields.generated";
import { FormSectionConstraintRuleFields } from "../../fragments/generated/FormSectionConstraintRuleFields.generated";
import { FormSectionStatusFields } from "../../fragments/generated/FormSectionStatusFields.generated";
import { FormInstanceSectionMetadataFields } from "../../fragments/generated/FormInstanceSectionMetadataFields.generated";
import { gql } from "@apollo/client";
import { FieldInstanceValueFields } from "../../fragments/generated/FieldInstanceValueFields.generated";
import { AcyclicFieldInstanceValueFields } from "../../fragments/generated/AcyclicFieldInstanceValueFields.generated";
import { FieldInstanceValueWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueWithCrossWorkflowFields.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ActionItemDetailsWithFormInstancesQueryVariables = Types.Exact<{
  actionItemUUID: Types.Scalars["UUID"]["input"];
}>;

export type ActionItemDetailsWithFormInstancesQuery = {
  formInstancesForActionItem?: {
    actionItem: { approvalForActionItem?: ActionItemDetailFields | null } & ActionItemDetailFields;
    formInstances: Array<FormInstanceFields>;
  } | null;
};

export const ActionItemDetailsWithFormInstancesQueryDocument = gql`
    query ActionItemDetailsWithFormInstancesQuery($actionItemUUID: UUID!) {
  formInstancesForActionItem(actionItemUUID: $actionItemUUID) {
    actionItem {
      ...ActionItemDetailFields
      approvalForActionItem {
        ...ActionItemDetailFields
      }
    }
    formInstances {
      ...FormInstanceFields
    }
  }
}
    ${ActionItemDetailFields}
${ActionItemFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${TagFields}
${TagTypeFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${AutomationRequestFields}
${AutomationOAuth2ConfigFields}
${AutomationRequestParamFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValueDateFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${AcyclicFieldInstanceMultiValueDateFields}
${FieldInstanceValueDateFields}
${FieldInstanceValueDateBaseFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${FieldInstanceMultiValueDateFields}
${FieldInstanceMultiValueDateBaseFields}
${ActionItemApprovalFields}
${ActionItemAuditHistoryFields}
${ActionItemAuditHistoryDeltaFields}
${ActionItemAuditHistoryDocumentRelatedRecordFields}
${ActionItemAuditHistoryFieldInstanceRelatedRecordFields}
${RootCommentFields}
${CommentFields}
${IntegrationAutomationInstanceFields}
${IntegrationAutomationSummaryFields}
${IntegrationAuthenticationFields}
${IntegrationAutomationInputFields}
${IntegrationAutomationOutputFields}
${ActionItemPermissionsFields}
${StarWithoutEntityFields}
${ActionItemAdditionalFields}
${AiAgentRunFields}
${FieldInstanceWithCrossWorkflowFields}
${FieldInstanceValueWithCrossWorkflowFields}
${FieldInstanceValueStringWithCrossWorkflowFields}
${FieldInstanceMultiValueStringWithCrossWorkflowFields}
${FieldInstanceValueIntWithCrossWorkflowFields}
${FieldInstanceValueFloatWithCrossWorkflowFields}
${FieldInstanceValueBooleanWithCrossWorkflowFields}
${FieldInstanceValueTimeWithCrossWorkflowFields}
${FieldInstanceValueDateWithCrossWorkflowFields}
${FieldInstanceValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValueDocumentWithCrossWorkflowFields}
${FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields}
${FieldInstanceMultiValueIntWithCrossWorkflowFields}
${FieldInstanceMultiValueFloatWithCrossWorkflowFields}
${FieldInstanceMultiValueTimeWithCrossWorkflowFields}
${FieldInstanceMultiValueDateWithCrossWorkflowFields}
${RegrelloObjectInstancesAuditHistoryFields}
${RegrelloObjectInstanceAuditHistoryFields}
${RegrelloObjectInstanceAuditHistoryDeltaFields}
${LineFields}
${DelegationFields}
${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${ActionItemRegrelloObjectAuditHistoryFields}
${RejectActionFields}
${FormInstanceFields}
${FormMinimalFields}
${FormVersionFields}
${WorkflowTemplatePermissionsFields}
${FormSectionFields}
${FormSectionBaseFields}
${FormFieldFields}
${FormFieldConstraintFields}
${FormFieldConstraintRuleFields}
${FormConstraintConditionGroupFields}
${FormConstraintConditionGroupBaseFields}
${FormConstraintConditionFields}
${FormSectionConstraintFields}
${FormSectionConstraintRuleFields}
${FormSectionStatusFields}
${FormInstanceSectionMetadataFields}`;

/**
 * __useActionItemDetailsWithFormInstancesQuery__
 *
 * To run a query within a React component, call `useActionItemDetailsWithFormInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemDetailsWithFormInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemDetailsWithFormInstancesQuery({
 *   variables: {
 *      actionItemUUID: // value for 'actionItemUUID'
 *   },
 * });
 */
export function useActionItemDetailsWithFormInstancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActionItemDetailsWithFormInstancesQuery,
    ActionItemDetailsWithFormInstancesQueryVariables
  > &
    ({ variables: ActionItemDetailsWithFormInstancesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActionItemDetailsWithFormInstancesQuery, ActionItemDetailsWithFormInstancesQueryVariables>(
    ActionItemDetailsWithFormInstancesQueryDocument,
    options,
  );
}
export function useActionItemDetailsWithFormInstancesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActionItemDetailsWithFormInstancesQuery,
    ActionItemDetailsWithFormInstancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActionItemDetailsWithFormInstancesQuery, ActionItemDetailsWithFormInstancesQueryVariables>(
    ActionItemDetailsWithFormInstancesQueryDocument,
    options,
  );
}
export function useActionItemDetailsWithFormInstancesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActionItemDetailsWithFormInstancesQuery,
    ActionItemDetailsWithFormInstancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActionItemDetailsWithFormInstancesQuery,
    ActionItemDetailsWithFormInstancesQueryVariables
  >(ActionItemDetailsWithFormInstancesQueryDocument, options);
}
export type ActionItemDetailsWithFormInstancesQueryHookResult = ReturnType<
  typeof useActionItemDetailsWithFormInstancesQuery
>;
export type ActionItemDetailsWithFormInstancesQueryLazyQueryHookResult = ReturnType<
  typeof useActionItemDetailsWithFormInstancesQueryLazyQuery
>;
export type ActionItemDetailsWithFormInstancesQuerySuspenseQueryHookResult = ReturnType<
  typeof useActionItemDetailsWithFormInstancesQuerySuspenseQuery
>;
export type ActionItemDetailsWithFormInstancesQueryResult = Apollo.QueryResult<
  ActionItemDetailsWithFormInstancesQuery,
  ActionItemDetailsWithFormInstancesQueryVariables
>;
