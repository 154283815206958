import type * as Types from "../../../generated/graphqlBaseTypes";

import { FieldInstanceFields } from "../../fragments/generated/FieldInstanceFields.generated";
import { FieldInstanceBaseFields } from "../../fragments/generated/FieldInstanceBaseFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FieldInstanceSpectrumMetadataFields } from "../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated";
import { FieldInstanceSpectrumFields } from "../../fragments/generated/FieldInstanceSpectrumFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldInstanceValueStringFields } from "../../fragments/generated/FieldInstanceValueStringFields.generated";
import { FieldInstanceValueStringBaseFields } from "../../fragments/generated/FieldInstanceValueStringBaseFields.generated";
import { AcyclicFieldInstanceValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated";
import { FieldInstanceMultiValueStringFields } from "../../fragments/generated/FieldInstanceMultiValueStringFields.generated";
import { FieldInstanceMultiValueStringBaseFields } from "../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated";
import { AcyclicFieldInstanceMultiValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated";
import { FieldInstanceValueIntFields } from "../../fragments/generated/FieldInstanceValueIntFields.generated";
import { FieldInstanceValueIntBaseFields } from "../../fragments/generated/FieldInstanceValueIntBaseFields.generated";
import { AcyclicFieldInstanceValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated";
import { FieldInstanceValueFloatFields } from "../../fragments/generated/FieldInstanceValueFloatFields.generated";
import { FieldInstanceValueFloatBaseFields } from "../../fragments/generated/FieldInstanceValueFloatBaseFields.generated";
import { AcyclicFieldInstanceValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated";
import { FieldInstanceValueBooleanFields } from "../../fragments/generated/FieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueBooleanBaseFields } from "../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated";
import { AcyclicFieldInstanceValueBooleanFields } from "../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueTimeFields } from "../../fragments/generated/FieldInstanceValueTimeFields.generated";
import { FieldInstanceValueTimeBaseFields } from "../../fragments/generated/FieldInstanceValueTimeBaseFields.generated";
import { AcyclicFieldInstanceValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated";
import { AcyclicFieldInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceFields.generated";
import { AcyclicFieldInstanceValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceValueDateFields.generated";
import { AcyclicFieldInstanceValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValueDocumentFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { DocumentBaseFields } from "../../fragments/generated/DocumentBaseFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { AcyclicFieldInstanceMultiValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated";
import { AcyclicFieldInstanceMultiValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated";
import { AcyclicFieldInstanceMultiValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated";
import { AcyclicFieldInstanceMultiValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDateFields.generated";
import { FieldInstanceValueDateFields } from "../../fragments/generated/FieldInstanceValueDateFields.generated";
import { FieldInstanceValueDateBaseFields } from "../../fragments/generated/FieldInstanceValueDateBaseFields.generated";
import { FieldInstanceValuePartyFields } from "../../fragments/generated/FieldInstanceValuePartyFields.generated";
import { FieldInstanceMultiValuePartyFields } from "../../fragments/generated/FieldInstanceMultiValuePartyFields.generated";
import { FieldInstanceMultiValueDocumentFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { FieldInstanceMultiValueIntFields } from "../../fragments/generated/FieldInstanceMultiValueIntFields.generated";
import { FieldInstanceMultiValueIntBaseFields } from "../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated";
import { FieldInstanceMultiValueFloatFields } from "../../fragments/generated/FieldInstanceMultiValueFloatFields.generated";
import { FieldInstanceMultiValueFloatBaseFields } from "../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated";
import { FieldInstanceMultiValueTimeFields } from "../../fragments/generated/FieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceMultiValueTimeBaseFields } from "../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated";
import { FieldInstanceMultiValueDateFields } from "../../fragments/generated/FieldInstanceMultiValueDateFields.generated";
import { FieldInstanceMultiValueDateBaseFields } from "../../fragments/generated/FieldInstanceMultiValueDateBaseFields.generated";
import { FieldInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceValueBooleanWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueBooleanWithCrossWorkflowFields.generated";
import { FieldInstanceValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueTimeWithCrossWorkflowFields.generated";
import { FieldInstanceValueDateWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueDateWithCrossWorkflowFields.generated";
import { FieldInstanceValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueDateWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueDateWithCrossWorkflowFields.generated";
import { gql } from "@apollo/client";
import { FieldInstanceValueFields } from "../../fragments/generated/FieldInstanceValueFields.generated";
import { AcyclicFieldInstanceValueFields } from "../../fragments/generated/AcyclicFieldInstanceValueFields.generated";
import { FieldInstanceValueWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueWithCrossWorkflowFields.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables = Types.Exact<{
  currentWorkflowStageId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  workflowId: Types.Scalars["ID"]["input"];
  dependingOnActionItemTemplateId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  dependingOnWorkflowStageIds?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
}>;

export type FieldInstancesAvailableAsSourceValuesInWorkflowQuery = {
  fieldInstancesAvailableAsSourceValuesInWorkflow?: {
    actionItemFieldInstances: Array<FieldInstanceFields>;
    actionItemTemplateFieldInstances: Array<FieldInstanceWithCrossWorkflowFields>;
    workflowFieldInstances: Array<FieldInstanceWithCrossWorkflowFields>;
    workflowTemplateFieldInstances: Array<FieldInstanceFields>;
  } | null;
};

export const FieldInstancesAvailableAsSourceValuesInWorkflowQueryDocument = gql`
    query FieldInstancesAvailableAsSourceValuesInWorkflowQuery($currentWorkflowStageId: ID, $workflowId: ID!, $dependingOnActionItemTemplateId: ID, $dependingOnWorkflowStageIds: [ID!]) {
  fieldInstancesAvailableAsSourceValuesInWorkflow(
    currentWorkflowStageId: $currentWorkflowStageId
    workflowId: $workflowId
    dependingOnActionItemTemplateID: $dependingOnActionItemTemplateId
    dependingOnWorkflowStageID: null
    dependingOnWorkflowStageIDs: $dependingOnWorkflowStageIds
  ) {
    actionItemFieldInstances {
      ...FieldInstanceFields
    }
    actionItemTemplateFieldInstances {
      ...FieldInstanceWithCrossWorkflowFields
    }
    workflowFieldInstances {
      ...FieldInstanceWithCrossWorkflowFields
    }
    workflowTemplateFieldInstances {
      ...FieldInstanceFields
    }
  }
}
    ${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValueDateFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${AcyclicFieldInstanceMultiValueDateFields}
${FieldInstanceValueDateFields}
${FieldInstanceValueDateBaseFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${FieldInstanceMultiValueDateFields}
${FieldInstanceMultiValueDateBaseFields}
${FieldInstanceWithCrossWorkflowFields}
${FieldInstanceValueWithCrossWorkflowFields}
${FieldInstanceValueStringWithCrossWorkflowFields}
${FieldInstanceMultiValueStringWithCrossWorkflowFields}
${FieldInstanceValueIntWithCrossWorkflowFields}
${FieldInstanceValueFloatWithCrossWorkflowFields}
${FieldInstanceValueBooleanWithCrossWorkflowFields}
${FieldInstanceValueTimeWithCrossWorkflowFields}
${FieldInstanceValueDateWithCrossWorkflowFields}
${FieldInstanceValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValueDocumentWithCrossWorkflowFields}
${FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields}
${FieldInstanceMultiValueIntWithCrossWorkflowFields}
${FieldInstanceMultiValueFloatWithCrossWorkflowFields}
${FieldInstanceMultiValueTimeWithCrossWorkflowFields}
${FieldInstanceMultiValueDateWithCrossWorkflowFields}`;

/**
 * __useFieldInstancesAvailableAsSourceValuesInWorkflowQuery__
 *
 * To run a query within a React component, call `useFieldInstancesAvailableAsSourceValuesInWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldInstancesAvailableAsSourceValuesInWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldInstancesAvailableAsSourceValuesInWorkflowQuery({
 *   variables: {
 *      currentWorkflowStageId: // value for 'currentWorkflowStageId'
 *      workflowId: // value for 'workflowId'
 *      dependingOnActionItemTemplateId: // value for 'dependingOnActionItemTemplateId'
 *      dependingOnWorkflowStageIds: // value for 'dependingOnWorkflowStageIds'
 *   },
 * });
 */
export function useFieldInstancesAvailableAsSourceValuesInWorkflowQuery(
  baseOptions: Apollo.QueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  > &
    ({ variables: FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowQueryDocument, options);
}
export function useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowQueryDocument, options);
}
export function useFieldInstancesAvailableAsSourceValuesInWorkflowQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
    FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
  >(FieldInstancesAvailableAsSourceValuesInWorkflowQueryDocument, options);
}
export type FieldInstancesAvailableAsSourceValuesInWorkflowQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowQuerySuspenseQueryHookResult = ReturnType<
  typeof useFieldInstancesAvailableAsSourceValuesInWorkflowQuerySuspenseQuery
>;
export type FieldInstancesAvailableAsSourceValuesInWorkflowQueryResult = Apollo.QueryResult<
  FieldInstancesAvailableAsSourceValuesInWorkflowQuery,
  FieldInstancesAvailableAsSourceValuesInWorkflowQueryVariables
>;
