import type { ColDef, GridApi } from "@ag-grid-community/core";
import type {
  FormFieldConstraintFields,
  FormFieldFields,
  FormSectionBaseFields,
  SpectrumFieldVersionFields,
} from "@regrello/graphql-api";

import { DocumentFieldPlugin } from "../../../molecules/customFields/plugins/DocumentFieldPlugin";
import { RegrelloObjectFieldPlugin } from "../../../molecules/customFields/plugins/RegrelloObjectFieldPlugin";
import { SignatureFieldPlugin } from "../../../molecules/customFields/plugins/SignatureFieldPlugin";
import { SpectrumFieldPluginRegistrar } from "../../../molecules/spectrumFields/registry/spectrumFieldPluginRegistrar";
import { SpectrumDocumentFieldPluginDecorator } from "../../../molecules/spectrumFields/SpectrumDocumentFieldPluginDecorator";
import { SpectrumObjectFieldPluginDecorator } from "../../../molecules/spectrumFields/SpectrumObjectFieldPluginDecorator";
import { SpectrumSignatureFieldPluginDecorator } from "../../../molecules/spectrumFields/SpectrumSignatureFieldPluginDecorator";
import type { FrontendSpectrumFormInstance } from "../../modals/taskDetailView/_internal/form/spectrumFormInstanceUtils";
import type { DragItemPayload } from "../spectrumForm/formBuilder/_internal/content/_internal/dndUtils";
import type { ColumnDefContext } from "../spectrumForm/formBuilder/_internal/content/table/TableColumnHeader";

export const GRID_OPTION_KEY_COLUMN_DEFS = "columnDefs" as const;
export type FormBuilderTableRowDatum = Record<`column-${number | string}`, string | FormFieldConstraintFields>;

export type RowDatum = {
  id: string;
  [key: `column-${string}`]: unknown;
};

/**
 * Focuses a column and selects all visible cells in that column.
 */
export function focusColumn(api: GridApi, columnKey: string) {
  api.setFocusedHeader(columnKey);
  api.clearCellSelection();
  api.addCellRange({
    rowStartIndex: 0,
    rowEndIndex: api.getLastDisplayedRowIndex(),
    columns: [columnKey],
  });
}

/**
 * Returns a column ID for a given index.
 */
export function getColumnIdFromIndex(index: number): `column-${number}` {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `column-${index}`;
}

export function getColumnIdFromFormField(formField: FormFieldFields): `column-${string}` {
  // (hchen): Include the name for easier debugging. This Regex is ripping out all whitespaces and
  // connecting words with a dash.
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `column-${formField.spectrumFieldVersion.name.trim().replace(/\s+/g, "-").toLowerCase()}-${formField.uuid}`;
}

export function reassignColumnIdByIndex(
  columnDefs: Array<ColDef<FormBuilderTableRowDatum>>,
): Array<ColDef<FormBuilderTableRowDatum>> {
  return columnDefs.map((columnDef, index) => {
    const typedContext = columnDef.context as ColumnDefContext;
    if (typedContext?.type === "formField") {
      return {
        ...columnDef,
        colId: getColumnIdFromFormField(typedContext.formField),
        field: getColumnIdFromFormField(typedContext.formField),
      };
    }
    return { ...columnDef, colId: getColumnIdFromIndex(index), field: getColumnIdFromIndex(index) };
  });
}

export function canDropFieldOfType(draggedItem: DragItemPayload) {
  const extractSpectrumFieldVersion = (draggedItemInternal: DragItemPayload) => {
    if (draggedItemInternal.type === "formField") {
      return draggedItemInternal.formField.spectrumFieldVersion;
    }
    if (draggedItemInternal.type === "spectrumField") {
      return draggedItemInternal.spectrumField;
    }
    return undefined;
  };

  const spectrumFieldVersion = extractSpectrumFieldVersion(draggedItem);
  if (spectrumFieldVersion == null) {
    return true;
  }

  return canSelectColumnOfType(spectrumFieldVersion);
}

export function canSelectColumnOfType(spectrumFieldVersion: SpectrumFieldVersionFields) {
  const unselectableFieldTypePlugins = [
    new SpectrumDocumentFieldPluginDecorator(DocumentFieldPlugin),
    new SpectrumSignatureFieldPluginDecorator(SignatureFieldPlugin),
    new SpectrumObjectFieldPluginDecorator(RegrelloObjectFieldPlugin),
  ];
  return unselectableFieldTypePlugins.every((plugin) => {
    return !plugin.canProcessSpectrumField.bind(plugin)(spectrumFieldVersion);
  });
}

export function getRowDataFromTableSection(
  table: FormSectionBaseFields,
  spectrumFormInstance: FrontendSpectrumFormInstance,
) {
  const rowData = new Map<number, RowDatum>();
  for (const formField of table.formFields) {
    const { spectrumFieldVersion } = formField;
    const { fieldInstance } = spectrumFormInstance.getCompositeFieldInstanceByFormField(formField);
    const tabularValues =
      SpectrumFieldPluginRegistrar.getPluginForSpectrumField(spectrumFieldVersion).getTabularValueForFrontend(
        fieldInstance,
      );
    for (const value of tabularValues) {
      const rowDatum = rowData.get(value.rowNumber);
      if (rowDatum == null) {
        rowData.set(value.rowNumber, {
          id: value.rowNumber.toString(),
          [getColumnIdFromFormField(formField)]: value.value,
        });
      } else {
        rowDatum[getColumnIdFromFormField(formField)] = value.value;
      }
    }
  }
  return Array.from(rowData.values());
}
