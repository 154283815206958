import type { FieldInstanceMultiValueDateBaseFields } from "./FieldInstanceMultiValueDateBaseFields.generated";
import type { AcyclicFieldInstanceMultiValueDateFields } from "./AcyclicFieldInstanceMultiValueDateFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueDateFields = {
  sourceFieldInstanceMultiValueDate?: AcyclicFieldInstanceMultiValueDateFields | null;
  sinksFieldInstanceMultiValueDate: Array<AcyclicFieldInstanceMultiValueDateFields>;
} & FieldInstanceMultiValueDateBaseFields;

export const FieldInstanceMultiValueDateFields = gql`
    fragment FieldInstanceMultiValueDateFields on FieldInstanceMultiValueDate {
  ...FieldInstanceMultiValueDateBaseFields
  sourceFieldInstanceMultiValueDate {
    ...AcyclicFieldInstanceMultiValueDateFields
  }
  sinksFieldInstanceMultiValueDate {
    ...AcyclicFieldInstanceMultiValueDateFields
  }
}
    `;
