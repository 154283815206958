import { EMPTY_STRING } from "@regrello/core-utils";
import type { FieldInstanceFields, FieldInstanceSourceFields } from "@regrello/graphql-api";

import { type CustomFieldSource, CustomFieldSourceType } from "../../types/CustomFieldSource";
import type { FieldInstanceFieldsWithSource } from "../../types/FieldInstanceFieldsWithSource";
import { CustomFieldPluginRegistrar } from "../../ui/molecules/customFields/plugins/registry/customFieldPluginRegistrar";

/**
 * Given a field instance loaded via GraphQL, returns a frontend construct that describes the source
 * (or context) within which this field instance was added.
 *
 * @param options.omitWorkflowsAndTemplates - Prevents workflows and templates from being
 * in the response. Use-case is hiding the source in the MyWorkflowsTable when it's a workflow or
 * template since it's redundant information.
 */
export function getCustomFieldSource(
  fieldInstance: FieldInstanceFields,
  options: { omitWorkflowsAndTemplates?: boolean } = {},
): CustomFieldSource | undefined {
  const omitWorkflowsAndTemplates = options.omitWorkflowsAndTemplates ?? false;

  // TODO (anthony): Remove. I believe this is deprecated.
  if (fieldInstance.regrelloObjectPropertyId != null) {
    return {
      regrelloObjectPropertyId: fieldInstance.regrelloObjectPropertyId ?? undefined,
      type: CustomFieldSourceType.LINE,
      actionItem:
        fieldInstance.writtenByActionItem != null
          ? {
              ...fieldInstance.writtenByActionItem,
              workflowStage:
                fieldInstance.writtenByActionItem.workflowReference != null
                  ? {
                      id: fieldInstance.writtenByActionItem.workflowReference.stageId,
                      name: fieldInstance.writtenByActionItem.workflowReference.stageName,
                    }
                  : undefined,
            }
          : undefined,
    };
  }
  if (fieldInstance.actionItem != null) {
    return {
      type: CustomFieldSourceType.TASK,
      name: fieldInstance.actionItem.name,
      stageName: fieldInstance.actionItem.workflowReference?.stageName,
    };
  }
  if (fieldInstance.actionItemTemplate != null) {
    return {
      type: CustomFieldSourceType.TASK,
      name: fieldInstance.actionItemTemplate.name,
      stageName: fieldInstance.actionItemTemplate.workflowTemplateReference?.stageTemplateName,
    };
  }
  if (fieldInstance.workflow != null && !omitWorkflowsAndTemplates) {
    return {
      type: CustomFieldSourceType.WORKFLOW,
      name: fieldInstance.workflow.name,
    };
  }
  if (fieldInstance.workflowTemplate != null && !omitWorkflowsAndTemplates) {
    return {
      type: CustomFieldSourceType.WORKFLOW,
      name: fieldInstance.workflowTemplate.name,
    };
  }
  return undefined;
}

/**
 * Given a frontend construct describing the source within which a particular field instance was
 * added, returns the name of that source object.
 */
export function getCustomFieldSourceName(customFieldSource: CustomFieldSource | undefined) {
  return customFieldSource?.type === CustomFieldSourceType.LINE
    ? (customFieldSource.actionItem?.name ?? EMPTY_STRING)
    : (customFieldSource?.name ?? EMPTY_STRING);
}

/**
 * Gets the attributes necessary to build the `source` property of `FieldInstanceFieldsWithSource`.
 */
export function getFieldInstanceFieldsSourceFromFieldInstance(
  maybeFieldInstanceToExtraceSourceFrom?: FieldInstanceFields,
): FieldInstanceFieldsWithSource["source"] {
  const parentSourceType: FieldInstanceFieldsWithSource["source"] = {
    type: "parent",
  };

  // If the source field instance is not defined, it's probably a workflow-level field.
  if (maybeFieldInstanceToExtraceSourceFrom == null) {
    return parentSourceType;
  }

  const { actionItem, actionItemTemplate } = maybeFieldInstanceToExtraceSourceFrom;

  // If there is no action item, then it's a workflow-level field instance.
  if (actionItem?.id == null && actionItemTemplate?.id == null) {
    return parentSourceType;
  }

  // Yay! It's a task-level field instance, add all the yummy deets.
  return {
    actionItemId: actionItem?.id,
    actionItemIsSplitter: actionItem?.isSplitter,
    actionItemName: actionItem?.name,
    actionItemDisplayOrder: actionItem?.displayOrder,
    actionItemTemplateId: actionItemTemplate?.id,
    actionItemTemplateIsSplitter: actionItemTemplate?.isSplitter,
    actionItemTemplateName: actionItemTemplate?.name,
    actionItemTemplateDisplayOrder: actionItemTemplate?.displayOrder,
    splitChildData:
      actionItem?.splitterParentActionItemId != null
        ? {
            splitterParentActionItemId: actionItem.splitterParentActionItemId,
            splitterParentActionItemName: "",
          }
        : undefined,
    stageId: actionItem?.workflowReference?.stageId || actionItemTemplate?.workflowReference?.stageId,
    stageName: actionItem?.workflowReference?.stageName || actionItemTemplate?.workflowReference?.stageName,
    stageExecutionOrder:
      actionItem?.workflowReference?.stageExecutionOrder || actionItemTemplate?.workflowReference?.stageExecutionOrder,
    stageTemplateId: actionItemTemplate?.workflowTemplateReference?.stageTemplateId,
    stageTemplateName: actionItemTemplate?.workflowTemplateReference?.stageTemplateName,
    stageTemplateExecutionOrder: actionItemTemplate?.workflowTemplateReference?.stageTemplateExecutionOrder,
    type: "previous",
    refCount: 1,
  };
}

/**
 * Container for keeping referential integrity of each field instance during stage rearrangement.
 */
interface FieldInstanceWrappedWithIndex {
  fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithSource;

  /**
   * Index of the field instance in the original array, before getting rearranged into stage groups.
   */
  index: number;
}

type FieldInstancesWithSourceGroupedByStageIdOrStageTemplateId = Record<
  string,
  {
    stageNameOrStageTemplateName: string;
    stageExecutionOrder: number;
    fieldInstancesWrappedWithIndex: FieldInstanceWrappedWithIndex[];
  }
>;
/** Groups all provided field instances by their source stage or stage template. */
export function groupFieldInstancesWithSourceByStageIdOrStageTemplateId(
  fieldInstances: FieldInstanceFieldsWithSource[],
) {
  const result: FieldInstancesWithSourceGroupedByStageIdOrStageTemplateId = {};

  fieldInstances.forEach((fieldInstance, index) => {
    if (fieldInstance.source?.type === "parent") {
      return;
    }

    const sourceStageIdOrStageTemplateId = fieldInstance.source?.stageId ?? fieldInstance.source?.stageTemplateId;
    const sourceStageNameOrStageTemplateName =
      fieldInstance.source?.stageName ?? fieldInstance.source?.stageTemplateName;
    const sourceStageExecutionOrder =
      fieldInstance.source?.stageExecutionOrder ?? fieldInstance.source?.stageTemplateExecutionOrder;

    if (sourceStageIdOrStageTemplateId == null) {
      return;
    }

    if (result[sourceStageIdOrStageTemplateId] == null) {
      result[sourceStageIdOrStageTemplateId] = {
        stageNameOrStageTemplateName: sourceStageNameOrStageTemplateName ?? EMPTY_STRING,
        stageExecutionOrder: sourceStageExecutionOrder ?? -1,
        fieldInstancesWrappedWithIndex: [],
      };
    }

    result[sourceStageIdOrStageTemplateId].fieldInstancesWrappedWithIndex.push({
      fieldInstance,
      index,
    });
  });

  return result;
}

/**
 * Attempts to find a `FieldInstanceFields` to use as a source for the first `FieldInstanceFields`
 * and adds it to the first on the `source` property.
 */
function addSourceToFieldInstance(
  fieldInstanceToAddSourceDataTo: FieldInstanceFields | FieldInstanceFieldsWithSource,
  fieldInstanceToUseAsSource?: FieldInstanceFields | FieldInstanceFieldsWithSource,
): FieldInstanceFieldsWithSource {
  return {
    ...fieldInstanceToAddSourceDataTo,
    source: getFieldInstanceFieldsSourceFromFieldInstance(fieldInstanceToUseAsSource),
  };
}

/**
 * Adds `FieldInstanceSource` data to `FieldInstanceFields` records by matching each `sourceId` to
 * one of the `fieldInstancesAvailableAsSourceValues`.
 */
export function hydrateSourcesInFieldInstances(
  /**
   * Field instances that have a value `sourceId` but do not have any other information populated
   * for the source.
   */
  fieldInstancesToAddSourceDataTo: Array<FieldInstanceFields | FieldInstanceFieldsWithSource>,

  /**
   * All field instances that could possibly be the sources of the field instances provided in the
   * first parameter.
   *
   * @example `[...fieldInstancesFromParent, ...fieldInstancesFromPrevious]`
   */
  fieldInstancesAvailableAsSourceValues: Array<FieldInstanceFields | FieldInstanceFieldsWithSource>,

  /**
   * When `true`, will not allow fields without sources to be in the hydrated set.
   *
   * @default false
   */
  omitFieldsWithoutSources = false,
) {
  const result: FieldInstanceFieldsWithSource[] = [];

  for (const fieldInstance of fieldInstancesToAddSourceDataTo) {
    // Prevent the `CustomFieldPluginRegistrar` from hitting an unexpected field placeholder from
    // around the app.
    if (fieldInstance.field.id == null) {
      continue;
    }

    const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);

    const sourceId =
      customFieldPlugin.getSourceFieldInstanceId(fieldInstance) ??
      customFieldPlugin.getCrossWorkflowSourceFieldInstanceIdFromValue(fieldInstance);

    let sourceFieldInstance: FieldInstanceFields | undefined;

    // It is its own source.
    if (sourceId == null) {
      sourceFieldInstance = fieldInstancesAvailableAsSourceValues.find(
        (fieldInstanceInContext) => fieldInstanceInContext.values[0].id === fieldInstance.values[0].id,
      );
    }
    // It has a source.
    else {
      sourceFieldInstance = fieldInstancesAvailableAsSourceValues.find(
        (fieldInstanceInContext) => fieldInstanceInContext.values[0].id === sourceId,
      );
    }

    if (sourceFieldInstance == null && omitFieldsWithoutSources) {
      continue;
    }

    result.push(addSourceToFieldInstance(fieldInstance, sourceFieldInstance));
  }

  return result;
}

/**
 * Adds field source data to `FieldInstanceFields` records by matching each `sourceId` to one of the
 * `FieldInstanceSourceFields`.
 */
export function hydrateSourcesInFieldInstancesV2(
  /**
   * Field instances that have a value `sourceId` but do not have any other information populated
   * for the source.
   */
  fieldInstancesToAddSourceDataTo: Array<FieldInstanceFields | FieldInstanceFieldsWithSource>,

  /**
   * All field instances sources that could possibly be the sources of the field instances provided
   * in the first parameter.
   */
  fieldInstanceSources: FieldInstanceSourceFields[],

  /**
   * When `true`, will not allow fields without sources to be in the hydrated set.
   *
   * @default false
   */
  omitFieldsWithoutSources = false,
) {
  const refCountMap: Record<number, number> = {};
  const result: FieldInstanceFieldsWithSource[] = [];

  // (dosipiuk): We need to count the number of times field is referenced to only show source
  // information if it's referenced more than once.
  for (const fieldInstance of fieldInstancesToAddSourceDataTo) {
    if (fieldInstance.field.id != null) {
      const oldCount = refCountMap[fieldInstance.field.id];
      refCountMap[fieldInstance.field.id] = oldCount != null ? refCountMap[fieldInstance.field.id] + 1 : 1;
    }
  }

  for (const fieldInstance of fieldInstancesToAddSourceDataTo) {
    // Prevent the `CustomFieldPluginRegistrar` from hitting an unexpected field placeholder from
    // around the app.
    if (fieldInstance.field.id == null) {
      continue;
    }

    const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);

    const sourceId =
      customFieldPlugin.getSourceFieldInstanceId(fieldInstance) ??
      customFieldPlugin.getCrossWorkflowSourceFieldInstanceIdFromValue(fieldInstance);

    // TODO (anthony): Handle this case in blueprints after Dragonfruit. See
    // `hydrateSourcesInFieldInstances`.
    if (sourceId == null && omitFieldsWithoutSources) {
      continue;
    }

    const sourceFieldInstance = fieldInstanceSources.find(({ fieldInstanceId }) => fieldInstanceId === sourceId);

    if (sourceFieldInstance == null && omitFieldsWithoutSources) {
      continue;
    }

    if (sourceFieldInstance == null) {
      result.push({
        ...fieldInstance,
        source: {
          type: "parent",
        },
      });
    } else {
      result.push({
        ...fieldInstance,
        source: {
          ...sourceFieldInstance,
          type: "previous",
          refCount: refCountMap[fieldInstance.field.id] ?? 1,
        },
      });
    }
  }

  return result;
}

/**
 * Given a frontend construct describing the source within which a particular field instance was
 * added, returns the name of the stage, if one exists, that the source object belongs to.
 */
export function maybeGetCustomFieldSourceStageName(customFieldSource: CustomFieldSource | undefined) {
  return customFieldSource?.type === CustomFieldSourceType.TASK ? customFieldSource.stageName : undefined;
}
