import { t } from "@lingui/core/macro";
import { comparatorIgnoreCase, EMPTY_STRING } from "@regrello/core-utils";
import {
  AccessRoleUserScope,
  ControllerBehaviorModifierType,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type PartyBaseFields,
  type PartyFields,
  type RoleFields,
  TeamType,
  UserAccessLevel,
  type UserFields,
  UserType,
} from "@regrello/graphql-api";

import { getFieldInstanceId } from "../customFields/getFieldInstanceId";
import { PartyBaseTypeUnion } from "./PartyBaseTypeUnion";
import { type NonFieldInstancePartyTypeUnion, PartyTypeUnion } from "./PartyTypeUnion";

/**
 * Unique id that indicates that the party is an inherited user field instance. This was arbitrarily
 * chosen to not interfere with other reserved IDs.
 */
export const USER_FIELD_INSTANCE_PARTY_ID = -6;

/**
 * Unique id to indicate the party is a role.
 */
export const ROLE_PARTY_ID = -7;

export function isPartyBaseFields(party: PartyFields | PartyBaseFields): party is PartyBaseFields {
  return !(party.user != null && "createdBy" in party.user);
}

export function isPartyDeleted(party: PartyTypeUnion): boolean {
  return PartyTypeUnion.visit(party, {
    user: (user) => user.deletedAt != null,
    baseUser: (baseUser) => baseUser.deletedAt != null,
    userWithRoles: (userWithRoles) => userWithRoles.deletedAt != null,
    fieldInstance: () => false,
    team: (team) => team.deletedAt != null,
    role: (role) => role.deletedAt != null,
    unknown: () => false,
  });
}

export function getPartyBaseTypeUnion(party: PartyFields | PartyBaseFields): PartyBaseTypeUnion {
  if (party.user != null) {
    return PartyBaseTypeUnion.user(party.user);
  }
  if (party.team != null) {
    return PartyBaseTypeUnion.team(party.team);
  }
  throw new Error(`Invalid party: ${party.id}`);
}

export function getPartyEmail(party: PartyTypeUnion): string | undefined {
  return PartyTypeUnion.visit(party, {
    user: (user) => user.email,
    baseUser: (baseUser) => baseUser.email,
    userWithRoles: (userWithRoles) => userWithRoles.email,
    fieldInstance: () => undefined,
    team: (team) => (team.email === EMPTY_STRING ? undefined : team.email),
    role: () => undefined,
    unknown: () => undefined,
  });
}

export function getPartyFieldInstance(party: PartyTypeUnion) {
  return PartyTypeUnion.visit(party, {
    user: () => undefined,
    baseUser: () => undefined,
    userWithRoles: () => undefined,
    fieldInstance: (fieldInstance) => fieldInstance,
    team: () => undefined,
    role: () => undefined,
    unknown: () => undefined,
  });
}

export function getPartyAccessRoleUserScope(party: PartyTypeUnion): AccessRoleUserScope {
  return PartyTypeUnion.visit(party, {
    user: (user) => user.accessRole?.userScope ?? AccessRoleUserScope.EXTERNAL,
    baseUser: (baseUser) => baseUser.accessRole?.userScope ?? AccessRoleUserScope.EXTERNAL,
    userWithRoles: (userWithRoles) => userWithRoles.accessRole?.userScope ?? AccessRoleUserScope.EXTERNAL,
    fieldInstance: () => AccessRoleUserScope.EXTERNAL,
    team: (team) => (team.type === TeamType.INTERNAL ? AccessRoleUserScope.INTERNAL : AccessRoleUserScope.EXTERNAL),
    role: () => {
      throw new Error("Roles can only be used in permissions v2.");
    },
    unknown: () => {
      throw new Error("Invalid party type");
    },
  });
}

export function isPartyInternalPermissionV2(party: PartyTypeUnion): boolean {
  return PartyTypeUnion.visit(party, {
    user: (user) => user.accessLevel === UserAccessLevel.INTERNAL,
    baseUser: (baseUser) => baseUser.accessRole?.userScope === AccessRoleUserScope.INTERNAL,
    userWithRoles: (userWithRoles) => userWithRoles.accessRole?.userScope === AccessRoleUserScope.INTERNAL,
    fieldInstance: () => false,
    team: (team) => team.type === TeamType.INTERNAL,
    role: () => false,
    unknown: () => {
      throw new Error("Invalid party type");
    },
  });
}

/** e.g. "John Smith" */
export function getPartyFullName(party: PartyTypeUnion): string {
  return PartyTypeUnion.visit(party, {
    user: (user) => getUserFullName(user.name, user.email, user.userType),
    baseUser: (baseUser) => getUserFullName(baseUser.name, baseUser.email, baseUser.userType),
    userWithRoles: (userWithRoles) => getUserFullName(userWithRoles.name, userWithRoles.email, userWithRoles.userType),
    fieldInstance: (fieldInstance) => getFieldInstanceName(fieldInstance),
    team: (team) => team.name,
    role: (role) => role.name,
    unknown: () => t`Unknown`,
  });
}

export function getFieldInstanceName(fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): string {
  const fieldName = fieldInstance.spectrumFieldVersion?.name ?? EMPTY_STRING;

  // FIXME (swerner): Needs to be checked and clarified how this works together with #i18n. #I18NMGR
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const ManagerSuffix = "'s manager";

  if (
    fieldInstance.controllerBehaviorModifier === ControllerBehaviorModifierType.MANAGERS_OF &&
    !fieldName.endsWith(ManagerSuffix)
  ) {
    return fieldName + ManagerSuffix;
  }

  return fieldName;
}

export namespace ScimPartyUtils {
  // Whether or not the party was added to the workspace via scim.
  export function isPartyAddedViaScim(party: PartyTypeUnion): boolean {
    return PartyTypeUnion.visit(party, {
      user: (user) => user.scimDetails.isAddedViaScim,
      // (elle) do not expect baseUser to find scim information as it is meant to display full user details.
      userWithRoles: () => false,
      baseUser: () => false,
      fieldInstance: () => false,
      team: () => false,
      role: () => false,
      unknown: () => false,
    });
  }

  // Whether or not the party is being actively scim provisioned or not.
  export function isPartyActivelyScimProvisioned(party: PartyTypeUnion): boolean {
    return PartyTypeUnion.visit(party, {
      user: (user) => user.scimDetails.isActivelyScimProvisioned,
      // (elle) do not expect baseUser to find scim information as it is meant to display full user details.
      userWithRoles: () => false,
      baseUser: () => false,
      fieldInstance: () => false,
      team: () => false,
      role: () => false,
      unknown: () => false,
    });
  }

  // Return whether the party is a user managed via SCIM.
  export function isActiveScimUser(party: PartyTypeUnion, isScimPermissionsEnabled: boolean): boolean {
    return PartyTypeUnion.visit(party, {
      user: (user) =>
        user.scimDetails.isActivelyScimProvisioned &&
        user.scimDetails.isAddedViaScim &&
        // (elle): Ignore user.scimDetails.managePermissions when isScimPermissionsEnabled is enabled.
        (isScimPermissionsEnabled ? true : user.scimDetails.managePermissions),
      // (elle) do not expect baseUser to find scim information as it is meant to display full user details.
      userWithRoles: () => false,
      baseUser: () => false,
      fieldInstance: () => false,
      team: () => false,
      role: () => false,
      unknown: () => false,
    });
  }
}

export function getPartyCreator(party: PartyTypeUnion): PartyTypeUnion | undefined {
  return PartyTypeUnion.visit(party, {
    user: (user) => (user.createdBy != null ? PartyTypeUnion.user(user.createdBy as UserFields) : undefined),
    // (elle) do not expect baseUser to call getPartyCreator as it is meant to display full user details.
    userWithRoles: () => undefined,
    baseUser: () => undefined,
    fieldInstance: () => undefined,
    role: (role) => {
      // AdminRoleFields has `createdByUser` on it.
      if ("createdByUser" in role && role.createdByUser != null) {
        return PartyTypeUnion.user(role.createdByUser);
      }

      return undefined;
    },
    team: () => undefined,
    unknown: () => undefined,
  });
}

/**
 * e.g. "John Smith" or "john.smith@" (extracted from the email address), if the party's name is
 * not yet set.
 */
export function getUserFullName(name: string | null | undefined, email: string, userType: string | null): string {
  if (userType === UserType.SCIM_SERVICE_ACCOUNT) {
    return t`SCIM Integration`;
  }

  if (name != null && name.trim().length > 0) {
    return name.trim();
  }

  // ex. "name@domain.com" -> "name@ (Domain)"
  const indexOfAtSign = email.indexOf("@");
  // (clewis): Include the @ to clarify that the name is coming from an email address.
  return indexOfAtSign >= 0 ? email.substring(0, indexOfAtSign + 1) : t`Unknown`;
}

/** e.g. "John Smith (Regrello)" */
export function getPartyFullNameAndOrganization(party: PartyTypeUnion): string {
  return PartyTypeUnion.visit(party, {
    user: (user) => getUserFullNameAndOrganizationFromParts(user.name, user.email, user.organization?.name),
    userWithRoles: (userWithRoles) =>
      getUserFullNameAndOrganizationFromParts(userWithRoles.name, userWithRoles.email, null),
    baseUser: (baseUser) => getUserFullNameAndOrganizationFromParts(baseUser.name, baseUser.email, null),
    fieldInstance: (fieldInstance) => getFieldInstanceName(fieldInstance),
    team: (team) => team.name,
    role: (role) => role.name,
    unknown: () => t`Unknown`,
  });
}

/** e.g. "John Smith (Regrello)" */
export function getUserFullNameAndOrganizationFromParts(
  name: string | null | undefined,
  email: string,
  organizationName: string | null | undefined,
): string {
  const fullName = getUserFullName(name, email, null);

  if (organizationName != null && organizationName.length > 0) {
    return `${fullName} (${organizationName})`;
  }

  return fullName;
}

/**
 * Returns a unique key for the party that can be used in a menu item.
 */
export function getMenuItemKey(party: PartyTypeUnion | NonFieldInstancePartyTypeUnion): string {
  return PartyTypeUnion.visit(party, {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    user: () => `party-${getPartyId(party)}`,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    baseUser: () => `party-${getPartyId(party)}`,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    userWithRoles: () => `party-${getPartyId(party)}`,

    fieldInstance: (fieldInstance) =>
      `${party.type}-${getFieldInstanceId(fieldInstance)}-${fieldInstance.controllerBehaviorModifier}`,
    role: (role) => `${party.type}-${role.id}`,
    team: () => `${party.type}-${getPartyId(party)}`,
    unknown: () => {
      throw new Error("Unknown party type");
    },
  });
}

export function getPartyId(party: PartyTypeUnion | NonFieldInstancePartyTypeUnion): number {
  // (clewis): I'm not positive, but I *think* user.id, etc., aren't be globally
  // unique across user types? Party ID seems like the most reliable source.
  return PartyTypeUnion.visit(party, {
    user: (user) => user.party.id,
    baseUser: (baseUser) => baseUser.party.id,
    userWithRoles: (userWithRoles) => userWithRoles.party.id,
    fieldInstance: () => USER_FIELD_INSTANCE_PARTY_ID,
    role: () => ROLE_PARTY_ID,
    team: (team) => team.party.id,
    unknown: () => {
      throw new Error("Unknown party type");
    },
  });
}

export function getPartyIds(parties: PartyTypeUnion[] | NonFieldInstancePartyTypeUnion[]): number[] {
  return parties.map(getPartyId);
}

export function getPartyTypeUnion(
  party: {
    roles?: RoleFields[];
  } & PartyFields,
): PartyTypeUnion {
  // (hchen): Check team before user because a team party with team email has both a user and a team.
  if (party.team != null) {
    return PartyTypeUnion.team(party.team);
  }
  if (party.roles != null && party.user != null) {
    return PartyTypeUnion.userWithRoles({
      ...party.user,
      party: {
        id: party.id,
        roles: party.roles,
      },
    });
  }
  if (party.user != null) {
    return PartyTypeUnion.user(party.user);
  }

  throw new Error(`Invalid party: ${party.id}`);
}

export function getPartyTypeUnions(parties: PartyFields[]): PartyTypeUnion[] {
  return parties.map(getPartyTypeUnion);
}

export function getBasePartyTypeUnion(party: PartyBaseFields): PartyTypeUnion {
  // (hchen): Check team before user because a team party with team email has both a user and a team.
  if (party.team != null) {
    return PartyTypeUnion.team(party.team);
  }
  if (party.user != null) {
    return PartyTypeUnion.baseUser(party.user);
  }

  throw new Error(`Invalid party: ${party.id}`);
}

export function getBasePartyTypeUnions(parties: PartyBaseFields[]): PartyTypeUnion[] {
  return parties.map(getBasePartyTypeUnion);
}

export function getUserId(party: PartyTypeUnion) {
  return PartyTypeUnion.visit(party, {
    user: (user) => user.id,
    baseUser: (baseUser) => baseUser.id,
    userWithRoles: (userWithRoles) => userWithRoles.id,
    role: () => undefined,
    fieldInstance: () => undefined,
    team: () => undefined,
    unknown: () => undefined,
  });
}

/**
 * Sorts parties alphabetically by display name (full name + organization). If the list contains
 * different party types, they are sorted in the following order `fieldInstance < team < user`
 */
export function partyListSortComparator(partyA: PartyTypeUnion, partyB: PartyTypeUnion) {
  const partyTypeSortableValue = {
    fieldInstance: 10,
    role: 20,
    team: 30,
    user: 40,
    baseUser: 40,
    userWithRoles: 40,
  };
  if (partyTypeSortableValue[partyA.type] === partyTypeSortableValue[partyB.type]) {
    // (clewis): Ignore case since it doesn't matter.
    return comparatorIgnoreCase(partyA, partyB, getPartyFullNameAndOrganization);
  }
  return partyTypeSortableValue[partyA.type] - partyTypeSortableValue[partyB.type];
}
