import { t } from "@lingui/core/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloCommandItem, RegrelloSpinner } from "@regrello/ui-core";

/**
 * This component can be used in `extraOptions` of `RegrelloFormFieldSelect` to render an `loading` state.
 */
export const RegrelloSelectV2LoadingOption = () => {
  return (
    <RegrelloCommandItem
      disabled={true}
      forceMount={true}
      text={
        <div
          className="flex justify-center items-center gap-2"
          data-testid={DataTestIds.FORM_FIELD_SELECT_LOADING_OPTION}
        >
          <RegrelloSpinner size="small" />
          <p>{t`Loading...`}</p>
        </div>
      }
      value="__SELECT_LOADING_OPTION__"
    />
  );
};
