import { t } from "@lingui/core/macro";
import { assertNever, clsx, EMPTY_STRING } from "@regrello/core-utils";
import React, { useMemo } from "react";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { RegrelloShape } from "../../utils/enums/RegrelloShape";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloBetaChip } from "../chip/RegrelloBetaChip";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloTooltip } from "../tooltip/RegrelloTooltip";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloAvatarProps {
  /**
   * A visual indicator that this party is out of the ordinary in some way. Currently, all
   * options here will show an icon that reveals an explanatory tooltip on hover.
   *
   * - `"inactive"`: Indicates that the party has been deleted.
   * - `"muted"`: Indicates that the party has muted all email notifications.
   */
  indicator?: "inactive" | "muted";

  /**
   * Whether this avatar is being rendered inline with other text. This will cause the avatar to
   * display in flow with the surrounding text.
   *
   * __Note:__ This is really only meaningful for `x-small` and `small` sizes. If you need to render
   * `medium` and above inline with surrounding text, reconsider your design before using this prop.
   *
   * @default false
   */
  inline?: boolean;

  /**
   * Whether to visually emphasize the avatar if `inactive` is `true`.
   *
   * @default false
   */
  isHighlightedWhenInactive?: boolean;

  /**
   * Whether this party is idle, indicating they are online but dormant. This can happen if they
   * have the Regrello tab open but haven't done anything in it in some time—or if they may have
   * even unfocused the page to do something else.
   *
   * @default false
   */
  idle?: boolean;

  /**
   * Whether to stack avatars with the topmost avatar being on the left ("start" side) or right
   * ("end" side).
   *
   * @default "end"
   */
  topmostSide?: "start" | "end";

  /**
   * When this avatar is being rendered as part of a stack of avatars and is not the first in the
   * stack, set this to `true` to show a white left border for visually delineating it from the
   * other avatars in the stack.
   *
   * @default false
   */
  isStackedBordersEnabled?: boolean;

  /**
   * Whether the avatar corresponds to an internal or external party. Renders slightly different
   * styling to differentiate the two. `pending` renders the avatar as a hollow circle with dotted
   * outline.
   *
   * @default "internal"
   */
  scope?: "internal" | "external" | "pending" | "unknown" | "placeholder";

  /** Helper text to render beneath the main {@link text}. Will be more muted than the main text. */
  secondaryText?: string;

  /**
   * The shape of the visual.
   *
   * @default RegrelloShape.CIRCLE
   */
  shape?: RegrelloShape;

  /**
   * The size of the avatar. Passing `RegrelloSize.X_SMALL` will render in a manner appropriate for
   * inline usage (as a chip). Passing `RegrelloSize.X_LARGE` will show only the visual, not the
   * {@link text} or {@link secondaryText}.
   *
   * @default RegrelloSize.MEDIUM
   */
  size?: RegrelloSize;

  /** The primary identifying text to display (e.g., the display name of the party). */
  text?: string;

  /**
   * A square-ish or circular visual element to represent this avatar (e.g., an image or an enclosed
   * visualization of the party's initials).
   */
  visual:
    | { type: "initials"; text: string }
    | { type: "image"; alt: string; src: string }
    | {
        type: "icon";
        iconName: RegrelloIconName;
        intent?: Extract<RegrelloIntent, "primary" | "danger">;
        isFullSize?: boolean;

        /**
         * Whether the icon should be rendered without a visual wrapping container (e.g., bubble /
         * box around it). This applies the color styles directly to the icon versus its parent and
         * makes the icon default to the size of the avatar itself.
         *
         * @default false
         */
        isVisuallyUnwrapped?: boolean;
      };

  /**
   * Add a beta chip after the party's name.
   */
  showBetaChip?: boolean;
}

/** Represents a user or team in Regrello. */
export const RegrelloAvatar = React.memo(
  React.forwardRef(function RegrelloAvatarFn(
    {
      indicator,
      inline: isInline = false,
      isHighlightedWhenInactive,
      idle: isIdle = false,
      isStackedBordersEnabled = false,
      scope = "internal",
      secondaryText,
      shape = RegrelloShape.CIRCLE,
      size = RegrelloSize.MEDIUM,
      text,
      // eslint-disable-next-line lingui/no-unlocalized-strings
      topmostSide = "end",
      visual,
      showBetaChip = false,
    }: RegrelloAvatarProps,
    ref: React.Ref<HTMLElement>,
  ) {
    const visualElement = useMemo(() => {
      const visualClassName = clsx(
        `
        box-border

        flex
        items-center
        justify-center

        bg-primary-icon
        rounded-circular

        w-5
        h-5
        overflow-hidden
      `,
        {
          "w-4 h-4": size === "x-small",
          "w-5 h-5": size === "small",
          "w-6 h-6": size === "medium",
          "w-9 h-9": size === "large",
          "w-10 h-10": size === "x-large",
        },
      );

      switch (visual.type) {
        case "image":
          return (
            <div className={visualClassName}>
              <img alt={visual.alt} className="w-full h-full" src={visual.src} />
            </div>
          );
        case "initials":
          return (
            <div
              className={clsx(visualClassName, {
                "bg-background border border-primary-icon": scope === "external",
                // HACKHACK (clewis): Non-standard color usage of "textMuted" for a border, because
                // "borderInteractiveStrong" feels too light for this dashed border.
                "bg-background border border-dashed border-neutral-textMuted":
                  scope === "pending" || scope === "unknown",
                "bg-primary-softPressed": isIdle,
                "rounded-circular": shape === "circle",
                rounded: shape === "rectangle",
                "border-r border-background":
                  topmostSide === "start" && isStackedBordersEnabled && scope === "internal",
                "border-l border-background": topmostSide === "end" && isStackedBordersEnabled && scope === "internal",
              })}
            >
              <div
                className={clsx("font-semibold leading-4", {
                  "text-textContrast": scope === "internal" || scope === "placeholder",
                  "text-primary-icon": scope === "external",
                  "text-textMuted": scope === "unknown",

                  "text-[8px]": size === "x-small",
                  "text-[10px]": size === "small",
                  // Needs a slight nudge down to optically center.
                  "text-[12px] mt-px": size === "medium",
                  "text-[16px] mt-px": size === "large",
                  "text-[18px] mt-px": size === "x-large",
                })}
              >
                {scope === "unknown" ? "?" : visual.text}
              </div>
            </div>
          );
        case "icon":
          if (visual.isVisuallyUnwrapped) {
            return (
              <RegrelloIcon
                className={clsx(visualClassName, {
                  "bg-transparent text-textMuted overflow-visible": true,
                  "text-primary-icon": visual.intent === "primary",
                  "text-danger-icon": visual.intent === "danger",
                })}
                iconName={visual.iconName}
                size="inherit"
              />
            );
          }
          return (
            <div
              className={clsx(visualClassName, {
                // (clewis): I acknowledge that this is a non-standard use of this semantic color,
                // but it's better than -icon because we have contrast text showing in the avatar
                // circle, and we need the contrast ratio to be high enough for the text to be
                // readable.
                "bg-neutral-iconMuted text-textContrast": !visual.isFullSize,
                // (zstanik): For some reason the path within the SVG was less than 100% size, making it
                // look smaller. Should likely be redrawn, this is a quick fix.
                "bg-background text-textMuted [&_svg]:scale-110": visual.isFullSize,
                // (clewis): Cheat a bit because we need the icon to be smaller than the smallest
                // official icon size.
                "[&_svg]:scale-[0.7]": size === "x-small" && !visual.isFullSize,
                "bg-primary-icon text-textContrast": visual.intent === "primary",
                "bg-danger-icon text-textContrast": visual.intent === "danger",
              })}
            >
              <RegrelloIcon
                iconName={visual.iconName}
                size={size === "x-small" || size === "small" || size === "medium" ? "x-small" : undefined}
              />
            </div>
          );

        default:
          assertNever(visual);
      }
    }, [isIdle, isStackedBordersEnabled, scope, shape, size, topmostSide, visual]);

    const isSizeXSmall = size === "x-small";
    const isSizeSmall = size === "small";
    const isSizeXLarge = size === "x-large";

    const isPrimaryTextShown = !isSizeXLarge && text != null;
    const isSecondaryTextShown = !isSizeXSmall && !isSizeSmall && !isSizeXLarge && secondaryText != null;
    const isAnyTextShown = isPrimaryTextShown || isSecondaryTextShown;

    const isInactive = indicator === "inactive";

    const tooltipTitle = scope === "unknown" ? t`Added via task but not a member of this workspace` : EMPTY_STRING;
    function getIndicatorTooltipText(indicatorValue: "inactive" | "muted"): string {
      switch (indicatorValue) {
        case "inactive":
          return t`Inactive`;
        case "muted":
          return t`This user has muted notification emails.`;
        default:
          assertNever(indicatorValue);
      }
    }

    function getIndicatorIconName(indicatorValue: "inactive" | "muted"): RegrelloIconName {
      switch (indicatorValue) {
        case "inactive":
          return "alert-outline";
        case "muted":
          return "bell-muted";
        default:
          assertNever(indicatorValue);
      }
    }

    return (
      <RegrelloTooltip content={tooltipTitle} disabled={tooltipTitle.length === 0} variant="popover">
        {/* (hchen): Wrap with `div` to support tooltip. the root div has it's own ref handling logic. */}
        {/*
         * (clewis): This vertical-alignment ensures that the avatar's text will share a baseline with
         * surrounding text of the same size.
         */}
        <div className={clsx("min-w-0", { flex: !isInline, "inline-flex align-bottom": isInline })}>
          <div
            ref={ref != null ? (ref as React.Ref<HTMLDivElement>) : undefined}
            // Set 0 minWidth for correct `textOverflow: ellipsis` behavior;
            className={clsx("flex items-center min-w-0", {
              [`
                bg-warning-soft
                rounded
                px-1
                py-0.5
                mx-[-1]
                my-[-0.5]
              `]: isInactive && isHighlightedWhenInactive,
              "p-0.5 m-0.5": isInactive && isHighlightedWhenInactive && !isAnyTextShown,
            })}
            title={text}
          >
            <div className="flex-none">{visualElement}</div>
            {isAnyTextShown && (
              <div
                className={clsx("min-w-0 flex-auto", {
                  "ml-1 flex items-center": size === "x-small" || size === "small",
                  "ml-1.5": size === "medium",
                  "ml-2": size === "large",
                  italic: scope === "placeholder",
                  "text-textMuted": scope === "placeholder",
                })}
              >
                {isPrimaryTextShown && (
                  <RegrelloTypography noWrap={true} variant={size === "x-small" ? "body-xs" : "body"}>
                    {text}
                  </RegrelloTypography>
                )}
                {isSecondaryTextShown && (
                  <RegrelloTypography muted={true} noWrap={true} variant="body-xs">
                    {secondaryText}
                  </RegrelloTypography>
                )}
              </div>
            )}
            {showBetaChip && (
              <>
                &nbsp;
                <RegrelloBetaChip size="small" />
              </>
            )}
            {indicator != null && size !== "x-large" && isAnyTextShown && (
              <div
                className={clsx("ml-1", {
                  "text-warning-textMuted": isHighlightedWhenInactive,
                  "right-0.5": isSizeXSmall,
                })}
              >
                <RegrelloTooltip content={getIndicatorTooltipText(indicator)} side="top">
                  <span>
                    <RegrelloIcon
                      iconName={getIndicatorIconName(indicator)}
                      intent={isHighlightedWhenInactive ? undefined : "neutral"}
                      size="x-small"
                    />
                  </span>
                </RegrelloTooltip>
              </div>
            )}
          </div>
        </div>
      </RegrelloTooltip>
    );
  }),
);
RegrelloAvatar.displayName = "RegrelloAvatar";
