import { t } from "@lingui/core/macro";
import { assertNever, EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import {
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  FieldInstanceValueInputType,
  type FormFieldConstraintFields,
  type PropertyDataType,
  type SpectrumFieldVersionFields,
  useAddFieldInstancesToWorkflowMutation,
  useAddFieldInstancesToWorkflowTemplateMutation,
  useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery,
  useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloSkeleton } from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, type UseFormReturn, useFormState } from "react-hook-form";
import { useDeepCompareEffect, useMount, useUnmount } from "react-use";

import { equateFieldInstanceFields } from "../../../../../utils/customFields/equateFieldInstanceFields";
import { getFieldInstanceId } from "../../../../../utils/customFields/getFieldInstanceId";
import { isQueryResultLoading } from "../../../../../utils/graphqlUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { getFieldInstanceFieldsFromAcyclicFieldInstanceFields } from "../../../../molecules/customFields/plugins/utils/getFieldInstanceFieldsFromAcyclicFieldInstanceFields";
import type { RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext } from "../../../../molecules/formFields/RegrelloFormFieldCustomFieldInstanceSelect";
import type { FormSelectFormVersionFields } from "../../../../molecules/formFields/RegrelloFormFieldSpectrumFormSelect";
import { MANDATORY_RULE_NAME } from "../../../pages/spectrumForm/_internal/useFormFieldConstraintRulesMap";
import { useEffectWithWatchedFormValues } from "../useEffectWithWatchedFormValues";
import { RegrelloConfigureCustomFieldsInputMappingFormSection } from "./configureCustomFieldsInputMappingFormSection/RegrelloConfigureCustomFieldsInputMappingFormSection";

export namespace RegrelloConfigureCustomFieldsInputMappingForm {
  export interface Fields {
    /**
     * The mappings between source field instances and destination input fields, which may or may
     * not be actual field instances in our data model.
     */
    inputs: Array<{
      /**
       * The source field instance mapped to the destination input field. `null` when no source has
       * been set yet.
       */
      sourceFieldInstance: FieldInstanceFields | null;

      /** The destination input field the source is mapped to. */
      destinationFieldInstance?: FieldInstanceFields;

      /** Display name for the chip representing the destination input field. */
      displayName: string;

      /** Whether the destination input field is hidden. */
      isHidden: boolean;

      /**
       * Whether the destination input field is required. Affects the validation rules applied to
       * the source field instance input.
       */
      isRequired: boolean;

      /** The destination input field's property data type. */
      valueType: PropertyDataType;
    }>;
  }

  export interface Props {
    /**
     * Initial values to render in the form. These should always be defined since the destination
     * input fields are known whenever a destination is selected. The difference is create contexts
     * will have `null` source field instances initially, whereas edit contexts will have those
     * defined for at least the required destination fields.
     */
    defaultValues: {
      destinationFieldInstances: FieldInstanceFields[];

      mappedFieldInstances?: FieldInstanceFields[];
    };

    /** ID of the action item template this task depends on, if one exists. */
    dependingOnActionItemTemplateId?: number;

    /** Description for this form. Rendered under the title. */
    description?: React.ReactNode;

    /** Configuration for enabling field options in the field instance select inputs. */
    fieldOptionsConfig: {
      /** Whether the current user is allowed to create fields. */
      isCreatingFieldsAllowed: boolean;

      /** Whether the current user is allowed to create other users. */
      isCreatingUsersAllowed: boolean;

      /** Name of the current workflow or blueprint. Used in the field options group label. */
      workflowOrBlueprintName?: string;
    };

    /** Placeholder to use in the source field instance input. */
    fieldSelectPlaceholder?: string;

    /** The form used for configuring custom field input mappings. */
    form: UseFormReturn<RegrelloConfigureCustomFieldsInputMappingForm.Fields>;

    /**
     * Whether the current task is an automated task.
     *
     * @default false
     */
    isAutomatedTask?: boolean;

    /**
     * Whether the form is disabled.
     *
     * @default false
     */
    isDisabled?: boolean;

    /**
     * Whether the form values are loading.
     *
     * @default false
     */
    isLoading?: boolean;

    /**
     * Callback to indicate query loading state.
     */
    onLoadStateChange: (isLoading: boolean) => void;

    /** The spectrum form that fields are being mapped to. */
    selectedSpectrumForm?: FormSelectFormVersionFields;

    /**
     * Whether to trigger form validation immediately upon loading the provided default values.
     *
     * @default false
     */
    isValidationTriggeredImmediately?: boolean;

    /** Headers for the source field and destination field columns. */
    mappingContainerHeaders?: {
      sourceFieldsHeader: string;
      destinationFieldsHeader: string;
    };

    /** Title for the form. */
    title: string;

    /**
     * The greater workflow context this task exists in. Affects which fields are presented for the
     * user to select from.
     */
    workflowContext: RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext;

    /**
     * Whether to render the component without default spacing and border.
     */
    omitSectionStyling?: boolean;

    /**
     * Fields that are selected in the inherit field section will be omitted from the dropdown
     * options.
     */
    selectedInheritableFieldInstances: FieldInstanceFields[];
  }

  /**
   * Converts a field instance into destination input fields. Useful in contexts where the
   * destination field is actually represented by a field instance.
   */
  export function getDestinationInputFieldsFromFieldInstance(
    fieldInstance: FieldInstanceFields,
    formFieldConstriants?: FormFieldConstraintFields[],
  ) {
    const isSpectrumFieldRequired = formFieldConstriants?.some(
      (constraint) => constraint.formFieldConstraintRule.formFieldConstraintRule === MANDATORY_RULE_NAME,
    );
    return {
      displayName: fieldInstance.spectrumFieldVersion?.name ?? EMPTY_STRING,
      destinationFieldInstance: fieldInstance,
      isHidden: false,
      isRequired:
        // (hchen): Honor smart rule constraints first because there is an edge case that when a
        // form with optional fields that needs field passing, it converts the underliying field
        // instance to "INHERITED". The behavior is correct by itself but cause trouble here.
        isSpectrumFieldRequired ||
        (isSpectrumFieldRequired == null && fieldInstance.inputType !== FieldInstanceValueInputType.OPTIONAL),
      valueType: fieldInstance.field.propertyType.dataType,
    };
  }

  /**
   * Converts the provided field instances into default values for the form.
   * `aggregrateSourceFieldInstances` should be the full list of available field instances to select
   * from in order to properly load the field instances pointed to by `sourceFieldInstanceId` on the
   * `mappedFieldInstances`.
   */
  export function getDefaultValuesFromFieldInstances(
    sourceFieldInstances:
      | {
          aggregateSourceFieldInstances: FieldInstanceFields[];
          mappedFieldInstances: FieldInstanceFields[];
        }
      | undefined,
    destinationFieldInstances: FieldInstanceFields[],
    destinationFieldInstanceIdToSelectedFieldInstanceMap: Map<number, FieldInstanceFields>,
    destinationSpectrumForm?: FormSelectFormVersionFields,
  ): RegrelloConfigureCustomFieldsInputMappingForm.Fields {
    return {
      inputs: destinationFieldInstances.map((destinationFieldInstance) => {
        const maybeSelectedFieldInstance = destinationFieldInstanceIdToSelectedFieldInstanceMap.get(
          getFieldInstanceId(destinationFieldInstance),
        );
        const maybeMappedFieldInstance = sourceFieldInstances?.mappedFieldInstances.find(
          (mappedFieldInstance) => mappedFieldInstance.field.id === destinationFieldInstance.field.id,
        );
        const maybeSourceFieldInstanceId =
          maybeMappedFieldInstance != null
            ? CustomFieldPluginRegistrar.getPluginForFieldInstance(maybeMappedFieldInstance).getSourceFieldInstanceId(
                maybeMappedFieldInstance,
              )
            : undefined;
        const maybeSourceFieldInstance = sourceFieldInstances?.aggregateSourceFieldInstances.find(
          (sourceFieldInstance) => getFieldInstanceId(sourceFieldInstance) === maybeSourceFieldInstanceId,
        );

        // See if a field instance exists on the parent workflow or blueprint that matches the field
        // ID of the destination field instance. If so, as a UX nicety we preload that field
        // instance as that's likely the one the user will pick (they can always change it if not).
        const maybeMatchingParentFieldInstance = sourceFieldInstances?.aggregateSourceFieldInstances
          .filter((fieldInstance) => fieldInstance.workflow != null || fieldInstance.workflowTemplate != null)
          .find((fieldInstance) => fieldInstance.field.id === destinationFieldInstance.field.id);

        // Before auto-mapping the above matching parent field instance, first check that it
        // wouldn't auto-map a multi-valued field to a single-valued one (an action that we already
        // prevent in the UI when a user manually selects an option). In other words, allow the
        // auto-mapping if the matching field instance is single-valued OR the destination field
        // instance is multi-valued.
        const isFieldInstanceMultiplicityValid =
          destinationFieldInstance.isMultiValued || !(maybeMatchingParentFieldInstance?.isMultiValued ?? true);
        const maybeAutomappedFieldInstance = isFieldInstanceMultiplicityValid
          ? maybeMatchingParentFieldInstance
          : undefined;

        const destinationFormFieldConstraints = destinationSpectrumForm?.compositeFieldInstances?.find(
          ({ formField }) => destinationFieldInstance.formFieldID === formField.id,
        )?.formField.formFieldConstraints;

        return {
          // Preload the field instances in the following order of priority: 1) an already selected
          // field instance, 2) an already mapped field instance (saved on the task), and 3) a
          // workflow or blueprint level field instance with the same field ID.
          sourceFieldInstance:
            maybeSelectedFieldInstance ?? maybeSourceFieldInstance ?? maybeAutomappedFieldInstance ?? null,
          ...RegrelloConfigureCustomFieldsInputMappingForm.getDestinationInputFieldsFromFieldInstance(
            destinationFieldInstance,
            destinationFormFieldConstraints,
          ),
        };
      }),
    };
  }

  /** Converts the provided field instance values into create field instance value inputs. */
  export function getMutationCreateFieldInstancesPayloadFromFormValues(
    formValues: RegrelloConfigureCustomFieldsInputMappingForm.Fields,
    /** The selected field instance to split on, if any. */
    splitterFieldInstance: FieldInstanceFields | undefined,
    /**
     * Whether or not to split assignees based on the splitter field instance. Splitter field must
     * be an instance of a Party field when this is true.
     */
    shouldSplitAssignees: boolean,
  ): CreateFieldInstanceValueInputs[] {
    return (
      formValues.inputs?.reduce<CreateFieldInstanceValueInputs[]>(
        (inputsArr, { sourceFieldInstance, destinationFieldInstance, isRequired }) => {
          // TODO: figure out best way to handle the input field instance being null. In general I
          // don't agree with throwing errors in such cases because that risks crashing the app over a
          // relatively minor data inconsistency, but we need to handle gracefully.
          if (destinationFieldInstance == null || (isRequired && sourceFieldInstance == null)) {
            return inputsArr;
          }

          const createFieldInstanceInputs: CreateFieldInstanceValueInputs = {
            fieldId: destinationFieldInstance.field.id,
            inputType: FieldInstanceValueInputType.INHERITED,
            isMultiValued: destinationFieldInstance.isMultiValued,
            sourceFieldInstanceValueId:
              sourceFieldInstance != null ? getFieldInstanceId(sourceFieldInstance) : undefined,
            splitConfigurationInput: {
              // (wsheehan): We know the current field instance is the splitter field instance if
              // they have the same source field instance id, i.e. are inherited from the same field
              // instance.
              isSplitterFieldInstance:
                splitterFieldInstance != null &&
                sourceFieldInstance != null &&
                equateFieldInstanceFields(sourceFieldInstance, splitterFieldInstance),
              shouldSplitAssignees: splitterFieldInstance != null ? shouldSplitAssignees : undefined,
            },
          };
          inputsArr.push(createFieldInstanceInputs);

          return inputsArr;
        },
        [],
      ) ?? EMPTY_ARRAY
    );
  }

  /**
   * Renders a form in a create or edit action item dialog where a user can select field instances
   * from the blueprint or workflow to map to destination fields.
   */
  export const Component = React.memo(function RegrelloConfigureCustomFieldsInputMappingFormFn({
    defaultValues,
    dependingOnActionItemTemplateId,
    fieldOptionsConfig,
    form,
    isLoading,
    isValidationTriggeredImmediately,
    onLoadStateChange,
    workflowContext,
    selectedSpectrumForm,
    selectedInheritableFieldInstances,
    ...props
  }: RegrelloConfigureCustomFieldsInputMappingForm.Props) {
    const {
      fields: inputRows,
      replace: replaceInputRows,
      remove: removeInputRows,
    } = useFieldArray({
      control: form.control,
      name: "inputs",
      shouldUnregister: true,
    });

    const { handleError } = useErrorHandler();

    const [getFieldInstancesAvailableAsSourceValuesInWorkflowAsync, workflowResult] =
      useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery({
        onError: (error) => {
          handleError(error);
        },
        fetchPolicy: "no-cache",
        onCompleted: () => {
          onLoadStateChange(false);
        },
      });

    const [getFieldInstancesAvailableAsSourceValuesInWorkflowTemplateAsync, workflowTemplateResult] =
      useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery({
        onError: (error) => {
          handleError(error);
        },
        fetchPolicy: "no-cache",
        onCompleted: () => {
          onLoadStateChange(false);
        },
      });

    // (zstanik): HACK aggregate all field instances that are available to select from in order to
    // properly replace any existing mapped field instances with the field instances pointed to by
    // their `sourceFieldInstanceId`. Ideally we should pull this info straight from the mapped
    // field instances, but with our current GraphQL schema that would require adding almost twice
    // the data for field instances on action item templates regardless of their type. This solution
    // has the tradeoff of calling an extra query whenever a linked workflow action item template is
    // edited, but I think that's worth it for now to avoid the extra response data until a more
    // elegant solution can be implemented.
    const aggregateSourceFieldInstances: FieldInstanceFields[] = useMemo(() => {
      switch (workflowContext.type) {
        case "workflowTemplate": {
          // (zstanik): `fieldInstancesAvailableAsSourceValuesInWorkflowTemplate` was updated to
          // return base field instances to fix a query loop caused by non-normalized field instance
          // values (which can't be normalized at the moment without a much larger lift) getting
          // replaced by slightly different data in a loop. These particular field instances don't
          // need to be full `FieldInstanceFields`, but convert them to stubbed
          // `FieldInstanceFields` first to make them compatible with child components below.
          const data = workflowTemplateResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate;
          const fieldInstancesFromParent = (data?.workflowTemplateFieldInstances ?? EMPTY_ARRAY).map(
            getFieldInstanceFieldsFromAcyclicFieldInstanceFields,
          );
          const fieldInstancesFromPrevious = data?.actionItemTemplateFieldInstances ?? EMPTY_ARRAY;
          return [...fieldInstancesFromParent, ...fieldInstancesFromPrevious];
        }
        case "workflow": {
          const data = workflowResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflow;
          const fieldInstancesFromParent = data?.workflowFieldInstances ?? EMPTY_ARRAY;
          const fieldInstancesFromPreviousActionItems = data?.actionItemFieldInstances ?? EMPTY_ARRAY;
          const fieldInstancesFromPreviousActionItemTemplates = data?.actionItemTemplateFieldInstances ?? EMPTY_ARRAY;
          return [
            ...fieldInstancesFromParent,
            ...fieldInstancesFromPreviousActionItems,
            ...fieldInstancesFromPreviousActionItemTemplates,
          ];
        }
        default:
          assertNever(workflowContext);
      }
    }, [
      workflowContext,
      workflowTemplateResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate,
      workflowResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflow,
    ]);

    // (zstanik): Once the component mounts, call the appropriate field instances query in order to
    // preload default values into the form.
    useMount(() => {
      onLoadStateChange(true);
      switch (workflowContext.type) {
        case "workflowTemplate":
          void getFieldInstancesAvailableAsSourceValuesInWorkflowTemplateAsync({
            variables: {
              workflowTemplateId: workflowContext.workflowTemplateId,
              currentWorkflowTemplateStageId: workflowContext.workflowStageTemplateId,
              dependingOnActionItemTemplateId: dependingOnActionItemTemplateId,
              dependingOnWorkflowTemplateStageIds: workflowContext.dependingOnWorkflowStageTemplateIds,
            },
          });
          break;
        case "workflow":
          void getFieldInstancesAvailableAsSourceValuesInWorkflowAsync({
            variables: {
              workflowId: workflowContext.workflowId,
              currentWorkflowStageId: workflowContext.workflowStageId,
              dependingOnActionItemTemplateId: dependingOnActionItemTemplateId,
              dependingOnWorkflowStageIds: workflowContext.dependingOnWorkflowStageIds,
            },
          });
          break;
        default:
          assertNever(workflowContext);
      }
    });

    // (zstanik) HACKHACK: this form pulls in updates automatically from different sources, so in
    // order for `form.formState.isDirty` to be valid in terms of user input, the form dirty state
    // needs to be cleared whenever the inputs have changed by an automatic update rather than a
    // manual one. Once the user has made a change, then allow the form to manage its own dirty
    // state from that point forward.
    const [isFormManuallyChanged, setIsFormManuallyChanged] = useState(false);

    // (zstanik): Watch for changes in the input rows as the user interacts with the form, and
    // whenever they select a new field instance update the map of destination field instance ID to
    // selected field instance.
    const { inputs: currInputRows } = form.watch();
    const [
      destinationFieldInstanceIdToSelectedFieldInstanceMap,
      setDestinationFieldInstanceIdToSelectedFieldInstanceMap,
    ] = useState<Map<number, FieldInstanceFields>>(new Map());
    const handleInputRowsChange = useCallback(
      (nextInputRows: RegrelloConfigureCustomFieldsInputMappingForm.Fields["inputs"]) => {
        setDestinationFieldInstanceIdToSelectedFieldInstanceMap(
          nextInputRows.reduce<Map<number, FieldInstanceFields>>((currMap, inputRow) => {
            if (inputRow.sourceFieldInstance != null && inputRow.destinationFieldInstance != null) {
              currMap.set(getFieldInstanceId(inputRow.destinationFieldInstance), inputRow.sourceFieldInstance);
              return currMap;
            }
            return currMap;
          }, new Map()),
        );
        if (!isFormManuallyChanged) {
          // Per the comment above, resetting the form with the same values just clears the other
          // state properties, like `isDirty`. Keep doing this whenever the values change, unless
          // the form has been manually updated.
          form.reset(form.getValues(), { keepErrors: true });
        }
      },
      [form, isFormManuallyChanged],
    );
    useEffectWithWatchedFormValues({ watchedFormValue: currInputRows, onChange: handleInputRowsChange });

    useDeepCompareEffect(() => {
      // Even though the call to `replaceInputRows` below should clear any stale validation rules
      // when the mapped field instances change, somehow in certain contexts ghost validation was
      // still being applied and the form couldn't be submitted. Starting fresh here by completely
      // unregistering the form field should prevent that from happening.
      replaceInputRows(
        getDefaultValuesFromFieldInstances(
          { aggregateSourceFieldInstances, mappedFieldInstances: defaultValues.mappedFieldInstances ?? EMPTY_ARRAY },
          defaultValues.destinationFieldInstances,
          destinationFieldInstanceIdToSelectedFieldInstanceMap,
          selectedSpectrumForm,
        ).inputs,
      );
      return () => {
        // (hchen): We must remove input rows when the component unmount. Otherwise, the forms end up
        // being `{}`, which violates the type constraint but Typescript failed to catch this case
        // and the app silently swallows the error.
        removeInputRows();
      };
    }, [
      defaultValues,
      aggregateSourceFieldInstances,
      replaceInputRows,
      removeInputRows,
      destinationFieldInstanceIdToSelectedFieldInstanceMap,
      selectedSpectrumForm,
    ]);

    // (zstanik): In the future we may want to consider baking automatic form validation into all
    // our forms, but for now this is a specific product request to immediately validate this input
    // mapping form in the context of linked workflows, so controlling it here with a prop until we
    // decide otherwise.
    const { isValid: isFormValid } = useFormState({ control: form.control });
    useEffect(() => {
      if (isValidationTriggeredImmediately && !isFormValid) {
        void form.trigger(undefined, { shouldFocus: true });
      }
    }, [form, isFormValid, isValidationTriggeredImmediately]);

    const [addFieldInstancesToWorkflowTemplateAsync] = useAddFieldInstancesToWorkflowTemplateMutation({
      onError: (error) => {
        handleError(error);
      },
    });

    const [addFieldInstancesToWorkflowAsync] = useAddFieldInstancesToWorkflowMutation({
      onError: (error) => {
        handleError(error);
      },
    });

    useUnmount(() => {
      onLoadStateChange(false);
    });

    const handleFieldOptionSelected = useCallback(
      (
        field: FieldFields,
        isMultiValued: boolean | undefined,
        projection:
          | {
              selectedRegrelloObjectPropertyIds: number[];
            }
          | undefined,
        value: unknown,
        onFieldInstanceCreated: (fieldInstance: FieldInstanceFields) => void,
        fieldVersion?: SpectrumFieldVersionFields,
      ) => {
        if (workflowContext.type === "workflowTemplate") {
          // (max): To maintain backwards compatability, omit the spectrumFieldVersionId.
          const { spectrumFieldVersionId, ...createInputsWithoutSpectrumId } =
            CustomFieldPluginRegistrar.getPluginForField(field).getCreateFieldInstanceValueInputsFromFormValue({
              field,
              inputType: FieldInstanceValueInputType.REQUESTED,
              isMultiValued,
              projection,
              spectrumFieldVersion: fieldVersion,
              value: undefined,
            });

          void addFieldInstancesToWorkflowTemplateAsync({
            onCompleted: (data) => {
              // Find the field instance that was just created, then pass that to the provided
              // callback to handle selecting it in the select field.
              const fieldInstance = data.addFieldInstancesToWorkflowTemplate?.workflowTemplate.fieldInstances.find(
                (fieldInstanceInternal) => fieldInstanceInternal.field.id === field.id,
              );
              if (fieldInstance != null) {
                onFieldInstanceCreated(fieldInstance);
              } else {
                console.warn(
                  "The addFieldInstancesToWorkflowTemplate mutation succeeded but didn't actually create the provided field instance, which shouldn't happen",
                );
              }
            },
            variables: {
              workflowTemplateId: workflowContext.workflowTemplateId,
              input: [createInputsWithoutSpectrumId],
            },
          });
        } else {
          // (max): To maintain backwards compatability, omit the spectrumFieldVersionId.
          const { spectrumFieldVersionId, ...createInputsWithoutSpectrumId } =
            CustomFieldPluginRegistrar.getPluginForField(field).getCreateFieldInstanceValueInputsFromFormValue({
              field,
              inputType: FieldInstanceValueInputType.PROVIDED,
              isMultiValued,
              projection,
              spectrumFieldVersion: fieldVersion,
              value,
            });

          void addFieldInstancesToWorkflowAsync({
            onCompleted: (data) => {
              // Find the field instance that was just created, then pass that to the provided
              // callback to handle selecting it in the select field.
              const fieldInstance = data.addFieldInstancesToWorkflow?.workflow.fieldInstances.find(
                (fieldInstanceInternal) => fieldInstanceInternal.field.id === field.id,
              );
              if (fieldInstance != null) {
                onFieldInstanceCreated(fieldInstance);
              } else {
                console.warn(
                  "The addFieldInstancesToWorkflow mutation succeeded but didn't actually create the provided field instance, which shouldn't happen",
                );
              }
            },
            variables: {
              workflowId: workflowContext.workflowId,
              input: [createInputsWithoutSpectrumId],
            },
          });
        }
      },
      [addFieldInstancesToWorkflowAsync, addFieldInstancesToWorkflowTemplateAsync, workflowContext],
    );

    // (zstanik): Prevent rendering the actual form section until the data necessary to initialize
    // any inputs has loaded. Otherwise the inputs will briefly flash as empty and/or as having an
    // error, which isn't an ideal UX interaction.
    const isDataLoading =
      isLoading ||
      (workflowContext.type === "workflow" && isQueryResultLoading(workflowResult)) ||
      (workflowContext.type === "workflowTemplate" && isQueryResultLoading(workflowTemplateResult));

    return isDataLoading ? (
      <div className="py-2">
        <RegrelloSkeleton className="w-full h-20" />
      </div>
    ) : (
      <RegrelloConfigureCustomFieldsInputMappingFormSection
        dependingOnActionItemTemplateId={dependingOnActionItemTemplateId}
        fieldOptionsConfig={
          workflowContext.type === "workflowTemplate" && workflowContext.workflowTemplateIsCreateViaEmailEnabled
            ? undefined
            : {
                configureFieldProps:
                  workflowContext.type === "workflow"
                    ? {
                        description: t`This field will be added to the workflow. Please provide a value below.`,
                        isCreatingUsersAllowed: fieldOptionsConfig.isCreatingUsersAllowed,
                        title: t`Add field`,
                      }
                    : undefined,
                isCreatingFieldsAllowed: fieldOptionsConfig.isCreatingFieldsAllowed,
                onFieldOptionSelected: handleFieldOptionSelected,
                workflowOrBlueprintName: fieldOptionsConfig.workflowOrBlueprintName,
                omittedFieldInstances: selectedInheritableFieldInstances,
              }
        }
        form={form}
        inputRows={inputRows}
        isSpectrumForm={selectedSpectrumForm != null}
        onChange={() => {
          setIsFormManuallyChanged(true);
        }}
        workflowContext={workflowContext}
        {...props}
      />
    );
  });
}
