import { EMPTY_STRING } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  FieldInstanceValueInputType,
  type FormFieldFields,
  type FormInstanceFields,
  type FormSectionBaseFields,
  type FormSectionFields,
  FormSectionKind,
  type FormSectionStatusFields,
  type FormSelectLatestFormFieldInstancesQuery,
  PropertyDataType,
} from "@regrello/graphql-api";

import { getFieldInstanceId } from "../../../../../../utils/customFields/getFieldInstanceId";
import { consoleWarnInDevelopmentModeOnly } from "../../../../../../utils/environmentUtils";
import { SpectrumFieldPluginRegistrar } from "../../../../../molecules/spectrumFields/registry/spectrumFieldPluginRegistrar";
import {
  getActiveRuleAsBoolean,
  getAllowedFileTypes,
  getAllValidationRulesFromFieldConstraints,
  getIsLinkDisallowed,
  getIsMultiPartyAllowed,
} from "../../../../../molecules/spectrumFields/utils/spectrumFieldConstraintUtils";

/**
 * The structure with the maximum depth of a form looks like this:
 * - Form
 *   - Depth 1: Wrapper default section
 *     - Depth 2: Section[kind:DEFAULT] 1
 *       - Field 1
 *       - Field 2
 *       - Field 3
 *       - Depth 3: Section[kind:COLUMN] 1
 *         - Field 4
 *         - Field 5
 *     - Depth 2: Section 2
 *       - Field 6
 *       - Field 7
 *     - Depth 2: Section 3
 *       - Field 8
 */
export const MAXIMUM_SECTION_DEPTH = 3;

export type SpectrumItem =
  | {
      type: "section";
      section: FormSectionFields | FormSectionBaseFields;
    }
  | {
      type: "formField";
      formField: FormFieldFields;
    };

export class FrontendSpectrumFormInstance {
  public readonly formInstance: FormInstanceFields;

  public readonly fieldInstances: FieldInstanceFields[];

  public readonly adhocFieldInstances: FieldInstanceFields[];

  public readonly formSectionStatusBySectionID: Map<number, FormSectionStatusFields>;

  private fieldInstancesByFormFieldID: Map<number, FieldInstanceFields>;

  private formFieldByFieldInstanceID: Map<number, FormFieldFields>;

  constructor(
    formInstance: FormInstanceFields,
    fieldInstances: FieldInstanceFields[],
    formSectionStatusBySectionID?: Map<number, FormSectionStatusFields>,
  ) {
    this.formInstance = formInstance;
    // (hchen): Filter out field instances that are not associated with the form instance. This
    // could happen when the user pass in ad-hoc fields along with the form.
    this.fieldInstances = fieldInstances.filter((fieldInstance) => fieldInstance.formFieldID != null);
    // (dosipiuk): We need to filter out `DERIVED` fields, as they should not be displayed along the form.
    this.adhocFieldInstances = fieldInstances.filter(
      (fieldInstance) =>
        fieldInstance.formFieldID == null && fieldInstance.inputType !== FieldInstanceValueInputType.DERIVED,
    );

    this.fieldInstancesByFormFieldID = getFieldInstancesByFormFieldID(this.fieldInstances);
    this.formFieldByFieldInstanceID = new Map();
    this.formSectionStatusBySectionID = new Map();

    this.recursivelyMapFieldInstancesToFormFields(this.formInstance.formVersion.defaultFormSection, 0);

    if (formSectionStatusBySectionID == null) {
      this.formInstance.formSectionStatuses.forEach((formSectionStatus) => {
        this.formSectionStatusBySectionID.set(formSectionStatus.formSectionID, formSectionStatus);
      });
    } else {
      this.formSectionStatusBySectionID = formSectionStatusBySectionID;
    }
  }

  public getDefaultSection() {
    return this.formInstance.formVersion.defaultFormSection;
  }

  public getCompositeFieldInstanceByFormField(formField: FormFieldFields) {
    const fieldInstance = this.fieldInstancesByFormFieldID.get(formField.id);
    if (fieldInstance == null) {
      consoleWarnInDevelopmentModeOnly("Field instances: ", this.fieldInstances, "Form instance: ", this.formInstance);
      throw new Error(`FormField (${formField.id}) doesn't map to any field instance in this task.`);
    }
    return {
      formField,
      fieldInstance,
    };
  }

  public getCompositeFieldInstanceByFieldInstance(fieldInstance: FieldInstanceFields) {
    const fieldInstanceId = getFieldInstanceId(fieldInstance);
    const formField = this.formFieldByFieldInstanceID.get(fieldInstanceId);
    if (formField == null) {
      throw new Error(`FieldInstance (${fieldInstanceId}) doesn't map to any form field in this task.`);
    }
    return {
      formField,
      fieldInstance,
    };
  }

  public updateSectionStatus(sectionID: number, status: FormSectionStatusFields) {
    this.formSectionStatusBySectionID.set(sectionID, status);
  }

  private recursivelyMapFieldInstancesToFormFields(section: FormSectionFields | FormSectionBaseFields, depth: number) {
    const recursiveSection = section as FormSectionFields;
    if (depth >= MAXIMUM_SECTION_DEPTH) {
      return;
    }

    section.formFields.forEach((formField) => {
      const fieldInstance = this.getCompositeFieldInstanceByFormField(formField).fieldInstance;
      if (this.formFieldByFieldInstanceID.has(getFieldInstanceId(fieldInstance))) {
        consoleWarnInDevelopmentModeOnly(
          `Multiple FieldInstance with FormFieldID ${formField.id} encountered, this should never happen.
            A field instance should alway map to exactly one spectrum form field.`,
        );
        return;
      }

      this.formFieldByFieldInstanceID.set(getFieldInstanceId(fieldInstance), formField);
    });

    if (recursiveSection.formSections == null) {
      return;
    }

    recursiveSection.formSections.forEach((childSection) => {
      this.recursivelyMapFieldInstancesToFormFields(childSection, depth + 1);
    });
  }
}

export function getFieldInstancesByFormFieldID(fieldInstances: FieldInstanceFields[]) {
  const fieldInstancesByFormFieldID = new Map<number, FieldInstanceFields>();
  fieldInstances.forEach((fieldInstance) => {
    if (fieldInstance.formFieldID != null) {
      if (fieldInstancesByFormFieldID.has(fieldInstance.formFieldID)) {
        consoleWarnInDevelopmentModeOnly(
          `Multiple FieldInstance with FormFieldID ${fieldInstance.formFieldID} encountered, this should never happen.
        A field instance should alway map to exactly one spectrum form field.`,
        );
        return;
      }
      fieldInstancesByFormFieldID.set(fieldInstance.formFieldID, fieldInstance);
    }
  });
  return fieldInstancesByFormFieldID;
}

export function getSpectrumFieldNamesByIds(
  occupiedSpectrumFieldNamesById: Array<{ spectrumFieldId: number; spectrumFieldVersionName: string }>,
) {
  return occupiedSpectrumFieldNamesById.reduce((acc, { spectrumFieldId, spectrumFieldVersionName }) => {
    acc.set(spectrumFieldId, spectrumFieldVersionName);
    return acc;
  }, new Map<number, string>());
}

export function recursivelyPairFieldInstancesWithFormFields(
  defaultFormSection: FormSelectLatestFormFieldInstancesQuery["latestFormVersion"]["defaultFormSection"],
  fieldInstances: FieldInstanceFields[],
) {
  const fieldInstancesByFormFieldID = getFieldInstancesByFormFieldID(fieldInstances);
  const compositeFieldInstances = new Array<{ formField: FormFieldFields; fieldInstance: FieldInstanceFields }>();

  function recursivelyPairFieldInstancesWithFormFieldsInternal(
    recursiveSection: FormSelectLatestFormFieldInstancesQuery["latestFormVersion"]["defaultFormSection"],
    depth: number,
  ) {
    if (depth >= MAXIMUM_SECTION_DEPTH) {
      return;
    }

    for (const formField of recursiveSection.formFields) {
      const fieldInstance = fieldInstancesByFormFieldID.get(formField.id);
      if (fieldInstance == null) {
        throw new Error(`FormField (${formField.id}) doesn't map to any field instance in this task.`);
      }
      compositeFieldInstances.push({
        formField,
        fieldInstance,
      });
    }

    if (recursiveSection.formSections == null || recursiveSection.formSections.length === 0) {
      return;
    }

    for (const formSection of recursiveSection.formSections) {
      recursivelyPairFieldInstancesWithFormFieldsInternal(
        formSection as FormSelectLatestFormFieldInstancesQuery["latestFormVersion"]["defaultFormSection"],
        depth,
      );
    }
  }

  recursivelyPairFieldInstancesWithFormFieldsInternal(defaultFormSection, 0);
  return compositeFieldInstances;
}

export function getCompositeFieldInstancesByFormFieldIds(
  compositeFieldInstances:
    | Array<{
        formField: FormFieldFields;
        fieldInstance: FieldInstanceFields;
      }>
    | undefined,
) {
  return compositeFieldInstances?.reduce((acc, { formField, fieldInstance }) => {
    acc.set(formField.id, { formField, fieldInstance });
    return acc;
  }, new Map<number, { formField: FormFieldFields; fieldInstance: FieldInstanceFields }>());
}

export function getFormManagerFieldName(sectionId: number, formFieldId: number, fieldInstanceId: number) {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `section-${sectionId}-form-field-${formFieldId}-field-instance-${fieldInstanceId}`;
}

export function getSpectrumItemSection(section: FormSectionFields | FormSectionBaseFields): SpectrumItem {
  return {
    type: "section",
    section,
  };
}

export function getSpectrumItemFormField(formField: FormFieldFields): SpectrumItem {
  return {
    type: "formField",
    formField,
  };
}

export function spectrumItemComparator(itemA: SpectrumItem, itemB: SpectrumItem) {
  const displayOrderA = getSpectrumItemDisplayOrder(itemA);
  const displayOrderB = getSpectrumItemDisplayOrder(itemB);

  if (displayOrderA < displayOrderB) {
    return -1;
  }
  if (displayOrderA === displayOrderB) {
    return spectrumItemFallbackComparator(itemA, itemB);
  }
  return 1;
}

export function spectrumItemFallbackComparator(itemA: SpectrumItem, itemB: SpectrumItem) {
  const createdAtA = new Date(getSpectrumItemCreatedAt(itemA));
  const createdAtB = new Date(getSpectrumItemCreatedAt(itemB));
  if (createdAtA < createdAtB) {
    return -1;
  }
  return 1;
}

export function getSpectrumItemDisplayOrder(item: SpectrumItem) {
  switch (item.type) {
    case "formField":
      return item.formField.displayOrder;
    case "section":
      return item.section.displayOrder;
    default:
      return -1;
  }
}

export function getSpectrumItemCreatedAt(item: SpectrumItem) {
  switch (item.type) {
    case "formField":
      return item.formField.createdAt;
    case "section":
      return item.section.createdAt;
    default:
      return EMPTY_STRING;
  }
}

export function prepareFormFieldForRender(
  formField: FormFieldFields,
  fieldInstance: FieldInstanceFields,
  getIsFormFieldHidden: (formField: FormFieldFields) => boolean,
) {
  const spectrumFieldVersion = formField.spectrumFieldVersion;

  const isFieldHidden = getIsFormFieldHidden(formField);
  const { required } = getActiveRuleAsBoolean(fieldInstance.spectrumMetadata ?? undefined);
  const isRequired = fieldInstance.field.propertyType.dataType !== PropertyDataType.BOOLEAN && required;
  const plugin = SpectrumFieldPluginRegistrar.getPluginForSpectrumField(spectrumFieldVersion);
  const renderFormField = plugin.renderFormField.bind(plugin);
  const getSpectrumFormAutosaveMode = plugin.getSpectrumFormAutosaveMode.bind(plugin);

  const renderSpectrumFormField = plugin.renderSpectrumFormField?.bind(plugin);

  const constraintRules = getAllValidationRulesFromFieldConstraints(
    isRequired,
    formField.spectrumFieldVersion.fieldConstraints,
  );
  const allowedFileTypes = getAllowedFileTypes(spectrumFieldVersion);
  const isDocumentLinkDisallowed = getIsLinkDisallowed(spectrumFieldVersion);
  const isMultiPartyAllowed = getIsMultiPartyAllowed(spectrumFieldVersion);

  const formFieldKey = getFormManagerFieldName(
    formField.formSection.id,
    formField.id,
    getFieldInstanceId(fieldInstance),
  );

  return {
    allowedFileTypes,
    constraintRules,
    formFieldKey,
    getSpectrumFormAutosaveMode,
    isDocumentLinkDisallowed,
    isFieldHidden,
    isMultiPartyAllowed,
    isRequired,
    renderFormField,
    renderSpectrumFormField,
  };
}

export function recursivelyFlattenFormFields(formSection: FormSectionFields): FormFieldFields[] {
  const recursiveFormSection = formSection as FormSectionFields;
  if (recursiveFormSection.formSections == null || recursiveFormSection.formSections.length === 0) {
    return recursiveFormSection.formFields;
  }
  return [
    ...recursiveFormSection.formFields,
    ...(recursiveFormSection.formSections as FormSectionFields[]).flatMap((formSectionInternal) =>
      recursivelyFlattenFormFields(formSectionInternal),
    ),
  ];
}

export function recursivelyCheckEmptySections(formSection: FormSectionFields): boolean {
  // Check if the current section is empty
  const isEmpty =
    formSection.kind !== FormSectionKind.TEXT &&
    (formSection.formSections == null || formSection.formSections.length === 0) &&
    (formSection.formFields == null || formSection.formFields.length === 0);

  if (isEmpty) {
    return true;
  }

  if (formSection.formSections && formSection.formSections.length > 0) {
    return formSection.formSections.some((childSection) =>
      recursivelyCheckEmptySections(childSection as FormSectionFields),
    );
  }

  return false;
}
