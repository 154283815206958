import { t } from "@lingui/core/macro";
import { assertNever, clsx, useSimpleFocus } from "@regrello/core-utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { useUnmount } from "react-use";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloButton } from "../button/RegrelloButton";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloSignatureInputProps {
  /**
   * Allows to clear the signature even when field is disabled
   *
   * @default false
   */
  allowClearWhenDisabled?: boolean;

  /**
   * Externally controlled value
   */
  dataUrl?: string;

  /**
   * Whether the input is non-interactive.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Whether to hide the clear button.
   *
   * @default false
   */
  hideClearButton?: boolean;

  /** A ref to pass to the input element. */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * The semantic intent of the input.
   *
   * @default "neutral"
   */
  intent?: RegrelloIntent;

  /**
   * Callback invoked when the user stops drawing or clears the canvas. May be invoked multiple
   * times as the user draws if they "lift their pencil" along the way.
   */
  onEnd: (dataUrl: string | null, width: number, height: number) => void;

  /**
   * The size of the input.
   *
   * @default "large"
   */
  size?: RegrelloSize;
}

/**
 * A form input that allows the user to draw their signature with their mouse onto an HTML canvas.
 * Can then emit the signature as an image.
 */
export const RegrelloSignatureInput = React.memo<RegrelloSignatureInputProps>(function RegrelloSignatureInputFn({
  allowClearWhenDisabled = false,
  dataUrl,
  disabled: isDisabled = false,
  hideClearButton = false,
  inputRef,
  intent = "neutral",
  onEnd,
  size = "large",
}) {
  const [hasBegunDrawing, setHasBegunDrawing] = useState(false);

  const sigPadRef = useRef<SignaturePad | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isFocused, onFocus, onBlur } = useSimpleFocus();

  useEffect(() => {
    if (dataUrl != null) {
      const canvas = sigPadRef.current?.getCanvas();
      if (!canvas) {
        return;
      }

      // (clewis): There's a subtle bug here in controlled usage, where the pad's internal size
      // falls out of sync with its actual size, causing the controlled-value image to be drawn
      // erroneously in the top-left of the pad.
      //
      // To fix, I'm explicitly resizing the canvas to its actual size and then drawing the
      // controlled-value image onto the canvas after loading it into an unmounted image element.

      // Create a new image element.
      const image = new Image();
      image.src = dataUrl;

      // When the image loads, draw it on the canvas.
      image.onload = () => {
        const context = canvas.getContext("2d");
        if (context == null) {
          return;
        }

        // Ensure the canvas size matches the image size
        canvas.width = containerRef.current?.clientWidth ?? image.width;
        canvas.height = containerRef.current?.clientHeight ?? image.height;

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the image onto the canvas
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        setHasBegunDrawing(true);
      };
    }
  }, [dataUrl]);

  useResetPadOnResize(containerRef, sigPadRef, isDisabled);

  const handleRef = useCallback((padRef: SignaturePad | null) => {
    sigPadRef.current = padRef;
  }, []);

  const handleBegin = useCallback(() => {
    // Prevent accidental selection of nearby text while drawing.
    // eslint-disable-next-line lingui/no-unlocalized-strings
    document.body.style.userSelect = "none";

    setHasBegunDrawing(true);
  }, []);

  const handleEnd = useCallback(() => {
    if (sigPadRef.current == null) {
      console.warn("SignaturePad ref is null");
      return;
    }
    if (containerRef.current == null) {
      console.warn("containerRef is null");
      return;
    }
    const newDataUrl = sigPadRef.current.isEmpty() ? null : sigPadRef.current.toDataURL();
    const width = containerRef.current.clientWidth;
    const height = containerRef.current.clientHeight;

    // Re-enable text selection.
    document.body.style.userSelect = "";

    onEnd(newDataUrl, width, height);
  }, [onEnd]);

  useUnmount(() => {
    // Re-enable text selection here, too, in case the component is unmounted while the user is
    // drawing.
    document.body.style.userSelect = "";
  });

  const handleClear = useCallback(() => {
    if (sigPadRef.current == null) {
      console.warn("SignaturePad ref is null");
      return;
    }
    sigPadRef.current.clear();
    setHasBegunDrawing(false);
    handleEnd();
  }, [handleEnd]);

  return (
    <div className="flex flex-col w-full relative">
      <div
        ref={containerRef}
        className={clsx("border border-neutral-borderInteractiveStrong rounded w-full h-35 bg-background", {
          "pointer-events-none opacity-30": isDisabled,

          "h-50": size === "x-large",
          "h-35": size === "large",
          "h-25": size === "medium",
          "h-20": size === "small",
          "h-15": size === "x-small",

          "border-primary-borderInteractiveStrong": intent === "primary",
          "border-success-borderInteractiveStrong": intent === "success",
          "border-warning-borderInteractiveStrong": intent === "warning",
          "border-danger-borderInteractiveStrong": intent === "danger",

          "rg-box-border": isFocused, // (clewis): Use box-shadow so we don't jitter the layout on focus.
          "border-primary-solid shadow-primary-solid": isFocused,
          "border-success-solid shadow-success-solid": isFocused && intent === "success",
          "border-warning-solid shadow-warning-solid": isFocused && intent === "warning",
          "border-danger-solid shadow-danger-solid": isFocused && intent === "danger",
        })}
      >
        <SignaturePad
          ref={handleRef}
          canvasProps={{ className: "w-full h-full" }}
          clearOnResize={false}
          onBegin={handleBegin}
          onEnd={handleEnd}
        />

        {/*
         * (clewis): Show a hidden yet focusable input so that react-hook-form has something to focus when
         * the user clicks submit while a form is still invalid.
         */}
        <input
          ref={inputRef}
          className="w-0 h-0 pointer-events-none"
          onBlur={onBlur}
          onFocus={onFocus}
          type="text"
          value={hasBegunDrawing ? "signed" : ""} // Arbitrary non-empty value.
        />

        {hasBegunDrawing ? null : (
          <RegrelloTypography
            className="absolute inset-0 flex items-center justify-center pointer-events-none"
            muted={true}
          >
            {t`Sign here`}
          </RegrelloTypography>
        )}
      </div>
      {hideClearButton ? null : (
        <div className="absolute right-2 bottom-2">
          <RegrelloButton
            disabled={(allowClearWhenDisabled ? false : isDisabled) || !hasBegunDrawing}
            onClick={handleClear}
            size={getButtonSizeFromInputSize(size)}
            variant="outline"
          >
            {t`Clear`}
          </RegrelloButton>
        </div>
      )}
    </div>
  );
});

function getButtonSizeFromInputSize(size: RegrelloSize): RegrelloSize {
  switch (size) {
    case "x-small":
      return "x-small";
    case "small":
      return "small";
    case "medium":
      return "small";
    case "large":
      return "medium";
    case "x-large":
      return "large";
    default:
      assertNever(size);
  }
}

/**
 * Hook to reset the signature pad when it resizes. If we don't do this, then the cursor and paint
 * positions will get out of sync.
 */
function useResetPadOnResize(
  containerRef: React.RefObject<HTMLDivElement>,
  sigPadRef: React.RefObject<SignaturePad>,
  isDisabled: boolean,
) {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          if (sigPadRef.current == null) {
            return;
          }

          // Save the current signature data.
          const data = sigPadRef.current.toData();

          // Clear and resize the canvas.
          sigPadRef.current.clear();
          sigPadRef.current.getCanvas().width = entry.contentRect.width;
          sigPadRef.current.getCanvas().height = entry.contentRect.height;

          // Reapply the saved data.
          sigPadRef.current.fromData(data);
        }
      }
    });

    if (containerRef.current != null && !isDisabled) {
      resizeObserver.observe(containerRef.current);
    }

    const containerElement = containerRef.current;

    return () => {
      if (containerElement != null) {
        resizeObserver.unobserve(containerElement);
      }
      resizeObserver.disconnect();
    };
  }, [containerRef, isDisabled, sigPadRef]);
}
