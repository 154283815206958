import { t } from "@lingui/core/macro";
import { clsx, EMPTY_STRING, getEmailDomain } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { RegrelloButton, RegrelloIcon, RegrelloLinkV2, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";
import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { AuthenticationConnectionName } from "../../../../../constants/auth";
import { REGRELLO_PRIVACY_POLICY_URL, ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "../../../../../utils/getFromSessionStorage";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { type PasswordRules, validatePassword } from "../../../../../utils/passwordRulesRegexUtils";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldCheckbox } from "../../../../molecules/formFields/RegrelloFormFieldCheckbox";

export interface UnauthenticatedSignUpPageFormFields {
  email: string;
  password: string;
  passwordConfirm: string;
}

const SUBMIT_THROTTLE_IN_MILLISECONDS = 1000;

export const UnauthenticatedSignUpPage = React.memo(function UnauthenticatedSignUpPageFn() {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const [isHintTooltipOpen, setIsHintTooltipOpen] = useState(false);
  const { signUp, loading, authError: signUpError } = useRegrelloAuthV2();
  const { getQueryParam } = useRegrelloHistory();

  const email = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;
  const userVerificationUUID = getQueryParam(RouteQueryStringKeys.USER_VERIFICATION_UUID);
  if (userVerificationUUID != null) {
    sessionStorageSetTyped(RegrelloSessionStorageKey.USER_VERIFICATION_UUID, userVerificationUUID);
  }
  const tenantUUID = getQueryParam(RouteQueryStringKeys.TENANT_UUID);

  const [workspaceLogo, setWorkspaceLogo] = useState<string | null>(null);

  useEffect(() => {
    const fetchWorkspaceLogo = async () => {
      // get FeatureFlag based on IP
      const isRevisedSignInFeatureFlagEnabledResponse = await RegrelloRestApiService.getFeatureFlagBasedOnIP(
        FeatureFlagKey.REVISED_SIGN_IN_2024_11,
      );
      const isRevisedSignInFeatureFlagEnabled = isRevisedSignInFeatureFlagEnabledResponse.json.enabled;

      if (tenantUUID != null && isRevisedSignInFeatureFlagEnabled) {
        const result = await RegrelloRestApiService.getLogo(tenantUUID);
        if (result.status === 200 && result.json.Logo?.value) {
          setWorkspaceLogo(result.json.Logo.value);
        } else {
          // null or default logo
          setWorkspaceLogo(null);
        }
      }
    };
    void fetchWorkspaceLogo();
  }, [tenantUUID]);

  const defaultValues: UnauthenticatedSignUpPageFormFields = {
    email,
    password: EMPTY_STRING,
    passwordConfirm: EMPTY_STRING,
  };

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setIsPasswordShown(isChecked);
  }, []);

  const form = useForm<UnauthenticatedSignUpPageFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const [ssoConnectionName, setSsoConnectionName] = useState<string | null>(null);
  const [isSsoOptional, setIsSsoOptional] = useState<boolean>(false);

  useEffect(() => {
    const domain = getEmailDomain(email);
    const updateConnectionName = async () => {
      const result = await RegrelloRestApiService.isSso(domain);
      setSsoConnectionName(result.status === 200 && result.json.isSso ? result.json.connectionName : null);
      setIsSsoOptional(result.status === 200 && result.json.isSsoOptional);
    };
    void updateConnectionName();
    return () => {
      setSsoConnectionName(null);
    };
  }, [email]);

  const shouldUseSSO = ssoConnectionName != null && isSsoOptional;

  // (hchen): Watch the form changes so that we can validate passwords.
  const password = useWatch({ control: form.control, name: "password" });

  const passwordRules = useMemo<PasswordRules>(() => {
    return validatePassword(password);
  }, [password]);

  const throttledOnSubmit = useMemo(
    () =>
      throttle(async (data: UnauthenticatedSignUpPageFormFields) => {
        await signUp({
          connection: AuthenticationConnectionName.DEFAULT,
          email: data.email,
          password: data.password,
        });
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [signUp],
  );

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (hchen): Prevent refreshing.
      event.preventDefault();
      // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
      void form.handleSubmit(throttledOnSubmit)();
    },
    [form, throttledOnSubmit],
  );

  const throttledOnContinueWithSso = useMemo(
    () =>
      throttle(async () => {
        if (ssoConnectionName != null) {
          await signUp({
            connection: ssoConnectionName,
            email: form.getValues().email,
            password: form.getValues().password,
          });
        } else {
          await signUp({
            connection: AuthenticationConnectionName.SSO,
          });
        }
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [signUp, form, ssoConnectionName],
  );

  const validatePasswordMatch = useCallback(
    (confirmPasswordValue: string) => {
      return confirmPasswordValue === password ? true : t`Passwords must be the same`;
    },
    [password],
  );

  const validatePasswordRule = useCallback(() => {
    if (!passwordRules.minLength) {
      return t`Password must have at least 15 characters`;
    }
    if (!passwordRules.hasValidCharacterCombination) {
      return t`Password must contain at least 3 of the 4 character types`;
    }
    if (!passwordRules.hasNoConsecutiveCharacters) {
      return t`Password must not have more than 2 identical characters in a row`;
    }
    return true;
  }, [passwordRules.minLength, passwordRules.hasValidCharacterCombination, passwordRules.hasNoConsecutiveCharacters]);

  const getPasswordRuleIcon = useCallback((isValid: boolean) => {
    return (
      <div className="mr-2">{isValid ? <RegrelloIcon iconName="selected" /> : <RegrelloIcon iconName="blank" />}</div>
    );
  }, []);

  const onPasswordFocus = useCallback(() => {
    setIsHintTooltipOpen(true);
  }, []);

  const onPasswordBlur = useCallback(() => {
    setIsHintTooltipOpen(false);
  }, []);

  // (hchen): Do this instead of setting `autoFocus={true}` to make sure the pop up shows up on
  // autofocus.
  useLayoutEffect(() => passwordInputRef.current?.focus(), []);

  const passwordPopover = useMemo(
    () => (
      <RegrelloTypography component="div">
        <div
          className={clsx("flex p-1.25", {
            "text-danger-textMuted": !passwordRules.minLength,
            "text-success-textMuted": passwordRules.minLength,
          })}
        >
          {getPasswordRuleIcon(passwordRules.minLength)}
          {t`At least 15 characters in length`}
        </div>
        <div
          className={clsx("flex p-1.25", {
            "text-success-textMuted": passwordRules.hasValidCharacterCombination,
          })}
        >
          {getPasswordRuleIcon(passwordRules.hasValidCharacterCombination)}
          {t`Contains at least 3 of the following 4 types of characters`}
        </div>
        <div className="pl-7">
          <div
            className={clsx("flex p-1.25", {
              "text-success-textMuted": passwordRules.hasLowercaseLetter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasLowercaseLetter)}
            {t`Lower case letters (a-z)`}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-textMuted": passwordRules.hasUppercaseLetter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasUppercaseLetter)}
            {t`Upper case letters (A-Z)`}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-textMuted": passwordRules.hasNumber,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasNumber)}
            {t`Numbers (1-9)`}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-textMuted": passwordRules.hasSpecialCharacter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasSpecialCharacter)}
            {t`Special characters (!@#$%^&*)`}
          </div>
        </div>
        <div
          className={clsx("flex p-1.25", {
            "text-success-textMuted": passwordRules.hasNoConsecutiveCharacters,
          })}
        >
          {getPasswordRuleIcon(passwordRules.hasNoConsecutiveCharacters)}
          {t`No more than 2 identical characters in a row (aaa is not allowed)`}
        </div>
      </RegrelloTypography>
    ),
    [
      getPasswordRuleIcon,
      passwordRules.hasLowercaseLetter,
      passwordRules.hasNoConsecutiveCharacters,
      passwordRules.hasNumber,
      passwordRules.hasSpecialCharacter,
      passwordRules.hasUppercaseLetter,
      passwordRules.hasValidCharacterCombination,
      passwordRules.minLength,
    ],
  );

  const passwordFields = useMemo(
    () => (
      <>
        <RegrelloTooltip
          content={passwordPopover}
          contentProps={{
            style: {
              width: "var(--radix-tooltip-trigger-width)",
              maxHeight: "var(--radix-tooltip-content-available-height)",
            },
          }}
          open={isHintTooltipOpen && !shouldUseSSO}
          variant="popover"
        >
          <div className="w-full mb-4">
            <RegrelloControlledFormFieldText
              controllerProps={{
                control: form.control,
                name: "password",
                rules: { ...ValidationRules.REQUIRED, validate: { rule: validatePasswordRule } },
              }}
              disabled={shouldUseSSO}
              inputRef={passwordInputRef}
              isDefaultMarginsOmitted={true}
              onBlur={onPasswordBlur}
              onFocus={onPasswordFocus}
              placeholder={t`Create password`}
              type={isPasswordShown ? "text" : "password"}
            />
          </div>
        </RegrelloTooltip>
        <RegrelloControlledFormFieldText
          className={clsx("w-full mb-1")}
          controllerProps={{
            control: form.control,
            name: "passwordConfirm",
            rules: {
              ...ValidationRules.REQUIRED,
              validate: { match: validatePasswordMatch },
            },
          }}
          disabled={shouldUseSSO}
          isDefaultMarginsOmitted={true}
          placeholder={t`Confirm password`}
          type={isPasswordShown ? "text" : "password"}
        />

        <div className={clsx("w-full flex items-center justify-between mb-6")}>
          <RegrelloFormFieldCheckbox
            disabled={shouldUseSSO}
            isDefaultMarginsOmitted={true}
            label={t`Show password`}
            onChange={onCheckboxChange}
            value={isPasswordShown}
          />
        </div>
      </>
    ),
    [
      passwordPopover,
      isHintTooltipOpen,
      shouldUseSSO,
      form.control,
      validatePasswordRule,
      onPasswordBlur,
      onPasswordFocus,
      isPasswordShown,
      validatePasswordMatch,
      onCheckboxChange,
    ],
  );

  const signInWithSSOButton = useMemo(
    () => (
      <RegrelloButton
        className={clsx("w-full mb-2.5")}
        loading={loading}
        onClick={throttledOnContinueWithSso}
        size="large"
        startIcon={<RegrelloIcon iconName="sso" />}
        variant="outline"
      >
        {t`Sign in with SSO`}
      </RegrelloButton>
    ),
    [loading, throttledOnContinueWithSso],
  );

  const signUpButton = useMemo(
    () => (
      <RegrelloButton
        className={clsx("w-full mb-8")}
        disabled={shouldUseSSO}
        intent="primary"
        loading={loading}
        size="large"
        type="submit"
      >
        {t`Sign up`}
      </RegrelloButton>
    ),
    [loading, shouldUseSSO],
  );

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col px-12 pb-4 w-97.5">
        <form className="flex flex-1 flex-col justify-center items-center" onSubmit={submit}>
          {workspaceLogo ? (
            <img alt={t`Workspace Logo`} className="h-16 mb-8 object-contain" src={workspaceLogo} />
          ) : null}
          <RegrelloTypography className="font-normal mb-6" variant="h5">
            {shouldUseSSO ? t`Sign in with SSO to get started.` : t`Create your password`}
          </RegrelloTypography>
          {passwordFields}
          {signUpError != null && (
            <div className="self-start mb-2">
              <RegrelloTypography className="text-danger-textMuted">
                {t`Failed to sign up: you may already have an account with us, try logging in instead`}
              </RegrelloTypography>
            </div>
          )}
          {ssoConnectionName != null ? signInWithSSOButton : signUpButton}
          <RegrelloLinkV2
            className="text-primary-textMuted"
            to={RoutePaths.LOGIN + "?" + RouteQueryStringKeys.EMAIL + "=" + encodeURIComponent(email)}
          >
            {t`Back to sign in`}
          </RegrelloLinkV2>
        </form>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});
