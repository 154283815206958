import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { AcyclicFieldInstanceValuePartyFields } from "./AcyclicFieldInstanceValuePartyFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValuePartyFields = {
  __typename: "FieldInstanceValueParty";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  partyValue?: PartyBaseFields | null;
  sourceFieldInstanceValueParty?: AcyclicFieldInstanceValuePartyFields | null;
  sinksFieldInstanceValueParty: Array<AcyclicFieldInstanceValuePartyFields>;
  tabularValueParty: Array<{ rowNumber: number; value?: number | null }>;
};

export const FieldInstanceValuePartyFields = gql`
    fragment FieldInstanceValuePartyFields on FieldInstanceValueParty {
  __typename
  id
  inputType
  partyValue {
    ...PartyBaseFields
  }
  sourceFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  sinksFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  tabularValueParty {
    rowNumber
    value
  }
}
    `;
