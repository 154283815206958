import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceValueFloatBaseFields = {
  __typename: "FieldInstanceValueFloat";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  floatValue?: number | null;
  tabularValueFloat: Array<{ rowNumber: number; value?: number | null }>;
};

export const FieldInstanceValueFloatBaseFields = gql`
    fragment FieldInstanceValueFloatBaseFields on FieldInstanceValueFloat {
  __typename
  id
  inputType
  floatValue
  tabularValueFloat {
    rowNumber
    value
  }
}
    `;
