import { t } from "@lingui/core/macro";
import { clsx, isDefined, KeyNames, queueMacrotask, useSimpleDialog, type WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type RegrelloDialogAction,
  RegrelloDialogV2,
  type RegrelloDialogV2Props,
  type RegrelloIconName,
  RegrelloTypography,
} from "@regrello/ui-core";
import type { Scope } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  type DefaultValues,
  type FieldValues,
  type SubmitHandler,
  useForm,
  type UseFormReturn,
  useFormState,
  useWatch,
} from "react-hook-form";

import {
  onBeforeSentryCaptureOnRegrelloFormDialog,
  type RegrelloFormDialogName,
} from "../../../utils/sentryScopeUtils";
import { RegrelloErrorBoundary } from "../../molecules/errorBoundary/RegrelloErrorBoundary";
import { RegrelloAvoidLosingEditsPrompt } from "../../views/modals/formDialogs/RegrelloAvoidLosingEditsPrompt";

export interface RegrelloFormDialogProps<TFieldValues extends FieldValues>
  extends Pick<RegrelloDialogV2Props, "footerStartContent" | "showMaximizeButton" | "size">,
    WithDataTestId {
  /**
   * If set, every time the user attempts to close the dialog through a standard method,
   * e.g., clicking off of the dialog on the page, the dialog will display a "are you sure?"
   * prompt.
   */
  alwaysAvoidLosingEdits?: boolean;

  /**
   * A render function that accepts a `form` construct (from the `react-hook-form` library), and
   * returns a single child element containing the contents of the form.
   */
  children: (form: UseFormReturn<TFieldValues>) => React.ReactNode;

  /** The default values to show in the form. */
  defaultValues: DefaultValues<TFieldValues>;

  /**
   * Explanatory text to display at the top of the content area of the dialog, immediately
   * below the header.
   */
  description?: React.ReactNode;

  /**
   * Styles that are applied to classes.paper of underlying material dialog.
   */
  formDialogClasses?: string;

  /**
   * Callback invoked to determine whether the form, including any sub forms, is dirty (i.e., the
   * user has made changes). If this prop is provided, `form.formState.isDirty` will not be used to
   * determine if the form state is dirty.
   */
  getIsFormStateDirty?: (data: TFieldValues) => boolean;

  /** A error message that happened on submit. Will be displayed at the top of the form. */
  error?: string;

  /**
   * Whether the data for this form is loading. When this prop changes from `true` to `false`, the
   * form will be reset with the updated default values.
   *
   * @default false
   */
  isDataLoading?: boolean;

  /**
   * Whether the nested forms in this component's children are valid. If this prop is provided,
   * submission will be disabled while its value is `false`.
   */
  isNestedFormValid?: boolean;

  /** Whether the dialog is currently open. */
  isOpen: boolean;

  /**
   * Whether the submit button is disabled.
   */
  isSubmitDisabled?: boolean;

  /**
   * Whether the submit button is disabled.
   */
  isSubmitAlternateDisabled?: boolean;

  /**
   * Use cautiously depending on the UX!
   *
   * Whether the form is optimistically submitted. If true, the form dialog will immediately close
   * and not wait for the response. If false (default), the form dialog will remain open to wait
   * for the response before closing.
   */
  isSubmitOptimistic?: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /**
   * Callback invoked when the user submits the form. Resolves to `true` if submission succeeded,
   * `false` if there were any errors.
   */
  onSubmit: (data: TFieldValues) => Promise<boolean>;

  /**
   * Callback that causes an alternate submit action to be shown in a dropdown menu if provided.
   * Resolves to `true` if submission succeeded, `false` if there were any errors.
   *
   * __Example:__ You can provide this if you want to show a "Create in new tab" submit action as an
   * alternative to a "Create" (in same tab) action.
   */
  onSubmitAlternate?: (data: TFieldValues) => Promise<boolean>;

  /**
   * Whether the dialog is read-only. If enabled, the content area and buttons are not clickable and
   * doesn't listen to keyboard events. A "PREVIEW" watermark will be displayed above the content.
   */
  option?: { isPreview: false } | { isPreview: true; isOverlayRotated: boolean };

  /**
   * The unique scope name to send to Sentry when an error occurs. If you've added a new form dialog
   * to Regrello, add it to the `RegrelloFormDialogName` enum.
   */
  scopeNameForSentry: RegrelloFormDialogName;

  /**
   * Props for an additional submit button to render in the dialog's footer actions. If defined, the
   * button will be rendered in between the cancel button and the primary submit button.
   */
  secondarySubmitButtonProps?: {
    onClick: (data: TFieldValues) => Promise<boolean>;
    text: string;
  };

  /**
   * Whether to show the '* Required' helper text in the bottom-start corner of the dialog.
   *
   * @default false
   */
  showRequiredInstruction?: boolean;

  /**
   * The icon to display for the alternate 'submit' action. Ignored unless `onSubmitAlternate` is
   * also provided.
   *
   * @default undefined
   */
  submitAlternateIcon?: RegrelloIconName;

  /**
   * The text to display for the alternate 'submit' action. Ignored unless `onSubmitAlternate` is
   * also provided.
   *
   * @default "Add"
   */
  submitAlternateText?: string;

  /** @default "primary" */
  submitButtonIntent?: "primary" | "danger";

  /**
   * The text to display in the 'submit' button.
   *
   * @default "Add"
   */
  submitButtonText?: string;

  /** The title to display in the dialog header. */
  title: string;

  /** Content to display immediately below the dialog header. */
  postHeaderBanner?: React.ReactNode;

  /** An icon to display before the title. */
  titleIcon?: RegrelloIconName | React.ReactElement;
}

/**
 * A dialog that is specifically meant to contain a custom `react-hook-form` form inside of it. This
 * component shows the dialog and all necessary buttons, and it manages most of the boilerplate for
 * submitting, canceling, and hooking up the form.
 */
function RegrelloFormDialogInternal<TFieldValues extends FieldValues>({
  children: childRenderFunction,
  dataTestId,
  defaultValues,
  description,
  footerStartContent: propsFooterStartContent,
  formDialogClasses,
  error,
  getIsFormStateDirty,
  isDataLoading = false,
  isOpen,
  isSubmitDisabled = false,
  isSubmitAlternateDisabled = false,
  isSubmitOptimistic = false,
  isNestedFormValid,
  onClose,
  onSubmit,
  onSubmitAlternate,
  option,
  scopeNameForSentry,
  secondarySubmitButtonProps,
  showMaximizeButton = false,
  showRequiredInstruction = false,
  size,
  submitAlternateIcon = undefined,
  submitAlternateText = t`Add`,
  submitButtonIntent = "primary",
  submitButtonText = t`Add`,
  title,
  postHeaderBanner: titleEndContent,
  titleIcon,
  alwaysAvoidLosingEdits = false,
}: RegrelloFormDialogProps<TFieldValues>) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const [whichButtonPressed, setWhichButtonPressed] = useState<"secondary" | "primary">("primary");

  const form = useForm<TFieldValues>({
    // Show initial validation on a field after its first "blur" event, then after each "change".
    // See: https://github.com/react-hook-form/react-hook-form/issues/2217#issuecomment-675160861
    // (zstanik): For the new start date form, need validation after first "blur" and each "change"
    // so that the submit button gets properly enabled. Unfortunately there's no mode option to
    // support this exact use case, "all" validates after every "blur" and "change".
    mode: "all",
    defaultValues,
  });
  useWatch({ control: form.control });

  const formHandleSubmit = form.handleSubmit;
  const formReset = form.reset;

  // (clewis): Store defaultValues in a ref to simplify the next 'useEffect', which resets the form
  // when the dialog opens. (If we didn't store defaultValues in a ref, the the next useEffect would
  // fire every time the defaultValues changed, which leads to other consistency bugs.)
  const defaultValuesRef = useRef(defaultValues);

  useEffect(() => {
    defaultValuesRef.current = defaultValues;
  }, [defaultValues]);

  useEffect(() => {
    if (isOpen && !isDataLoading) {
      formReset(defaultValuesRef.current);

      // (clewis): Reset the submitting state every time the dialog opens.
      setIsSubmitting(false);
    }
  }, [formReset, isDataLoading, isOpen]);

  const { isValid: isFormValid } = useFormState({ control: form.control });

  const areAllFormsValid = useMemo(() => {
    return isNestedFormValid != null ? isFormValid && isNestedFormValid : true;
  }, [isFormValid, isNestedFormValid]);

  const handleSubmitInternalConstructor: (
    submitContext: "primary" | "primaryAlternate" | "secondary",
  ) => SubmitHandler<TFieldValues> = useCallback(
    (submitContext) => {
      if (submitContext === "primaryAlternate" && onSubmitAlternate == null) {
        throw new Error("onSubmitAlternate must be provided if submitContext is 'primaryAlternate'");
      }

      const localOnSubmit =
        submitContext === "secondary" && secondarySubmitButtonProps != null
          ? secondarySubmitButtonProps.onClick
          : submitContext === "primaryAlternate"
            ? // (clewis): This is guaranteed to be defined now.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onSubmitAlternate!
            : onSubmit;

      return async (data) => {
        // Assuming all forms are valid, optimistically close the form and do not wait for the submit
        // response. This improves perceived performance.
        if (isSubmitOptimistic && areAllFormsValid) {
          await localOnSubmit(data);
          // Ensures we accurately submit with all data as some onClose() callbacks reset fields.
          queueMacrotask(onClose);
        } else {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          setWhichButtonPressed(submitContext === "primaryAlternate" ? "primary" : submitContext);
          setIsSubmitting(true);
          const didSucceed = await localOnSubmit(data);
          setIsSubmitting(false);
          if (didSucceed) {
            onClose();
          }
        }
      };
    },
    [areAllFormsValid, onClose, isSubmitOptimistic, onSubmit, onSubmitAlternate, secondarySubmitButtonProps],
  );

  // Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
  const handlePrimarySubmit = useCallback(() => {
    void formHandleSubmit(handleSubmitInternalConstructor("primary"))();
  }, [formHandleSubmit, handleSubmitInternalConstructor]);
  const handlePrimarySubmitAlternate = useCallback(() => {
    void formHandleSubmit(handleSubmitInternalConstructor("primaryAlternate"))();
  }, [formHandleSubmit, handleSubmitInternalConstructor]);
  const handleSecondarySubmit = useCallback(() => {
    void formHandleSubmit(handleSubmitInternalConstructor("secondary"))();
  }, [formHandleSubmit, handleSubmitInternalConstructor]);

  const isSubmitButtonDisabled = (isSubmitting || isSubmitDisabled || option?.isPreview) ?? false;
  const isSubmitAlternateButtonDisabled = (isSubmitting || isSubmitAlternateDisabled || option?.isPreview) ?? false;

  const dialogActions: RegrelloDialogAction[] = useMemo(() => {
    return [
      {
        buttonProps: {
          onClick: onClose,
          disabled: isSubmitting ?? option?.isPreview ?? false,
          variant: "outline" as const,
          dataTestId: DataTestIds.FORM_DIALOG_CANCEL_BUTTON,
        },
        text: t`Cancel`,
      },
      secondarySubmitButtonProps != null
        ? {
            buttonProps: {
              intent: "neutral" as const,
              dataTestId: DataTestIds.FORM_DIALOG_SUBMIT_BUTTON_SECONDARY,
              disabled: isSubmitAlternateButtonDisabled,
              loading: isSubmitting && whichButtonPressed === "secondary",
              onClick: handleSecondarySubmit,
              variant: "outline" as const,
            },
            text: secondarySubmitButtonProps.text,
          }
        : undefined,
      {
        buttonProps: {
          intent: submitButtonIntent,
          dataTestId: DataTestIds.FORM_DIALOG_SUBMIT_BUTTON_PRIMARY,
          disabled: isSubmitButtonDisabled,
          loading: isSubmitting && whichButtonPressed === "primary",
          onClick: handlePrimarySubmit,
        },
        text: submitButtonText,
        dropdownMenuItems:
          onSubmitAlternate != null
            ? [
                {
                  icon: submitAlternateIcon,
                  intent: submitButtonIntent,
                  onClick: handlePrimarySubmitAlternate,
                  text: submitAlternateText,
                },
              ]
            : undefined,
      },
    ].filter(isDefined);
  }, [
    onClose,
    isSubmitting,
    option?.isPreview,
    secondarySubmitButtonProps,
    isSubmitAlternateButtonDisabled,
    whichButtonPressed,
    handleSecondarySubmit,
    submitButtonIntent,
    isSubmitButtonDisabled,
    handlePrimarySubmit,
    submitButtonText,
    onSubmitAlternate,
    submitAlternateIcon,
    handlePrimarySubmitAlternate,
    submitAlternateText,
  ]);

  const onBeforeSentryCapture = useCallback(
    (scope: Scope) => {
      onBeforeSentryCaptureOnRegrelloFormDialog(scope, scopeNameForSentry);
    },
    [scopeNameForSentry],
  );

  const handleNativeFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (clewis): Prevent the native-form submission from refreshing the page.
      event.preventDefault();
      handlePrimarySubmit();
    },
    [handlePrimarySubmit],
  );

  const {
    isOpen: isAvoidLosingEditsPromptOpen,
    open: openAvoidLosingEditsPrompt,
    close: closeAvoidLosingEditsPrompt,
  } = useSimpleDialog();

  const handleClose = useCallback(() => {
    // (dosipiuk): Remove after upgrading `react-hook-form`
    const isFormDirty = getIsFormStateDirty?.(form.getValues()) ?? form.formState.isDirty;
    // Show an 'Are you sure?' prompt if the user cancels via a shortcut (i.e., by clicking
    // on the backdrop or by pressing 'Esc'). This is important to avoid the user losing
    // data because of a stray click.
    if (alwaysAvoidLosingEdits || isFormDirty) {
      openAvoidLosingEditsPrompt();
    } else {
      onClose();
    }
  }, [alwaysAvoidLosingEdits, onClose, form, getIsFormStateDirty, openAvoidLosingEditsPrompt]);

  const onAvoidLosingEditsPromptCancel = useCallback(() => {
    closeAvoidLosingEditsPrompt();
  }, [closeAvoidLosingEditsPrompt]);

  const onAvoidLosingEditsPromptConfirm = useCallback(() => {
    closeAvoidLosingEditsPrompt();
    onClose();
  }, [closeAvoidLosingEditsPrompt, onClose]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (option?.isPreview === true && event.key !== KeyNames.ESCAPE) {
        // (hchen): Disable keyboard except "Escape", especially tab, when the dialog is read-only
        event.preventDefault();
        return;
      }

      // UX Nicety: Submit on Cmd + Enter.
      if (event.key === KeyNames.ENTER && event.metaKey && !isSubmitButtonDisabled) {
        handlePrimarySubmit();

        // (clewis): Prevent propagation to any dialogs lower in the dialog stack.
        event.stopPropagation();
      } else if (event.key === KeyNames.ESCAPE) {
        // (clewis): Apparently passing our own `onKeyDown` causes close-on-Esc to stop working in
        // the underlying Material dialog, so we have to implement it ourselves here.
        handleClose();

        // (clewis): Prevent propagation to any dialogs lower in the dialog stack.
        event.stopPropagation();
      }
    },
    [handleClose, handlePrimarySubmit, isSubmitButtonDisabled, option?.isPreview],
  );

  return (
    <RegrelloDialogV2
      actions={dialogActions}
      contentClassName={formDialogClasses}
      dataTestId={dataTestId}
      footerStartContent={
        propsFooterStartContent ??
        (showRequiredInstruction ? (
          <>
            <span className="text-danger-textMuted font-semibold">*</span> {t`Required`}
          </>
        ) : undefined)
      }
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      open={isOpen}
      showCloseButton={true}
      showMaximizeButton={showMaximizeButton}
      size={size}
      title={title}
      titleEndContent={titleEndContent}
      titleIcon={titleIcon}
    >
      <RegrelloErrorBoundary beforeCapture={onBeforeSentryCapture}>
        {description != null && <RegrelloTypography className="mb-4">{description}</RegrelloTypography>}
        {error != null && (
          <RegrelloTypography className="mb-4" intent="danger" muted={true}>
            {error}
          </RegrelloTypography>
        )}

        <form
          // Move the `pointer-events: none` to the form itself so that any element within the form
          // can be enabled if need be.
          className={clsx("relative", { "pointer-events-none": option?.isPreview === true })}
          onSubmit={handleNativeFormSubmit}
        >
          {option?.isPreview === true && (
            <div className="flex justify-center items-center absolute inset-0 z-2 opacity-20 bg-backgroundSoft">
              <RegrelloTypography
                className={clsx("text-[6.25rem] uppercase", {
                  "rotate--45": option.isOverlayRotated,
                })}
                variant="h1"
              >
                {t`Preview`}
              </RegrelloTypography>
            </div>
          )}
          {childRenderFunction(form)}
        </form>
        <RegrelloAvoidLosingEditsPrompt
          isBrowserNativePromptEnabled={form.formState.isDirty}
          isOpen={isAvoidLosingEditsPromptOpen}
          onClose={onAvoidLosingEditsPromptCancel}
          onConfirm={onAvoidLosingEditsPromptConfirm}
        />
      </RegrelloErrorBoundary>
    </RegrelloDialogV2>
  );
}

export const RegrelloFormDialog = React.memo(RegrelloFormDialogInternal) as typeof RegrelloFormDialogInternal;
