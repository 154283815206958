import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type { CustomFieldFrontendSelectableOption } from "../customFields/plugins/types/CustomFieldFrontendSelectableOption";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldSelectV2 } from "../formFields/RegrelloFormFieldSelectV2";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";

type MultiSelectPluginFrontendValue = CustomFieldFrontendSelectableOption[];

export class SpectrumMultiselectFieldPluginDecorator extends SpectrumFieldPluginDecorator<MultiSelectPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<MultiSelectPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.multiSelect";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.MULTI_SELECT;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.MULTI_SELECT,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(_valueConstraints: SpectrumValueConstraintFields[]) {
    return EMPTY_ARRAY;
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.ARRAY_CONTAINS_ONE_OF]: {
        label: t`Contains any of`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_EMPTY]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_EMPTY]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.ARRAY_EQUALS]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_EQUALS]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_CONTAINS_ALL]: {
        label: t`Contains all of`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.ARRAY_NOT_CONTAINS_ONE_OF]: {
        label: t`Contains none of`,
        inputCount: 1,
        isMultiselect: true,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onChange" as const;
  }

  public renderPreviewFormField() {
    return (
      <RegrelloFormFieldSelectV2
        disabled={true}
        getOptionLabel={(option) => option}
        onChange={noop}
        options={[]}
        size="medium"
        value=""
      />
    );
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    return null;
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, MultiSelectPluginFrontendValue>, "onCellValueChanged">,
  ) {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    return {
      colId: getColumnIdFromFormField(formField),
      headerName: spectrumFieldVersion.name,
      ...colDefParams,
    };
  }
}
