import type { FieldInstanceBaseFields } from "./FieldInstanceBaseFields.generated";
import type { FieldInstanceSpectrumFields } from "./FieldInstanceSpectrumFields.generated";
import type {
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueDate,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueString,
  FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime,
  FieldInstanceValueBaseFieldsFieldInstanceValueBoolean,
  FieldInstanceValueBaseFieldsFieldInstanceValueDate,
  FieldInstanceValueBaseFieldsFieldInstanceValueFloat,
  FieldInstanceValueBaseFieldsFieldInstanceValueInt,
  FieldInstanceValueBaseFieldsFieldInstanceValueParty,
  FieldInstanceValueBaseFieldsFieldInstanceValueString,
  FieldInstanceValueBaseFieldsFieldInstanceValueTime,
} from "./FieldInstanceValueBaseFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceFieldsWithBaseValues = {
  values: Array<
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueDate
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueString
    | FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime
    | FieldInstanceValueBaseFieldsFieldInstanceValueBoolean
    | FieldInstanceValueBaseFieldsFieldInstanceValueDate
    | FieldInstanceValueBaseFieldsFieldInstanceValueFloat
    | FieldInstanceValueBaseFieldsFieldInstanceValueInt
    | FieldInstanceValueBaseFieldsFieldInstanceValueParty
    | FieldInstanceValueBaseFieldsFieldInstanceValueString
    | FieldInstanceValueBaseFieldsFieldInstanceValueTime
  >;
} & FieldInstanceBaseFields &
  FieldInstanceSpectrumFields;

export const FieldInstanceFieldsWithBaseValues = gql`
    fragment FieldInstanceFieldsWithBaseValues on FieldInstance {
  ...FieldInstanceBaseFields
  ...FieldInstanceSpectrumFields
  values {
    ...FieldInstanceValueBaseFields
  }
}
    `;
