import { t } from "@lingui/core/macro";
import { EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  type CreateFieldInstanceValueInputs,
  FieldInstanceValueInputType,
  useCreateWorkflowTemplateMutation,
  type WorkflowTemplateForSelectFieldFields,
  WorkflowTemplateType,
} from "@regrello/graphql-api";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import { useErrorHandler } from "../../../../utils/hooks/useErrorHandler";
import { useRegrelloHistory } from "../../../../utils/hooks/useRegrelloHistory";
import { useTitle } from "../../../../utils/hooks/useTitle";
import { getWorkflowTemplatesListQueryWithVariables } from "../../../../utils/queryUtils";
import { RegrelloFormDialogName } from "../../../../utils/sentryScopeUtils";
import { RoutePathsWithId } from "../../../app/routes/consts";
import type { NameTemplateHandle } from "../../../molecules/nameTemplate/utils/nameTemplateUtils";
import { RegrelloConfigureCustomFieldsForm } from "../formDialogs/customFields/RegrelloConfigureCustomFieldsForm";
import {
  ConfigureWorkflowFormNativeFieldKeys,
  RegrelloConfigureWorkflowFormDialog,
  type RegrelloConfigureWorkflowFormFieldValues,
} from "../formDialogs/workflow/RegrelloConfigureWorkflowFormDialog";

export interface CreateWorkflowTemplateFromScratchDialogProps {
  /** Default name to initially populate the create template dialog. */
  defaultName?: string;

  /** Whether the dialog is currently open. */
  isOpen: boolean;

  /** Callback invoked when the dialog closes. */
  onClose: () => void;

  /** Callback invoked when the dialog is submitted and a new workflow template is created. */
  onSubmit?: (newWorkflowTemplate: WorkflowTemplateForSelectFieldFields) => void;
}

/**
 * A dialog that allows the user to create a new workflow template. This dialog uses our new form
 * fields as of Dec 2021.
 */
export const CreateWorkflowTemplateFromScratchDialog = React.memo(function CreateWorkflowTemplateFromScratchDialogFn({
  defaultName,
  isOpen,
  onClose,
  onSubmit,
}: CreateWorkflowTemplateFromScratchDialogProps) {
  const { handleError } = useErrorHandler();
  const regrelloHistory = useRegrelloHistory();

  const isStage0Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.STAGE_0_2024_03);

  const [createWorkflowTemplateAsync] = useCreateWorkflowTemplateMutation({
    onError: (error) => {
      handleError(error, {
        toastMessage: t`Failed to create workflow blueprint. Please try again, or contact a Regrello admin if you continue to see this error.`,
      });
    },
  });

  const { getTitle, setTitle } = useTitle();
  useEffect(() => {
    const oldTitle = getTitle();
    if (isOpen) {
      setTitle(t`Create blueprint`);
      return () => {
        setTitle(oldTitle);
      };
    }
    return () => null;
  }, [getTitle, isOpen, setTitle]);

  const customFieldsFormV2 = useForm<RegrelloConfigureCustomFieldsForm.Fields>({
    mode: "onChange",
    defaultValues: {
      customFields: EMPTY_ARRAY,
    },
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const nameTemplateFormFieldRef = useRef<NameTemplateHandle>(null);

  const handleSubmit = useCallback(
    async (data: RegrelloConfigureWorkflowFormFieldValues) => {
      const isCustomFieldsFormValid = await customFieldsFormV2.trigger(undefined, { shouldFocus: true });
      if (!isCustomFieldsFormValid) {
        return false;
      }

      const fieldInstances: CreateFieldInstanceValueInputs[] =
        RegrelloConfigureCustomFieldsForm.getMutationCreatePayloadFromFormValues(customFieldsFormV2.getValues());

      const nameTemplateInputs = nameTemplateFormFieldRef.current?.getNameTemplateInputs();
      const result = await createWorkflowTemplateAsync({
        variables: {
          input: {
            name: data.name,
            nameTemplate: nameTemplateInputs,
            description: data.description,
            fieldInstances,
            tagIds: EMPTY_ARRAY,
          },
        },
        refetchQueries: [
          getWorkflowTemplatesListQueryWithVariables({
            types: [WorkflowTemplateType.MINE],
          }),
        ],
      });

      if (result.errors != null) {
        return false;
      }

      const workflowTemplate = result?.data?.createWorkflowTemplate?.workflowTemplate;

      if (workflowTemplate == null) {
        return false;
      }

      onSubmit?.(workflowTemplate);
      regrelloHistory.pushWithId(RoutePathsWithId.BLUEPRINTS, workflowTemplate.id);
      return true;
    },
    [createWorkflowTemplateAsync, customFieldsFormV2, onSubmit, regrelloHistory],
  );

  return (
    <RegrelloConfigureWorkflowFormDialog
      addCollaborationsFieldProps={{
        defaultCollaborations: EMPTY_ARRAY,
        helperText: t`Adding people to the blueprint will allow them to view any workflows created from this blueprint`,
      }}
      addCustomFieldProps={{
        addCustomFieldFormV2: customFieldsFormV2,
        customFieldFormHelperText: t`Information necessary to start a workflow from this blueprint.`,
        customFieldFormTitle: t`Requested information`,
        customFieldValueHelperText: t`From the person starting the workflow`,
        allowSelectWorkflowOwner: true,
        disallowSelectInputType: false,
        disallowEditFieldValue: true,
        isCustomFieldSectionHidden: isStage0Enabled,
      }}
      dataTestId={DataTestIds.CREATE_TEMPLATE_DIALOG}
      defaultInteractionType={FieldInstanceValueInputType.REQUESTED}
      defaultName={defaultName}
      hiddenNativeFields={[
        ConfigureWorkflowFormNativeFieldKeys.COLLABORATIONS,
        ConfigureWorkflowFormNativeFieldKeys.TAGS,
        ConfigureWorkflowFormNativeFieldKeys.VERSION_NOTES,
      ]}
      isAddFieldsButtonHidden={isStage0Enabled}
      isOpen={isOpen}
      nameTemplateProps={{ nameTemplateFormFieldRef, context: "blueprint" }}
      onClose={handleClose}
      onSubmit={handleSubmit}
      scopeNameForSentry={RegrelloFormDialogName.ADD_WORKFLOW}
      submitButtonText={t`Create blueprint`}
      title={t`Create blueprint`}
    />
  );
});
