import { t } from "@lingui/core/macro";
import { EMPTY_STRING, getEmailDomain } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloButton, RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  REGRELLO_PRIVACY_POLICY_URL,
  REGRELLO_SUPPORT_EMAIL,
  ValidationRules,
} from "../../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";

export interface UnauthenticatedRequestForEmailPageFormFields {
  email: string;
}

export interface UnauthenticatedRequestForEmailPageProps {
  /**
   * Whether the user is requesting for a new invitatation or a password reset email. Clicking on
   * submit calls different APIs base on the mode. Different instructions are rendered based on the
   * selection.
   */
  mode: "lostInvite" | "passwordReset";
}

export const UnauthenticatedRequestForEmailPage = React.memo(function UnauthenticatedRequestForEmailPageFn({
  mode,
}: UnauthenticatedRequestForEmailPageProps) {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierModeEnabled = supplierQueryValue != null;
  const { resendInvite, requestResetPassword, loading, authError: resendInviteError } = useRegrelloAuthV2();
  const { getQueryParam } = useRegrelloHistory();
  const email = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;
  const source = getQueryParam(RouteQueryStringKeys.SOURCE) ?? EMPTY_STRING;

  const isRegrelloLiteRedirect = source === "regrello_lite";

  const form = useForm<UnauthenticatedRequestForEmailPageFormFields>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // (clewis): Our own defaultValues prop requires a default value for each field, whereas this
    // hook expects a partial. A full object is simply a superset of a partial, but the types are
    // still complaining that we're not providing a partial-typed object. Hence, we cast as any.
    defaultValues: {
      email: email,
    },
  });

  const [shouldUseSso, setShouldUseSso] = useState<boolean>(false);

  useEffect(() => {
    const domain = getEmailDomain(email);
    const updateShouldUseSso = async () => {
      const result = await RegrelloRestApiService.isSso(domain);
      setShouldUseSso(result.status === 200 && result.json.isSso);
    };

    void updateShouldUseSso();
  }, [email]);

  const onResendInvite = useCallback(
    async (data: UnauthenticatedRequestForEmailPageFormFields) => {
      await resendInvite(data.email, isSupplierModeEnabled);
    },
    [resendInvite, isSupplierModeEnabled],
  );

  const onSendPasswordResetEmail = useCallback(
    async (data: UnauthenticatedRequestForEmailPageFormFields) => {
      await requestResetPassword(data.email);
    },
    [requestResetPassword],
  );

  const submit = useCallback(async () => {
    // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
    await form.handleSubmit(mode === "lostInvite" ? onResendInvite : onSendPasswordResetEmail)();
  }, [form, mode, onResendInvite, onSendPasswordResetEmail]);

  const signInLinkHref = {
    pathname: RoutePaths.LOGIN,
    search: isSupplierModeEnabled ? `${RouteQueryStringKeys.SUPPLIER}=1` : undefined,
  };

  return (
    <div
      className="flex justify-center w-full h-full"
      data-testid={DataTestIds.UNAUTHENTICATED_EMAIL_REQUEST_PAGE_ROOT}
    >
      <div className="flex flex-col w-150 px-12 pb-4">
        <div className="flex flex-col flex-auto justify-center">
          <RegrelloTypography className="mb-10" variant="h1">
            {getHeaderText(mode, isSupplierModeEnabled, isRegrelloLiteRedirect)}
          </RegrelloTypography>
          <RegrelloTypography className="mb-10" variant="body" weight="semi-bold">
            {getDescriptionText(mode, isSupplierModeEnabled, isRegrelloLiteRedirect)}
          </RegrelloTypography>

          <div className="flex mb-10">
            <div className="w-1/2 pr-2">
              <RegrelloControlledFormFieldText
                controllerProps={{
                  control: form.control,
                  name: "email",
                  rules: {
                    ...ValidationRules.REQUIRED,
                    ...ValidationRules.VALID_EMAIL,
                  },
                  defaultValue: email,
                }}
                dataTestId={DataTestIds.RESEND_INVITE_INPUT}
                isDefaultMarginsOmitted={true}
                placeholder={t`Email`}
              />
              {resendInviteError != null && (
                <RegrelloTypography className="text-danger-textMuted">{resendInviteError}</RegrelloTypography>
              )}
            </div>

            <RegrelloButton
              className="h-min"
              dataTestId={DataTestIds.RESEND_INVITE_SUBMIT_BUTTON}
              disabled={shouldUseSso}
              intent="primary"
              loading={loading}
              onClick={submit}
            >
              {t`Send`}
            </RegrelloButton>
          </div>

          <div className="mb-10">
            {isRegrelloLiteRedirect ? (
              <RegrelloLinkV2
                className="font-normal"
                onClick={() => {
                  history.back();
                }}
                to="#"
              >
                {t`Return to previous page`}
              </RegrelloLinkV2>
            ) : (
              <RegrelloLinkV2 className="mb-10 font-normal" to={signInLinkHref}>
                {isSupplierModeEnabled ? t`Back to supplier sign in` : t`Back to sign in`}
              </RegrelloLinkV2>
            )}
          </div>

          {isRegrelloLiteRedirect ? null : (
            <RegrelloTypography>
              {t`Having trouble? Contact us at `}{" "}
              <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
                {REGRELLO_SUPPORT_EMAIL}
              </RegrelloLinkV2>
            </RegrelloTypography>
          )}
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});

function getHeaderText(
  mode: "lostInvite" | "passwordReset",
  isSupplierModeEnabled = false,
  isRegrelloLiteRedirect = false,
) {
  if (mode === "passwordReset") {
    return t`Forgot your password?`;
  }
  if (isSupplierModeEnabled) {
    return t`Let's get started`;
  }
  if (isRegrelloLiteRedirect) {
    return t`Need an invite?`;
  }

  return t`Received an invite?`;
}

function getDescriptionText(
  mode: "lostInvite" | "passwordReset",
  isSupplierModeEnabled = false,
  isRegrelloLiteRedirect = false,
) {
  if (mode === "passwordReset") {
    return t`Enter the email address and we’ll send you a link to reset your password.`;
  }
  if (isSupplierModeEnabled) {
    return t`First, we need to verify your identity. Enter the email address used to send you a task.`;
  }
  if (isRegrelloLiteRedirect) {
    return t`Enter the email address and we’ll send your invite.`;
  }

  return t`Enter the email address and we’ll resend your invite.`;
}
