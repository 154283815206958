import type { FieldInstanceValueDateBaseFields } from "./FieldInstanceValueDateBaseFields.generated";
import type { AcyclicFieldInstanceValueDateFields } from "./AcyclicFieldInstanceValueDateFields.generated";
import type { AcyclicFieldInstanceFields } from "./AcyclicFieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueDateFields = {
  sourceFieldInstanceValueDate?: AcyclicFieldInstanceValueDateFields | null;
  sinksFieldInstanceValueDate: Array<AcyclicFieldInstanceValueDateFields>;
  sourceFieldInstanceValueDateV2?: AcyclicFieldInstanceFields | null;
} & FieldInstanceValueDateBaseFields;

export const FieldInstanceValueDateFields = gql`
    fragment FieldInstanceValueDateFields on FieldInstanceValueDate {
  ...FieldInstanceValueDateBaseFields
  sourceFieldInstanceValueDate {
    ...AcyclicFieldInstanceValueDateFields
  }
  sinksFieldInstanceValueDate {
    ...AcyclicFieldInstanceValueDateFields
  }
  sourceFieldInstanceValueDateV2 {
    ...AcyclicFieldInstanceFields
  }
}
    `;
