import type { FieldInstanceValueDateFields } from "./FieldInstanceValueDateFields.generated";
import type { AcyclicFieldInstanceValueDateFields } from "./AcyclicFieldInstanceValueDateFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueDateWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueDate?: AcyclicFieldInstanceValueDateFields | null;
  crossWorkflowSinksFieldInstanceValueDate?: Array<AcyclicFieldInstanceValueDateFields> | null;
} & FieldInstanceValueDateFields;

export const FieldInstanceValueDateWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueDateWithCrossWorkflowFields on FieldInstanceValueDate {
  ...FieldInstanceValueDateFields
  crossWorkflowSourceFieldInstanceValueDate {
    ...AcyclicFieldInstanceValueDateFields
  }
  crossWorkflowSinksFieldInstanceValueDate {
    ...AcyclicFieldInstanceValueDateFields
  }
}
    `;
