import type { FieldInstanceValueStringWithCrossWorkflowFields } from "./FieldInstanceValueStringWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueStringWithCrossWorkflowFields } from "./FieldInstanceMultiValueStringWithCrossWorkflowFields.generated";
import type { FieldInstanceValueIntWithCrossWorkflowFields } from "./FieldInstanceValueIntWithCrossWorkflowFields.generated";
import type { FieldInstanceValueFloatWithCrossWorkflowFields } from "./FieldInstanceValueFloatWithCrossWorkflowFields.generated";
import type { FieldInstanceValueBooleanWithCrossWorkflowFields } from "./FieldInstanceValueBooleanWithCrossWorkflowFields.generated";
import type { FieldInstanceValueTimeWithCrossWorkflowFields } from "./FieldInstanceValueTimeWithCrossWorkflowFields.generated";
import type { FieldInstanceValueDateWithCrossWorkflowFields } from "./FieldInstanceValueDateWithCrossWorkflowFields.generated";
import type { FieldInstanceValuePartyWithCrossWorkflowFields } from "./FieldInstanceValuePartyWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from "./FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from "./FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from "./FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueIntWithCrossWorkflowFields } from "./FieldInstanceMultiValueIntWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from "./FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from "./FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated";
import type { FieldInstanceMultiValueDateWithCrossWorkflowFields } from "./FieldInstanceMultiValueDateWithCrossWorkflowFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDate = {
  id: number;
} & FieldInstanceMultiValueDateWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument = {
  id: number;
} & FieldInstanceMultiValueDocumentWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat = {
  id: number;
} & FieldInstanceMultiValueFloatWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt = {
  id: number;
} & FieldInstanceMultiValueIntWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty = {
  id: number;
} & FieldInstanceMultiValuePartyWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance = {
  id: number;
} & FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString = {
  id: number;
} & FieldInstanceMultiValueStringWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime = {
  id: number;
} & FieldInstanceMultiValueTimeWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean = {
  id: number;
} & FieldInstanceValueBooleanWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueDate = {
  id: number;
} & FieldInstanceValueDateWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat = {
  id: number;
} & FieldInstanceValueFloatWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt = {
  id: number;
} & FieldInstanceValueIntWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty = {
  id: number;
} & FieldInstanceValuePartyWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString = {
  id: number;
} & FieldInstanceValueStringWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime = {
  id: number;
} & FieldInstanceValueTimeWithCrossWorkflowFields;

export type FieldInstanceValueWithCrossWorkflowFields =
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDate
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueDate
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString
  | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime;

export const FieldInstanceValueWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueWithCrossWorkflowFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringWithCrossWorkflowFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntWithCrossWorkflowFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatWithCrossWorkflowFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanWithCrossWorkflowFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeWithCrossWorkflowFields
  }
  ... on FieldInstanceValueDate {
    ...FieldInstanceValueDateWithCrossWorkflowFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeWithCrossWorkflowFields
  }
  ... on FieldInstanceMultiValueDate {
    ...FieldInstanceMultiValueDateWithCrossWorkflowFields
  }
}
    `;
