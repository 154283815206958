import {
  type ApolloError,
  type FetchResult,
  isApolloError,
  NetworkStatus,
  type OperationVariables,
  type QueryResult,
} from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { t } from "@lingui/core/macro";
import { ErrorCode, type ErrorDetails } from "@regrello/graphql-api";
import type { DocumentNode } from "graphql";

// (clewis): These function are new as of Aug 18 and haven't been rigorously battle-tested to verify
// correctness with edge cases like refetching, polling, etc. Use them cautiously.

export async function awaitCorrectlyTypedAwaitedMutationResults(
  promises: Array<string | Promise<FetchResult<unknown>>>,
): Promise<FetchResult[]> {
  // (dosipiuk): Type cast is needed due to invalid inference from generated apollo types
  //
  // See: https://github.com/apollographql/apollo-client/issues/9292
  const results = (await Promise.all(promises)) as FetchResult[];
  return results;
}

/**
 * Returns `true` if the provided Apollo Client query result has not been called or is still
 * initially loading.
 */
export function isQueryResultLoading<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  return !queryResult.called || queryResult.loading;
}

/**
 * Returns `true` if the provided Apollo Client query result has been called and finished loading -
 * either successfully (with data) or unsuccessfully (with an error).
 */
export function isQueryResultReady<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  return (
    !isQueryResultLoading(queryResult) &&
    (queryResult.networkStatus === NetworkStatus.ready || queryResult.networkStatus === NetworkStatus.error)
  );
}

/**
 * Returns `true` if the provided Apollo Client query result has returned an error.
 */
export function isQueryResultError<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
) {
  // (clewis): This isn't the most beautiful - or even verifiably correct - check, but it's what we
  // tend to check nowadays. Probably deserves more investigation (e.g., into networkStatus).
  return !isQueryResultLoading(queryResult) && (queryResult.error != null || queryResult.data == null);
}

/** Returns `true` if at least one result in the provided array of fetch results has failed. */
// (dosipiuk): Type cast is needed due to invalid inference from generated apollo types
//
// See: https://github.com/apollographql/apollo-client/issues/9292
export function isSomeMutationResultFailed(results: FetchResult[]): boolean {
  return results.some((result) => result.errors != null);
}

/**
 * Returns `true` if the provided GraphQL response error was considered a user-validation error by
 * the backend. This distinction affects how the error is reported to our monitoring tools.
 */
export function isValidationApolloError({ graphQLErrors }: Pick<ApolloError, "graphQLErrors">): boolean {
  const errorCode = getRegrelloErrorCodeFromApolloError({ graphQLErrors });
  return errorCode?.startsWith("ValidationErrorCode") ?? false;
}

/**
 * Returns the freshest data possible from the provided query result. The `getPreviousId` and
 * `currentId` parameters help determine whether the previous data and current data correspond to
 * the same entity or separate entities. If they're different entities, then the function won't fall
 * back to the `previousData`.
 */
export function getDataOrFallbackToPreviousData<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>,
  getPreviousId: (previousData: TData | undefined) => string | number | null | undefined,
  currentId: string | number | null | undefined,
): TData | undefined {
  if (queryResult.data != null) {
    return queryResult.data;
  }

  const previousId = getPreviousId(queryResult.previousData);
  const shouldIgnorePreviousData = previousId != null && currentId != null && previousId !== currentId;
  if (queryResult.error == null && !shouldIgnorePreviousData) {
    return queryResult.previousData;
  }
  return undefined;
}

/**
 * Returns the Regrello error code from the provided `ApolloError` if an error code is present, or
 * `undefined` otherwise. If for some reason there are two error codes, this function will return
 * only the first one.
 */
export function getRegrelloErrorCodeFromApolloError({
  graphQLErrors,
}: Pick<ApolloError, "graphQLErrors">): string | undefined {
  if (graphQLErrors.length === 0) {
    return undefined;
  }
  const errorCode = graphQLErrors[0].extensions.code;
  if (typeof errorCode !== "string") {
    return undefined;
  }
  return errorCode;
}

function isErrorDetails(obj: unknown): obj is ErrorDetails {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const { errorcode } = obj as ErrorDetails;
  if (typeof errorcode !== "string") {
    return false;
  }

  const parameter = (obj as ErrorDetails).parameter;
  if (!Array.isArray(parameter) || parameter === null) {
    return false;
  }

  return true;
}

function assertNever(x: never): never {
  throw new Error(`Unhandled case: ${x}`);
}

function getParameter(parameters: ErrorDetails["parameter"], key: string) {
  for (const parameter of parameters) {
    if (parameter.key === key) {
      return parameter.value;
    }
  }

  return undefined;
}

function getNumberParameter(parameters: ErrorDetails["parameter"], key: string) {
  const value = getParameter(parameters, key);

  if (typeof value === "number") {
    return value;
  }

  return undefined;
}

function getBooleanParameter(parameters: ErrorDetails["parameter"], key: string) {
  const value = getParameter(parameters, key);

  if (typeof value === "boolean") {
    return value;
  }

  return undefined;
}

function getStringParameter(parameters: ErrorDetails["parameter"], key: string) {
  const value = getParameter(parameters, key);

  if (typeof value === "string") {
    return value;
  }

  return String(value);
}

/**
 * Returns the Regrello "FriendlyMessageObject" as translated string (if it exists) from the provided `ApolloError`. If
 * for some reason there are more than one error, this function will use only the first one.
 */
export function getRegrelloErrorFriendlyMessageFromApolloError(error: Error | string): string | undefined {
  if (typeof error !== "object" || !isApolloError(error)) {
    return undefined;
  }

  const { graphQLErrors } = error;

  if (graphQLErrors.length === 0) {
    return undefined;
  }

  const errorDetails = graphQLErrors[0].extensions.errorDetails;

  if (!isErrorDetails(errorDetails)) {
    // Fallback to the old friendly message format
    const friendlyMessage = graphQLErrors[0].extensions.friendlyMessage;

    if (typeof friendlyMessage !== "string") {
      return undefined;
    }

    return friendlyMessage;
  }

  const errorCode = errorDetails.errorcode;
  switch (errorCode) {
    case ErrorCode.FILE_EXCEEDS_SIZE: {
      const maxFileSize = getNumberParameter(errorDetails.parameter, "maxFileSize" as const);

      if (!maxFileSize) {
        return t`Uploaded file exceeded file size limit.`;
      }

      const fileSizeInKilobyte = Math.round(maxFileSize / 1000);
      return t`Uploaded file cannot exceed ${fileSizeInKilobyte} kB.`;
    }

    case ErrorCode.DOCUSIGN_TEAMS_CANNOT_BE_ASSIGNED: {
      return t`The document cannot be sent to a team for signature via DocuSign. Please restart the task or review the workflow level data that is sending in the signor(s) and only include people.`;
    }

    case ErrorCode.CANNOT_ASSIGN_SCIM_ROLE: {
      return t`Cannot manually assign a role that is managed by SCIM. All assignments must be done via SCIM.`;
    }

    case ErrorCode.NAME_CONFLICT: {
      const objectName = getStringParameter(errorDetails.parameter, "objectName" as const) as "role" | "field";

      if (objectName === "role") {
        return t`A role with the provided name already exists. Please choose another name.`;
      }

      if (objectName === "field") {
        return t`A field with the provided name already exists. Please choose another name.`;
      }

      return t`A ${objectName} with the provided name already exists. Please choose another name.`;
    }

    case ErrorCode.CANNOT_PUBLISH_DUE_TO_WORKFLOW_TEMPLATE_RELATION_MISMATCH: {
      return t`Ensure that each linked blueprint has either an assigned owner or matching start workflow permissions.`;
    }

    case ErrorCode.BLUEPRINT_CONTAINS_UNMAPPED_FORM_FIELDS: {
      const actionItemTemplateName = getStringParameter(errorDetails.parameter, "actionItemTemplateName" as const);
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`Cannot publish Blueprint because the task ${actionItemTemplateName} has an unmapped required field ${fieldName}. Please update the mapping and try again.`;
    }

    case ErrorCode.BLUEPRINT_FORM_NAME_CANNOT_BE_EMPTY: {
      return t`Cannot publish Blueprint because one of the forms has an empty name. Please name your form and try again.`;
    }

    case ErrorCode.BLUEPRINT_FORM_INVALID_SECTION: {
      const formName = getStringParameter(errorDetails.parameter, "formName" as const);
      const formSection = getStringParameter(errorDetails.parameter, "formSection" as const);
      return t`Cannot publish Blueprint because the form ${formName} contains an invalid section. Please remove the section ${formSection} and try again.`;
    }

    case ErrorCode.BLUEPRINT_FORM_HAS_NO_SECTIONS: {
      const formName = getStringParameter(errorDetails.parameter, "formName" as const);
      return t`Cannot publish Blueprint because the form ${formName} has no sections. Please add a section and try again.`;
    }

    case ErrorCode.BLUEPRINT_FORM_HAS_EMPTY_COLUMNS: {
      const formName = getStringParameter(errorDetails.parameter, "formName" as const);
      return t`Cannot publish Blueprint because the form ${formName} has empty columns. Please remove empty columns and try again.`;
    }

    case ErrorCode.BLUEPRINT_FORM_HAS_EMPTY_SECTIONS: {
      const formName = getStringParameter(errorDetails.parameter, "formName" as const);
      return t`Cannot publish Blueprint because the form ${formName} has empty sections. Please remove empty sections and try again.`;
    }

    case ErrorCode.FORM_NAME_IS_MUST_BE_UNIQUE_IN_BLUEPRINT: {
      const name = getStringParameter(errorDetails.parameter, "name" as const);
      return t`Cannot publish Blueprint because the form name ${name} is used more than once. Please rename your form and try again.`;
    }

    case ErrorCode.FORM_NAME_CANNOT_BE_EMPTY: {
      return t`Form name cannot be empty, please name your form and try again.`;
    }

    case ErrorCode.FORM_NAME_IS_ALREADY_IN_USE: {
      const name = getStringParameter(errorDetails.parameter, "name" as const);
      return t`Form version name ${name} is already in use, please rename your form and try again.`;
    }

    case ErrorCode.FORM_HAS_EMPTY_SECTIONS: {
      return t`Cannot publish a form that has empty sections. Please remove empty sections and try again.`;
    }

    case ErrorCode.FORM_HAS_NO_SECTIONS: {
      return t`Cannot publish a form that has no sections. Please add a section and try again.`;
    }

    case ErrorCode.MULTIPLE_PARTY_NOT_ALLOWED: {
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`The ${fieldName}field is configured to only accept a single party. Multiple parties are not allowed.`;
    }

    case ErrorCode.CANNOT_DELETE_ROLE_FIELD: {
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`The ${fieldName} field cannot be deleted until the role it is associated with is deleted.`;
    }

    case ErrorCode.CANNOT_REQUEST_ROLE_FIELD_MULTIPLE_TIMES: {
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`The ${fieldName} field cannot be requested more than once in a workflow or blueprint.`;
    }

    case ErrorCode.CANNOT_REQUEST_ROLE_FIELD_MULTIPLE_TIMES_VIA_FORM: {
      return t`Roles cannot be requested more than once in a workflow or blueprint (including forms).`;
    }

    case ErrorCode.SCOPED_NON_ADMIN_PEOPLE_AND_TEAMS_QUERIES_NOT_ALLOWED: {
      return t`Specific people and teams queries are not accessible to non-admins.`;
    }

    case ErrorCode.PARTY_CANNOT_FILL_ROLE_FIELD_WITH_ID: {
      const roleName = getStringParameter(errorDetails.parameter, "roleName" as const);
      return t`The supplied person does not belong to the role ${roleName}.`;
    }

    case ErrorCode.CANNOT_MODIFY_PUBLISHED_FORM: {
      return t`Form is already published and cannot be modified.`;
    }

    case ErrorCode.CANNOT_MODIFY_FORM_VERSION_USED_IN_PUBLISHED_BLUEPRINT: {
      return t`Form version is being used in a published blueprint and cannot be modified.`;
    }

    case ErrorCode.CANNOT_CREATE_FORM_ON_PUBLISHED_BLUEPRINT: {
      return t`Cannot create a new form version on a published blueprint.`;
    }

    case ErrorCode.EXTERNAL_USER_CANNOT_CREATE_ACTION_ITEM_TEMPLATE_WITH_FIELD_INSTANCES: {
      return t`External users cannot create tasks with field instances.`;
    }

    case ErrorCode.EXTERNAL_USER_CANNOT_CREATE_AUTOMATED_TASKS: {
      return t`External users cannot create automated tasks.`;
    }

    case ErrorCode.CANNOT_ASSIGN_EXTERNAL_USER_OR_TEAM_AS_WORKFLOW_OWNER: {
      return t`The workflow owner was set to an external user or team. Please set the owner to an internal user or team and try again.`;
    }

    case ErrorCode.CANNOT_UPDATE_WORKFLOW_OWNER: {
      return t`Cannot update workflow owner since it is controlled by the parent workflow.`;
    }

    case ErrorCode.CANNOT_VIEW_FORM_VERSION: {
      return t`User does not have permission to view this form version.`;
    }

    case ErrorCode.FORM_IS_LOCKED: {
      return t`Form is currently being modified by another user. Please try again later.`;
    }

    case ErrorCode.ACTION_ITEM_TEMPLATE_IS_LOCKED: {
      return t`The task is currently being modified by another user. Please try again later.`;
    }

    case ErrorCode.CANNOT_MODIFY_FORM_VERSION_WITH_ACTION_ITEMS: {
      return t`Cannot modify form version with tasks attached. Please create a new form version.`;
    }

    case ErrorCode.CANNOT_DELETE_IN_USE_FORM: {
      return t`Cannot delete a form that is in use.`;
    }

    case ErrorCode.CANNOT_EDIT_FORM_FROM_COMPLETED_WORKFLOW: {
      return t`Cannot edit a form from completed workflows.`;
    }

    case ErrorCode.FORM_VERSION_NOT_FOUND: {
      return t`Form version not found.`;
    }

    case ErrorCode.UNKNOWN_DIRECTIVE_FIELD: {
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`${fieldName} is not a known field and could not be validated.`;
    }

    case ErrorCode.CANNOT_ACTION_FORM_CREATED_BY_ANOTHER_USER: {
      const action = getStringParameter(errorDetails.parameter, "action" as const);
      return t`Cannot ${action} a form created by another user.`;
    }

    case ErrorCode.EXTERNAL_USER_CANNOT_SAVE_COLLABORATION_WITH_UNRELATED_USER: {
      const offendingPartyID = getStringParameter(errorDetails.parameter, "offendingPartyID" as const);
      return t`User does not have permission to add party ID '${offendingPartyID}' as a workflow collaborator`;
    }

    case ErrorCode.FIELD_MUST_BE_FILLED_OUT_BEFORE_SUBMITTING_TASK: {
      const fieldName = getStringParameter(errorDetails.parameter, "fieldName" as const);
      return t`Field '${fieldName}' must be filled out before submitting the task.`;
    }

    case ErrorCode.USER_DOES_NOT_HAVE_PERMISSION_TO_DISMISS_NOTIFICATION_CARD_ON_WORKFLOW: {
      const workflowID = getStringParameter(errorDetails.parameter, "workflowID" as const);
      return t`Insufficient permission to dismiss a notification card on workflow '${workflowID}'.`;
    }

    case ErrorCode.USER_DOES_NOT_HAVE_PERMISSION_TO_UPDATE_NOTIFICATION_CARD_ON_WORKFLOW: {
      const workflowID = getStringParameter(errorDetails.parameter, "workflowID" as const);
      return t`Insufficient permission to update a notification card on workflow '${workflowID}'.`;
    }

    case ErrorCode.INVALID_FEEDBACK_SENTIMENT: {
      const feedbackSentiment = getStringParameter(errorDetails.parameter, "feedbackSentiment" as const);
      return t`'${feedbackSentiment}' is not valid feedback sentiment for a notification card.`;
    }

    case ErrorCode.USER_NOT_ALLOWED_TO_INVITE_USERS: {
      const missingEmail = getStringParameter(errorDetails.parameter, "missingEmail" as const);
      return t`The user ${missingEmail} does not exist and you do not have permission to invite new users.`;
    }

    case ErrorCode.CANNOT_SHARE_NON_COMPLIANT_WORKFLOW_TEMPLATE: {
      return t`Cannot publish a blueprint variant that is not compliant with the standard blueprint.`;
    }

    case ErrorCode.CANNOT_SHARE_WORKFLOW_TEMPLATE_WITHOUT_VERSION_NOTES: {
      const isChildBlueprint = getBooleanParameter(errorDetails.parameter, "isChildBlueprint" as const);
      const blueprintName = getStringParameter(errorDetails.parameter, "blueprintName" as const);

      if (isChildBlueprint) {
        return t`The blueprint cannot be published with missing version notes for the linked blueprint ${blueprintName}. Editors of the blueprint can fill in version notes by going to the About tab and selecting Edit details.`;
      }

      return t`The blueprint ${blueprintName} cannot be published with missing version notes. Editors of the blueprint can fill in version notes by going to the About tab and selecting Edit details.`;
    }

    case ErrorCode.NESTED_CONDITION_GROUPS_NOT_SUPPORTED: {
      return t`Nested condition groups are not supported.`;
    }

    case ErrorCode.VERSION_NOTES_MAX_LENGTH_EXCEEDED: {
      const maxLength = getNumberParameter(errorDetails.parameter, "maxLength" as const) ?? "?";
      return t`The provided version notes are too long, please try again with fewer than ${maxLength} characters.`;
    }

    case ErrorCode.USER_DOES_NOT_HAVE_PERMISSION_TO_GENERATE_BLUEPRINTS_WITHOUT_PROVIDING_DOCUMENTS: {
      return t`You do not have the permissions required to generate blueprints without providing documents.`;
    }

    case ErrorCode.FORBIDDEN: {
      return t`Insufficient permissions to perform this action.`;
    }

    case ErrorCode.FIELD_MAPPING_FOR_AUTOMATION_TASK_MUST_BE_COMPLETED: {
      return t`Field mapping for automation task must be completed.`;
    }

    case ErrorCode.MULTI_USER_FIELD_MUST_BE_UNIQUE: {
      return t`People field must contain unique values.`;
    }

    case ErrorCode.COLUMN_SECTION_FULLY_OCCUPIED: {
      return t`Cannot add fields to fully occupied columns.`;
    }

    case ErrorCode.DELETE_ROLE_WHILE_PUBLISHED: {
      return t`Cannot delete a role while it is still used in published workflow templates.`;
    }

    case ErrorCode.UPDATE_USER_FIELD_FAILED: {
      return t`Unable to update user field instances.`;
    }

    case ErrorCode.CANNOT_REMOVE_SELF_FROM_ADMIN_ROLE: {
      return t`Cannot remove yourself from the Admin role.`;
    }

    case ErrorCode.INSUFFICIENT_PERMISSIONS_TO_INVITE_USER: {
      return t`Insufficient permissions to invite this user.`;
    }

    case ErrorCode.INSUFFICIENT_PERMISSIONS_TO_ASSIGN_ROLES: {
      return t`Insufficient permissions to assign roles to another user.`;
    }

    case ErrorCode.MAX_RESULTS_OUT_OF_RANGE: {
      const min = getNumberParameter(errorDetails.parameter, "min" as const) ?? "?";
      const max = getNumberParameter(errorDetails.parameter, "max" as const) ?? "?";
      return t`Max results must be between ${min} and ${max}.`;
    }

    default: {
      assertNever(errorCode);
    }
  }
}

/**
 * This function uses `apollo client` trick to refetch queries with latest variables from any place,
 * without passing those variables around. This requires to pass an array of strings containing
 * operation names, instead of full `DocumentNode`.
 *
 * @param documents List of graphql DocumentNode to refetch
 */
export function refetchWithLatestVariablesByDocument(documents: DocumentNode[]) {
  const refetchQueries = [];

  for (const document of documents) {
    const operationName = getOperationName(document);
    if (operationName != null) {
      refetchQueries.push(operationName);
    }
  }

  return refetchQueries.length > 0 ? refetchQueries : undefined;
}
