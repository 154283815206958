/** An enumeration of all feature flag keys currently defined in Launch Darkly. */
// (clewis): If you delete a feature flag in Launch Darkly, please remember to delete it here!
export enum FeatureFlagKey {
  // Please keep alphabetized:
  AI_AGENT_2024_09 = "ai-agent-2024-09",
  AI_AGENT_V2_2025_01 = "ai-agent-v2-2025-01",
  AI_MONITOR_2024_05 = "ai-monitor-2024-05",
  APP_GENERATION_2023_12 = "app-generation-2023-12",
  ASYNC_BULK_START_WORKFLOWS_2024_09 = "async-bulk-start-workflows-2024-09",
  AI_TAILORING_BLUEPRINT_TO_TEMPLATE = "ai-tailoring-blueprint-to-template-2024-11",
  BLUEPRINT_TRANSFER_20204_02 = "blueprint-transfer-2024-02",
  BLUEPRINT_TRANSFER_BETA_2025_04 = "blueprint-transfer-beta-2025-04",
  BLUEPRINT_TRANSFER_FIELD_MATCHING_2024_09 = "blueprint-transfer-field-matching-2024-09",
  BLUEPRINT_VARIATIONS_2024_08 = "blueprint-variations-08-24",
  BLUEPRINT_VERSIONS_2024_07 = "blueprint-versions-2024-07",
  BULK_START_WORKFLOWS_WITHOUT_FLATFILE_2024_12 = "bulk-start-workflows-without-flatfile-2024-12",
  COLLABORATIVE_BLUEPRINT_EDITING_2024_11 = "collaborative-blueprint-editing-2024-11",
  CONDITIONALLY_REQUIRED_SMART_RULE_2024_12 = "conditionally-required-smart-rule-2024-12",
  CREATE_WORKFLOW_SHORTCUT_2025_02 = "create-workflow-shortcut-2025-02",
  DISABLE_OLD_APPROVALS_2023_09 = "disable-old-approvals-2023-09",
  FORM_GEN_2024_05 = "form-gen-2024-05",
  I18N_PHASE_ONE_2024_10 = "i18n-phase-one-2024-10",
  INACTIVE_ASSIGNEES_2024_08 = "inactive-assignees-2024-08",
  INBOX_2024_10 = "inbox-2024-10",
  INTERNAL_DEV_ONLY_PAGES_2024_04 = "internal-dev-only-pages-2024-04",
  MIGRATE_ALLOWED_VALUES_AUDIT_HISTORY_2024_12 = "migrate-allowed-values-audit-history-2024-12",
  PERMISSIONS_V2_2024_01 = "permissions-v2-2024-01",
  PROJECT_TENSION_2024_11 = "project-tension-2024-11",
  PROJECT_TENSION_FALLBACK_RICH_TEXT_HTML_TO_PLAIN_TEXT_2024_12 = "project-tension-fallback-rich-text-html-to-plain-text-2024-12",
  PROJECT_TENSION_RICH_TEXT_EDITING_2024_12 = "project-tension-rich-text-editing-2024-12",
  RBAC_M6_2024_11 = "rbac-m6-2024-11",
  REMOVE_FORMS_PAGE_2024_08 = "remove-forms-page-2024-08",
  REVISED_SIGN_IN_2024_11 = "revised-sign-in-2024-11",
  SAVE_FORM_AS_PDF_2024_04 = "save-form-as-pdf-2024-04",
  SCIM_PERMISSIONS_2024_04 = "scim-permissions-2024-04",
  SCIM_OAUTH_2024_07 = "scim-oauth-2024-07",
  SESSION_MANAGEMENT_2023_04 = "session-management-2023-04",
  SPECTRUM_ALLOWED_VALUES_2024_12 = "spectrum-allowed-values-2024-12",
  SPECTRUM_BUTTON_TO_UPDATE_BLUEPRINT_FIELD_VERSIONS_2024_05 = "spectrum-button-to-update-blueprint-field-versions-2024-05",
  SPECTRUM_CUSTOM_DATE_FIELDS_AS_DATE_ONLY_FIELDS_2024_11 = "custom-date-fields-as-date-only-fields-2024-11",
  SPECTRUM_TASK_INFORMATION_ORDER_TOGGLE_2024_03 = "spectrum-task-information-order-toggle-2024-03",
  SPECTRUM_TWO_COLUMN_LAYOUT_2024_11 = "spectrum-two-column-layout-2024-11",
  SPECTRUM_VERSION_AWARENESS_IN_UI = "spectrum-version-awareness-in-ui",
  STAGE_0_2024_03 = "stage-0-2024-03",
  STAGE_CONDITION_GROUPS_2024_12 = "stage-condition-groups-2024-12",
  TABLES_MILESTONE_1_2025_01 = "tables-milestone-1-2025-01",
  UI_REDESIGN_WORKFLOW_PROGRESS_BAR_2024_08 = "ui-redesign-workflow-progress-bar-2024-08",
  UPDATED_WORKFLOW_SUMMARY_2023_06 = "updated-workflow-summary-2023-06",
  WORKFLOW_SUMMARY_2023_03 = "workflow-summary-2023-03",
  WORKSPACE_CREATION_2022_05 = "workspace-creation-2022-05",
  WORKLFOW_OWNER_M2_2024_04 = "workflow-owner-m2-2024-04",
}

/**
 * A map of feature flag keys to any keys that they depend on in order to be considered enabled.
 * This should be used sparingly as in practice feature flags should not depend on each other but
 * rather should be enabled per workspace segments. However, sometimes wide-reaching features will
 * be in development for long stretches of time and they need to start being incorporated into the
 * technical designs and functionality of new features.
 * NOTE: this should be kept in sync with the backend equivalent in 'flags.go'
 * (should we consider moving all flag evaluation to the backend to make this dry?)
 */
export const FeatureFlagKeyDependencyMap = new Map<FeatureFlagKey, FeatureFlagKey[]>([
  [FeatureFlagKey.BLUEPRINT_VERSIONS_2024_07, [FeatureFlagKey.PERMISSIONS_V2_2024_01]],
  [
    FeatureFlagKey.BLUEPRINT_VARIATIONS_2024_08,
    [FeatureFlagKey.BLUEPRINT_VERSIONS_2024_07, FeatureFlagKey.PERMISSIONS_V2_2024_01],
  ],
  [FeatureFlagKey.SPECTRUM_ALLOWED_VALUES_2024_12, [FeatureFlagKey.MIGRATE_ALLOWED_VALUES_AUDIT_HISTORY_2024_12]],
]);
