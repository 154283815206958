import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import {
  type CreateFieldInstanceValueInputs,
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import { isValid } from "date-fns";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import { truncateToDateString } from "workspace/libs/core-utils/src/utils/dateUtils";

import { getErrorMessageWithPayload } from "../../../utils/getErrorMessageWithPayload";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type {
  CustomFieldPlugin,
  GetCreateFieldInstanceValueInputsFromFormValueParams,
} from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldSelect } from "../formFields/controlled/regrelloControlledFormFields";
import { RegrelloFormFieldDate } from "../formFields/RegrelloFormFieldDate";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import {
  FrontendValueConstraintRuleName,
  ON_OR_AFTER_TODAY_ARG,
  ON_OR_BEFORE_TODAY_ARG,
} from "./utils/spectrumFieldConstraintUtils";

type DateFieldPluginFrontendValue = Date | null;

const ERROR_INVALID_FORM_VALUE = "Provided 'date'-field form value is not a valid date string";

export class SpectrumDateFieldPluginDecorator extends SpectrumFieldPluginDecorator<DateFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<DateFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.date";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.TIMESTAMP;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.TIME);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.TIMESTAMP,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE,
    );
  }

  public isDataFormatToggleVisible() {
    return true;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public override getCreateFieldInstanceValueInputsFromFormValue = (
    inputs: GetCreateFieldInstanceValueInputsFromFormValueParams,
  ): CreateFieldInstanceValueInputs => {
    const { displayOrder, field, inputType, spectrumFieldVersion, value } = inputs;
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }

    if (value == null) {
      return {
        fieldId: field.id,
        timeValue: undefined,
        inputType,
        displayOrder,
        spectrumFieldVersionId: spectrumFieldVersion?.id,
      };
    }

    const yearPart = value.getFullYear();
    const monthPart = pad2(value.getMonth() + 1);
    const datePart = pad2(value.getDate());

    const hourPart = pad2(value.getHours());
    const minutePart = pad2(value.getMinutes());
    const secondPart = pad2(value.getSeconds());
    const milliPart = String(value.getMilliseconds()).padStart(3, "0");

    const fullDatePart = `${yearPart}-${monthPart}-${datePart}`;
    const fullTimePart = `${hourPart}:${minutePart}:${secondPart}.${milliPart}`;
    const timezonePart = getClientTimezoneOffset();

    const localISOString = `${fullDatePart}T${fullTimePart}${timezonePart}`;

    return {
      fieldId: field.id,
      timeValue: localISOString,
      dateValue: truncateToDateString(localISOString),
      inputType,
      displayOrder,
      spectrumFieldVersionId: spectrumFieldVersion?.id,
    };
  };

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.GTE]: {
        label: t`Is after or on`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.LTE]: {
        label: t`Is before or on`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is on same calendar day`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not on same calendar day`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onChange" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloFormFieldDate disabled={true} onChange={noop} size="medium" value={undefined} />;
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    const { constraints, form, disabled } = props;
    if (constraints.length <= 0) {
      return null;
    }
    const options = [
      { constraint: undefined, args: EMPTY_ARRAY },
      { constraint: constraints[0].constraint, args: [ON_OR_AFTER_TODAY_ARG] },
      { constraint: constraints[0].constraint, args: [ON_OR_BEFORE_TODAY_ARG] },
    ];

    return (
      <RegrelloControlledFormFieldSelect
        className="w-full"
        controllerProps={{
          control: form.control,
          name: "valueConstraints.0",
        }}
        disabled={disabled}
        getOptionLabel={(option) => {
          const isOnOrBeforeToday =
            option.constraint?.valueConstraintRule ===
              FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE &&
            option.args.length > 0 &&
            option.args[0] === ON_OR_BEFORE_TODAY_ARG;
          const isOnOrAfterToday =
            option.constraint?.valueConstraintRule ===
              FrontendValueConstraintRuleName.BEFORE_OR_AFTER_TODAY_INCLUSIVE &&
            option.args.length > 0 &&
            option.args[0] === ON_OR_AFTER_TODAY_ARG;

          return isOnOrAfterToday
            ? t`Only dates in the future, including today`
            : isOnOrBeforeToday
              ? t`Only dates in the past, including today`
              : t`All dates`;
        }}
        label={t`Accepted dates`}
        options={options}
      />
    );
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, DateFieldPluginFrontendValue>, "onCellValueChanged">,
  ) {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    return {
      colId: getColumnIdFromFormField(formField),
      headerName: spectrumFieldVersion.name,
      ...colDefParams,
    };
  }
}

function isValueValid(value: unknown): value is DateFieldPluginFrontendValue {
  return value == null || (value instanceof Date && isValid(value));
}

/**
 * Returns a string of the form "-07:00", "+00:00", etc., indicating the client's timezone offset.
 */
function getClientTimezoneOffset() {
  // (hchen): The ISO string must carry the timezone offset to be correctly handled by the
  // backend. When evaluating date field constraints such as "must be on or before today", the
  // stored value should be compared against EOD in the client timezone. It won't make sense to
  // compare a UTC timestamp against EOD in UTC, nor comparing a UTC timestamp with EOD in server
  // timezone.
  const date = new Date();
  const offset = -date.getTimezoneOffset();
  const sign = offset >= 0 ? "+" : "-";

  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;

  return `${sign}${pad2(offsetHours)}:${pad2(offsetMinutes)}`;
}

/** Pads the provided values to 2 digits with a leading "0" if needed. */
function pad2(num: number) {
  return num.toString().padStart(2, "0");
}
