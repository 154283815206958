import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";
import React, { useMemo } from "react";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RoutePaths } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";

export const UnauthenticatedCredentialsErrorPage = React.memo(function UnauthenticatedCredentialsErrorPageFn() {
  const { redirect } = useQueryMap();

  const redirectTo = useMemo(() => (redirect === RoutePaths.LOGIN ? RoutePaths.LOGIN : RoutePaths.HOME), [redirect]);
  const email = REGRELLO_SUPPORT_EMAIL;

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col px-12 pb-4 w-200">
        <div className="flex flex-col flex-1 justify-center gap-10">
          <RegrelloTypography variant="h1">{t`There's a problem with your credentials.`}</RegrelloTypography>
          <RegrelloTypography variant="body">
            <Trans>
              Please contact <RegrelloLinkV2 to={`mailto:${email}`}>{email}</RegrelloLinkV2> for assistance.
            </Trans>
          </RegrelloTypography>

          <RegrelloLinkV2 to={redirectTo}>
            {redirect === RoutePaths.LOGIN ? t`Back to sign in` : t`Back to home`}
          </RegrelloLinkV2>
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});
