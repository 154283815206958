import { t } from "@lingui/core/macro";
import { isDefined } from "@regrello/core-utils";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import type { FieldFields, PartyBaseFields, SpectrumFieldVersionFields } from "@regrello/graphql-api";

import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { getBasePartyTypeUnion, getPartyEmail } from "../../../../../utils/parties/partyUtils";

export interface ValidationResult {
  value?: string;
  info?: Array<{
    message: string;
    level?: "error" | "warning" | "info";
  }>;
}

export function normalizeSelectValue(value: string): string {
  return value.toLocaleLowerCase();
}

export function validateRequired(value: string, isRequired: boolean): ValidationResult | null {
  if (isRequired && value === "") {
    return {
      value,
      info: [
        {
          message: t`Required`,
          level: "error",
        },
      ],
    };
  }
  return null;
}

/**
 * Extracts the allowed values from the field, using the versioned allowed values if available.
 *
 * @param field - The field to extract the allowed values from.
 * @param fieldVersion - The version of the field to use to extract the allowed values from. Supersedes {@link field} if provided.
 * @returns The allowed-value strings from the field.
 */
export function extractAllowedValues(field: FieldFields, fieldVersion?: SpectrumFieldVersionFields) {
  const isAllowedValueVersioningEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.SPECTRUM_ALLOWED_VALUES_2024_12);
  const allowedValueStrings: string[] = (
    isAllowedValueVersioningEnabled && fieldVersion?.allowedValues != null
      ? fieldVersion.allowedValues
      : field.allowedValues
  )
    .map((allowedValue: { stringValue?: string | null; partyValue?: PartyBaseFields | null }) =>
      allowedValue.partyValue != null
        ? getPartyEmail(getBasePartyTypeUnion(allowedValue.partyValue))
        : allowedValue.stringValue,
    )
    .filter(isDefined);

  return allowedValueStrings;
}
