import type * as Types from "../../../generated/graphqlBaseTypes";

import { WorkflowTemplatePrimitiveFields } from "../../fragments/generated/WorkflowTemplatePrimitiveFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { WorkflowTemplatePermissionsFields } from "../../fragments/generated/WorkflowTemplatePermissionsFields.generated";
import { ConditionalExpressionGroupFields } from "../../fragments/generated/ConditionalExpressionGroupFields.generated";
import { FieldInstanceConditionalExpressionFields } from "../../fragments/generated/FieldInstanceConditionalExpressionFields.generated";
import { FieldInstanceFields } from "../../fragments/generated/FieldInstanceFields.generated";
import { FieldInstanceBaseFields } from "../../fragments/generated/FieldInstanceBaseFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FieldInstanceSpectrumMetadataFields } from "../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated";
import { FieldInstanceSpectrumFields } from "../../fragments/generated/FieldInstanceSpectrumFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldInstanceValueStringFields } from "../../fragments/generated/FieldInstanceValueStringFields.generated";
import { FieldInstanceValueStringBaseFields } from "../../fragments/generated/FieldInstanceValueStringBaseFields.generated";
import { AcyclicFieldInstanceValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated";
import { FieldInstanceMultiValueStringFields } from "../../fragments/generated/FieldInstanceMultiValueStringFields.generated";
import { FieldInstanceMultiValueStringBaseFields } from "../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated";
import { AcyclicFieldInstanceMultiValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated";
import { FieldInstanceValueIntFields } from "../../fragments/generated/FieldInstanceValueIntFields.generated";
import { FieldInstanceValueIntBaseFields } from "../../fragments/generated/FieldInstanceValueIntBaseFields.generated";
import { AcyclicFieldInstanceValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated";
import { FieldInstanceValueFloatFields } from "../../fragments/generated/FieldInstanceValueFloatFields.generated";
import { FieldInstanceValueFloatBaseFields } from "../../fragments/generated/FieldInstanceValueFloatBaseFields.generated";
import { AcyclicFieldInstanceValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated";
import { FieldInstanceValueBooleanFields } from "../../fragments/generated/FieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueBooleanBaseFields } from "../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated";
import { AcyclicFieldInstanceValueBooleanFields } from "../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueTimeFields } from "../../fragments/generated/FieldInstanceValueTimeFields.generated";
import { FieldInstanceValueTimeBaseFields } from "../../fragments/generated/FieldInstanceValueTimeBaseFields.generated";
import { AcyclicFieldInstanceValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated";
import { AcyclicFieldInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceFields.generated";
import { AcyclicFieldInstanceValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceValueDateFields.generated";
import { AcyclicFieldInstanceValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValueDocumentFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { DocumentBaseFields } from "../../fragments/generated/DocumentBaseFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { AcyclicFieldInstanceMultiValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated";
import { AcyclicFieldInstanceMultiValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated";
import { AcyclicFieldInstanceMultiValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated";
import { AcyclicFieldInstanceMultiValueDateFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDateFields.generated";
import { FieldInstanceValueDateFields } from "../../fragments/generated/FieldInstanceValueDateFields.generated";
import { FieldInstanceValueDateBaseFields } from "../../fragments/generated/FieldInstanceValueDateBaseFields.generated";
import { FieldInstanceValuePartyFields } from "../../fragments/generated/FieldInstanceValuePartyFields.generated";
import { FieldInstanceMultiValuePartyFields } from "../../fragments/generated/FieldInstanceMultiValuePartyFields.generated";
import { FieldInstanceMultiValueDocumentFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { FieldInstanceMultiValueIntFields } from "../../fragments/generated/FieldInstanceMultiValueIntFields.generated";
import { FieldInstanceMultiValueIntBaseFields } from "../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated";
import { FieldInstanceMultiValueFloatFields } from "../../fragments/generated/FieldInstanceMultiValueFloatFields.generated";
import { FieldInstanceMultiValueFloatBaseFields } from "../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated";
import { FieldInstanceMultiValueTimeFields } from "../../fragments/generated/FieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceMultiValueTimeBaseFields } from "../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated";
import { FieldInstanceMultiValueDateFields } from "../../fragments/generated/FieldInstanceMultiValueDateFields.generated";
import { FieldInstanceMultiValueDateBaseFields } from "../../fragments/generated/FieldInstanceMultiValueDateBaseFields.generated";
import { gql } from "@apollo/client";
import { FieldInstanceValueFields } from "../../fragments/generated/FieldInstanceValueFields.generated";
import { AcyclicFieldInstanceValueFields } from "../../fragments/generated/AcyclicFieldInstanceValueFields.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type WorkflowTemplatesListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  includeDeleted?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  types?: Types.InputMaybe<Array<Types.WorkflowTemplateType>>;
  permissions?: Types.InputMaybe<Array<Types.WorkflowTemplateAction>>;
  usingAutomationIds?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
  usingFieldIds?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
  search?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type WorkflowTemplatesListQuery = {
  paginatedWorkflowTemplates: { totalCount: number; workflowTemplates: Array<WorkflowTemplatePrimitiveFields> };
};

export const WorkflowTemplatesListQueryDocument = gql`
    query WorkflowTemplatesListQuery($id: ID, $includeDeleted: Boolean, $types: [WorkflowTemplateType!], $permissions: [WorkflowTemplateAction!], $usingAutomationIds: [ID!], $usingFieldIds: [ID!], $search: String, $offset: Int, $limit: Int) {
  paginatedWorkflowTemplates(
    id: $id
    includeDeleted: $includeDeleted
    types: $types
    permissions: $permissions
    usingAutomationIds: $usingAutomationIds
    usingFieldIds: $usingFieldIds
    search: $search
    offset: $offset
    limit: $limit
  ) {
    totalCount
    workflowTemplates {
      ...WorkflowTemplatePrimitiveFields
    }
  }
}
    ${WorkflowTemplatePrimitiveFields}
${UserBaseFields}
${WorkflowTemplatePermissionsFields}
${ConditionalExpressionGroupFields}
${FieldInstanceConditionalExpressionFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PartyBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValueDateFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${AcyclicFieldInstanceMultiValueDateFields}
${FieldInstanceValueDateFields}
${FieldInstanceValueDateBaseFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${FieldInstanceMultiValueDateFields}
${FieldInstanceMultiValueDateBaseFields}`;

/**
 * __useWorkflowTemplatesListQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeDeleted: // value for 'includeDeleted'
 *      types: // value for 'types'
 *      permissions: // value for 'permissions'
 *      usingAutomationIds: // value for 'usingAutomationIds'
 *      usingFieldIds: // value for 'usingFieldIds'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWorkflowTemplatesListQuery(
  baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(
    WorkflowTemplatesListQueryDocument,
    options,
  );
}
export function useWorkflowTemplatesListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(
    WorkflowTemplatesListQueryDocument,
    options,
  );
}
export function useWorkflowTemplatesListQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(
    WorkflowTemplatesListQueryDocument,
    options,
  );
}
export type WorkflowTemplatesListQueryHookResult = ReturnType<typeof useWorkflowTemplatesListQuery>;
export type WorkflowTemplatesListQueryLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesListQueryLazyQuery>;
export type WorkflowTemplatesListQuerySuspenseQueryHookResult = ReturnType<
  typeof useWorkflowTemplatesListQuerySuspenseQuery
>;
export type WorkflowTemplatesListQueryResult = Apollo.QueryResult<
  WorkflowTemplatesListQuery,
  WorkflowTemplatesListQueryVariables
>;
