import type * as Types from "../../../generated/graphqlBaseTypes";

import type { ActionItemTemplateFields } from "./ActionItemTemplateFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { FieldFields } from "./FieldFields.generated";
import type { SpectrumFieldVersionFields } from "./SpectrumFieldVersionFields.generated";
import type {
  FieldInstanceValueFieldsFieldInstanceMultiValueDate,
  FieldInstanceValueFieldsFieldInstanceMultiValueDocument,
  FieldInstanceValueFieldsFieldInstanceMultiValueFloat,
  FieldInstanceValueFieldsFieldInstanceMultiValueInt,
  FieldInstanceValueFieldsFieldInstanceMultiValueParty,
  FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  FieldInstanceValueFieldsFieldInstanceMultiValueString,
  FieldInstanceValueFieldsFieldInstanceMultiValueTime,
  FieldInstanceValueFieldsFieldInstanceValueBoolean,
  FieldInstanceValueFieldsFieldInstanceValueDate,
  FieldInstanceValueFieldsFieldInstanceValueFloat,
  FieldInstanceValueFieldsFieldInstanceValueInt,
  FieldInstanceValueFieldsFieldInstanceValueParty,
  FieldInstanceValueFieldsFieldInstanceValueString,
  FieldInstanceValueFieldsFieldInstanceValueTime,
} from "./FieldInstanceValueFields.generated";
import type { ActionItemTemplateActionItemWithLinkedWorkflowFields } from "./ActionItemTemplateActionItemWithLinkedWorkflowFields.generated";
import type { NameTemplateFields } from "./NameTemplateFields.generated";
import type { ApprovalActionItemTemplateWithActionItemFields } from "./ApprovalActionItemTemplateWithActionItemFields.generated";
import { gql } from "@apollo/client";
export type WorkflowStageTemplateActionItemTemplateFields = {
  createsWorkflowFromWorkflowTemplateId?: number | null;
  isSplitter?: boolean | null;
  hasDocuments: boolean;
  validationError?: Types.ActionItemTemplateValidationError | null;
  actionItems: Array<ActionItemTemplateActionItemWithLinkedWorkflowFields>;
  createsWorkflowFromWorkflowTemplateReference?: {
    id: number;
    name: string;
    type: Types.WorkflowTemplateType;
    predictedDurationDays: number;
    fieldInstances: Array<FieldInstanceFields>;
    nameTemplate?: NameTemplateFields | null;
    workflowOwnerParty?: PartyBaseFields | null;
  } | null;
  approvalActionItemTemplates: Array<ApprovalActionItemTemplateWithActionItemFields>;
  splitterFieldInstance?: {
    shouldSplitAssignees?: boolean | null;
    field: FieldFields;
    spectrumFieldVersion?: SpectrumFieldVersionFields | null;
    values: Array<
      | FieldInstanceValueFieldsFieldInstanceMultiValueDate
      | FieldInstanceValueFieldsFieldInstanceMultiValueDocument
      | FieldInstanceValueFieldsFieldInstanceMultiValueFloat
      | FieldInstanceValueFieldsFieldInstanceMultiValueInt
      | FieldInstanceValueFieldsFieldInstanceMultiValueParty
      | FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
      | FieldInstanceValueFieldsFieldInstanceMultiValueString
      | FieldInstanceValueFieldsFieldInstanceMultiValueTime
      | FieldInstanceValueFieldsFieldInstanceValueBoolean
      | FieldInstanceValueFieldsFieldInstanceValueDate
      | FieldInstanceValueFieldsFieldInstanceValueFloat
      | FieldInstanceValueFieldsFieldInstanceValueInt
      | FieldInstanceValueFieldsFieldInstanceValueParty
      | FieldInstanceValueFieldsFieldInstanceValueString
      | FieldInstanceValueFieldsFieldInstanceValueTime
    >;
  } | null;
} & ActionItemTemplateFields;

export const WorkflowStageTemplateActionItemTemplateFields = gql`
    fragment WorkflowStageTemplateActionItemTemplateFields on ActionItemTemplate {
  ...ActionItemTemplateFields
  actionItems {
    ...ActionItemTemplateActionItemWithLinkedWorkflowFields
  }
  createsWorkflowFromWorkflowTemplateId
  createsWorkflowFromWorkflowTemplateReference {
    id
    name
    fieldInstances {
      ...FieldInstanceFields
    }
    nameTemplate {
      ...NameTemplateFields
    }
    workflowOwnerParty {
      ...PartyBaseFields
    }
    type
    predictedDurationDays
  }
  approvalActionItemTemplates {
    ...ApprovalActionItemTemplateWithActionItemFields
  }
  isSplitter
  hasDocuments
  splitterFieldInstance {
    field {
      ...FieldFields
    }
    shouldSplitAssignees
    spectrumFieldVersion {
      ...SpectrumFieldVersionFields
    }
    values {
      ...FieldInstanceValueFields
    }
  }
  validationError
}
    `;
