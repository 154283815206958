import { t } from "@lingui/core/macro";
import type { WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useId } from "react";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { RegrelloIcon } from "../icons/RegrelloIcon";
import { RegrelloTooltippedInfoIcon } from "../tooltip/RegrelloTooltippedInfoIcon";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloFieldInputProps {
  /**
   * Whether the input is required in order for the form to submit. This is an ARIA attribute that
   * natively enforces that the field be valid before the form can be submitted.
   */
  "aria-required": boolean;

  /**
   * The unique identifier for this input on the page. Must be spread to the input so that
   * clicking the `<label>` will automatically focus the input.
   */
  id: string;

  /** A semantic intent for this input. Will be `danger` if this field has an error. */
  intent: Extract<RegrelloIntent, "neutral" | "warning" | "danger">;

  /**
   * The unique name for this input within the form. Must be spread to the input so that our
   * higher-order form-management libraries can properly track the input's value.
   */
  name: string;
}

export interface RegrelloFieldProps extends WithDataTestId {
  /** A render prop that should return the input. */
  children: (fieldInputProps: RegrelloFieldInputProps) => React.ReactNode;

  /**
   * An optional block of explanatory text to display after the {@link label}.
   *
   * Probably won't be necessary often since {@link helperText} can cover most use cases, but it's
   * occasionally useful to provide a description immediately after the label so that users can gain
   * more context about the field before their eyes track down to the input itself.
   */
  description?: string;

  /** Whether to show a tooltiped deleted badge besides the label. */
  deleted?: boolean;

  /**
   * An error message to show beneath the input, if the field is in an invalid state.
   *
   * Supersedes {@link helperText} if provided.
   */
  errorMessage?: string;

  /** Used to connect a label with a field */
  htmlFor?: string;

  /** A label to display above the input. Should be fairly concise. */
  label: string;

  /**
   * The unique name of this field within the form. This will not actually be displayed; it is just
   * required used for proper form accessibility.
   */
  name: string;

  /**
   * Helper text to display beneath the input. Use this to communicate expected value format (e.g.,
   * "(123) 456-7890") or just to provide additional information about how to fill out this field.
   *
   * Superseded by {@link errorMessage} if that prop is provided.
   */
  helperText?: React.ReactNode;

  /**
   * Whether this field is required in order for th form to be submitted. If `true`, will show a red
   * `*` indicator after the {@link label}.
   */
  required?: boolean;
}

/**
 * A field is a generic form element consisting of a label, an input, and optional helper or
 * validation text.
 */
export const RegrelloField = React.memo<RegrelloFieldProps>(function RegrelloFieldFn({
  children,
  dataTestId,
  deleted: isDeleted = false,
  htmlFor,
  label,
  description,
  errorMessage,
  helperText,
  name,
  required: isRequired = false,
}) {
  const id = useId();

  const hasError = errorMessage != null && errorMessage !== "";

  return (
    <div className="flex flex-col gap-1.5" data-testid={dataTestId}>
      <div className="flex items-center gap-1">
        <label htmlFor={htmlFor ?? id}>
          <RegrelloTypography variant="body">
            <span className="rg-textFormLabel">{label}</span>{" "}
            {isRequired && (
              <span className="text-danger-textMuted" title={t`Required`}>
                *
              </span>
            )}
          </RegrelloTypography>
        </label>
        <RegrelloTooltippedInfoIcon iconName="help-outline" tooltipText={description} variant="popover" />
        {isDeleted && (
          <RegrelloTypography className="flex items-center" muted={true}>
            {`(${t`Deleted`})`}
            <RegrelloTooltippedInfoIcon
              iconName="info-outline"
              tooltipText={t`This field has been deleted from your workspace. We recommend using a different field instead.`}
              variant="popover"
            />
          </RegrelloTypography>
        )}
      </div>
      {/* Don't memoize the children. Memoizing may lead to weird staleness issues. */}
      <div>
        {children({
          "aria-required": isRequired,
          id: htmlFor ?? id,
          intent: hasError ? "danger" : "neutral",
          name,
        })}
      </div>
      {hasError && (
        <div className="flex items-center text-danger-textMuted gap-1">
          <RegrelloIcon className="flex-none text-danger-icon" iconName="alert-outline" size="x-small" />
          <RegrelloTypography dataTestId={DataTestIds.FIELD_ERROR} variant="body-xs">
            {errorMessage}
          </RegrelloTypography>
        </div>
      )}
      {helperText != null && !hasError && (
        <RegrelloTypography muted={true} variant="body-xs">
          {helperText}
        </RegrelloTypography>
      )}
    </div>
  );
});
