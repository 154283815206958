import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type { ColumnDefContext } from "../../views/pages/spectrumForm/formBuilder/_internal/content/table/TableColumnHeader";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type { CustomFieldFrontendSelectableOption } from "../customFields/plugins/types/CustomFieldFrontendSelectableOption";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldSelectV2 } from "../formFields/RegrelloFormFieldSelectV2";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";

type SelectFieldPluginSingleValuedFrontendValue = CustomFieldFrontendSelectableOption | null;

export class SpectrumSelectFieldPluginDecorator extends SpectrumFieldPluginDecorator<
  SelectFieldPluginSingleValuedFrontendValue | CustomFieldFrontendSelectableOption[]
> {
  constructor(
    plugin: CustomFieldPlugin<SelectFieldPluginSingleValuedFrontendValue | CustomFieldFrontendSelectableOption[]>,
  ) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.select";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.SELECT;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.SELECT,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(_valueConstraints: SpectrumValueConstraintFields[]) {
    return EMPTY_ARRAY;
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_IS_ONE_OF]: {
        label: t`Is any of`,
        inputCount: 1,
        isMultiselect: true,
      },
      [FormConstraintConditionOperator.STRING_IS_NONE_OF]: {
        label: t`Is none of`,
        inputCount: 1,
        isMultiselect: true,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onChange" as const;
  }

  public renderPreviewFormField() {
    return (
      <RegrelloFormFieldSelectV2
        disabled={true}
        getOptionLabel={(option) => option}
        onChange={noop}
        options={[]}
        size="medium"
        value=""
      />
    );
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    return null;
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, string>, "onCellValueChanged">,
  ): ColDef {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    const context: ColumnDefContext = {
      type: "formField",
      formField,
    };
    const columnId = getColumnIdFromFormField(formField);
    return {
      ...colDefParams,
      field: columnId,
      colId: columnId,
      context,
      headerName: spectrumFieldVersion.name,
      // eslint-disable-next-line lingui/no-unlocalized-strings
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: spectrumFieldVersion.allowedValues.map((allowedValue) => ({
          id: allowedValue.id,
          value: allowedValue.stringValue,
        })),
      },
      valueFormatter: (params) => {
        if (typeof params.value === "string") {
          return params.value;
        }
        return params.value.value;
      },
      valueParser: (params) => {
        if (typeof params.newValue === "string") {
          const allowedValue = spectrumFieldVersion.allowedValues.find(
            (allowedValue) => allowedValue.stringValue === params.newValue,
          );
          if (allowedValue == null) {
            return null;
          }

          return {
            id: allowedValue.id,
            value: allowedValue.stringValue,
          };
        }
        return params.newValue;
      },
      valueGetter: (params) => {
        const cellValue = params.data[columnId];
        if (cellValue == null) {
          return null;
        }
        return cellValue.value;
      },
      cellClass: "regrello-center-aligned-cell",
    };
  }
}
