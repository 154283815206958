import { t } from "@lingui/core/macro";
import { intersperse } from "@regrello/core-utils";
import React from "react";

export interface RegrelloListPhraseProps<T> {
  items: T[];

  renderItem: (item: T) => React.ReactNode;

  /**
   * Whether to put "and" before the final item, instead of just a comma.
   *
   * @default false
   */
  showAsConjunctionList?: boolean;
}

/**
 * Displays a generic list of things as a textual phrase (handling comma-separation, "and", etc.).
 */
const RegrelloListPhraseInternal = function RegrelloListPhraseFn<T>({
  items,
  renderItem,
  showAsConjunctionList = false,
}: RegrelloListPhraseProps<T>) {
  const itemElements = items.map(renderItem);

  if (showAsConjunctionList && itemElements.length === 2) {
    return (
      <>
        {intersperse(itemElements, {
          type: "callback",
          // (clewis): Should come up with a better i18n strategy here.
          callback: (_leftElement, leftIndex) => <span key={`separator-${leftIndex}`}> {t`and`} </span>,
        })}
      </>
    );
  }

  return (
    <>
      {intersperse(itemElements, {
        type: "callback",
        // (clewis): Display name is not necessary here.
        callback: (_leftElement, leftIndex) => {
          if (showAsConjunctionList && itemElements.length >= 3 && leftIndex === itemElements.length - 2) {
            return <span key={`separator-${leftIndex}`}>, {t`and`} </span>;
          }
          return <span key={`separator-${leftIndex}`}>, </span>;
        },
      })}
    </>
  );
};

export const RegrelloListPhrase = React.memo(RegrelloListPhraseInternal) as typeof RegrelloListPhraseInternal;
