import type { ColDef } from "@ag-grid-community/core";
import { t } from "@lingui/core/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import {
  type FieldInstanceFields,
  FormConstraintConditionOperator,
  type FormFieldFields,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { getColumnIdFromFormField, type RowDatum } from "../../views/pages/table/tableV2Utils";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldCheckbox } from "../formFields/RegrelloFormFieldCheckbox";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";

type CheckboxFieldPluginFrontendValue = boolean;

export class SpectrumCheckboxFieldPluginDecorator extends SpectrumFieldPluginDecorator<CheckboxFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<CheckboxFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.checkbox";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.BOOLEAN;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.BOOLEAN);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.BOOLEAN,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(_valueConstraints: SpectrumValueConstraintFields[]) {
    return EMPTY_ARRAY;
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is checked`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is not checked`,
        inputCount: 0,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onChange" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloFormFieldCheckbox disabled={true} onChange={noop} value={false} />;
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    return null;
  }

  public getColumnDef(
    fieldInstance: FieldInstanceFields,
    formField: FormFieldFields,
    colDefParams: Pick<ColDef<RowDatum, CheckboxFieldPluginFrontendValue>, "onCellValueChanged">,
  ) {
    const spectrumFieldVersion = fieldInstance.spectrumFieldVersion;
    if (spectrumFieldVersion == null) {
      throw new Error("SpectrumFieldVersion is null on fieldInstance, this should never happen");
    }
    return {
      colId: getColumnIdFromFormField(formField),
      headerName: spectrumFieldVersion.name,
      ...colDefParams,
    };
  }
}
