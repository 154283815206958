import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { AcyclicFieldInstanceMultiValuePartyFields } from "./AcyclicFieldInstanceMultiValuePartyFields.generated";
import type { AcyclicFieldInstanceFields } from "./AcyclicFieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValuePartyFields = {
  __typename: "FieldInstanceMultiValueParty";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  partyMultiValue: Array<PartyBaseFields>;
  sourceFieldInstanceMultiValueParty?: AcyclicFieldInstanceMultiValuePartyFields | null;
  sinksFieldInstanceMultiValueParty: Array<AcyclicFieldInstanceMultiValuePartyFields>;
  sourceFieldInstanceMultiValuePartyV2?: AcyclicFieldInstanceFields | null;
  tabularMultiValueParty: Array<{ rowNumber: number; value: Array<number> }>;
};

export const FieldInstanceMultiValuePartyFields = gql`
    fragment FieldInstanceMultiValuePartyFields on FieldInstanceMultiValueParty {
  __typename
  id
  inputType
  partyMultiValue {
    ...PartyBaseFields
  }
  sourceFieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
  sinksFieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
  sourceFieldInstanceMultiValuePartyV2 {
    ...AcyclicFieldInstanceFields
  }
  tabularMultiValueParty {
    rowNumber
    value
  }
}
    `;
