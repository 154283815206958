import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueDateFields = {
  __typename: "FieldInstanceMultiValueDate";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  dateMultiValue: Array<string>;
};

export const AcyclicFieldInstanceMultiValueDateFields = gql`
    fragment AcyclicFieldInstanceMultiValueDateFields on FieldInstanceMultiValueDate {
  __typename
  id
  inputType
  dateMultiValue
}
    `;
