import type { FieldInstanceMultiValueDateFields } from "./FieldInstanceMultiValueDateFields.generated";
import type { AcyclicFieldInstanceMultiValueDateFields } from "./AcyclicFieldInstanceMultiValueDateFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueDateWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueDate?: AcyclicFieldInstanceMultiValueDateFields | null;
  crossWorkflowSinksFieldInstanceMultiValueDate?: Array<AcyclicFieldInstanceMultiValueDateFields> | null;
} & FieldInstanceMultiValueDateFields;

export const FieldInstanceMultiValueDateWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueDateWithCrossWorkflowFields on FieldInstanceMultiValueDate {
  ...FieldInstanceMultiValueDateFields
  crossWorkflowSourceFieldInstanceMultiValueDate {
    ...AcyclicFieldInstanceMultiValueDateFields
  }
  crossWorkflowSinksFieldInstanceMultiValueDate {
    ...AcyclicFieldInstanceMultiValueDateFields
  }
}
    `;
