import { t } from "@lingui/core/macro";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { clsx, KeyNames, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { type KeyboardEventHandler, useCallback, useRef, useState } from "react";

import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloButton, type RegrelloButtonProps } from "../button/RegrelloButton";
import { RegrelloButtonGroup } from "../button/RegrelloButtonGroup";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloMenuV2, RegrelloMenuV2Item } from "../menuV2/RegrelloMenuV2";
import { RegrelloTypography } from "../typography/RegrelloTypography";

const RegrelloDialogV2Root = DialogPrimitive.Root;

const RegrelloDialogV2Trigger = DialogPrimitive.Trigger;

const RegrelloDialogV2Portal = ({ ...props }: DialogPrimitive.DialogPortalProps & WithClassName) => (
  <DialogPrimitive.Portal {...props} />
);
RegrelloDialogV2Portal.displayName = DialogPrimitive.Portal.displayName;

const RegrelloDialogV2Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={clsx(
      [
        "fixed",
        "inset-0",
        "z-dialog",

        "bg-backdrop",
        "backdrop-blur-sm",

        "data-[state=open]:animate-in",
        "data-[state=open]:fade-in-0",

        "data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0",
      ],
      className,
    )}
    {...props}
  />
));
RegrelloDialogV2Overlay.displayName = DialogPrimitive.Overlay.displayName;

interface RegrelloDialogV2ContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
    WithClassName {
  /**
   * Whether the dialog is currently maximized and should cover the full height and width of the
   * parent container.
   *
   * @default false
   */
  maximized?: boolean;

  /**
   * The size of the dialog, primarily controlling its maximum width.
   *
   * @default "small"
   */
  size?: Extract<RegrelloSize, "small" | "medium" | "large">;
}

const RegrelloDialogV2Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  RegrelloDialogV2ContentProps
>(({ className, children, maximized: isMaximized = false, size = "medium", ...props }, ref) => {
  const overlayRef = useRef<HTMLDivElement | null | undefined>();

  return (
    <RegrelloDialogV2Portal>
      <RegrelloDialogV2Overlay
        ref={(elementRef) => {
          overlayRef.current = elementRef;
        }}
      />
      <DialogPrimitive.Content
        ref={ref}
        aria-describedby={undefined}
        className={clsx(
          [
            "fixed",
            "left-1/2",
            "top-1/2",
            "z-dialog",

            "w-full",
            "max-w-170",

            "translate-x--1/2",
            "translate-y--1/2",

            "rounded",

            "bg-background",

            "shadow-lg",

            // Make sure this matches DIALOG_CLOSE_DURATION.
            "duration-100",

            "data-[state=open]:animate-in",
            "data-[state=open]:fade-in-0",
            "data-[state=open]:zoom-in-95",
            "data-[state=open]:slide-in-from-left-1/2",
            "data-[state=open]:slide-in-from-top-[48%]",

            "data-[state=closed]:animate-out",
            "data-[state=closed]:fade-out-0",
            "data-[state=closed]:zoom-out-95",
            "data-[state=closed]:slide-out-to-left-1/2",
            "data-[state=closed]:slide-out-to-top-[48%]",
          ],
          {
            "max-w-100": size === "small",
            "max-w-170": size === "medium",
            "max-w-240": size === "large",
            // (dosipiuk): MUI specific margin for full-screen dialog. This must come after the max-w
            // rules above.
            "max-w-none w-[calc(100%-40px)] h-[calc(100%-40px)]": isMaximized,
            "md:w-full": !isMaximized,
          },
          className,
        )}
        data-maximized={isMaximized}
        onPointerDownOutside={(event) => {
          // (dosipiuk): We do not want to close the dialog if user clicks outside but not on the overlay.
          if (event.currentTarget !== overlayRef.current) {
            event.preventDefault();
          }
        }}
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </RegrelloDialogV2Portal>
  );
});
RegrelloDialogV2Content.displayName = DialogPrimitive.Content.displayName;

interface RegrelloDialogV2HeaderProps extends WithClassName {
  /** A button that appears after the title in the dialog header. */
  action?: {
    label: string;
    props: Omit<RegrelloButtonProps, "intent" | "variant">;
  };

  /**
   * Instructions to show in a muted container beneath the header bar. Will be fixed and not scroll
   * with the dialog content.
   */
  instructions?: React.ReactNode;

  /**
   * Whether the dialog is currently maximized. Ignored unless `onMaximizeChange` is provided.
   *
   * @default false
   */
  maximized?: boolean;

  /**
   * Callback invoked when the user clicks the maximize/minimize button. Providing this prop will
   * show a maximize/minimize button that expands the dialog to the full height and width of the
   * parent container on click.
   */
  onMaximizeChange?: (nextIsMaximized: boolean) => void;

  showCloseButton?: boolean;
  title: string;
  titleIcon?: RegrelloIconName | React.ReactElement;
}

const RegrelloDialogV2Header = ({
  action,
  className,
  instructions,
  maximized: isMaximized = false,
  onMaximizeChange,
  showCloseButton = false,
  title,
  titleIcon,
}: RegrelloDialogV2HeaderProps) => (
  <div className="flex flex-col">
    <div className={clsx(["flex", "items-center", "pl-4", "pr-2", "py-2", "border-b", "border-b-border"], className)}>
      {/* Title icon */}
      {typeof titleIcon === "string" ? (
        <RegrelloIcon className="mr-1.5" iconName={titleIcon} />
      ) : titleIcon != null ? (
        <div className="mr-1.5">{titleIcon}</div>
      ) : undefined}

      {/* Title */}
      <DialogPrimitive.Title className="text-base font-semibold flex-none h-8 flex items-center">
        {title}
      </DialogPrimitive.Title>

      {/* Action */}
      {action != null && (
        <div className="flex-none ml-3">
          <RegrelloButton intent="primary" size="medium" variant="ghost" {...action?.props}>
            {action?.label}
          </RegrelloButton>
        </div>
      )}

      <div className="flex-auto" />

      <div className="flex gap-1">
        {/* Maximize button */}
        {onMaximizeChange != null && (
          <RegrelloButton
            aria-label="fullscreen"
            className="flex-none"
            dataTestId={DataTestIds.DIALOG_MAXIMIZE_BUTTON}
            iconOnly={true}
            onClick={() => {
              onMaximizeChange(!isMaximized);
            }}
            size="medium"
            startIcon={isMaximized ? "close-fullscreen" : "open-fullscreen"}
            variant="ghost"
          />
        )}

        {/* Close button */}
        {showCloseButton && (
          <DialogPrimitive.Close asChild={true} className="flex-none">
            <RegrelloButton
              dataTestId={DataTestIds.DIALOG_CLOSE_BUTTON}
              iconOnly={true}
              size="medium"
              startIcon="close"
              variant="ghost"
            />
          </DialogPrimitive.Close>
        )}
      </div>
    </div>

    {instructions != null && (
      <div
        className={clsx([
          "bg-backgroundSoft",
          "py-3",
          "pb-3.25", // (clewis): Fixes a blurry bottom border.
          "px-4",
          "text-textMuted",
          "border-b",
          "border-b-border",
        ])}
      >
        <RegrelloTypography component="div" variant="body">
          {instructions}
        </RegrelloTypography>
      </div>
    )}
  </div>
);
RegrelloDialogV2Header.displayName = "RegrelloDialogV2Header";

export interface RegrelloDialogAction extends WithDataTestId {
  buttonProps?: RegrelloButtonProps;
  text: React.ReactNode;

  /**
   * Menu items to display in a dropdown menu that can be opened via a caret button next to the
   * main button. You can use this to display alternate submit actions, for example.
   *
   * __Warning:__ This is ignored unless the provided array is non-empty.
   */
  dropdownMenuItems?: Array<React.ComponentProps<typeof RegrelloMenuV2Item>>;
}

interface RegrelloDialogV2Props
  extends WithClassName,
    WithDataTestId,
    Pick<DialogPrimitive.DialogProps, "modal" | "onOpenChange" | "open">,
    Pick<RegrelloDialogV2HeaderProps, "instructions">,
    Pick<RegrelloDialogV2ContentProps, "size"> {
  /** Buttons to show at the bottom of the dialog. */
  actions?: RegrelloDialogAction[];

  /** The content to display in the dialog. */
  children: React.ReactNode;

  /** Class name applied to the content container, useful for changing the max-width */
  contentClassName?: string;

  /** Class name to override content wrapper classes */
  contentWrapperClassName?: string;

  /** Disables default dialog content padding */
  disableDefaultPadding?: boolean;

  /** Content to show in the end area of the footer before the action button(s). */
  footerEndContent?: React.ReactNode;

  /** Content to show in the start area of the footer. */
  footerStartContent?: React.ReactNode;

  /** A button that appears after the title in the dialog header. */
  headerAction?: {
    label: string;
    props: Omit<RegrelloButtonProps, "intent" | "variant">;
  };

  /**
   * Whether or not the dialog is currently maximized. Should be used alongside
   * {@link onMaximizeChange}.
   *
   * @default false
   */
  maximized?: boolean;

  /**
   * Whether the background should have a muted gray color. Use this in select circumstances when
   * the dialog needs to show multiple visually "chunked" cards.
   *
   * @default false
   */
  mutedBackground?: boolean;

  /** Invoked on dialog close */
  onClose?: () => void;

  /** Invoked onKeyDown when content  */
  onKeyDown?: KeyboardEventHandler;

  /**
   * Callback invoked when the user clicks the maximize/minimize button.
   * Should be used alongsize {@link maximized}.
   */
  onMaximizeChange?: (nextIsMaximized: boolean) => void;

  /**
   * Whether to show a close button ('x') in the dialog header.
   *
   * @default false
   */
  showCloseButton?: boolean;

  /**
   * Whether to show a open-in-fullscreen (maximize) button in the dialog header.
   *
   * @default false
   */
  showMaximizeButton?: boolean;

  /** Ignored unless defined and non-empty. If omitted, the header will not appear. */
  title?: string;

  /** An element that appears immediately beneath the title of the dialog. */
  titleEndContent?: React.ReactNode;

  /** An icon to display before the `title`. */
  titleIcon?: RegrelloIconName | React.ReactElement;

  /**
   * An optional trigger element that opens the dialog on click. You can omit this trigger and use
   * `open` and `onOpenChange` if you wish to trigger the dialog via another element elsewhere in
   * the React tree.
   */
  triggerElement?: React.ReactNode;
}

const RegrelloDialogV2 = React.memo<RegrelloDialogV2Props>(
  ({
    actions,
    children,
    contentClassName,
    contentWrapperClassName,
    dataTestId,
    disableDefaultPadding = false,
    footerEndContent,
    footerStartContent,
    headerAction,
    instructions,
    maximized: propsIsMaximized,
    mutedBackground: isBackgroundMuted = false,
    onClose,
    onKeyDown,
    onMaximizeChange,
    showCloseButton,
    showMaximizeButton,
    size,
    title,
    titleIcon,
    titleEndContent,
    triggerElement,
    ...rootProps
  }) => {
    const [isMaximizedInternal, setIsMaximizedInternal] = useState<boolean>(propsIsMaximized ?? false);

    // Use derived state: controlled prop wins, fallback to internal state
    const isMaximized = propsIsMaximized ?? isMaximizedInternal;

    const handleMaximizeChange = useCallback(
      (nextIsMaximized: boolean) => {
        if (onMaximizeChange != null) {
          onMaximizeChange(nextIsMaximized); // Controlled usage
        } else {
          setIsMaximizedInternal(nextIsMaximized); // Uncontrolled usage
        }
      },
      [onMaximizeChange],
    );

    const onOpenChange = useCallback(
      (isNextOpen: boolean) => {
        if (!isNextOpen) {
          onClose?.();
        }
      },
      [onClose],
    );

    const onKeyDownInternal: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        if (onKeyDown) {
          onKeyDown(event);
        } else if (event.key === KeyNames.ESCAPE) {
          onClose?.();
        }
        event.stopPropagation();
      },
      [onClose, onKeyDown],
    );

    return (
      <RegrelloDialogV2Root onOpenChange={onOpenChange} {...rootProps}>
        {triggerElement != null && <RegrelloDialogV2Trigger>{triggerElement}</RegrelloDialogV2Trigger>}
        <RegrelloDialogV2Content
          className={clsx("max-h-[calc(100%-40px)]", "min-h-0", "flex", "flex-col", contentClassName)}
          data-testid={dataTestId}
          maximized={isMaximized}
          onKeyDown={onKeyDownInternal}
          size={size}
        >
          {title != null ? (
            <RegrelloDialogV2Header
              action={headerAction}
              className="flex-none"
              instructions={instructions}
              maximized={isMaximized}
              onMaximizeChange={showMaximizeButton ? handleMaximizeChange : undefined}
              showCloseButton={showCloseButton}
              title={title}
              titleIcon={titleIcon}
            />
          ) : (
            <DialogPrimitive.Title className="sr-only">{t`Dialog`}</DialogPrimitive.Title>
          )}
          {titleEndContent != null && (
            <div className="px-4 py-3 border-b bg-backgroundSoft text-textMuted">{titleEndContent}</div>
          )}

          <DialogPrimitive.Description asChild={true}>
            <RegrelloTypography
              className={clsx(
                "flex-auto overflow-y-auto",
                {
                  "p-4": !disableDefaultPadding,
                  "bg-backgroundSoft rounded-b": isBackgroundMuted,
                  "bg-background": !isBackgroundMuted,
                },
                contentWrapperClassName,
              )}
              component="div"
              variant="body"
            >
              {children}
            </RegrelloTypography>
          </DialogPrimitive.Description>

          {footerStartContent != null || footerEndContent != null || actions != null ? (
            <div
              className={clsx([
                "flex",
                "flex-none",
                "gap-2",
                "items-center",
                "justify-end",
                "p-2",
                "pl-4",
                "border-t",
                "border-t-border",
              ])}
            >
              {footerStartContent != null && <div className="flex-auto">{footerStartContent}</div>}
              {footerEndContent != null && <div className="flex-none">{footerEndContent}</div>}
              <div className="flex justify-end gap-4 flex-none">
                {actions?.map((action, i) => {
                  const wrapperKey = `action-${i}`;

                  const { dropdownMenuItems } = action;

                  if (dropdownMenuItems != null && dropdownMenuItems.length > 0) {
                    // Next to the main button, show a button group with a caret button that opens a
                    // dropdown menu.
                    return (
                      <div key={wrapperKey} className="flex justify-end">
                        <RegrelloButtonGroup variant={action.buttonProps?.variant ?? "solid"}>
                          <RegrelloButton
                            {...action.buttonProps}
                            className={action.buttonProps?.className}
                            size="medium"
                          >
                            {action.text}
                          </RegrelloButton>
                          <RegrelloMenuV2
                            align="end"
                            content={dropdownMenuItems.map((menuItemProps, menuItemIndex) => (
                              // (clewis): This index-as-key is trustworthy enough if the caller
                              // doesn't add or remove menu items (which would seem uncommon).
                              <RegrelloMenuV2Item key={menuItemIndex} {...menuItemProps} />
                            ))}
                          >
                            <RegrelloButton
                              {...action.buttonProps}
                              dataTestId={DataTestIds.DIALOG_ACTION_DROPDOWN_TRIGGER}
                              iconOnly={true}
                              loading={false} // Don't show a loading indicator on the caret button; it's too busy.
                              size="medium"
                              startIcon="chevron-down"
                            />
                          </RegrelloMenuV2>
                        </RegrelloButtonGroup>
                      </div>
                    );
                  }

                  return (
                    <RegrelloButton
                      key={`action-${i}`}
                      {...action.buttonProps}
                      className={action.buttonProps?.className}
                    >
                      {action.text}
                    </RegrelloButton>
                  );
                })}
              </div>
            </div>
          ) : null}
        </RegrelloDialogV2Content>
      </RegrelloDialogV2Root>
    );
  },
);
RegrelloDialogV2.displayName = "RegrelloDialogV2";

export { RegrelloDialogV2, RegrelloDialogV2Content, RegrelloDialogV2Root };

export type { RegrelloDialogV2ContentProps, RegrelloDialogV2Props };
