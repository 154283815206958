import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceValueDateFields = {
  __typename: "FieldInstanceValueDate";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  dateValue?: string | null;
};

export const AcyclicFieldInstanceValueDateFields = gql`
    fragment AcyclicFieldInstanceValueDateFields on FieldInstanceValueDate {
  __typename
  id
  inputType
  dateValue
}
    `;
