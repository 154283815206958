import type { FieldInstanceValueStringFields } from "./FieldInstanceValueStringFields.generated";
import type { FieldInstanceMultiValueStringFields } from "./FieldInstanceMultiValueStringFields.generated";
import type { FieldInstanceValueIntFields } from "./FieldInstanceValueIntFields.generated";
import type { FieldInstanceValueFloatFields } from "./FieldInstanceValueFloatFields.generated";
import type { FieldInstanceValueBooleanFields } from "./FieldInstanceValueBooleanFields.generated";
import type { FieldInstanceValueTimeFields } from "./FieldInstanceValueTimeFields.generated";
import type { FieldInstanceValueDateFields } from "./FieldInstanceValueDateFields.generated";
import type { FieldInstanceValuePartyFields } from "./FieldInstanceValuePartyFields.generated";
import type { FieldInstanceMultiValuePartyFields } from "./FieldInstanceMultiValuePartyFields.generated";
import type { FieldInstanceMultiValueDocumentFields } from "./FieldInstanceMultiValueDocumentFields.generated";
import type { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "./FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import type { FieldInstanceMultiValueIntFields } from "./FieldInstanceMultiValueIntFields.generated";
import type { FieldInstanceMultiValueFloatFields } from "./FieldInstanceMultiValueFloatFields.generated";
import type { FieldInstanceMultiValueTimeFields } from "./FieldInstanceMultiValueTimeFields.generated";
import type { FieldInstanceMultiValueDateFields } from "./FieldInstanceMultiValueDateFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueFieldsFieldInstanceMultiValueDate = { id: number } & FieldInstanceMultiValueDateFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueDocument = {
  id: number;
} & FieldInstanceMultiValueDocumentFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueFloat = { id: number } & FieldInstanceMultiValueFloatFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueInt = { id: number } & FieldInstanceMultiValueIntFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueParty = { id: number } & FieldInstanceMultiValuePartyFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance = {
  id: number;
} & FieldInstanceMultiValueRegrelloObjectInstanceFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueString = {
  id: number;
} & FieldInstanceMultiValueStringFields;

export type FieldInstanceValueFieldsFieldInstanceMultiValueTime = { id: number } & FieldInstanceMultiValueTimeFields;

export type FieldInstanceValueFieldsFieldInstanceValueBoolean = { id: number } & FieldInstanceValueBooleanFields;

export type FieldInstanceValueFieldsFieldInstanceValueDate = { id: number } & FieldInstanceValueDateFields;

export type FieldInstanceValueFieldsFieldInstanceValueFloat = { id: number } & FieldInstanceValueFloatFields;

export type FieldInstanceValueFieldsFieldInstanceValueInt = { id: number } & FieldInstanceValueIntFields;

export type FieldInstanceValueFieldsFieldInstanceValueParty = { id: number } & FieldInstanceValuePartyFields;

export type FieldInstanceValueFieldsFieldInstanceValueString = { id: number } & FieldInstanceValueStringFields;

export type FieldInstanceValueFieldsFieldInstanceValueTime = { id: number } & FieldInstanceValueTimeFields;

export type FieldInstanceValueFields =
  | FieldInstanceValueFieldsFieldInstanceMultiValueDate
  | FieldInstanceValueFieldsFieldInstanceMultiValueDocument
  | FieldInstanceValueFieldsFieldInstanceMultiValueFloat
  | FieldInstanceValueFieldsFieldInstanceMultiValueInt
  | FieldInstanceValueFieldsFieldInstanceMultiValueParty
  | FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
  | FieldInstanceValueFieldsFieldInstanceMultiValueString
  | FieldInstanceValueFieldsFieldInstanceMultiValueTime
  | FieldInstanceValueFieldsFieldInstanceValueBoolean
  | FieldInstanceValueFieldsFieldInstanceValueDate
  | FieldInstanceValueFieldsFieldInstanceValueFloat
  | FieldInstanceValueFieldsFieldInstanceValueInt
  | FieldInstanceValueFieldsFieldInstanceValueParty
  | FieldInstanceValueFieldsFieldInstanceValueString
  | FieldInstanceValueFieldsFieldInstanceValueTime;

export const FieldInstanceValueFields = gql`
    fragment FieldInstanceValueFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeFields
  }
  ... on FieldInstanceValueDate {
    ...FieldInstanceValueDateFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeFields
  }
  ... on FieldInstanceMultiValueDate {
    ...FieldInstanceMultiValueDateFields
  }
}
    `;
