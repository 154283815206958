// eslint-disable-next-line @nx/enforce-module-boundaries
import i18nConfig from "../../../../i18n.config.json";

/**
 * Represents a mapping of supported locales based on the i18n configuration.
 *
 * This type is derived from the `supportedLocales` property of the `i18nConfig` object.
 * It is used to define the structure of the language map used in the application.
 */
type LanguageMap = typeof i18nConfig.supportedLocales;

/**
 * Represents a mapping of dictionaries to each language based on the i18n configuration.
 */
type DictionaryMap = typeof i18nConfig.dictionary;

/**
 * Represents the supported language codes for translations.
 *
 * This type is derived from the keys of the `LanguageMap` type.
 * It is used to define the list of supported language codes in the application.
 */
export type LanguageCode = keyof LanguageMap;

/**
 * Represents the supported language codes for translations.
 */
export const SUPPORTED_LOCALES = Object.keys(i18nConfig.supportedLocales) as LanguageCode[];

/**
 * A mapping of language codes to their corresponding language names.
 */
export const LANG_MAP: LanguageMap = i18nConfig.supportedLocales;

/**
 * Represents a mapping of dictionaries to each language based on the i18n configuration.
 */
export const DICT_MAP: DictionaryMap = i18nConfig.dictionary;

/**
 * A mapping of language codes to their corresponding language names that is translated and would be used AS-IS.
 */
export const TARGET_LANG_MAP: LanguageMap = i18nConfig.supportedLocalesTranslated;

export const I18N_CONFIG = i18nConfig;

/**
 * A marker used to indicate that a translation has been marked to verify it's correctness.
 * Uses a zero-width no-break space character to avoid affecting the layout
 * - also a good choice as it's removed by string.trim().
 */
export const UPDATE_MARKER = "\uFEFF";
