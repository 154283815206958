import { t } from "@lingui/core/macro";
import { clsx, EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective, type FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloTypography } from "@regrello/ui-core";
import { isValid } from "date-fns";
import { useCallback } from "react";
import type { UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import type { ConditionOperatorConfig } from "../../../../molecules/customFields/plugins/types/ConditionOperator";
import {
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import type { SpectrumFieldPluginDecorator } from "../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import { ConnectiveComponent } from "./RegrelloConnectiveForm";
import {
  type RegrelloScheduleTimeFormSectionBase,
  type ScheduleTimeKeys,
  ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import { LEFTMOST_LABEL_CLASSES } from "./scheduleTimeConstants";
import type { Condition } from "./utils";

/**
 * RegrelloSingleConditionFormProps is largely a subset of the props from RegrelloConfigureConditionsRecursiveProps,
 * which are more thoroughly documented.
 * Essentially, RegrelloSingleConditionForm renders a single condition.
 */
type RegrelloSingleConditionFormProps = {
  /**
   * absoluteIndex is the position of the condition relative to other conditions and condition groups
   * at this branch.
   */
  absoluteIndex: number;

  allowedFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>>;

  /**
   * condition is the condition to be rendered.
   */
  condition: Condition;

  /**
   * connective is the connective for the branch.
   */
  // TODO(workflows): these connective parameters could
  // be deleted if the connective is pulled up a level.
  // This would reduce the repetitiveness between
  // the group and condition component.
  connective: ConditionConnective;
  connectivePath: `${ScheduleTimeKeys.CONDITIONS}.connective`;

  dependingOnStageIds: number[];
  filterCondition?: (option: FieldInstanceFields) => boolean;

  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;
  isDisabled: boolean;
  /**
   * prefix must point to a condition row within some condition group.
   * For example, `${ScheduleTimeKeys.CONDITIONS}.conditions[1]`.
   */
  prefix: string;

  /**
   * removeCondition causes the condition to be removed from the condition group.
   */
  removeCondition: () => void;
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;
  // biome-ignore lint/suspicious/noExplicitAny: any subset of the condition fields may be (un)set.
  updateCondition: (value: any) => void;
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
};

/**
 * RegrelloSingleConditionForm renders a configurable row of a condition group.
 * It maintains its state in the provided form so callers still have access to it.
 */
export function RegrelloSingleConditionForm({
  allowedFieldPlugins,
  dependingOnStageIds,
  form,
  absoluteIndex,
  isDisabled,
  condition,
  connectivePath,
  connective,
  startConditionOption,
  workflowContext,
  filterCondition,
  updateCondition,
  removeCondition,
  prefix,
}: RegrelloSingleConditionFormProps) {
  const fieldInstancePath = `${prefix}.fieldInstance` as `conditions.conditions.${number}.fieldInstance`;
  const fieldInstancePropertyIdPath =
    `${prefix}.fieldInstancePropertyId` as `conditions.conditions.${number}.fieldInstancePropertyId`;
  const operatorPath = `${prefix}.operator` as `conditions.conditions.${number}.operator`;
  const value1Path = `${prefix}.value1` as `${ScheduleTimeKeys.CONDITIONS}.conditions.${number}.value1`;
  const value2Path = `${prefix}.value2` as `${ScheduleTimeKeys.CONDITIONS}.conditions.${number}.value2`;

  const fieldInstance = condition.fieldInstance;
  const fieldInstanceProperties =
    fieldInstance &&
    CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getFieldProperties?.(fieldInstance.field);

  const handleStartConditionFieldInstanceChange = useCallback(
    (newFieldInstance: FieldInstanceFields | null) => {
      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`.
      updateCondition({ fieldInstance: newFieldInstance, operator: null, value1: null, value2: null });
    },
    [updateCondition],
  );
  const handleStartConditionFieldPropertyChange = useCallback(
    (newFieldInstance: FieldInstanceFields | null, newPropertyId: number) => {
      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`.
      updateCondition({ fieldInstance: newFieldInstance, fieldInstancePropertyId: newPropertyId });
    },
    [updateCondition],
  );

  const handleStartConditionOperatorChange = useCallback(
    (
      currentFieldInstance: FieldInstanceFields | null,
      newOperator: ConditionOperatorConfig | null,
      oldValue1: unknown,
    ) => {
      const newValue1 =
        currentFieldInstance != null && newOperator != null
          ? CustomFieldPluginRegistrar.getPluginForFieldInstance(currentFieldInstance).getEmptyValueForFrontend({
              operator: newOperator.operator,
            })
          : null;

      // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
      // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
      // the fieldArray fields and set it as `never`, making it unusable.
      updateCondition({
        fieldInstance: currentFieldInstance,
        fieldInstancePropertyId: condition.fieldInstancePropertyId,
        operator: newOperator,
        value1: areOperatorValuesCompatible(oldValue1, newValue1) ? oldValue1 : newValue1,
        value2: null,
      });
    },
    [updateCondition, condition],
  );

  return (
    <div className="flex grow gap-1 min-w-0">
      {/* Label */}
      <div className={clsx(LEFTMOST_LABEL_CLASSES)}>
        {absoluteIndex === 0 && <div className="pl-3 pt-2.25">{t`If`}</div>}
        {absoluteIndex === 1 && (
          <ConnectiveComponent className="pl-0" connectivePath={connectivePath} form={form} isDisabled={isDisabled} />
        )}
        {absoluteIndex > 1 && (
          <div className="pl-3 pt-2.5">{connective === ConditionConnective.AND ? t`and` : t`or`}</div>
        )}
      </div>

      {/* Field-instance selector */}
      <RegrelloControlledFormFieldCustomFieldInstanceSelect
        allowedFieldPlugins={allowedFieldPlugins}
        className="w-40 flex-none"
        controllerProps={{
          control: form.control,
          name: fieldInstancePath,
          rules: ValidationRules.REQUIRED,
        }}
        dataTestId={DataTestIds.START_DEPENDENCY_START_CONDITION_FIELD_SELECT}
        disabled={
          isDisabled ||
          ((dependingOnStageIds === undefined || dependingOnStageIds.length === 0) &&
            startConditionOption === ScheduleTimeValues.START_CONDITION_STAGE)
        }
        filterCondition={filterCondition}
        isAllowEmptyOptionalFieldInstances={true}
        isInactiveFieldInstancesHidden={true}
        isRetainOptionalFieldInstanceInputType={true}
        isSourceHelperTextVisible={true}
        onValueChange={(_, newValue) => {
          handleStartConditionFieldInstanceChange(newValue);
        }}
        optionsConfig={{
          type: "asyncLoaded",
          dependingOnActionItemTemplateId: undefined,
          workflowContext:
            workflowContext.type === "workflow"
              ? {
                  type: "workflow",
                  workflowId: workflowContext.workflowId,
                  workflowStageId: undefined,
                  dependingOnWorkflowStageIds: dependingOnStageIds ?? EMPTY_ARRAY,
                }
              : {
                  type: "workflowTemplate",
                  workflowTemplateId: workflowContext.workflowTemplateId,
                  workflowStageTemplateId: undefined,
                  workflowTemplateIsCreateViaEmailEnabled: workflowContext.workflowTemplateIsCreateViaEmailEnabled,
                  dependingOnWorkflowStageTemplateIds: dependingOnStageIds ?? EMPTY_ARRAY,
                },
        }}
        placeholder={t`Select field`}
      />

      {/* Smart-object-property selector */}
      {fieldInstance != null && fieldInstanceProperties != null && fieldInstanceProperties.length > 0 ? (
        <RegrelloControlledFormFieldSelect
          className="w-40"
          controllerProps={{
            control: form.control,
            name: fieldInstancePropertyIdPath,
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.START_DEPENDENCY_FIELD_PROPERTY}
          getOptionLabel={(option) =>
            // biome-ignore lint/suspicious/noExplicitAny: fieldInstancesProperties is any
            fieldInstanceProperties.find((prop: any) => prop.id === option)?.name || String(option)
          }
          isFilterable={false}
          onValueChange={(_, newValue) => {
            if (newValue) {
              handleStartConditionFieldPropertyChange(fieldInstance, newValue as number);
            }
          }}
          options={
            // biome-ignore lint/suspicious/noExplicitAny: fieldInstanceProperties is an any.
            fieldInstanceProperties != null ? fieldInstanceProperties.map((prop: any) => prop.id) : EMPTY_ARRAY
          }
          placeholder={t`Select property`}
        />
      ) : null}

      {/* Operator selector */}
      <RegrelloControlledFormFieldSelect
        className="w-40 flex-none"
        controllerProps={{
          control: form.control,
          name: operatorPath,
          rules: ValidationRules.REQUIRED,
        }}
        dataTestId={DataTestIds.START_DEPENDENCY_OPERATOR_SELECT}
        disabled={
          isDisabled ||
          fieldInstance == null ||
          (fieldInstanceProperties != null && condition.fieldInstancePropertyId == null)
        }
        getOptionLabel={(option) => option.displayValue}
        isFilterable={false}
        onValueChange={(_, newValue) => {
          handleStartConditionOperatorChange(fieldInstance, newValue, condition.value1);
        }}
        options={
          fieldInstance != null
            ? CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getConditionOperators()
            : EMPTY_ARRAY
        }
        placeholder={t`Select condition`}
      />

      {/* Input 1 */}
      {condition.operator != null &&
        fieldInstance != null &&
        condition.operator.numFields > 0 &&
        CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(fieldInstance.field, {
          className: condition.operator.numFields > 1 ? "flex-auto" : "flex-auto overflow-hidden",
          controllerProps: {
            control: form.control,
            name: value1Path,
            rules: {
              ...ValidationRules.REQUIRED,
              ...(condition.operator.numFields > 1
                ? isValid(condition.value2)
                  ? ValidationRules.BEFORE_DATE(condition.value2 as Date)
                  : isValidNonEmptyNumber(condition.value2)
                    ? ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number(condition.value2))
                    : {}
                : {}),
            },
          },
          disabled: isDisabled,
          operator: condition.operator.operator,
          onValueChange: (_, newValue) => {
            updateCondition({ ...condition, value1: newValue });
          },
          fieldInstance,
        })}

      {/* Input 2 (if there are 2 inputs - e.g., for "Is between") */}
      {condition.operator != null && fieldInstance != null && condition.operator.numFields > 1 && (
        <>
          <RegrelloTypography className="h-9 flex items-center">{t`and`}</RegrelloTypography>
          {CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).renderFormField(fieldInstance.field, {
            className: "flex-auto",
            controllerProps: {
              control: form.control,
              name: value2Path,
              rules: {
                ...ValidationRules.REQUIRED,
                ...(isValid(condition.value1)
                  ? ValidationRules.AFTER_DATE(condition.value1 as Date)
                  : isValidNonEmptyNumber(condition.value1)
                    ? ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number(condition.value1))
                    : {}),
              },
            },
            disabled: isDisabled,
            operator: condition.operator.operator,
            onValueChange: (_, newValue) => {
              updateCondition({ ...condition, value2: newValue });
            },
            fieldInstance,
          })}
        </>
      )}

      {/* Remove button */}
      <RegrelloButton
        aria-label={t`Remove condition`}
        className="ml-auto flex-none"
        disabled={isDisabled}
        iconOnly={true}
        onClick={removeCondition}
        startIcon="delete"
        variant="ghost"
      />
    </div>
  );
}

// TODO Conditional Branching: Potentially tie this into the custom field plugin framework. This is
// a quick patch, but would require adding a new function to the framework or refactoring
// `getEmptyValueForFrontend`.
function areOperatorValuesCompatible(oldValue: unknown, newValue: unknown): boolean {
  return (
    (typeof oldValue === typeof newValue || newValue == null) &&
    oldValue != null &&
    Array.isArray(oldValue) === Array.isArray(newValue)
  );
}

function isValidNonEmptyNumber(value: unknown): boolean {
  return value != null && value !== "" && !Number.isNaN(Number(value));
}
