/*
  Based upon code from date-fns, but heavily modified to use Intl and our own translation
  system.
  date-fns license is MIT.
*/
import { plural, t } from "@lingui/core/macro";
import { compareAsc, differenceInMonths, differenceInSeconds } from "date-fns";

import { parseDate } from "./dateUtils";

export type DistanceOptions = {
  includeSeconds?: boolean;
};
export type DateType = Date | string;

const minutesInDay = 1440;
const minutesInAlmostTwoDays = 2520;
const minutesInMonth = 43200;

export function parseAndSortDate(date1: DateType, date2: DateType, comparisonAsc: -1 | 0 | 1) {
  const parsedDate1 = parseDate(date1);
  const parsedDate2 = parseDate(date2);

  if (comparisonAsc < 0) {
    return { laterDate: parsedDate2, earlierDate: parsedDate1 };
  }

  return { laterDate: parsedDate1, earlierDate: parsedDate2 };
}

export function getTimezoneOffsetInMilliseconds(date: Date) {
  // We don't care about historic time zones. For that case getTimezoneOffset() is a reasonable choice.
  return date.getTimezoneOffset() * 60 * 1000;
}

/**
 * Formats the distance between two dates in a human-readable format.
 * Returns approximate values, e.g., "less than 5 seconds ago".
 *
 * @param date - The target date to compare.
 * @param baseDate - The base date to compare against.
 * @param options - Optional settings for formatting.
 * @param options.includeSeconds - Whether to include seconds in the output.
 * @returns A string representing the distance between the two dates.
 * @throws {RangeError} If the date or baseDate is invalid.
 */
export const formatDistance = (date: DateType, baseDate: DateType, options?: DistanceOptions) => {
  const comparison = compareAsc(date, baseDate) as -1 | 0 | 1;
  if (Number.isNaN(comparison)) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    throw new RangeError("Invalid time value");
  }

  const { laterDate: currentDate, earlierDate: currentBaseDate } = parseAndSortDate(date, baseDate, comparison);

  const offsetInSeconds =
    (getTimezoneOffsetInMilliseconds(currentBaseDate) - getTimezoneOffsetInMilliseconds(currentDate)) / 1000;
  const diffInSeconds = differenceInSeconds(currentDate, currentBaseDate);
  const diffInMinutes = Math.round((diffInSeconds - offsetInSeconds) / 60);

  const addFutureSuffix = comparison > 0;

  // 0 up to 2 mins
  if (diffInMinutes < 2) {
    if (options?.includeSeconds) {
      if (diffInSeconds < 5) {
        const seconds = 5;

        if (addFutureSuffix) {
          return t`in less than ${seconds} seconds`;
        }
        return t`less than ${seconds} seconds ago`;
      }
      if (diffInSeconds < 10) {
        const seconds = 10;
        if (addFutureSuffix) {
          return t`in less than ${seconds} seconds`;
        }
        return t`less than ${seconds} seconds ago`;
      }
      if (diffInSeconds < 20) {
        const seconds = 20;
        if (addFutureSuffix) {
          return t`in less than ${seconds} seconds`;
        }
        return t`less than ${seconds} seconds ago`;
      }
      if (diffInSeconds < 40) {
        if (addFutureSuffix) {
          return t`in half a minute`;
        }
        return t`half a minute ago`;
      }
      if (diffInSeconds < 60) {
        if (addFutureSuffix) {
          return t`in less than a minute`;
        }
        return t`less than a minute ago`;
      }

      if (addFutureSuffix) {
        return plural(1, {
          one: "in # minute",
          other: "in # minutes",
        });
      }
      return plural(1, {
        one: "# minute ago",
        other: "# minutes ago",
      });
    }

    if (diffInMinutes === 0) {
      if (addFutureSuffix) {
        return t`in ess than a minute`;
      }
      return t`less than a minute ago`;
    }

    if (addFutureSuffix) {
      return plural(1, {
        one: "in # minute",
        other: "in # minutes",
      });
    }
    return plural(1, {
      one: "# minute ago",
      other: "# minutes ago",
    });
  }

  // 2 mins up to 0.75 hrs
  if (diffInMinutes < 45) {
    if (addFutureSuffix) {
      return plural(diffInMinutes, {
        one: "in # minute",
        other: "in # minutes",
      });
    }
    return plural(diffInMinutes, {
      one: "# minute ago",
      other: "# minutes ago",
    });
  }
  // 0.75 hrs up to 1.5 hrs
  if (diffInMinutes < 90) {
    if (addFutureSuffix) {
      return plural(1, {
        one: "in about # hour",
        other: "in about # hours",
      });
    }
    return plural(1, {
      one: "# hour ago",
      other: "# hours ago",
    });
  }
  // 1.5 hrs up to 24 hrs
  if (diffInMinutes < minutesInDay) {
    const hours = Math.round(diffInMinutes / 60);
    if (addFutureSuffix) {
      return plural(hours, {
        one: "in about # hour",
        other: "in about # hours",
      });
    }
    return plural(hours, {
      one: "about # hour ago",
      other: "about # hours ago",
    });
  }

  // 1 day up to 1.75 days
  if (diffInMinutes < minutesInAlmostTwoDays) {
    if (addFutureSuffix) {
      return plural(1, {
        one: "in # day",
        other: "in # days",
      });
    }
    return plural(1, {
      one: "# day ago",
      other: "# days ago",
    });
  }

  // 1.75 days up to 30 days
  if (diffInMinutes < minutesInMonth) {
    const days = Math.round(diffInMinutes / minutesInDay);
    if (addFutureSuffix) {
      return plural(days, {
        one: "in # day",
        other: "in # days",
      });
    }
    return plural(days, {
      one: "# day ago",
      other: "# days ago",
    });
  }

  // 1 month up to 2 months
  if (diffInMinutes < minutesInMonth * 2) {
    const month = Math.round(diffInMinutes / minutesInMonth);
    if (addFutureSuffix) {
      return plural(month, {
        one: "in about # month",
        other: "in about # months",
      });
    }
    return plural(month, {
      one: "about # month ago",
      other: "about # months ago",
    });
  }

  const months = differenceInMonths(currentDate, currentBaseDate);

  // 2 months up to 12 months
  if (months < 12) {
    const nearestMonth = Math.round(diffInMinutes / minutesInMonth);
    if (addFutureSuffix) {
      return plural(nearestMonth, {
        one: "in about # month",
        other: "in about # months",
      });
    }
    return plural(nearestMonth, {
      one: "about # month ago",
      other: "about # months ago",
    });
  }

  // 1 year up to max Date
  const monthsSinceStartOfYear = months % 12;
  const years = Math.trunc(months / 12);

  // N years up to 1 years 3 months
  if (monthsSinceStartOfYear < 3) {
    if (addFutureSuffix) {
      return plural(years, {
        one: "in about # year",
        other: "in about # years",
      });
    }
    return plural(years, {
      one: "about # year ago",
      other: "about # years ago",
    });
  }

  // N years 3 months up to N years 9 months
  if (monthsSinceStartOfYear < 9) {
    if (addFutureSuffix) {
      return plural(years, {
        one: "in over # year",
        other: "in over # years",
      });
    }
    return plural(years, {
      one: "over # year ago",
      other: "over # years ago",
    });
  }

  // N years 9 months up to N year 12 months
  if (addFutureSuffix) {
    return plural(years + 1, {
      one: "in almost # year",
      other: "in almost # years",
    });
  }
  return plural(years + 1, {
    one: "almost # year ago",
    other: "almost # years ago",
  });
};
