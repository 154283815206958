import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceValueTimeBaseFields = {
  __typename: "FieldInstanceValueTime";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  timeValue?: string | null;
  tabularValueTime: Array<{ rowNumber: number; value?: string | null }>;
};

export const FieldInstanceValueTimeBaseFields = gql`
    fragment FieldInstanceValueTimeBaseFields on FieldInstanceValueTime {
  __typename
  id
  inputType
  timeValue
  tabularValueTime {
    rowNumber
    value
  }
}
    `;
