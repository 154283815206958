import { t } from "@lingui/core/macro";
import React, { useCallback } from "react";

import { RegrelloIcon } from "../icons/RegrelloIcon";
import { RegrelloNonIdealState, type RegrelloNonIdealStateProps } from "./RegrelloNonIdealState";

export type RegrelloNonIdealStateWithRefreshProps = Pick<RegrelloNonIdealStateProps, "title" | "description">;

/**
 * A `NonIdealState` that covers the entire viewport. The `action` prop is not supported because a
 * 'Refresh' button will always be shown (to give the user a consistent way out of the error state).
 *
 * See `NonIdealState.tsx` for more information about non-ideal states in general.
 */
export const RegrelloNonIdealStateWithRefresh = React.memo(function RegrelloNonIdealStateWithRefreshFn(
  props: RegrelloNonIdealStateWithRefreshProps,
) {
  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <RegrelloNonIdealState
      actionProps={{
        text: t`Refresh`,
        onClick: refreshPage,
      }}
      description={props.description}
      size="large"
      title={props.title}
      visual={<RegrelloIcon iconName="alert" size="x-large" />}
    />
  );
});
