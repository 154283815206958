import type { FieldInstanceValueStringBaseFields } from "./FieldInstanceValueStringBaseFields.generated";
import type { FieldInstanceMultiValueStringBaseFields } from "./FieldInstanceMultiValueStringBaseFields.generated";
import type { FieldInstanceValueIntBaseFields } from "./FieldInstanceValueIntBaseFields.generated";
import type { FieldInstanceValueFloatBaseFields } from "./FieldInstanceValueFloatBaseFields.generated";
import type { FieldInstanceValueBooleanBaseFields } from "./FieldInstanceValueBooleanBaseFields.generated";
import type { FieldInstanceValueTimeBaseFields } from "./FieldInstanceValueTimeBaseFields.generated";
import type { FieldInstanceValueDateBaseFields } from "./FieldInstanceValueDateBaseFields.generated";
import type { FieldInstanceValuePartyBaseFields } from "./FieldInstanceValuePartyBaseFields.generated";
import type { FieldInstanceMultiValuePartyBaseFields } from "./FieldInstanceMultiValuePartyBaseFields.generated";
import type { FieldInstanceMultiValueDocumentBaseFields } from "./FieldInstanceMultiValueDocumentBaseFields.generated";
import type { FieldInstanceMultiValueIntBaseFields } from "./FieldInstanceMultiValueIntBaseFields.generated";
import type { FieldInstanceMultiValueFloatBaseFields } from "./FieldInstanceMultiValueFloatBaseFields.generated";
import type { FieldInstanceMultiValueTimeBaseFields } from "./FieldInstanceMultiValueTimeBaseFields.generated";
import type { FieldInstanceMultiValueDateBaseFields } from "./FieldInstanceMultiValueDateBaseFields.generated";
import type { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "./FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueDate = {
  id: number;
} & FieldInstanceMultiValueDateBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument = {
  id: number;
} & FieldInstanceMultiValueDocumentBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat = {
  id: number;
} & FieldInstanceMultiValueFloatBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt = {
  id: number;
} & FieldInstanceMultiValueIntBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty = {
  id: number;
} & FieldInstanceMultiValuePartyBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance = {
  id: number;
} & FieldInstanceMultiValueRegrelloObjectInstanceFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueString = {
  id: number;
} & FieldInstanceMultiValueStringBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime = {
  id: number;
} & FieldInstanceMultiValueTimeBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueBoolean = {
  id: number;
} & FieldInstanceValueBooleanBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueDate = { id: number } & FieldInstanceValueDateBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueFloat = { id: number } & FieldInstanceValueFloatBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueInt = { id: number } & FieldInstanceValueIntBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueParty = { id: number } & FieldInstanceValuePartyBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueString = { id: number } & FieldInstanceValueStringBaseFields;

export type FieldInstanceValueBaseFieldsFieldInstanceValueTime = { id: number } & FieldInstanceValueTimeBaseFields;

export type FieldInstanceValueBaseFields =
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueDate
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueString
  | FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime
  | FieldInstanceValueBaseFieldsFieldInstanceValueBoolean
  | FieldInstanceValueBaseFieldsFieldInstanceValueDate
  | FieldInstanceValueBaseFieldsFieldInstanceValueFloat
  | FieldInstanceValueBaseFieldsFieldInstanceValueInt
  | FieldInstanceValueBaseFieldsFieldInstanceValueParty
  | FieldInstanceValueBaseFieldsFieldInstanceValueString
  | FieldInstanceValueBaseFieldsFieldInstanceValueTime;

export const FieldInstanceValueBaseFields = gql`
    fragment FieldInstanceValueBaseFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringBaseFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringBaseFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntBaseFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatBaseFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanBaseFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeBaseFields
  }
  ... on FieldInstanceValueDate {
    ...FieldInstanceValueDateBaseFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyBaseFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyBaseFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentBaseFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntBaseFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatBaseFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeBaseFields
  }
  ... on FieldInstanceMultiValueDate {
    ...FieldInstanceMultiValueDateBaseFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  }
}
    `;
