import type * as Types from "../../../generated/graphqlBaseTypes";

import type { RegrelloObjectInstanceFields } from "./RegrelloObjectInstanceFields.generated";
import type { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "./AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueRegrelloObjectInstanceFields = {
  __typename: "FieldInstanceMultiValueRegrelloObjectInstance";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  regrelloObjectInstanceMultiValue: Array<RegrelloObjectInstanceFields>;
  sourceFieldInstanceMultiValueRegrelloObjectInstance?: AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields | null;
  sinksFieldInstanceMultiValueRegrelloObjectInstance: Array<AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields>;
  tabularMultiValueRegrelloObjectInstance: Array<{ rowNumber: number; value: Array<number> }>;
};

export const FieldInstanceMultiValueRegrelloObjectInstanceFields = gql`
    fragment FieldInstanceMultiValueRegrelloObjectInstanceFields on FieldInstanceMultiValueRegrelloObjectInstance {
  __typename
  id
  inputType
  regrelloObjectInstanceMultiValue {
    ...RegrelloObjectInstanceFields
  }
  sourceFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  sinksFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  tabularMultiValueRegrelloObjectInstance {
    rowNumber
    value
  }
}
    `;
