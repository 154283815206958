import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { WorkflowQueryActionItemFields } from "./WorkflowQueryActionItemFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { FieldFields } from "./FieldFields.generated";
import type { SpectrumFieldVersionFields } from "./SpectrumFieldVersionFields.generated";
import type {
  FieldInstanceValueFieldsFieldInstanceMultiValueDate,
  FieldInstanceValueFieldsFieldInstanceMultiValueDocument,
  FieldInstanceValueFieldsFieldInstanceMultiValueFloat,
  FieldInstanceValueFieldsFieldInstanceMultiValueInt,
  FieldInstanceValueFieldsFieldInstanceMultiValueParty,
  FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  FieldInstanceValueFieldsFieldInstanceMultiValueString,
  FieldInstanceValueFieldsFieldInstanceMultiValueTime,
  FieldInstanceValueFieldsFieldInstanceValueBoolean,
  FieldInstanceValueFieldsFieldInstanceValueDate,
  FieldInstanceValueFieldsFieldInstanceValueFloat,
  FieldInstanceValueFieldsFieldInstanceValueInt,
  FieldInstanceValueFieldsFieldInstanceValueParty,
  FieldInstanceValueFieldsFieldInstanceValueString,
  FieldInstanceValueFieldsFieldInstanceValueTime,
} from "./FieldInstanceValueFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import type { IntegrationAutomationInstanceFields } from "./IntegrationAutomationInstanceFields.generated";
import type { WorkflowQueryChildActionItemTemplateFields } from "./WorkflowQueryChildActionItemTemplateFields.generated";
import type { ActionItemTemplatePermissionsFields } from "./ActionItemTemplatePermissionsFields.generated";
import type { ActionItemTemplateStartAfterActionItemTemplateChain } from "./ActionItemTemplateStartAfterActionItemTemplateChain.generated";
import type { RejectActionFields } from "./RejectActionFields.generated";
import type { NameTemplateFields } from "./NameTemplateFields.generated";
import { gql } from "@apollo/client";
export type WorkflowQueryActionItemTemplateFields = {
  __typename: "ActionItemTemplate";
  uuid: string;
  id: number;
  name: string;
  description?: string | null;
  dueOn?: string | null;
  displayOrder: number;
  type: Types.ActionItemType;
  frozeForReason?: Types.ActionItemTemplateFrozeForReason | null;
  isSplitter?: boolean | null;
  originalAssigneePartyIDs?: Array<number> | null;
  requiresAck: boolean;
  createdAt: string;
  updatedAt: string;
  dueOnIntervalSeconds?: number | null;
  dueOnIntervalType?: Types.ActionItemTemplateDueOnIntervalType | null;
  dueOnIntervalSecondsAfterTrigger?: Types.ActionItemDueOnIntervalTrigger | null;
  startAt?: string | null;
  integrationType?: Types.IntegrationType | null;
  isLocked: boolean;
  createsWorkflowFromWorkflowTemplateId?: number | null;
  plannedDueOn?: string | null;
  plannedStartAt?: string | null;
  conditionalDueOn?: string | null;
  conditionalStartAt?: string | null;
  adjustedStartAt?: string | null;
  hasDocuments: boolean;
  validationError?: Types.ActionItemTemplateValidationError | null;
  assignedBy: PartyBaseFields;
  actionItems: Array<
    {
      auditHistory: Array<{
        __typename: "ActionItemAuditHistory";
        id: number;
        createdAt: string;
        event: Types.AuditHistoryEvent;
        reason?: Types.TaskStatusChangeReason | null;
        createdBy: PartyBaseFields;
        fields: Array<{
          __typename: "ActionItemAuditHistoryDelta";
          id: number;
          fieldName: string;
          valueBefore?: string | null;
          valueAfter?: string | null;
          collectionAdd?: string | null;
          collectionRemove?: string | null;
        }>;
      }>;
    } & WorkflowQueryActionItemFields
  >;
  splitterFieldInstance?: {
    shouldSplitAssignees?: boolean | null;
    field: FieldFields;
    spectrumFieldVersion?: SpectrumFieldVersionFields | null;
    values: Array<
      | FieldInstanceValueFieldsFieldInstanceMultiValueDate
      | FieldInstanceValueFieldsFieldInstanceMultiValueDocument
      | FieldInstanceValueFieldsFieldInstanceMultiValueFloat
      | FieldInstanceValueFieldsFieldInstanceMultiValueInt
      | FieldInstanceValueFieldsFieldInstanceMultiValueParty
      | FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
      | FieldInstanceValueFieldsFieldInstanceMultiValueString
      | FieldInstanceValueFieldsFieldInstanceMultiValueTime
      | FieldInstanceValueFieldsFieldInstanceValueBoolean
      | FieldInstanceValueFieldsFieldInstanceValueDate
      | FieldInstanceValueFieldsFieldInstanceValueFloat
      | FieldInstanceValueFieldsFieldInstanceValueInt
      | FieldInstanceValueFieldsFieldInstanceValueParty
      | FieldInstanceValueFieldsFieldInstanceValueString
      | FieldInstanceValueFieldsFieldInstanceValueTime
    >;
  } | null;
  splitChildTemplates: Array<WorkflowQueryChildActionItemTemplateFields>;
  approvalActionItemTemplates: Array<WorkflowQueryChildActionItemTemplateFields>;
  assignees: Array<PartyBaseFields>;
  aiAgentBackupAssignees: Array<PartyBaseFields>;
  reviewers: Array<PartyBaseFields>;
  cc: Array<PartyBaseFields>;
  documents: Array<DocumentFields>;
  tags: Array<TagFields>;
  createdBy: UserBaseFields;
  automationRequests: Array<AutomationRequestFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  fieldInstancesControllingCc?: Array<FieldInstanceFields> | null;
  fieldInstanceControllingDueOn?: FieldInstanceFields | null;
  integrationAutomationInstance?: IntegrationAutomationInstanceFields | null;
  rejectAction?: RejectActionFields | null;
  permissions: ActionItemTemplatePermissionsFields;
  createsWorkflowFromWorkflowTemplateReference?: {
    id: number;
    name: string;
    type: Types.WorkflowTemplateType;
    predictedDurationDays: number;
    fieldInstances: Array<FieldInstanceFields>;
    nameTemplate?: NameTemplateFields | null;
    workflowOwnerParty?: PartyBaseFields | null;
  } | null;
} & ActionItemTemplateStartAfterActionItemTemplateChain;

export const WorkflowQueryActionItemTemplateFields = gql`
    fragment WorkflowQueryActionItemTemplateFields on ActionItemTemplate {
  __typename
  uuid
  id
  name
  description
  dueOn
  displayOrder
  type
  frozeForReason
  assignedBy {
    ...PartyBaseFields
  }
  actionItems {
    ...WorkflowQueryActionItemFields
    auditHistory {
      __typename
      id
      createdAt
      createdBy {
        ...PartyBaseFields
      }
      event
      fields {
        id
        __typename
        fieldName
        valueBefore
        valueAfter
        collectionAdd
        collectionRemove
      }
      reason
    }
  }
  isSplitter
  splitterFieldInstance {
    shouldSplitAssignees
    field {
      ...FieldFields
    }
    spectrumFieldVersion {
      ...SpectrumFieldVersionFields
    }
    values {
      ...FieldInstanceValueFields
    }
  }
  splitChildTemplates {
    ...WorkflowQueryChildActionItemTemplateFields
  }
  approvalActionItemTemplates {
    ...WorkflowQueryChildActionItemTemplateFields
  }
  assignees {
    ...PartyBaseFields
  }
  aiAgentBackupAssignees {
    ...PartyBaseFields
  }
  originalAssigneePartyIDs
  reviewers {
    ...PartyBaseFields
  }
  cc {
    ...PartyBaseFields
  }
  documents {
    ...DocumentFields
  }
  tags {
    ...TagFields
  }
  requiresAck
  createdAt
  createdBy {
    ...UserBaseFields
  }
  updatedAt
  dueOnIntervalSeconds
  dueOnIntervalType
  dueOnIntervalSecondsAfterTrigger
  startAt
  ...ActionItemTemplateStartAfterActionItemTemplateChain
  automationRequests {
    ...AutomationRequestFields
  }
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  fieldInstancesControllingCc {
    ...FieldInstanceFields
  }
  fieldInstanceControllingDueOn {
    ...FieldInstanceFields
  }
  integrationType
  integrationAutomationInstance {
    ...IntegrationAutomationInstanceFields
  }
  rejectAction {
    ...RejectActionFields
  }
  permissions {
    ...ActionItemTemplatePermissionsFields
  }
  isLocked
  createsWorkflowFromWorkflowTemplateId
  createsWorkflowFromWorkflowTemplateReference {
    id
    name
    fieldInstances {
      ...FieldInstanceFields
    }
    nameTemplate {
      ...NameTemplateFields
    }
    workflowOwnerParty {
      ...PartyBaseFields
    }
    type
    predictedDurationDays
  }
  plannedDueOn
  plannedStartAt
  conditionalDueOn
  conditionalStartAt
  adjustedStartAt
  hasDocuments
  validationError
}
    `;
