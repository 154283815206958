import { t } from "@lingui/core/macro";
import { assertNever } from "@regrello/core-utils";
import {
  type FormFieldFields,
  type FormSectionFields,
  FormSectionKind,
  type SpectrumFieldVersionFields,
} from "@regrello/graphql-api";

import type { RegrelloToastIntent } from "../ui/molecules/toast/RegrelloToast";

// (clewis): Some of these are standard (strongly typeable) reasons from react-hook-form, while
// others are custom. They exist in this enum to avoid spelling mistakes.
export enum RegrelloFormValidationReason {
  PATTERN = "pattern",
  UNIQUE = "unique",
}

/**
 * Returns the status needed in the function `getBulkResponseToastDetails`, based off the number of
 * successful and failed results in a bulk operation.
 */
export function getBulkResponseStatus(numSuccess: number, numFailure: number) {
  /* eslint-disable lingui/no-unlocalized-strings */
  return numSuccess > 0 && numFailure > 0
    ? "successAndFailure"
    : numSuccess > 0
      ? "success"
      : numFailure > 0
        ? "failure"
        : "noChanges";
  /* eslint-enable lingui/no-unlocalized-strings */
}

/**
 * Constructs the info for `showToast` when displaying the results of bulk operations.
 */
export function getBulkResponseToastDetails(
  bulkResponseStatus: "successAndFailure" | "success" | "failure" | "noChanges",
  successMessage: string,
  failMessage: string,
  successAndFailureMessage: string,
): {
  content: string;
  intent: RegrelloToastIntent;
} {
  switch (bulkResponseStatus) {
    case "successAndFailure":
      return {
        content: successAndFailureMessage,
        intent: "info",
      };
    case "success":
      return {
        content: successMessage,
        intent: "success",
      };
    case "failure":
      return {
        content: failMessage,
        intent: "danger",
      };
    case "noChanges":
      return {
        content: t`No changes were made.`,
        intent: "warning",
      };
    default:
      assertNever(bulkResponseStatus);
  }
}

/**
 * A flattened, array of fields from a form instance, containing either:
 * 1. Spectrum field versions, or
 * 2. Table representations (which contain an ordered list of column fields)
 *
 * This can be used to display the fields in a form instance in a flat list, with tables represented
 * as a composite field.
 */
export type ExtractedFormInstanceFields = Array<
  | { type: "fieldVersion"; id: string; fieldVersion: SpectrumFieldVersionFields }
  | { type: "table"; id: string; name: string; columns: SpectrumFieldVersionFields[] }
>;

/**
 * Takes a form version and returns a flattened array containing either:
 * 1. Spectrum field versions, or
 * 2. Table representations (which contain an ordered list of column fields)
 * The array maintains the original ordering of fields from the form.
 *
 * @param formVersion - The form version to flatten
 * @returns The flattened array of spectrum field versions and table representations, maintaining
 * original field order
 */
export function extractFieldsFromFormVersion(formVersion: {
  defaultFormSection: FormSectionFields;
}): ExtractedFormInstanceFields {
  const sectionQueue: FormSectionFields[] = [formVersion.defaultFormSection];
  const result: Array<ReturnType<typeof extractFieldsFromFormVersion>[0] & { displayOrder: number }> = [];

  const toFieldVersions = (formFields: FormFieldFields[]) => {
    return formFields.map((field) => field.spectrumFieldVersion);
  };

  const compareDisplayOrder = (a: { displayOrder: number }, b: { displayOrder: number }) => {
    return a.displayOrder - b.displayOrder;
  };

  while (sectionQueue.length > 0) {
    const section = sectionQueue.shift();
    if (section == null) {
      throw new Error("Unexpected empty section queue");
    }
    if (section.kind === FormSectionKind.TABLE) {
      result.push({
        type: "table",
        id: `table-${section.id}`,
        name: section.title,
        columns: toFieldVersions(section.formFields.sort(compareDisplayOrder)),
        displayOrder: section.displayOrder,
      });
    } else {
      result.push(
        ...section.formFields.map((formField) => ({
          type: "fieldVersion" as const,
          id: `fieldVersion-${formField.spectrumFieldVersion.id.toString()}`,
          fieldVersion: formField.spectrumFieldVersion,
          displayOrder: formField.displayOrder,
        })),
      );
    }
    if ("formSections" in section) {
      sectionQueue.push(...(section.formSections as FormSectionFields[]));
    }
  }

  // Sort tables and fields in ascending displayOrder.
  return result.sort(compareDisplayOrder);
}
