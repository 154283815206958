import { t } from "@lingui/core/macro";
import { Plural } from "@lingui/react/macro";
import { RegrelloChip, RegrelloIcon, RegrelloPopover, RegrelloTypography } from "@regrello/ui-core";

interface RegrelloCustomFieldMultiValuePopoverProps {
  instancesWithSource: Array<{
    content: React.ReactNode;
    workflowName?: string;
    stageName?: string;
    taskName?: string;
  }>;
}

export function RegrelloCustomFieldMultiValuePopover({
  instancesWithSource,
}: RegrelloCustomFieldMultiValuePopoverProps) {
  const popoverContent = (
    // (clewis): table-fixed is necessary to clip the width of standalone chips that may appear in
    // the content cell.
    <table className="w-full table-fixed">
      {instancesWithSource.map((instanceWithSource, index) => (
        <tr key={index} className="border-b last:border-none group">
          <td className="p-2 bg-backgroundSoft border-r last:border-none group-first:rounded-tl group-last:rounded-bl">
            <RegrelloTypography variant="body-xs">
              {instanceWithSource.workflowName
                ? t`Workflow`
                : `${instanceWithSource.stageName} / ${instanceWithSource.taskName}`}
            </RegrelloTypography>
          </td>
          <td className="p-2 text-center">{instanceWithSource.content ?? "-"}</td>
        </tr>
      ))}
    </table>
  );

  return (
    <RegrelloPopover content={popoverContent} contentProps={{ className: "p-0 max-w-100 w-auto" }}>
      {/* (clewis): Use div instead of span because there may be nested divs in the chip. */}
      <div className="inline">
        <RegrelloChip
          // (clewis): Show a custom tooltip instead of the default overflow tooltip.
          disabledOverflowTooltip={true}
          intent="primary"
          size="small"
          visibleChipClassName="hover:bg-primary-softHovered hover:cursor-pointer active:bg-primary-softPressed"
        >
          <Plural one="# Occurrence" other="# Occurrences" value={instancesWithSource.length} />
          <RegrelloIcon iconName="chevron-down" size="x-small" />
        </RegrelloChip>
      </div>
    </RegrelloPopover>
  );
}
