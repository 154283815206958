import type { FieldInstanceBaseFields } from "./FieldInstanceBaseFields.generated";
import type { FieldInstanceSpectrumFields } from "./FieldInstanceSpectrumFields.generated";
import type {
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDate,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueDate,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString,
  FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime,
} from "./FieldInstanceValueWithCrossWorkflowFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueId?: number | null;
  values: Array<
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDate
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueDocument
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueFloat
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueInt
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueParty
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueRegrelloObjectInstance
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueString
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceMultiValueTime
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueBoolean
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueDate
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueFloat
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueInt
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueParty
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueString
    | FieldInstanceValueWithCrossWorkflowFieldsFieldInstanceValueTime
  >;
} & FieldInstanceBaseFields &
  FieldInstanceSpectrumFields;

export const FieldInstanceWithCrossWorkflowFields = gql`
    fragment FieldInstanceWithCrossWorkflowFields on FieldInstance {
  ...FieldInstanceBaseFields
  ...FieldInstanceSpectrumFields
  crossWorkflowSourceFieldInstanceValueId
  values {
    ...FieldInstanceValueWithCrossWorkflowFields
  }
}
    `;
