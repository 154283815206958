import { t } from "@lingui/core/macro";
import { assertNever, clsx, EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective, type FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";
import React, { useCallback } from "react";
import type { UseFormReturn } from "react-hook-form";

import { isOnSplitChild, isOnSplitterTask } from "../../../../../utils/customFields/splitFieldUtils";
import type { SpectrumFieldPluginDecorator } from "../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import { ConnectiveComponent } from "./RegrelloConnectiveForm";
import {
  type RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
  type ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import { RegrelloSingleConditionForm } from "./RegrelloSingleConditionForm";
import { LEFTMOST_LABEL_CLASSES } from "./scheduleTimeConstants";
import {
  type Condition,
  type ConditionGroup,
  makeEmptyConditionGroup,
  MAX_CONDITION_GROUP_NESTING_LEVEL,
} from "./utils";

export interface RegrelloConfigureConditionsRecursiveProps {
  /**
   * The field plugins allowed in the custom field instance select; i.e., the only field instances
   * that can be selected are those that can be processed by one of the field plugins in this array.
   */
  allowedFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>>;

  className?: string;

  /**
   * currentGroup is the branch in the tree that will be rendered.
   * Recursive calls will be made with the currentGroups.conditionGroups.
   */
  currentGroup: ConditionGroup;

  dependingOnStageIds: number[];

  /**
   * The form for configuring the schedule time of the stage or action item. Should have `mode`
   * set to 'all' for form validation to work properly. Note, because the condition group is
   * a complex object of arrays of objects and so on, one should not watch or set values in the form directly.
   * See updateGroup for more details.
   */
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

  /**
   * Whether the entire schedule time section is disabled.
   */
  isDisabled: boolean;

  /**
   * isRecursiveGroupsEnabled allows nesting groups within groups. Otherwise, only conditions/predicates can be configured.
   */
  isRecursiveGroupsEnabled: boolean;

  /**
   * onRemove is called to remove the currentGroup and all its descendants
   * from the form.
   */
  onRemove?: () => void;

  /**
   * prefix is potentially recursive, so it cannot be captured in types well.
   * This is essentially ScheduleTimeKeys.CONDITIONS recursively followed by "groups" and indices.
   * This prop should only be set by the recursive component when it makes recursive calls.
   */
  prefix?: string;

  // Whether to show an explanatory header above the conditional group.
  // onRemove must be specified if showHeader is specified. onRemoved
  // is a request to remove the whole condition group.
  showHeader: boolean;

  /**
   * The start condition schedule time option selected by the user. Only 2 options should render
   * this component, hence the stricter union type. If not defined, it's assumed this component is
   * being rendered as a standalone form section.
   */
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;

  /**
   * updateGroup should be called for any mutation to the currentGroup (except for removing, use onRemove.
   * To be clear, maintainers should use update group and not rely on setting fields directly within the form.
   * Setting fields directly breaks the immutability of the whole condition group, which can have unexpected
   * side effects. As updateGroup is passed to subsequent conditionGroups, it is wrapped so the updates are
   * applied to the appropriate level of the tree.
   */
  // TODO(workflows): The only exception to the "all mutations run through updateGroup" is the connective.
  // this works fine, but should be moved over for uniformity.
  updateGroup: (newGroup: ConditionGroup) => void;
  /**
   * Current workflow or workflow template context for the stage. Used to determine which field
   * instances to show users when selecting fields for start conditions.
   */
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
}

type GroupOrCondition = {
  kind: "group" | "condition";
  // idx is the index in the relative array.
  idx: number;
  // displayOrder is unique, and will often be equal to idx, but
  // it should not be assumed to be equal. Mixing creates and deletes
  // causes reuse of old idxs, but unique displayOrder values are awarded.
  displayOrder: number;
};

export const RegrelloConfigureConditionsRecursive = React.memo<RegrelloConfigureConditionsRecursiveProps>(
  function RegrelloConfigureConditionsRecursive(props: RegrelloConfigureConditionsRecursiveProps) {
    const {
      allowedFieldPlugins,
      isRecursiveGroupsEnabled,
      className,
      currentGroup,
      prefix,
      form,
      isDisabled,
      dependingOnStageIds,
      startConditionOption,
      workflowContext,
      showHeader,
      onRemove,
      updateGroup,
    } = props;
    const fullPrefix = (prefix ?? ScheduleTimeKeys.CONDITIONS) as ScheduleTimeKeys.CONDITIONS;
    const conditionGroups = currentGroup?.conditionGroups ?? EMPTY_ARRAY;
    const conditions = currentGroup?.conditions ?? EMPTY_ARRAY;

    const removeCondition = useCallback(
      (idx: number) => {
        const next: Condition[] = conditions.filter((_, index) => index !== idx);
        updateGroup({
          ...currentGroup,
          conditions: next,
        });
      },
      [conditions, currentGroup, updateGroup],
    );

    const updateCondition = useCallback(
      // biome-ignore lint/suspicious/noExplicitAny: any subset of the condition fields may be (un)set.
      (idx: number, value: any) => {
        const newVal = { ...value, displayOrder: conditions[idx].displayOrder };
        const next: Condition[] = conditions.map((v, index) => (idx === index ? newVal : v));
        updateGroup({
          ...currentGroup,
          conditions: next,
        });
      },
      [currentGroup, conditions, updateGroup],
    );

    const ordering: GroupOrCondition[] = [
      ...conditions.map((r, idx): GroupOrCondition => ({ kind: "condition", idx, displayOrder: r.displayOrder })),
      ...conditionGroups.map((r, idx): GroupOrCondition => ({ kind: "group", idx, displayOrder: r.displayOrder })),
    ].sort((a, b) => a.displayOrder - b.displayOrder);

    const nextDisplayOrder = ordering.length > 0 ? ordering[ordering.length - 1].displayOrder + 1 : 0;
    const appendCondition = useCallback(() => {
      const next: Condition[] = [
        ...conditions,
        {
          fieldInstance: null,
          operator: null,
          value1: null,
          value2: null,
          displayOrder: nextDisplayOrder,
        },
      ];
      updateGroup({
        ...currentGroup,
        conditions: next,
      });
    }, [currentGroup, conditions, updateGroup, nextDisplayOrder]);

    const appendGroup = useCallback(() => {
      const next: ConditionGroup[] = [...conditionGroups, makeEmptyConditionGroup(nextDisplayOrder)];
      updateGroup({
        ...currentGroup,
        conditionGroups: next,
      });
    }, [conditionGroups, currentGroup, updateGroup, nextDisplayOrder]);

    const removeGroup = useCallback(
      (toDelete: number) => {
        const next: ConditionGroup[] = conditionGroups.filter((_, index) => index !== toDelete);
        updateGroup({
          ...currentGroup,
          conditionGroups: next,
        });
      },
      [conditionGroups, currentGroup, updateGroup],
    );

    // eslint-disable-next-line lingui/no-unlocalized-strings
    const nestingLevel = fullPrefix.split("conditionGroups").length;
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const conditionsIndexPath = useCallback((index: number) => `${fullPrefix}.conditions.${index}`, [fullPrefix]);
    const connectivePath = `${fullPrefix}.connective` as `${ScheduleTimeKeys.CONDITIONS}.connective`;
    const connective = currentGroup.connective;
    // (wsheeha): Split fields can't be used for start conditions.
    const filterCondition = (fieldInstance: FieldInstanceFields) =>
      !isOnSplitterTask(fieldInstance) && !isOnSplitChild(fieldInstance);

    return (
      <div
        className={clsx(
          "flex flex-1 flex-col min-w-0 rounded mb-2",
          {
            "border border-neutral-borderInteractive shadow-sm": nestingLevel >= 2,
          },
          className,
        )}
      >
        {/* Header */}
        {showHeader ? (
          <div className="flex justify-between items-center rounded-t bg-backgroundSoft border-b border-dashed pr-2 py-1 pl-4">
            <RegrelloTypography muted={true}>
              {connective === ConditionConnective.AND
                ? t`All of the following are true:`
                : t`Any of the following is true:`}
            </RegrelloTypography>
            <RegrelloButton iconOnly={true} onClick={onRemove} startIcon="delete" variant="ghost" />
          </div>
        ) : null}

        {/* Condition rows */}
        <div className={clsx("flex flex-col min-w-0", { "p-2": nestingLevel >= 2 })}>
          {ordering.map((v, absIdx) => {
            switch (v.kind) {
              case "condition":
                return (
                  <RegrelloSingleConditionForm
                    key={v.displayOrder}
                    absoluteIndex={absIdx}
                    allowedFieldPlugins={allowedFieldPlugins}
                    condition={conditions[v.idx]}
                    connective={connective}
                    connectivePath={connectivePath}
                    dependingOnStageIds={dependingOnStageIds}
                    filterCondition={filterCondition}
                    form={form}
                    isDisabled={isDisabled}
                    prefix={conditionsIndexPath(v.idx)}
                    removeCondition={() => {
                      removeCondition(v.idx);
                    }}
                    startConditionOption={startConditionOption}
                    updateCondition={(newVal) => {
                      updateCondition(v.idx, newVal);
                    }}
                    workflowContext={workflowContext}
                  />
                );
              case "group":
                if (!isRecursiveGroupsEnabled) {
                  return null;
                }
                return (
                  <ConditionGroupRow
                    key={v.displayOrder}
                    {...props}
                    absoluteIndex={absIdx}
                    connective={connective}
                    connectivePath={connectivePath}
                    currentGroup={currentGroup.conditionGroups[v.idx]}
                    onRemove={() => {
                      removeGroup(v.idx);
                    }}
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    prefix={`${fullPrefix}.conditionGroups.${v.idx}`}
                    showHeader={true}
                    updateGroup={(newGroup) => {
                      const newGroups = currentGroup.conditionGroups.map((old, idx) =>
                        idx === v.idx ? newGroup : old,
                      );
                      updateGroup({
                        ...currentGroup,
                        conditionGroups: newGroups,
                      });
                    }}
                    workflowContext={workflowContext}
                  />
                );
              default:
                assertNever(v.kind);
            }
          })}

          {/* 'Add condition' buttons */}
          {/*
        // (zstanik): Add some arbitrary margin to make the add button line up with the field
        // instance selects.
        */}
          <div className="flex">
            <div className={clsx(LEFTMOST_LABEL_CLASSES, "ml-1")} />
            <RegrelloButton
              dataTestId={DataTestIds.START_DEPENDENCY_ADD_START_CONDITION_BUTTON}
              disabled={isDisabled}
              intent="primary"
              onClick={appendCondition}
              size="small"
              startIcon="add"
              variant="ghost"
            >
              {t`Add condition`}
            </RegrelloButton>
            {isRecursiveGroupsEnabled && (
              <RegrelloTooltip
                content={
                  nestingLevel === MAX_CONDITION_GROUP_NESTING_LEVEL
                    ? t`Condition groups can only be nested 3 levels deep`
                    : undefined
                }
              >
                <div>
                  <RegrelloButton
                    dataTestId={DataTestIds.START_DEPENDENCY_ADD_START_CONDITION_GROUP_BUTTON}
                    disabled={isDisabled || nestingLevel === MAX_CONDITION_GROUP_NESTING_LEVEL}
                    intent="primary"
                    onClick={appendGroup}
                    size="small"
                    startIcon="add"
                    variant="ghost"
                  >
                    {t`Add condition group`}
                  </RegrelloButton>
                </div>
              </RegrelloTooltip>
            )}
          </div>
        </div>
      </div>
    );
  },
);

// ConditionGroupRowProps is similar to RegrelloSingleConditionFormProps,
// which is more thoroughly documented.
type ConditionGroupRowProps = {
  absoluteIndex: number;
  connective: ConditionConnective;
  connectivePath: `${ScheduleTimeKeys.CONDITIONS}.connective`;
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;
  isDisabled: boolean;
} & RegrelloConfigureConditionsRecursiveProps;

/**
 * ConditionGroupRow is only intended to be called by RegrelloConfigureConditionsRecursive.
 * It exists only to make the recursive component less bloated.
 */
function ConditionGroupRow(props: ConditionGroupRowProps) {
  const { absoluteIndex, connectivePath, form, isDisabled, connective } = props;
  return (
    <div className="flex gap-1">
      <div className={clsx(LEFTMOST_LABEL_CLASSES)}>
        {absoluteIndex === 0 && <div className="pl-3 pt-3.5">{t`If`}</div>}
        {absoluteIndex === 1 && (
          <ConnectiveComponent connectivePath={connectivePath} form={form} isDisabled={isDisabled} />
        )}
        {absoluteIndex > 1 && (
          <div className="pl-3 pt-3.5">{connective === ConditionConnective.AND ? t`and` : t`or`}</div>
        )}
      </div>
      <RegrelloConfigureConditionsRecursive {...props} />
    </div>
  );
}
