import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldFields } from "./FieldFields.generated";
import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import type { FieldUnitFields } from "./FieldUnitFields.generated";
import type {
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDate,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString,
  AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueDate,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueInt,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueParty,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueString,
  AcyclicFieldInstanceValueFieldsFieldInstanceValueTime,
} from "./AcyclicFieldInstanceValueFields.generated";
import type { SpectrumFieldFields } from "./SpectrumFieldFields.generated";
import type { SpectrumFieldValidationTypeFields } from "./SpectrumFieldValidationTypeFields.generated";
import type { SpectrumFieldConstraintFields } from "./SpectrumFieldConstraintFields.generated";
import type { SpectrumFieldAllowedValueFields } from "./SpectrumFieldAllowedValueFields.generated";
import { gql } from "@apollo/client";
export type AcyclicFieldInstanceFields = {
  regrelloObjectPropertyId?: number | null;
  controlsActionItemOrActionItemTemplateField?: Types.ActionItemOrActionItemTemplateField | null;
  field: FieldFields;
  values: Array<
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDate
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString
    | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueDate
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueInt
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueParty
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueString
    | AcyclicFieldInstanceValueFieldsFieldInstanceValueTime
  >;
  spectrumFieldVersion?: {
    id: number;
    uuid: string;
    createdAt: string;
    name: string;
    helperText: string;
    description: string;
    internalName: string;
    versionNumber: number;
    spectrumField: SpectrumFieldFields;
    propertyType: PropertyTypeFields;
    validationType: SpectrumFieldValidationTypeFields;
    fieldConstraints: Array<SpectrumFieldConstraintFields>;
    allowedValues: Array<SpectrumFieldAllowedValueFields>;
    fieldUnit?: FieldUnitFields | null;
  } | null;
  actionItem?: {
    id: number;
    name: string;
    status: Types.ActionItemStatus;
    displayOrder: number;
    workflowReference?: { stageId: number; stageName: string; stageExecutionOrder: number } | null;
  } | null;
  actionItemTemplate?: { id: number; displayOrder: number; name: string } | null;
  workflow?: { id: number; name: string } | null;
  workflowTemplate?: { id: number; name: string } | null;
  writtenByActionItem?: {
    id: number;
    name: string;
    workflowReference?: { stageId: number; stageName: string; stageExecutionOrder: number } | null;
  } | null;
};

export const AcyclicFieldInstanceFields = gql`
    fragment AcyclicFieldInstanceFields on FieldInstance {
  field {
    ...FieldFields
  }
  values {
    ...AcyclicFieldInstanceValueFields
  }
  spectrumFieldVersion {
    id
    uuid
    createdAt
    name
    helperText
    description
    internalName
    versionNumber
    spectrumField {
      ...SpectrumFieldFields
    }
    propertyType {
      ...PropertyTypeFields
    }
    validationType {
      ...SpectrumFieldValidationTypeFields
    }
    fieldConstraints {
      ...SpectrumFieldConstraintFields
    }
    allowedValues {
      ...SpectrumFieldAllowedValueFields
    }
    fieldUnit {
      ...FieldUnitFields
    }
  }
  actionItem {
    id
    name
    status
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  actionItemTemplate {
    id
    displayOrder
    name
  }
  workflow {
    id
    name
  }
  workflowTemplate {
    id
    name
  }
  writtenByActionItem {
    id
    name
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  regrelloObjectPropertyId
  controlsActionItemOrActionItemTemplateField
}
    `;
