import type { AiAgentRunFields } from "./AIAgentRunFields.generated";
import type { RootCommentFields } from "./RootCommentFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import { gql } from "@apollo/client";
export type ActionItemAdditionalFields = {
  description?: string | null;
  aiAgentRuns: Array<AiAgentRunFields | null>;
  comments: Array<RootCommentFields>;
  documents: Array<DocumentFields>;
};

export const ActionItemAdditionalFields = gql`
    fragment ActionItemAdditionalFields on ActionItem {
  aiAgentRuns {
    ...AIAgentRunFields
  }
  comments {
    ...RootCommentFields
  }
  documents {
    ...DocumentFields
  }
  description
}
    `;
