import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import type {
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  NameTemplateFields,
  NameTemplateWithSuffixFields,
  UpdateNameTemplateInputs,
} from "@regrello/graphql-api";
import type { JSONContent } from "@tiptap/react";
import { v4 as uuidV4 } from "uuid";

import { retainDefaultFieldInstances } from "../../../../utils/customFields/customFieldTypeUtils";
import type { RegrelloConfigureCustomFieldsInputMappingForm } from "../../../views/modals/formDialogs/customFields/RegrelloConfigureCustomFieldsInputMappingForm";
import { CustomFieldPluginRegistrar } from "../../customFields/plugins/registry/customFieldPluginRegistrar";
import { RegrelloObjectFieldPlugin } from "../../customFields/plugins/RegrelloObjectFieldPlugin";
import type { InteractiveChipAttributes } from "../../textEditors/_internal/types/interactiveChipTypes";
import type { TextEditorFieldChipAttributesSpec } from "../components/RegrelloTextEditorFieldChip";
import type { NameTemplateFieldDisplayValue } from "../RegrelloNameTemplatePreviewWrapper";

/**
 * Describes the props available for options in the autocomplete component. Derived from the
 * attribute spec for the field chips used in the name template input.
 */
export type TextEditorFieldChipOption = InteractiveChipAttributes<TextEditorFieldChipAttributesSpec>;

/** Type to uniquely identify field chip nodes in the editor's JSON content. */
export const FIELD_CHIP_COMPONENT_NAME = "interactiveFieldChip";

/**
 * Ref exposed by the name template form field component to imperatively get name template inputs
 * from the current editor state.
 */
export type NameTemplateHandle = {
  /**
   * Get inputs from the current editor content for creating or updating a name template. Returns
   * undefined if the editor is rendered in read only mode or for a name template with a suffix.
   */
  getNameTemplateInputs: () => UpdateNameTemplateInputs | undefined;

  /**
   * Get the name template suffix from the current editor content. Returns undefined if the editor
   * is rendered for a name template without a suffix.
   */
  getNameTemplateSuffix: () => string | undefined;
};

/**
 * A version of name templates with the full `FieldFields` instead of IDs. Necessary for converting
 * from a string template to editor JSON content.
 */
export type FrontendNameTemplate = {
  stringTemplate: string;
  fields: FieldFields[];
  suffix?: string;
  nameTemplateFieldDisplayValues?: NameTemplateFieldDisplayValue[];
};

/** A utility type for rendering a string template as a preview. */
export type PreviewToken =
  | { key: string; type: "text"; text: string }
  | { key: string; type: typeof FIELD_CHIP_COMPONENT_NAME; props: TextEditorFieldChipOption };

// (zstanik): Regular expression that identifies field and Regrello object properties in string
// templates.
const FIELD_REFERENCE_REG_EXP_PATTERN =
  /\{\{\.fieldId(?<fieldId>[1-9]\d*)(?:\.regrelloObjectPropertyId(?<regrelloObjectPropertyId>[1-9]\d*))?\}\}/g;

/**
 * Returns the human-readable label for the provided field chip option. This should conventionally
 * be injected in place of the `fieldName`.
 */
export function getOptionLabel(option: TextEditorFieldChipOption): string {
  if (option.regrelloObjectPropertyId != null) {
    // It's a synced-object property. Format as "[object name] / [property name]"
    return `${option.fieldName} / ${option.regrelloObjectPropertyName}`;
  }
  return option.fieldName;
}

/**
 * Extracts a preview of the string template from the current editor state. Field chips are
 * replaced with the field name.
 */
export function getPreviewStringFromJson(json: JSONContent): string {
  const rootParagraph = maybeGetRootParagraphFromJson(json);
  if (rootParagraph == null) {
    return EMPTY_STRING;
  }
  return (
    rootParagraph.content
      ?.reduce((extractedText: string[], node) => {
        if (node.type === "text" && node.text != null) {
          extractedText.push(node.text);
        }
        if (node.type === FIELD_CHIP_COMPONENT_NAME && node.attrs != null) {
          // (zstanik): Necessary typecast as the underlying Tiptap types don't enumerate all the
          // `attrs` members even though it's guaranteed to be of this shape.
          const attrs = node.attrs as TextEditorFieldChipOption;
          extractedText.push(
            attrs.regrelloObjectPropertyName != null ? attrs.regrelloObjectPropertyName : attrs.fieldName,
          );
        }
        return extractedText;
      }, [])
      .join(EMPTY_STRING) ?? EMPTY_STRING
  );
}

/** Extracts inputs for creating or updating a name template from the editor JSON content. */
export function getNameTemplateInputsFromJson(json?: JSONContent): UpdateNameTemplateInputs | undefined {
  const rootParagraph = maybeGetRootParagraphFromJson(json);
  if (rootParagraph == null) {
    return undefined;
  }
  const rawInputs = rootParagraph.content?.reduce(
    (inputs: { extractedText: string[]; extractedFieldIds: number[] }, node) => {
      if (node.type === "text" && node.text != null) {
        inputs.extractedText.push(node.text);
      }
      if (node.type === FIELD_CHIP_COMPONENT_NAME && node.attrs != null) {
        const attrs = node.attrs as TextEditorFieldChipOption;
        const fieldIdReference = `.fieldId${attrs.fieldId}`;
        const maybeRegrelloObjectPropertyReference =
          attrs.regrelloObjectPropertyId != null
            ? `.regrelloObjectPropertyId${attrs.regrelloObjectPropertyId}`
            : EMPTY_STRING;
        inputs.extractedText.push(`{{${fieldIdReference}${maybeRegrelloObjectPropertyReference}}}`);
        inputs.extractedFieldIds.push(attrs.fieldId);
      }
      return inputs;
    },
    { extractedText: [], extractedFieldIds: [] },
  );
  const stringTemplate = (rawInputs?.extractedText.join(EMPTY_STRING) ?? EMPTY_STRING).trim();
  return stringTemplate !== EMPTY_STRING
    ? {
        stringTemplate,
        fieldIds: rawInputs?.extractedFieldIds ?? EMPTY_ARRAY,
      }
    : undefined;
}

/**
 * Extracts the currently in use field IDs and Regrello object property IDs from the provided editor
 * JSON.
 */
export function getIdsFromEditorJson(json: JSONContent): [Set<number>, Set<number>] {
  const fieldIds = new Set<number>();
  const regrelloObjectPropertyIds = new Set<number>();
  const options: TextEditorFieldChipOption[] = [];
  const rootParagraph = maybeGetRootParagraphFromJson(json);
  if (rootParagraph == null) {
    return [fieldIds, regrelloObjectPropertyIds];
  }
  rootParagraph.content?.forEach((node) => {
    if (node.type === FIELD_CHIP_COMPONENT_NAME && node.attrs != null) {
      const option = node.attrs as TextEditorFieldChipOption;
      options.push(option);
      fieldIds.add(option.fieldId);
      if (option.regrelloObjectPropertyId != null) {
        regrelloObjectPropertyIds.add(option.regrelloObjectPropertyId);
      }
    }
  });
  return [fieldIds, regrelloObjectPropertyIds];
}

function maybeGetRootParagraphFromJson(json?: JSONContent): JSONContent | undefined {
  return json?.content != null && json.content.length > 0 && json.content[0].type === "paragraph"
    ? json.content[0]
    : undefined;
}

/** Converts the provided name template into JSON to load into the editor. */
export function getJsonFromNameTemplate(nameTemplate: FrontendNameTemplate): JSONContent {
  const fieldIdToFieldMap = new Map<number, FieldFields>();
  nameTemplate.fields?.forEach((field) => fieldIdToFieldMap.set(field.id, field));
  // (zstanik): Find all field reference matches in the string template.
  const matches = [...nameTemplate.stringTemplate.matchAll(FIELD_REFERENCE_REG_EXP_PATTERN)];
  const jsonContext = matches.reduce(
    (context: { content: JSONContent[]; lastIndex: number }, match) => {
      const fullMatchLength = match.length > 0 ? match[0].length : 0;
      const maybeFieldId = !Number.isNaN(Number(match.groups?.fieldId)) ? Number(match.groups?.fieldId) : undefined;
      const maybeRegrelloObjectPropertyId = !Number.isNaN(Number(match.groups?.regrelloObjectPropertyId))
        ? Number(match.groups?.regrelloObjectPropertyId)
        : undefined;
      // If there was plaintext between this field reference and the last, add a text node to the
      // content.
      if (match.index != null && context.lastIndex < match.index) {
        context.content.push({
          type: "text",
          text: nameTemplate.stringTemplate.substring(context.lastIndex, match.index),
        });
      }
      if (maybeFieldId != null && fieldIdToFieldMap.has(maybeFieldId)) {
        const field = fieldIdToFieldMap.get(maybeFieldId);
        const plugin = field != null ? CustomFieldPluginRegistrar.getPluginForField(field) : undefined;
        const fieldName = field?.name ?? EMPTY_STRING;
        const maybeRegrelloObjectPropertyName =
          field?.regrelloObject?.properties.find((property) => property.id === maybeRegrelloObjectPropertyId)
            ?.displayName ?? undefined;
        const fieldChipAttrs: TextEditorFieldChipOption = {
          fieldId: maybeFieldId,
          fieldName,
          regrelloObjectPropertyId: maybeRegrelloObjectPropertyId,
          regrelloObjectPropertyName: maybeRegrelloObjectPropertyName,
          iconName: maybeRegrelloObjectPropertyId != null ? "text-field" : plugin?.getIconName(field?.fieldType, field),
        };
        // Add a field chip to the content with the appropriate attributes, plus a uuid so the chip
        // can be uniquely identified for DnD.
        context.content.push({ type: FIELD_CHIP_COMPONENT_NAME, attrs: { ...fieldChipAttrs, uuid: uuidV4() } });
      }
      // Update `lastIndex` so the next span of plaintext can be extracted.
      return { content: context.content, lastIndex: (match.index ?? 0) + fullMatchLength };
    },
    { content: [], lastIndex: 0 },
  );

  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content:
          // If the last field chip wasn't the last node in the content, then add the remaining
          // plaintext.
          jsonContext.lastIndex < nameTemplate.stringTemplate.length
            ? [
                ...jsonContext.content,
                { type: "text", text: nameTemplate.stringTemplate.substring(jsonContext.lastIndex) },
              ]
            : jsonContext.content,
      },
    ],
  };
}

/** Converts the provided name template to tokens for rendering a preview of the string template. */
export function getPreviewTokensFromNameTemplate(nameTemplate: FrontendNameTemplate): PreviewToken[] {
  const json = getJsonFromNameTemplate(nameTemplate);
  const rootParagraph = maybeGetRootParagraphFromJson(json);
  if (rootParagraph == null) {
    return EMPTY_ARRAY;
  }
  return (
    rootParagraph.content?.reduce((arr: PreviewToken[], node) => {
      if (node.type === FIELD_CHIP_COMPONENT_NAME && node.attrs != null) {
        arr.push({ key: uuidV4(), type: FIELD_CHIP_COMPONENT_NAME, props: node.attrs as TextEditorFieldChipOption });
      }
      if (node.type === "text" && node.text != null) {
        arr.push({ key: uuidV4(), type: "text", text: node.text });
      }
      return arr;
    }, []) ?? EMPTY_ARRAY
  );
}

/**
 * Extracts display values from the provided field instances. Useful for initializing the display
 * values in a name template input when there are field instances with preexisting values.
 */
export function getNameTemplateFieldDisplayValuesFromFieldInstances(
  fieldInstances: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>,
): NameTemplateFieldDisplayValue[] {
  return fieldInstances.flatMap((fieldInstance) => {
    const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);
    const isFieldInstanceEmpty = customFieldPlugin.isFieldInstanceEmpty(fieldInstance);
    if (fieldInstance.field.regrelloObject != null) {
      return fieldInstance.field.regrelloObject.properties.map((regrelloObjectProperty) => {
        return {
          fieldId: fieldInstance.field.id,
          regrelloObjectPropertyId: regrelloObjectProperty.id,
          displayValue: isFieldInstanceEmpty
            ? undefined
            : customFieldPlugin.getNameTemplateDisplayValueFromFormValue(
                customFieldPlugin.getValueForFrontend(fieldInstance),
                { regrelloObjectProperty },
              ),
        };
      });
    }
    return {
      fieldId: fieldInstance.field.id,
      displayValue: isFieldInstanceEmpty
        ? undefined
        : customFieldPlugin.getNameTemplateDisplayValueFromFormValue(
            customFieldPlugin.getValueForFrontend(fieldInstance),
            { fieldUnit: fieldInstance.spectrumFieldVersion?.fieldUnit ?? fieldInstance.field.fieldUnit ?? undefined },
          ),
    };
  });
}

/**
 * Returns updated name template field display values from the provided field instance and inputted
 * value (which should be of the type expected by that field instance's form field).
 */
export function getNewNameTemplateFieldDisplayValuesFromFieldInstanceUpdate(
  nameTemplateFieldDisplayValues: NameTemplateFieldDisplayValue[],
  fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  value: unknown,
): NameTemplateFieldDisplayValue[] {
  const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);
  return nameTemplateFieldDisplayValues.reduce((arr: NameTemplateFieldDisplayValue[], fieldDisplayValue) => {
    if (fieldDisplayValue.fieldId === fieldInstance.field.id) {
      if (fieldDisplayValue.regrelloObjectPropertyId != null && fieldInstance.field.regrelloObject != null) {
        const regrelloObjectProperty = fieldInstance.field.regrelloObject.properties.find(
          (property) => property.id === fieldDisplayValue.regrelloObjectPropertyId,
        );
        const newRegrelloObjectPropertyDisplayValue =
          regrelloObjectProperty != null
            ? customFieldPlugin.getNameTemplateDisplayValueFromFormValue(value, { regrelloObjectProperty })
            : undefined;
        arr.push({
          ...fieldDisplayValue,
          displayValue:
            newRegrelloObjectPropertyDisplayValue === EMPTY_STRING ? undefined : newRegrelloObjectPropertyDisplayValue,
        });
        return arr;
      }
      const newFieldDisplayValue = customFieldPlugin.getNameTemplateDisplayValueFromFormValue(value, {
        fieldUnit: fieldInstance.spectrumFieldVersion?.fieldUnit ?? fieldInstance.field.fieldUnit ?? undefined,
      });
      arr.push({
        ...fieldDisplayValue,
        displayValue: newFieldDisplayValue === EMPTY_STRING ? undefined : newFieldDisplayValue,
      });
      return arr;
    }
    arr.push(fieldDisplayValue);
    return arr;
  }, []);
}

/**
 * Converts values coming from a `RegrelloConfigureCustomFieldsInputMappingForm` into
 * `NameTemplateFieldDisplayValue`s, to use in filling in the name template preview as field
 * instances are mapped to their inputs.
 */
export function getFieldDisplayValuesFromCustomFieldsInputMappingFormInputs(
  values: RegrelloConfigureCustomFieldsInputMappingForm.Fields["inputs"] | null,
): NameTemplateFieldDisplayValue[] {
  return values != null
    ? values.reduce<NameTemplateFieldDisplayValue[]>(
        (fieldDisplayValueArr, { sourceFieldInstance, destinationFieldInstance }) => {
          if (
            sourceFieldInstance != null &&
            destinationFieldInstance != null &&
            // Don't add a display value for synced object fields as those will always display the
            // name of the selected Regrello object property name. This is default behavior when
            // converting from display values to preview string.
            !RegrelloObjectFieldPlugin.canProcessFieldInstance(destinationFieldInstance)
          ) {
            fieldDisplayValueArr.push({
              fieldId: destinationFieldInstance.field.id,
              displayValue: sourceFieldInstance.spectrumFieldVersion?.name,
            });
          }
          return fieldDisplayValueArr;
        },
        [],
      )
    : EMPTY_ARRAY;
}

/**
 * Converts an existing `NameTemplate` and field instances into a `FrontendNameTemplate`, a utility
 * type that makes it easier to pass name templates around on the FE.
 */
export function getFrontendNameTemplateFromNameTemplateAndAllFieldInstances(
  nameTemplate: NameTemplateFields | NameTemplateWithSuffixFields,
  allFieldInstances: FieldInstanceFields[],
): FrontendNameTemplate {
  const nameTemplateFieldIdSet = new Set(nameTemplate.fieldIds);
  return {
    stringTemplate: nameTemplate.stringTemplate,
    fields: retainDefaultFieldInstances(allFieldInstances)
      .filter((fieldInstance) => nameTemplateFieldIdSet.has(fieldInstance.field.id))
      .map((fieldInstance) => fieldInstance.field),
    suffix: "suffix" in nameTemplate && nameTemplate.suffix != null ? nameTemplate.suffix : undefined,
  };
}

/**
 * Converts the provided frontend name template and field display values into a preview string for
 * rendering what the string template would look like with the display values injected.
 */
export function getPreviewStringFromNameTemplateFieldDisplayValuesAndSuffix(
  frontendNameTemplate: FrontendNameTemplate,
  nameTemplateFieldDisplayValues: NameTemplateFieldDisplayValue[],
  suffix: string | undefined,
): string {
  const fieldIdToFieldMap = new Map<number, FieldFields>();
  const fieldIdToDisplayValueMap = new Map<number, string>();
  frontendNameTemplate.fields.forEach((field) => fieldIdToFieldMap.set(field.id, field));
  nameTemplateFieldDisplayValues.forEach(({ fieldId, displayValue }) => {
    if (displayValue != null && displayValue !== EMPTY_STRING) {
      fieldIdToDisplayValueMap.set(fieldId, displayValue);
    }
  });

  // (zstanik): Find all field reference matches in the string template.
  const matches = [...frontendNameTemplate.stringTemplate.matchAll(FIELD_REFERENCE_REG_EXP_PATTERN)];
  const { previewString: previewStringWithoutSuffix, lastIndex } = matches.reduce(
    (context: { previewString: string; lastIndex: number }, match) => {
      const fullMatchLength = match.length > 0 ? match[0].length : 0;
      const maybeFieldId = !Number.isNaN(Number(match.groups?.fieldId)) ? Number(match.groups?.fieldId) : undefined;
      const maybeRegrelloObjectPropertyId = !Number.isNaN(Number(match.groups?.regrelloObjectPropertyId))
        ? Number(match.groups?.regrelloObjectPropertyId)
        : undefined;

      // If there was plaintext between this field reference and the last, add text to the end of
      // the preview string.
      if (match.index != null && context.lastIndex < match.index) {
        context.previewString = `${context.previewString}${frontendNameTemplate.stringTemplate.substring(
          context.lastIndex,
          match.index,
        )}`;
      }
      if (maybeFieldId != null) {
        if (fieldIdToDisplayValueMap.has(maybeFieldId)) {
          // Append to the preview string the display value if it exists because that takes highest
          // priority.
          context.previewString = `${context.previewString}${fieldIdToDisplayValueMap.get(maybeFieldId)}`;
        } else if (fieldIdToFieldMap.has(maybeFieldId)) {
          const field = fieldIdToFieldMap.get(maybeFieldId);
          const fieldName = field?.name ?? EMPTY_STRING;
          const maybeRegrelloObjectPropertyName =
            field?.regrelloObject?.properties.find((property) => property.id === maybeRegrelloObjectPropertyId)
              ?.displayName ?? undefined;

          // Append to the preview string the Regrello object property name if it exists, otherwise
          // the field name.
          context.previewString = `${context.previewString}${maybeRegrelloObjectPropertyName ?? fieldName}`;
        }
      }

      // Update `lastIndex` so the next span of plaintext can be extracted.
      return { previewString: context.previewString, lastIndex: (match.index ?? 0) + fullMatchLength };
    },
    { previewString: "", lastIndex: 0 },
  );

  const adjustedPreviewStringWithoutSuffix =
    lastIndex < frontendNameTemplate.stringTemplate.length
      ? `${previewStringWithoutSuffix}${frontendNameTemplate.stringTemplate.substring(lastIndex)}`
      : previewStringWithoutSuffix;

  return `${adjustedPreviewStringWithoutSuffix}${suffix ?? EMPTY_STRING}`;
}
