import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceMultiValueIntBaseFields = {
  __typename: "FieldInstanceMultiValueInt";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  intMultiValue: Array<number>;
  tabularMultiValueInt: Array<{ rowNumber: number; value: Array<number> }>;
};

export const FieldInstanceMultiValueIntBaseFields = gql`
    fragment FieldInstanceMultiValueIntBaseFields on FieldInstanceMultiValueInt {
  __typename
  id
  inputType
  intMultiValue
  tabularMultiValueInt {
    rowNumber
    value
  }
}
    `;
