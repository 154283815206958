import { clsx } from "@regrello/core-utils";
import { RegrelloIcon, RegrelloTooltippedText, RegrelloTypography } from "@regrello/ui-core";
import React from "react";

import { RegrelloFormFieldSelectOptionContainer } from "./RegrelloFormFieldSelectOptionContainer";

// (clewis): This type is taken directly from the autohiglight/parse library and simply renamed.
export type RegrelloFormFieldSelectOptionSnippet = {
  text: string;
  highlight: boolean;
  emphasized?: boolean;
};

export interface RegrelloFormFieldSelectOptionProps {
  dataTestId?: string;

  /** Matched snippets that will be displayed as secondary text beneath the main text. */
  detailSnippets?: RegrelloFormFieldSelectOptionSnippet[];

  /** Whether this option is currently selected and should display as such. */
  isSelected?: boolean;

  /** Matched snippets that will be displayed as the main text of the option. */
  mainSnippets: RegrelloFormFieldSelectOptionSnippet[];

  startAdornment?: React.ReactNode;
}

/**
 * A standard "select" menu option that highlights the specified snippets and shows a selection state
 * if needed.
 */
export const RegrelloFormFieldSelectOption = React.memo<RegrelloFormFieldSelectOptionProps>(
  function RegrelloFormFieldSelectOptionFn({ dataTestId, detailSnippets, isSelected, mainSnippets, startAdornment }) {
    return (
      <RegrelloFormFieldSelectOptionContainer dataTestId={dataTestId}>
        {startAdornment}
        <div className="grow hyphens-auto break-words">
          <RegrelloTypography component="div" variant="body">
            <RegrelloTooltippedText lines={2}>
              {mainSnippets.map((part, index) => (
                <span
                  key={`main-snippet-${index}`}
                  className={clsx({ "font-semibold": part.highlight, "text-danger-solid": part.emphasized })}
                >
                  {part.text}
                </span>
              ))}
            </RegrelloTooltippedText>
          </RegrelloTypography>

          {detailSnippets != null && (
            <div className="break-words text-textPlaceholder">
              <RegrelloTypography variant="body-xs">
                {detailSnippets.map((part, index) => (
                  <span
                    key={`detail-snippet-${index}`}
                    className={clsx({ "font-semibold": part.highlight, "text-danger-solid": part.emphasized })}
                  >
                    {part.text}
                  </span>
                ))}
              </RegrelloTypography>
            </div>
          )}
        </div>

        {isSelected && <RegrelloIcon className="shrink-0 text-textDefault" iconName="selected" />}
      </RegrelloFormFieldSelectOptionContainer>
    );
  },
);
