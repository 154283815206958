import { t } from "@lingui/core/macro";
import { EMPTY_STRING, formatDateString } from "@regrello/core-utils";
import {
  type ActionItemDetailFields,
  type FieldInstanceFields,
  type FieldInstanceWithCrossWorkflowFields,
  WorkflowScheduleStatus,
} from "@regrello/graphql-api";

import { retainRequestedAndOptionalFieldInstances } from "../../../../../utils/customFields/customFieldInputTypeUtils";
import { RegrelloPermissions } from "../../../../app/authorization/permissions";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";

export enum LinkedWorkflowProblemReason {
  MISSING_INFORMATION_TO_START = "missingInformationToStart",
  OUTPUT_FIELD_DELETED = "outputFieldDeleted",
  UNABLE_TO_START = "unableToStart",
  WORKFLOW_DELETED = "workflowDeleted",
  DEFAULT = "default",
}

/**
 * Determines the reason why a linked workflow action item is in a problem state, and returns any
 * associated text to display with the task status icon.
 */
export function getReasonForLinkedWorkflowProblem(
  actionItem: Pick<ActionItemDetailFields, "fieldInstances"> & {
    linkedWorkflowReference?:
      | (Pick<
          NonNullable<ActionItemDetailFields["linkedWorkflowReference"]>,
          | "completedAt"
          | "daysDelayed"
          | "deletedAt"
          | "endDate"
          | "fieldInstances"
          | "id"
          | "permissions"
          | "scheduleStatus"
        > & { startedAt?: string | null })
      | null;
  },
): {
  problemReason: LinkedWorkflowProblemReason;
  text: React.ReactNode;
  tooltipText: string;
} {
  if (actionItem.linkedWorkflowReference == null) {
    return {
      text: t`Workflow could not be created`,
      tooltipText: EMPTY_STRING,
      problemReason: LinkedWorkflowProblemReason.DEFAULT,
    };
  }
  if (actionItem.linkedWorkflowReference.deletedAt != null) {
    return {
      text: `${t`Workflow has been deleted`} - ${formatDateString(actionItem.linkedWorkflowReference.deletedAt)}`,
      tooltipText: EMPTY_STRING,
      problemReason: LinkedWorkflowProblemReason.WORKFLOW_DELETED,
    };
  }
  if (actionItem.linkedWorkflowReference.startedAt == null) {
    const isSomeRequiredInputFieldInstanceMissing =
      getLinkedWorkflowFieldInstancesNeedingValues(actionItem.linkedWorkflowReference).length > 0;
    return isSomeRequiredInputFieldInstanceMissing
      ? {
          text: getProblemReportedText(t`Information not available to start linked workflow`),
          tooltipText: EMPTY_STRING,
          problemReason: LinkedWorkflowProblemReason.MISSING_INFORMATION_TO_START,
        }
      : {
          text: getProblemReportedText(t`Unable to start linked workflow`),
          tooltipText:
            RegrelloPermissions.Workflow.canView(actionItem.linkedWorkflowReference) &&
            actionItem.linkedWorkflowReference.deletedAt == null
              ? EMPTY_STRING
              : t`You do not have sufficient permissions to view the linked workflow`,
          problemReason: LinkedWorkflowProblemReason.UNABLE_TO_START,
        };
  }
  if (actionItem.linkedWorkflowReference?.scheduleStatus === WorkflowScheduleStatus.COMPLETED) {
    const isSomeRequiredOutputFieldInstanceMissing = retainRequestedAndOptionalFieldInstances(
      actionItem.fieldInstances,
    ).some((fieldInstance) => getIsOutputFieldInstanceDeletedFromWorkflow(fieldInstance));
    if (isSomeRequiredOutputFieldInstanceMissing) {
      return {
        text: getProblemReportedText(t`Field removed`),
        tooltipText: EMPTY_STRING,
        problemReason: LinkedWorkflowProblemReason.OUTPUT_FIELD_DELETED,
      };
    }
  }
  return {
    text: t`Problem reported on workflow`,
    tooltipText: EMPTY_STRING,
    problemReason: LinkedWorkflowProblemReason.DEFAULT,
  };
}

export function getLinkedWorkflowFieldInstancesNeedingValues(
  linkedWorkflow: Pick<NonNullable<ActionItemDetailFields["linkedWorkflowReference"]>, "fieldInstances">,
): FieldInstanceFields[] {
  return retainRequestedAndOptionalFieldInstances(linkedWorkflow.fieldInstances).filter((fieldInstance) =>
    CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).isFieldInstanceEmpty(fieldInstance),
  );
}

export function getIsOutputFieldInstanceDeletedFromWorkflow(
  fieldInstance: FieldInstanceWithCrossWorkflowFields,
): boolean {
  // (zstanik): Per how the BE treats deleted field logic, output fields that were deleted
  // from a running workflow have their base `crossWorkflowSourceFieldInstanceValueId` set but
  // not the source field instance value itself. The base field gets cleared when a user
  // resolves the problem by acknowledging it.
  return (
    CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getCrossWorkflowSourceFieldInstanceIdFromValue(
      fieldInstance,
    ) == null && fieldInstance.crossWorkflowSourceFieldInstanceValueId != null
  );
}

function getProblemReportedText(warning: string) {
  return (
    <>
      <strong>{t`Problem reported`}</strong> ({warning.toLocaleLowerCase()})
    </>
  );
}
