import { t } from "@lingui/core/macro";
import { getEmailDomain } from "@regrello/core-utils";
import {
  RegrelloFullColorLogoMarkIcon,
  RegrelloIconStyler,
  RegrelloLinkV2,
  RegrelloTypography,
} from "@regrello/ui-core";
import throttle from "lodash/throttle";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import {
  REGRELLO_PRIVACY_POLICY_URL,
  REGRELLO_SUPPORT_EMAIL,
  ValidationRules,
} from "../../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloRedirectingSpinner } from "./_internal/RegrelloRedirectingSpinner";

const SUBMIT_THROTTLE_IN_MILLISECONDS = 1000;
const ARTIFICIAL_DELAY = 1500;

export const UnauthenticatedSignInSsoPage = React.memo(function UnauthenticatedSignInSsoPageFn() {
  const form = useForm();
  const { loading, login } = useRegrelloAuthV2();

  const throttledOnContinueWithSso = useMemo(
    () =>
      throttle(async (connectionName: string) => {
        await login(
          {
            connection: connectionName,
            email: form.getValues().email,
            password: form.getValues().password,
          },
          ARTIFICIAL_DELAY,
        );
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [login, form],
  );

  const onEmailChange = useCallback(async () => {
    const { email: currentEmail } = form.getValues();

    const domain = getEmailDomain(currentEmail);

    // (krashanoff): Don't start searching on keystroke until it's a
    // sufficiently long domain. This is an arbitrary value.
    if (domain.length < 5) {
      return;
    }

    const result = await RegrelloRestApiService.isSso(domain);
    if (result.json.isSso && !result.json.isSsoOptional) {
      await throttledOnContinueWithSso(result.json.connectionName);
    }
  }, [form, throttledOnContinueWithSso]);

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col w-150 px-12 pb-4">
        <div className="flex flex-col flex-1 justify-center gap-5">
          <RegrelloIconStyler size="x-large">
            <RegrelloFullColorLogoMarkIcon />
          </RegrelloIconStyler>

          {loading && <RegrelloRedirectingSpinner message={t`Redirecting to your company's sign in page`} />}

          <RegrelloTypography variant="h1">{t`Sign in with SSO`}</RegrelloTypography>
          <RegrelloTypography variant="body" weight="semi-bold">
            {t`If your company requires you to use single sign-on (SSO), enter your work email below and we'll
            redirect you to their sign in page.`}
          </RegrelloTypography>
          <RegrelloTypography className="mb-5">
            {t`TIP: Entering your email on the Regrello sign page will redirect you automatically.`}
          </RegrelloTypography>

          <form onChange={onEmailChange}>
            <RegrelloControlledFormFieldText
              autoFocus={true}
              className="w-1/2 mb-5"
              controllerProps={{
                control: form.control,
                name: "email",
                rules: {
                  ...ValidationRules.VALID_EMAIL,
                },
              }}
              disabled={loading}
              isDefaultMarginsOmitted={true}
              placeholder={t`Email`}
            />
          </form>

          <div>
            <RegrelloLinkV2 to={RoutePaths.LOGIN}>{t`Back to sign in`}</RegrelloLinkV2>
          </div>

          <RegrelloTypography>
            {t`Having trouble? Contact us at `}{" "}
            <RegrelloLinkV2 to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>{REGRELLO_SUPPORT_EMAIL}</RegrelloLinkV2>
          </RegrelloTypography>
        </div>
        <RegrelloTypography className="text-center flex gap-6 justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="hover:underline" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});
