import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValuePartyBaseFields = {
  __typename: "FieldInstanceMultiValueParty";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  partyMultiValue: Array<PartyBaseFields>;
  tabularMultiValueParty: Array<{ rowNumber: number; value: Array<number> }>;
};

export const FieldInstanceMultiValuePartyBaseFields = gql`
    fragment FieldInstanceMultiValuePartyBaseFields on FieldInstanceMultiValueParty {
  __typename
  id
  inputType
  partyMultiValue {
    ...PartyBaseFields
  }
  tabularMultiValueParty {
    rowNumber
    value
  }
}
    `;
