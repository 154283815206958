import type { ApolloError, LazyQueryResult, OperationVariables, QueryResult } from "@apollo/client";
import { useMemo } from "react";

import { AsyncLoaded } from "../typescript/AsyncLoaded";

/**
 * Wraps the provided Apollo GraphQL query result in a memoized `AsyncLoaded` value.
 *
 * __WARNING:__ This hook ignores referential changes to `transformData` to avoid excessive
 * re-renders when passing in an anonymous function (e.g. `(data) => data.myValue`).
 */
export function useMemoizedAsyncLoadedValue<
  TData,
  TTransformedData = TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  queryResult: QueryResult<TData, TVariables> | LazyQueryResult<TData, TVariables>,
  transformData?: (data: TData) => TTransformedData,
): AsyncLoaded<TTransformedData, ApolloError> {
  // biome-ignore lint/correctness/useExhaustiveDependencies: See comment below.
  const asyncLoadedQueryResult = useMemo(
    () => AsyncLoaded.fromGraphQlQueryResult(queryResult, transformData),
    // (clewis): Intentionally omit `transformData` from the dependency array to prevent
    // unnecessary re-renders. The only pattern we support is to pass in a constant function
    // (e.g. `() => myValue`).
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryResult],
  );
  return asyncLoadedQueryResult;
}
