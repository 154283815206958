import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceMultiValueStringBaseFields = {
  __typename: "FieldInstanceMultiValueString";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  stringMultiValue: Array<string>;
  tabularMultiValueString: Array<{ rowNumber: number; value: Array<string> }>;
};

export const FieldInstanceMultiValueStringBaseFields = gql`
    fragment FieldInstanceMultiValueStringBaseFields on FieldInstanceMultiValueString {
  __typename
  id
  inputType
  stringMultiValue
  tabularMultiValueString {
    rowNumber
    value
  }
}
    `;
